import React, {useContext, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import PayrollHeader from '../components/Organisms/Headers/PayrollHeader'
import SummaryPayrollPanel from '../components/Organisms/Panels/SummaryPayrollPanel'
import ProcessPanel from '../components/Organisms/Panels/ProcessPanel'
import NotesPayrollPanel from '../components/Organisms/Panels/NotesPayrollPanel'
import TabsWrapper from '../components/Wrappers/TabsWrapper'
import PanelTemplate from '../components/Wrappers/PanelTemplate'
import TabTemplate from '../components/Atoms/Templates/TabTemplate'
import {useMedia} from '../hooks/useMedia'
import {mediaFromLg} from '../helpers/media-queries'
import {PayrollContext} from '../contexts/contexts'
import {getPayrollDashboardByYearAndMonth} from '../services/payrolldashboard'
import PayrollDashboardSkeleton from '../components/Organisms/Skeleton/PayrollDashboardSkeleton'
import {isNull, isUndefined, len} from '../helpers/utils'
import {useSelector} from 'react-redux'
import {selectors as userSelectors} from '../redux/ducks/user'
import {selectors as employerMonthSelector} from '../redux/ducks/employerMonth'
import {redirectRoute} from '../config/routes'
import PeriodPanel from '../components/Organisms/Panels/PeriodsPanel'

const PayrollDashboard = () => {
  const fromLg = useMedia(mediaFromLg)
  const user = useSelector(userSelectors.get)
  const activeMonth = useSelector(employerMonthSelector.active)

  const history = useHistory()

  const [dashboardData, setDashboardData] = useState(null)
  const {payrollDate} = useContext(PayrollContext)
  const [maxMonth, setMaxMonth] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isUndefined(activeMonth)) return

    setMaxMonth({year: activeMonth.year_number, month: activeMonth.month_number})

    // clean up after this effect (unmount component):
    return function cleanup() {
      setMaxMonth(null)
    }
  }, [activeMonth])

  useEffect(() => {
    setIsLoading(true)

    if (!payrollDate?.year || !payrollDate?.month) return

    getPayrollDashboardByYearAndMonth(payrollDate.year, payrollDate.month, data => {
      if (len(data?.employer_month?.errors) > 0) {
        history.push(redirectRoute.privateEmployer)
        return
      }

      setDashboardData(data)
      setIsLoading(false)
    })

    return function cleanupDashboard() {
      setDashboardData(null)
    }
  }, [payrollDate])

  const handleAddNote = note => {
    note.name = user.name
    note.picture = user.picture

    if (isNull(dashboardData.notes.data)) {
      setDashboardData({
        ...dashboardData,
        notes: {...dashboardData.notes, data: [note]},
      })
      return
    }

    setDashboardData({
      ...dashboardData,
      notes: {...dashboardData.notes, data: [...dashboardData.notes.data, note]},
    })
  }

  const handleEditNote = editedNote => {
    let notes = dashboardData.notes.data.filter(note => note.id !== editedNote.id)
    notes.push(editedNote)
    notes.sort((a, b) => a.id - b.id)

    setDashboardData({
      ...dashboardData,
      notes: {...dashboardData.notes, data: [...notes]},
    })
  }

  const handleDeleteNote = id => {
    setDashboardData({
      ...dashboardData,
      notes: {
        ...dashboardData.notes,
        data: [...dashboardData.notes.data.filter(note => note.id !== id)],
      },
    })
  }

  return isLoading || isNull(payrollDate) || isNull(dashboardData) || isNull(maxMonth) ? (
    <PayrollDashboardSkeleton />
  ) : (
    <div className="ed-grid row-gap">
      <PayrollHeader
        maxMonth={maxMonth}
        employerMonthID={dashboardData?.employer_month?.data.id}
        isActiveMonth={dashboardData?.employer_month?.data.is_active}
        isMigratedMonth={dashboardData?.employer_month?.data.is_migrated}
        periods={dashboardData?.pay_periods?.data}
      />
      {/* Contenido en computadoras */}

      {fromLg ? (
        <div className="ed-grid lg-grid-2 row-gap">
          <div className="ed-grid row-gap s-cross-start">
            <SummaryPayrollPanel summary={dashboardData?.summary?.data} />
            <NotesPayrollPanel
              notes={dashboardData?.notes?.data}
              employerMonthID={dashboardData?.employer_month?.data?.id}
              onAdd={handleAddNote}
              onDelete={handleDeleteNote}
              onEdit={handleEditNote}
              userID={user.id}
            />
          </div>
          <div className="ed-grid row-gap s-cross-start">
            <PeriodPanel
              periods={dashboardData?.pay_periods?.data}
              processes={dashboardData?.payroll_processes?.data}
              setDashboardData={setDashboardData}
            />
            <ProcessPanel
              isActiveMonth={dashboardData?.employer_month?.data.is_active}
              periods={dashboardData?.pay_periods?.data}
              processes={dashboardData?.payroll_processes?.data}
              setDashboardData={setDashboardData}
            />
          </div>
        </div>
      ) : (
        // Contenido en tabletas y celulares
        <div className="ed-grid row-gap">
          <TabsWrapper>
            <div className="tab-container s-cross-center">
              <TabTemplate index={0} className="tab-item">
                Resumen
              </TabTemplate>
              <TabTemplate index={1} className="tab-item">
                Notas
              </TabTemplate>
              <TabTemplate index={2} className="tab-item">
                Periodos
              </TabTemplate>
            </div>
            <PanelTemplate index={0}>
              <SummaryPayrollPanel summary={dashboardData?.summary?.data} />
            </PanelTemplate>
            <PanelTemplate index={1}>
              <NotesPayrollPanel
                notes={dashboardData?.notes?.data}
                employerMonthID={dashboardData?.employer_month?.data?.id}
                onAdd={handleAddNote}
                onDelete={handleDeleteNote}
                onEdit={handleEditNote}
                userID={user.id}
              />
            </PanelTemplate>
            <PanelTemplate index={2}>
              <PeriodPanel
                periods={dashboardData?.pay_periods?.data}
                processes={dashboardData?.payroll_processes?.data}
                setDashboardData={setDashboardData}
              />
            </PanelTemplate>
          </TabsWrapper>
          <ProcessPanel
            isActiveMonth={dashboardData?.employer_month?.data.is_active}
            periods={dashboardData?.pay_periods?.data}
            processes={dashboardData?.payroll_processes?.data}
            setDashboardData={setDashboardData}
          />
        </div>
      )}
    </div>
  )
}

export default PayrollDashboard
