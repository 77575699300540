import React from 'react';
import Icon from '../Icon'

const LinkTextButton = ({className, onClick, text, isDisable}) => {
  return (
    <div
      onClick={onClick}
      className={`s-color-blue blue s-cross-center nowrap hover-change small small-radius s-px-4px select-none ${
        isDisable ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${className}`}
    >
      <p className="s-mb-0  s-mr-4px">{text}</p>
      <Icon svg="external-link" className="flex-none s-mt-2px" />
    </div>
  )
}

export default LinkTextButton;
