import React, {useState, forwardRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import FormInput from '../../Atoms/Templates/FormInput'
import Select from '../../Atoms/Forms/Select'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getLocalCache, getCacheKeyGeneral} from '../../../helpers/cache'
import {isNull, isEqual, isUndefined} from '../../../helpers/utils'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import ValidatorForm from 'helpers/validator'
import {fieldNamesEmployee, rulesEmployee} from 'helpers/employee'
import {notifyError} from 'services/notification'
import {updateBasicInformationEmployee} from 'services/employees'

const PersonalDataEmployeeEditModal = forwardRef(
  ({formData, setFormData, onUpdate}, ref) => {
    const dispatch = useDispatch()
    const employerSession = useSelector(employerSelectors.getSession)

    const [isLoading, setIsLoading] = useState(false)

    const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
    const identifications =
      !isNull(cacheGeneral) && cacheGeneral.data?.identification_types

    const findIdentificationType = id => {
      const found = identifications.find(i => isEqual(i.id, id))

      return isUndefined(found) ? '' : found.description
    }

    const updateFormField = e => {
      const target = e.target
      let value = isEqual(target.type, 'checkbox') ? target.checked : target.value
      const name = target.name

      if (name.endsWith('_id')) value = Number(value)
      if (isEqual(target.type, 'number')) value = Number(value)

      if (isEqual(target.type, 'radio') && isEqual(value, 'true')) value = true
      if (isEqual(target.type, 'radio') && isEqual(value, 'false')) value = false

      setFormData(state => ({...state, [name]: value}))
    }

    const handleOnChangeNumber = e => {
      const value = e.target.value

      const pattern = /^\+?[0-9]{0,3}\s?[0-9]*$/
      // only accept numbers or '' when the user empty the input
      if (isEqual(value, '') || pattern.test(value)) updateFormField(e)
    }

    const handleCancel = () => {
      ref.current.closeModal()
    }

    const handleUpdatePersonalData = () => {
      const validator = new ValidatorForm(formData, rulesEmployee)
      validator.setAttributeNames(fieldNamesEmployee)

      if (validator.fails()) {
        dispatch(notifyError(validator.errors()))
        return
      }

      setIsLoading(true)
      updateBasicInformationEmployee(
        formData,
        response => {
          setIsLoading(false)
          onUpdate(state => ({
            ...state,
            employee: {...state.employee, ...response},
          }))
          handleCancel()
        },
        error => {
          dispatch(notifyError(error))
          setIsLoading(false)
        }
      )
    }

    return (
      <ModalTemplate ref={ref} className="large">
        <h3 className="s-left s-mb-0">Datos personales</h3>
        <p className="small s-color-light-text s-mb-24px">
          Actualiza los datos básicos del empleado
        </p>
        <form className="s-mb-0">
          <div className="form-grid ed-grid m-grid-2 l-block">
            <FormInput
              label="Tipo de identificación"
              name="identification_type_id"
              id="identification_type_id"
              value={findIdentificationType(formData.identification_type_id)}
              disabled={true}
            />

            <FormInput
              label="No. de identificatión"
              name="identification_number"
              id="identification_number"
              value={formData.identification_number}
              disabled={true}
            />

            <FormInput
              isRequired
              label="Primer nombre"
              name="first_name"
              id="first_name"
              value={formData.first_name}
              onChange={updateFormField}
            />

            <FormInput
              label="Segundo nombre"
              name="middle_name"
              id="middle_name"
              value={formData.middle_name}
              onChange={updateFormField}
            />

            <FormInput
              isRequired
              label="Primer apellido"
              name="last_name"
              id="last_name"
              value={formData.last_name}
              onChange={updateFormField}
            />

            <FormInput
              label="Segundo apellido"
              name="surname"
              id="surname"
              value={formData.surname}
              onChange={updateFormField}
            />

            <Select
              label="Estado civil"
              name="marital_status"
              id="marital_status"
              placeholder="Elige un estado civil"
              value={formData.marital_status}
              onChange={updateFormField}
            >
              {['CASADO', 'DIVORCIADO', 'SOLTERO', 'UNION LIBRE', 'VIUDO'].map(
                maritalStatus => (
                  <option key={maritalStatus} value={maritalStatus}>
                    {maritalStatus}
                  </option>
                )
              )}
            </Select>

            <FormInput
              type="email"
              label="Correo electrónico"
              placeholder="correo@example.com"
              name="email"
              id="email"
              value={formData.email}
              onChange={updateFormField}
            />

            <FormInput
              type="tel"
              label="Teléfono"
              placeholder="+057 7112233"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleOnChangeNumber}
            />

            <FormInput
              type="tel"
              label="Celular"
              placeholder="+057 3119876543"
              name="mobile"
              id="mobile"
              value={formData.mobile}
              onChange={handleOnChangeNumber}
            />

            <div className="m-cols-2 form-grid ed-grid m-grid-3">
              <DatePickerInput
                label="Fecha de nacimiento"
                name="birthdate"
                id="birthdate"
                value={formData.birthdate}
                onChange={date => setFormData(state => ({...state, birthdate: date}))}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />

              <FormInput
                label="Lugar de nacimiento"
                name="birthplace"
                id="birthplace"
                placeholder="Bogotá"
                value={formData.birthplace}
                onChange={updateFormField}
              />

              <Select
                isRequired
                label="Género"
                name="gender"
                id="gender"
                placeholder
                value={formData.gender}
                onChange={updateFormField}
              >
                {['FEMENINO', 'MASCULINO'].map(gender => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </Select>
            </div>

            <FormInput
              className="m-cols-2"
              label="Dirección"
              placeholder="CL 1 23 45"
              name="address"
              id="address"
              value={formData.address}
              onChange={updateFormField}
            />
          </div>

          <div className="s-column s-cross-center">
            <p className="smaller">
              Los campos con <span className="s-color-blue">*</span> son obligatorios
            </p>
            <div className="s-column m-row m-main-center s-flex-gap-16px s-100">
              <button
                type="button"
                className="button ghost cancel s-order-3 m-order-1"
                onClick={handleCancel}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="button ghost s-order-1 m-order-3"
                onClick={handleUpdatePersonalData}
                disabled={isLoading}
              >
                {isLoading ? 'Actualizando...' : 'Actualizar'}
              </button>
            </div>
          </div>
        </form>
      </ModalTemplate>
    )
  }
)

export default PersonalDataEmployeeEditModal
