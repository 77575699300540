import React, {useState} from 'react'
import Container from '../../Wrappers/Container'
import PanelTemplate from '../../Wrappers/PanelTemplate'
import ConceptSettingPanelList from './ConceptSettingPanelList'
import {CREATE} from '../../../helpers/payroll'
import ConceptSettingPanelForm from './ConceptSettingPanelForm'
import ConceptSettingPanelFormula from './ConceptSettingPanelFormula'
import {KEY_NOVELTY_OCCASIONAL} from '../../../helpers/novelties'

const DEFAULT_FORM_DATA = {
  action: CREATE,
  data: {
    id: 0,
    concept_type_id: 0,
    concept_type_description: '',
    code: '',
    employer_id: 0,
    description: '',
    is_fixed: false,
    note: '',
    quantity_param: '',
    rate_param: '',
    begins_at_param: '',
    ends_at_param: '',
    base_param: '',
    formula: 'resultado = $.VALOR_NOVEDAD;',
    country_id: 0,
    priority_order_before_id: 0,
    classification: KEY_NOVELTY_OCCASIONAL,
    classification_metadata: {
      absence: {
        absence_type_id: 0,
      },
    },
    is_standard: false,
    is_blocked: false,
    is_override: false,
    override: {
      id: 0,
      employer_id: 0,
      concept_id: 0,
      quantity_param: '',
      rate_param: '',
      begins_at_param: '',
      ends_at_param: '',
      base_param: '',
      formula: '',
    },
  },
  bases: [],
  processes: [],
}

const ConceptSettingPanel = ({
  list,
  setList,
  orderPriorityList,
  setOrderPriority,
  bases,
}) => {
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA)

  return (
    <Container>
      <PanelTemplate index={1} onlyHidden>
        <ConceptSettingPanelList
          list={list}
          setFormData={setFormData}
          setDefaultFormData={() => setFormData(DEFAULT_FORM_DATA)}
        />
      </PanelTemplate>

      <PanelTemplate index={2}>
        <ConceptSettingPanelForm
          formData={formData}
          setFormData={setFormData}
          setList={setList}
          orderPriorityList={orderPriorityList}
          setOrderPriority={setOrderPriority}
          bases={bases}
        />
      </PanelTemplate>

      <PanelTemplate index={3}>
        <ConceptSettingPanelFormula formData={formData} setFormData={setFormData} />
      </PanelTemplate>
    </Container>
  )
}

export default ConceptSettingPanel
