import React from 'react'
import Icon from '../../Atoms/Icon'
import FloatContainer from '../../Wrappers/FloatContainer'

const DropdownSelect = props => {
  const {children, selected, className} = props
  return (
    <div className={`dropdown-select s-relative ${className}`}>
      <FloatContainer>
        <div className="small small-radius s-px-8px s-py-2px s-bg-body s-hover-bg-body-alt s-cross-center nowrap cursor-pointer">
          <p className="s-mb-0 s-mr-8px">{selected}</p>
          <Icon svg="down" />
        </div>
        <div className="float-container ed-grid row-gap s-gap-1 small">{children}</div>
      </FloatContainer>
    </div>
  )
}

export default DropdownSelect
