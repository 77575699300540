import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import FormInput from '../../Atoms/Templates/FormInput'
import Select from '../../Atoms/Forms/Select'
import SelectPlaceLaborMunicipalityInput from '../../Molecules/Selects/SelectPlaceLaborMunicipalityInput'
import ValidatorForm from '../../../helpers/validator'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {
  getLocalCache,
  getCacheKeyGeneral,
  getCacheKeySocialSecurity,
} from '../../../helpers/cache'
import {createBranchOffice} from '../../../services/branchOffices'
import {isEqual, isNull} from '../../../helpers/utils'
import {COLOMBIA_CODE} from '../../../helpers/country'

const formFields = {
  description: 'Nombre',
  phone: 'Teléfono',
  address: 'Dirección',
  department_id: 'Departamento',
  municipality_id: 'Ciudad',
  ccf_id: 'Caja de compensación familiar',
  code: 'Código de la sucursal',
}

const BranchOfficeForm = ({onCancel}) => {
  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const departments = !isNull(cacheGeneral) && cacheGeneral?.data?.departments

  const socialSecurity = getLocalCache(
    getCacheKeySocialSecurity(employerSession.code_iso_3)
  )
  const ccfArray =
    !isNull(socialSecurity) &&
    socialSecurity?.data?.filter(f => isEqual(f.entity, 'CCF'))[0]?.data

  const [ccfList, setCcfList] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [formData, setFormData] = useState({
    description: '',
    phone: '',
    address: '',
    department_id: 0,
    municipality_id: 0,
    ccf_id: 0,
    ccf_name: '',
    code: '',
  })

  const handleCreateBranchOffice = () => {
    setIsDisabled(true)

    const rules = {
      description: 'required|string',
      department_id: 'integer|required|not_in:0',
      municipality_id: 'integer|required|not_in:0',
      code: 'required|string|max:10',
      phone: 'string',
      address: 'string',
    }
    
    const validation = new ValidatorForm(formData, rules)
    validation.setAttributeNames(formFields)

    if (validation.fails()) {
      dispatch(notifyError(validation.errors()))
      setIsDisabled(false)
      return
    }

    // Additional validation for Colombia
    if (isEqual(employerSession.code_iso_3, COLOMBIA_CODE)) {
      const rulesColombia = {
        ccf_id: 'integer|required|not_in:0',
      }

      const validationColombia = new ValidatorForm(formData, rulesColombia)
      validationColombia.setAttributeNames(formFields)

      if (validationColombia.fails()) {
        dispatch(notifyError(validationColombia.errors()))
        setIsDisabled(false)
        return
      }    
    }

    dispatch(
      createBranchOffice(formData, callback => {
        onCancel()
        dispatch(notifySuccessful('Sucursal creada exitosamente'))
      })
    )
  }

  const handleOnChangeCode = event => {
    const pattern = /^\w{1,10}$/

    if (isEqual(event.target.value, '') || pattern.test(event.target.value))
      setFormData({...formData, code: event.target.value})
  }

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const onDepartmentInputChange = e => {
    setFormData({
      ...formData,
      department_id: Number(e.target.value),
      municipality_id: 0,
      ccf_id: 0,
      ccf_name: '',
    })

    if (!isEqual(employerSession.code_iso_3, COLOMBIA_CODE)) return

    setCcfList(
      !ccfArray
        ? []
        : ccfArray.filter(ccf => isEqual(ccf.department_id, Number(e.target.value)))
    )
  }

  const handleOnSelectMunicipalityInput = municipalityID => {
    setFormData(state => ({
      ...state,
      municipality_id: Number(municipalityID),
    }))
  }

  const onCcfInputChange = e => {
    const options = e.target.options
    const i = options.selectedIndex
    let ccfName = options[i].getAttribute('data-ccf-name')

    setFormData({
      ...formData,
      ccf_id: Number(e.target.value),
      ccf_name: ccfName,
    })
  }

  return (
    <form noValidate className="s-mb-0 ed-grid rows-gap form-grid">
      <div className="ed-grid m-grid-2 rows-gap">
        <FormInput
          isRequired
          name="description"
          label={formFields.description}
          placeholder="Mi sucursal"
          autocomplete={'off'}
          onChange={handleInputChange}
        />
        <FormInput
          name="phone"
          label={formFields.phone}
          placeholder="123 45 67"
          onChange={handleInputChange}
        />
      </div>

      <FormInput
        name="address"
        label={formFields.address}
        placeholder="CL 8C 12 34"
        onChange={handleInputChange}
      />

      <Select
        name="department"
        label={formFields.department_id}
        placeholder
        isRequired
        onChange={onDepartmentInputChange}
        value={formData.department_id}
      >
        {Array.isArray(departments) &&
          departments.map(department => (
            <option key={department.id} value={department.id}>
              {department.description}
            </option>
          ))}
      </Select>

      <SelectPlaceLaborMunicipalityInput
        isRequired
        label="Ciudad de trabajo"
        name="place_labor_municipality_id"
        id="place_labor_municipality_id"
        placeholder="Escribe para buscar"
        departmentID={formData.department_id}
        onSelect={handleOnSelectMunicipalityInput}
      />

      {isEqual(employerSession.code_iso_3, COLOMBIA_CODE) && (
        <Select
          name="ccf"
          isRequired
          label={formFields.ccf_id}
          placeholder="female"
          onChange={onCcfInputChange}
          value={formData.ccf_id}
        >
          {Array.isArray(ccfList) &&
            ccfList.map(ccf => (
              <option key={ccf.id} value={ccf.id} data-ccf-name={ccf.short_name}>
                {ccf.business_name}
              </option>
            ))}
        </Select>
      )}

      <FormInput
        isRequired
        name="code"
        label={formFields.code}
        placeholder="VY787iu90"
        value={formData.code}
        onChange={handleOnChangeCode}
        autocomplete={'off'}
      />

      <div className="buttons-container">
        <button className="button cancel" type="button" onClick={onCancel}>
          Cancelar
        </button>
        <button
          className="button"
          type="button"
          onClick={handleCreateBranchOffice}
          disabled={isDisabled}
        >
          {isDisabled ? 'Creando...' : ' Crear sucursal'}
        </button>
      </div>
    </form>
  )
}

export default BranchOfficeForm
