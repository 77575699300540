import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {PhonePanelContext} from '../../contexts/contexts'
import ComeBackHeader from '../Organisms/Headers/ComeBackHeader'
import {useMedia} from '../../hooks/useMedia'
import {mediaToM} from '../../helpers/media-queries'

const PhonePanel = ({children, className, container, onClickComeBack}) => {
  const [active, setActive] = useState(false)

  const handleOnClickComeBack = () => {
    setActive(false)
    onClickComeBack()
  }

  const toTablet = useMedia(mediaToM)
  return (
    <PhonePanelContext.Provider value={{setActive}}>
      <div className={`phone-panel ${className} ${active && 'is-active'}`}>
        {toTablet && <ComeBackHeader onClick={handleOnClickComeBack} />}
        <div className={`panels ed-grid transform-transition ${container.className}`}>
          {children}
        </div>
      </div>
    </PhonePanelContext.Provider>
  )
}

PhonePanel.propTypes = {
  children: PropTypes.node.isRequired,
  onClickComeBack: PropTypes.func,
  className: PropTypes.string,
  container: PropTypes.shape({
    className: PropTypes.string,
  }),
}

PhonePanel.defaultProps = {
  className: '',
  onClickComeBack: () => {},
  container: {
    className: '',
  },
}

export default PhonePanel
