import React from 'react'
import Skeleton from 'react-loading-skeleton'

const DashboardSkeleton = () => (
  <div className="ed-grid m-cols-6 lg-cols-4 m-grid-2 lg-x-2 rows-gap">
    <div className="m-cols-2">
      <Skeleton height={200} />
    </div>
    <div>
      <Skeleton height={300} />
    </div>
    <div>
      <Skeleton height={300} />
    </div>
    <div className="m-cols-2">
      <Skeleton height={300} />
    </div>
  </div>
)

export default DashboardSkeleton
