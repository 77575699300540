import React, {useState} from 'react'
import DropdownButton from '../Molecules/Dropsdown/DropdownButton'
import DropdownItem from '../Atoms/Items/DropdownItem'
import PropTypes from 'prop-types'
import {isEqual} from '../../helpers/utils'

export const ALL_OPTION = {key: 0, value: 'Todo'}

const Filter = ({options, className, onSelect, showContent, label}) => {
  const [selected, setSelected] = useState(ALL_OPTION)

  const handleClick = option => {
    setSelected(option)
    onSelect(option)
  }

  return (
    <DropdownButton
      icon="filter"
      content={
        showContent
          ? isEqual(selected.key, ALL_OPTION.key)
            ? label || 'Filtrar'
            : selected.value
          : ''
      }
      isSelected={!isEqual(selected.key, ALL_OPTION.key)}
      sizeFloatContainer="m"
      className={className}
    >
      <DropdownItem
        text={ALL_OPTION.value}
        isActive={isEqual(selected.key, ALL_OPTION.key)}
        onClick={() => handleClick(ALL_OPTION)}
      />
      {options.map(option => (
        <DropdownItem
          key={option.key}
          text={option.value}
          isActive={isEqual(selected.key, option.key)}
          onClick={() => handleClick(option)}
        />
      ))}
    </DropdownButton>
  )
}

Filter.propTypes = {
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  showContent: PropTypes.bool,
}

Filter.defaultProps = {
  options: [],
  className: '',
  onSelect: () => {},
  showContent: true,
}

export default Filter
