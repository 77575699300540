import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/payroll-dashboard'

export const getPayrollDashboardOfActiveMonth = callback => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/active-month`, callback)
}

export const getPayrollDashboardByYearAndMonth = (year, month, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}?year=${year}&month=${month}`, callback)
}
