import { decode } from "jsonwebtoken";

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;
const TOKEN_EMPLOYER_KEY = process.env.REACT_APP_TOKEN_EMPLOYER_KEY;

export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token === null) {
    removeSession();
    return false;
  }

  const tokenDecoded = decode(token);
  if (tokenDecoded === null) {
    removeSession();
    return false;
  }

  if (
    tokenDecoded.exp !== null &&
    Number(`${tokenDecoded.exp}000`) <= Date.now()
  ) {
    removeSession();
    return false;
  }

  return (
    tokenDecoded.user_id !== null &&
    tokenDecoded.role_id !== null &&
    tokenDecoded.email !== null &&
    tokenDecoded.iss !== null &&
    Number(tokenDecoded.exp) > 0
  );
};

export const isAuthenticatedWithEmployer = () => {
  const token = sessionStorage.getItem(TOKEN_EMPLOYER_KEY);
  if (token === null) {
    return false;
  }

  const tokenDecoded = decode(token);
  if (tokenDecoded === null) {
    return false;
  }

  if (
    tokenDecoded.exp !== null &&
    Number(`${tokenDecoded.exp}000`) <= Date.now()
  ) {
    return false;
  }

  return (
    tokenDecoded.user_id !== null &&
    tokenDecoded.role_id !== 0 &&
    tokenDecoded.employer_id !== 0 &&
    tokenDecoded.email !== null &&
    tokenDecoded.iss !== null &&
    Number(tokenDecoded.exp) > 0
  );
};

// removeSession remueve la sesión en la app
export const removeSession = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.clear();

  sessionStorage.removeItem(TOKEN_EMPLOYER_KEY);
  sessionStorage.clear();
};

// closeSession cierra la sesión del usuario
export const closeSession = () => {
  removeSession();
  window.location.reload();
};
