import React from 'react'
import PropTypes from 'prop-types'
import {getFormatCurrency} from '../../../helpers/format-number'
import {mediaFromM} from '../../../helpers/media-queries'
import {useMedia} from '../../../hooks/useMedia'

const PayslipContractInformationSection = ({children, netPay, employerSession}) => {
  const fromM = useMedia(mediaFromM)

  return (
    <section className="m-cross-center m-main-justify s-mb-4">
      {fromM && <div className="s-center m-left s-mb-2 m-mb-4 m-mb-0">{children}</div>}
      <div className="s-center m-left">
        <p className="small s-mb-0">Neto a Pagar</p>
        <p className="t1 s-mb-0 whitespace-nowrap">
          {getFormatCurrency(
            netPay,
            employerSession.code_iso_3,
            !employerSession.not_include_decimals_in_calculation
          )}
        </p>
      </div>
    </section>
  )
}

PayslipContractInformationSection.propTypes = {
  children: PropTypes.node.isRequired,
  netPay: PropTypes.number.isRequired,
}

PayslipContractInformationSection.defaultProps = {
  netPay: 0,
}

export default PayslipContractInformationSection
