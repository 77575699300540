import React, {useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import PanelInformationItem from '../../Atoms/Items/PanelInformationItem'
import EmployeeInformationContainer from '../Containers/EmployeeInformationContainer'
import ContractPaymentPanelEmptyState from '../Emptys/ContractPaymentPanelEmptyState'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getLocalCache, getCacheKeyBanks} from '../../../helpers/cache'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import PaymentDataEditModal from '../Modals/PaymentDataEditModal'

const PaymentDataPanel = ({data, onUpdate}) => {
  const employerSession = useSelector(employerSelectors.getSession)

  const [formData, setFormData] = useState(data)

  const cacheBanks = getLocalCache(getCacheKeyBanks(employerSession.code_iso_3))
  const banks = !isNull(cacheBanks) && cacheBanks.data?.banks
  const bank = banks.filter(bank => isEqual(bank.id, data.bank_account_history.bank_id))

  const editModal = useRef()

  const handleOnEdit = () => {
    setFormData(data)
    editModal.current.openModal()
  }

  return (
    <>
      <EmployeeInformationContainer
        title="Datos de pago"
        icon="cash"
        editFunction={handleOnEdit}
      >
        {isEmpty(data.contract.payment_method) ? (
          <ContractPaymentPanelEmptyState className="s-cols-2" />
        ) : (
          <>
            <PanelInformationItem
              title="Método de pago"
              text={data.contract.payment_method}
              twoColumns
            />
            {isEqual(
              data.contract.payment_method.toUpperCase(),
              'TRANSFERENCIA BANCARIA'
            ) && (
              <>
                <PanelInformationItem
                  title="Banco"
                  text={
                    isEmpty(bank) ? data.bank_account_history.bank_id : bank[0].short_name
                  }
                />
                <PanelInformationItem
                  title="Tipo de cuenta"
                  text={data.bank_account_history.account_type}
                />
                <PanelInformationItem
                  title="No. de cuenta"
                  text={data.bank_account_history.account_number}
                />
              </>
            )}
          </>
        )}
      </EmployeeInformationContainer>
      <PaymentDataEditModal
        ref={editModal}
        banks={banks}
        formData={formData}
        setFormData={setFormData}
        onUpdate={onUpdate}
      />
    </>
  )
}

export default PaymentDataPanel
