import React from 'react';
import ActionTextButton from "../Templates/ActionTextButton";

const CancelTextButton = ({className, onClick}) => {
  return (
    <ActionTextButton
      className={`s-color-text ${className}`}
      onClick={onClick}
      icon="cross"
      text="Cancelar"
    />
  );
};

export default CancelTextButton;
