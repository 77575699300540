import React from 'react'
import MonthProgress from '../../Molecules/MonthProgress'
import MonthProgressEmptyState from '../Emptys/MonthProgressEmptyState'
import Container from '../../Wrappers/Container'
import {isEmpty} from '../../../helpers/utils'
import {getMonth} from '../../../helpers/dates'

const DashboardProgress = ({processes, month}) => {
  return (
    <Container>
      <h3>Progreso de {getMonth(month)}</h3>

      {isEmpty(processes) ? (
        <MonthProgressEmptyState />
      ) : (
        <MonthProgress processes={processes} />
      )}
    </Container>
  )
}

export default DashboardProgress
