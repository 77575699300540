import {RequestWithEmployerAuthorization} from '../helpers/request'

const resource = '/process-parameters'

export const getProcessParameters = (callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    resource,
    callback,
    error => console.error('process parameters:', error)
  )
}

export const getGlobalParameters = callback => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${resource}/global-parameters`,
    callback,
    error => console.error('global parameters:', error)
  )
}

export const getParameterStandardDetailByID = (parameterID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${resource}/${parameterID}/standard-details`, callback, error =>
    console.error('getParameterStandardDetailByID:', error)
  )
}

export const getParameterEmployerDetailByID = (parameterID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${resource}/${parameterID}/employer-details`, callback, error =>
    console.error('getParameterEmployerDetailByID:', error)
  )
}
