import React from 'react'
import Settings from '../../../static/images/stories/settings.svg'
import Container from '../../Wrappers/Container'

const SettingsEmptyState = () => {
  return (
    <Container className="lg-cols-2 s-column s-center s-cross-center s-main-center">
      <div className="l-block" />
      <img
        className="s-mb-2"
        src={Settings}
        alt="Configuraciones"
        width="200px"
        height="200px"
      />
      <h3>Configuraciones</h3>
      <p className="s-color-light-text">
        Selecciona una opción de la lista para verla o editarla aquí.
      </p>
    </Container>
  )
}

export default SettingsEmptyState
