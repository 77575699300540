import React, {useState, useContext, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import NoveltyFormContainer from '../Containers/NoveltyFormContainer'
import SelectEmployeeInput from '../../Molecules/Selects/SelectEmployeeInput'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import RadioButton from '../../Atoms/Forms/RadioButton'
import {isAnyEqual, isEmpty, isEqual} from '../../../helpers/utils'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {initialStateAction} from '../Tables/NoveltyTable'
import TextArea from '../../Atoms/Forms/TextArea'
import SelectConceptInput from '../../Molecules/Selects/SelectConceptInput'
import {PayrollContext} from '../../../contexts/contexts'
import {selectors as employerConceptSelectors} from '../../../redux/ducks/concepts'
import {getConceptsNoveltyByProcessType} from '../../../helpers/concepts'
import TooltipContainer from '../Containers/TooltipContainer'
import Icon from '../../Atoms/Icon'
import Tooltip from '../../Atoms/Templates/Tooltip'
import {readBackendDate, writeBackendDate} from '../../../helpers/dates'
import ValidatorForm from '../../../helpers/validator'
import {
  createRecurrentNovelty,
  updateRecurrentNovelty,
} from '../../../services/recurrentnovelty'
import {notifyError, notifySuccessful} from '../../../services/notification'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'
import FormulatorEditor from '../FormulatorEditor'
import {KEY_NOVELTY_RECURRENT} from '../../../helpers/novelties'

const formFields = {
  contract_id: 'Empleado',
  concept_id: 'Concepto',
  value: 'Valor',
  begins_at: 'Inicial',
  ends_at: 'Finalización',
  is_override: '¿Usas una formula diferente para esta novedad?',
  is_custom_formula: '¿Cómo quieres crear la formula?',
  application_period_sequence: 'Periodo en qué se incluye la novedad',
  standard_behavior: 'Proporcionalidad',
  formula: 'Formula',
  notes: 'Notas u observaciones',
}

const rules = {
  contract_id: 'required|integer|not_in:0',
  concept_id: 'required|integer|not_in:0',
  value: 'required|numeric|min:1',
  begins_at: 'required|date',
  ends_at: 'date|after_or_equal:begins_at',
  is_override: 'required|boolean',
  is_custom_formula: 'required|boolean',
  application_period_sequence: 'string',
  standard_behavior: 'string',
  formula: 'string',
  notes: 'string',
}

// Period options
const FIRST_PERIOD = 'FIRST_PERIOD'
const LAST_PERIOD = 'LAST_PERIOD'
const ALL_PERIODS = 'ALL_PERIODS'

// Proportional options
const BY_SALARY_DAYS = 'APPLY_BY_SALARY_DAYS'
const BY_CONTRACT_DAYS = 'APPLY_BY_CONTRACT_DAYS'
const BY_FIX_VALUE = 'APPLY_BY_FIX_VALUE'

const RecurrentNoveltyForm = ({
  action,
  setAction,
  setResizeFormReason,
  reloadNovelties,
  setReloadNovelties,
  initialState,
}) => {
  const dispatch = useDispatch()

  const {activeProcess} = useContext(PayrollContext)
  const noveltyConcepts = useSelector(employerConceptSelectors.getNoveltyConcepts)
  const ConceptByProcessType = useSelector(employerConceptSelectors.getProcessConcepts)
  const concepts = getConceptsNoveltyByProcessType(
    noveltyConcepts,
    ConceptByProcessType,
    activeProcess.process_type_id
  )
  const editorRef = useRef(null)

  const isDisabled = isEqual(action.type, SHOW)
  const [formData, setFormData] = useState(initialState)

  const handleChangeValue = e => {
    const valueField = e.target.value
    const pattern = /^[0-9]+\.?(\d{1,2})?$/

    if (isEqual(valueField, '') || pattern.test(valueField)) {
      setFormData(state => ({
        ...state,
        value: valueField,
      }))
    }
  }

  const handleOnChangeInputText = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      [name]: value,
    }))
  }

  const handleOnSelectConceptInput = conceptID => {
    setFormData(state => ({
      ...state,
      concept_id: Number(conceptID),
    }))
  }

  const handleOnSelectEmployeeInput = contractID => {
    setFormData(state => ({
      ...state,
      contract_id: Number(contractID),
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date,
    }))
  }

  const handleOnChangeEndsAt = date => {
    setFormData(state => ({
      ...state,
      ends_at: date,
    }))
  }

  const handleOnChangeOverrideFormula = e => {
    const value = e.target.value
    const isOverride = isEqual(value, 'true')

    setFormData(state => ({
      ...state,
      is_override: isOverride,
      is_custom_formula: false,
      application_period_sequence: '',
      standard_behavior: '',
      formula: '',
    }))

    // we request resize the form because we show or hide fields
    setResizeFormReason(`IS_OVERRIDE_${isOverride}`)
  }

  const handleOnChangeIsCustomFormula = e => {
    const value = e.target.value
    const isCustomFormula = isEqual(value, 'true')
    setFormData(state => ({
      ...state,
      is_custom_formula: isCustomFormula,
      application_period_sequence: '',
      standard_behavior: '',
      formula: `resultado = $.VALOR_NOVEDAD;`,
    }))

    // we request resize the form because we show or hide fields
    setResizeFormReason(`IS_CUSTOM_FORMULA_${isCustomFormula}`)
  }

  const handleCancelForm = () => setAction(initialStateAction)

  const handleSuccessForm = () => {
    if (isEqual(action.type, CREATE)) handleCreate()
    if (isEqual(action.type, EDIT)) handleEdit()
  }

  const handleValidate = () => {
    const payload = {
      contract_id: formData.contract_id,
      concept_id: formData.concept_id,
      value: Number(formData.value),
      begins_at: writeBackendDate(formData.begins_at),
      ends_at: writeBackendDate(formData.ends_at),
      is_override: formData.is_override,
      is_custom_formula: formData.is_custom_formula,
      application_period_sequence: formData.application_period_sequence,
      standard_behavior: formData.standard_behavior,
      formula: '',
      notes: formData.notes,
    }

    if (!payload.is_override) {
      payload.is_custom_formula = false
      payload.application_period_sequence = ''
      payload.standard_behavior = ''
      payload.formula = ''
    }

    if (payload.is_custom_formula) {
      payload.application_period_sequence = ''
      payload.standard_behavior = ''
      payload.formula = editorRef.current.getValue()
    }

    const validator = new ValidatorForm(payload, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      throw validator.errors()
    }

    // validate "Formulación Personalizada"
    if (payload.is_custom_formula) {
      if (isEmpty(payload.formula)) {
        throw 'Debes agregar una formula personalizada' // eslint-disable-line
      }

      const pattern = /^resultado\s*=\s*/
      if (!pattern.test(payload.formula)) {
        throw 'La formula se debe asignar a la palabra resultado' // eslint-disable-line
      }
    }

    // validate "Formulación Asistida"
    if (payload.is_override && !payload.is_custom_formula) {
      if (isEmpty(payload.application_period_sequence)) {
        throw 'Debes seleccionar un periodo en qué se incluye la novedad' // eslint-disable-line
      }

      if (
        !isAnyEqual(payload.application_period_sequence, [
          FIRST_PERIOD,
          LAST_PERIOD,
          ALL_PERIODS,
        ])
      ) {
        throw 'Debes indicar un periodo valido' // eslint-disable-line
      }

      if (isEmpty(payload.standard_behavior)) {
        throw 'Debes seleccionar un proporcionalidad' // eslint-disable-line
      }

      if (
        !isAnyEqual(payload.standard_behavior, [
          BY_SALARY_DAYS,
          BY_CONTRACT_DAYS,
          BY_FIX_VALUE,
        ])
      ) {
        throw 'Debes indicar una proporcionalidad valida' // eslint-disable-line
      }
    }

    return payload
  }

  const handleCreate = () => {
    try {
      const payload = handleValidate()
      createRecurrentNovelty(payload, response => {
        setAction(initialStateAction)
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('Hemos creado la novedad recurrente exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleEdit = () => {
    try {
      const payload = handleValidate()
      updateRecurrentNovelty(formData.id, payload, response => {
        setAction(initialStateAction)
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('Hemos actualizado la novedad recurrente exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleOnMountEditor = () => {
    setResizeFormReason(`ON_MOUNT_EDITOR_${new Date().getTime()}`)
  }

  const successButtonText = isEqual(action.type, CREATE)
    ? 'Agregar Novedad'
    : isEqual(action.type, EDIT)
    ? 'Editar Novedad'
    : 'Guardar'

  return (
    <NoveltyFormContainer
      cancelFunction={handleCancelForm}
      successFunction={handleSuccessForm}
      successButtonText={successButtonText}
    >
      <SelectEmployeeInput
        isRequired
        isDisabled={isAnyEqual(action.type, [EDIT, SHOW])}
        name="employee_id"
        id="employee_id"
        label={formFields.contract_id}
        placeholder="Busca por Identificación o nombre"
        onSelect={handleOnSelectEmployeeInput}
        initialValue={formData.employee_description}
      />

      <SelectConceptInput
        isRequired
        isDisabled={isDisabled || formData.has_history}
        name="concept_id"
        id="concept_id"
        label={formFields.concept_id}
        placeholder="Busca por código o nombre"
        onSelect={handleOnSelectConceptInput}
        concepts={concepts}
        initialValue={formData.concept_description}
      />

      <FormInput
        isRequired
        isDisabled={isDisabled}
        label={isDisabled ? 'Valor Aplicado' : formFields.value}
        name="value"
        id="value"
        placeholder="0.00"
        value={isDisabled ? formData.history_amount : formData.value}
        onChange={handleChangeValue}
        align="right"
      />

      <DatePickerInput
        disabled={isDisabled || formData.has_history}
        isRequired
        label={formFields.begins_at}
        id="begins_at"
        name="begins_at"
        value={formData.begins_at}
        onChange={handleOnChangeBeginsAt}
        minDate={
          isEqual(activeProcess.application_pay_period_id, 0)
            ? readBackendDate(activeProcess.begins_at)
            : readBackendDate(activeProcess.begins_at_application_period)
        }
        maxDate={
          isEqual(activeProcess.application_pay_period_id, 0)
            ? readBackendDate(activeProcess.ends_at)
            : readBackendDate(activeProcess.ends_at_application_period)
        }
      />

      <DatePickerInput
        disabled={isDisabled}
        label={formFields.ends_at}
        id="ends_at"
        name="ends_at"
        openToDate={isEqual(formData.ends_at, '') ? formData.begins_at : formData.ends_at}
        minDate={formData.begins_at}
        value={formData.ends_at}
        onChange={handleOnChangeEndsAt}
      />

      <RadioButtonsWrapper
        label={
          isDisabled
            ? '¿Usaste una formula diferente para esta novedad?'
            : formFields.is_override
        }
      >
        <RadioButton
          disabled={isDisabled}
          name="is_override"
          label="Sí"
          value={true}
          onChange={handleOnChangeOverrideFormula}
          checked={isEqual(formData.is_override, true)}
        />
        <RadioButton
          disabled={isDisabled}
          name="is_override"
          label="No"
          value={false}
          onChange={handleOnChangeOverrideFormula}
          checked={isEqual(formData.is_override, false)}
        />
      </RadioButtonsWrapper>

      {formData.is_override && (
        <>
          <div className="flex">
            <RadioButtonsWrapper
              label={
                isDisabled ? '¿Cómo creaste la formula?' : formFields.is_custom_formula
              }
            >
              <RadioButton
                disabled={isDisabled}
                name="is_custom_formula"
                label="Asistida"
                value={false}
                onChange={handleOnChangeIsCustomFormula}
                checked={isEqual(formData.is_custom_formula, false)}
              />
              <RadioButton
                disabled={isDisabled}
                name="is_custom_formula"
                label="Personalizada"
                value={true}
                onChange={handleOnChangeIsCustomFormula}
                checked={isEqual(formData.is_custom_formula, true)}
              />
            </RadioButtonsWrapper>

            <TooltipContainer className="s-cross-start s-py-2px">
              <Icon
                svg="information"
                color="var(--blue-accent)"
                className="s-mr-4px cursor-pointer"
              />
              <Tooltip>
                <div className="whitespace-nowrap">
                  <p className="smaller s-mb-0">
                    Asistida:{' '}
                    <span className="s-color-lighter-text">
                      {isDisabled
                        ? 'Indicaste una periocidad y proporcionalidad.'
                        : 'Indica una periocidad y proporcionalidad.'}
                    </span>
                  </p>
                  <p className="smaller s-mb-0">
                    Personalizada:{' '}
                    <span className="s-color-lighter-text">
                      {isDisabled
                        ? 'Use mi propia formula.'
                        : 'Quiero crear mi propia formula.'}
                    </span>
                  </p>
                </div>
              </Tooltip>
            </TooltipContainer>
          </div>

          {/* Only show when the action is EDIT or CREATE, i.e. when "isDisabled = false" */}
          {!isDisabled && !formData.is_custom_formula && (
            <>
              <Select
                isRequired
                placeholder
                isDisabled={isDisabled}
                name="application_period_sequence"
                label={formFields.application_period_sequence}
                value={formData.application_period_sequence}
                onChange={handleOnChangeInputText}
              >
                {[
                  {key: FIRST_PERIOD, value: 'Primer Periodo'},
                  {key: LAST_PERIOD, value: 'Último Periodo'},
                  {key: ALL_PERIODS, value: 'Todos los Periodos'},
                ].map(item => (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Select>

              <Select
                isRequired
                placeholder
                isDisabled={isDisabled}
                name="standard_behavior"
                label={formFields.standard_behavior}
                value={formData.standard_behavior}
                onChange={handleOnChangeInputText}
              >
                {[
                  {key: BY_SALARY_DAYS, value: 'Días del Salario'},
                  {key: BY_CONTRACT_DAYS, value: 'Días Laborados'},
                  {key: BY_FIX_VALUE, value: 'Ninguna, usa valor fijo'},
                ].map(item => (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Select>
            </>
          )}
        </>
      )}

      <TextArea
        isDisabled={isDisabled}
        className="s-h-full lg-x-3 lg-y-1 s-rows-2 s-order-1 m-cols-2 lg-cols-1"
        name="notes"
        id="notes"
        placeholder="Escribe una nota"
        value={formData.notes}
        onChange={handleOnChangeInputText}
        align="left"
        label={formFields.notes}
        textarea={{
          className: 's-h-full',
        }}
      />

      {/* Only show when the action is EDIT or CREATE, i.e. when "isDisabled = false" */}
      {!isDisabled && formData.is_custom_formula && (
        <FormulatorEditor
          editorRef={editorRef}
          initialFormula={formData.formula}
          noveltySource={KEY_NOVELTY_RECURRENT}
          width="100%"
          height="150px"
          theme="light"
          onMount={handleOnMountEditor}
        />
      )}

      {/* Only show when the action is SHOW, i.e. when "isDisabled = true" */}
      {isDisabled && (
        <div className="s-h-full m-rows-2 lg-rows-2 m-cols-3 lg-cols-3 s-border-border normal-radius s-pxy-2">
          <PreviewMarkdown
            content={
              '**Formula utilizada:**\n```js\n' + formData.history_formula + '\n```'
            }
          />
        </div>
      )}
    </NoveltyFormContainer>
  )
}

export default RecurrentNoveltyForm
