import React from 'react';
import PropTypes from 'prop-types';

const ButtonContainerTableHeader = props => {
  const { children, className } = props
  return (
    <div className={`s-order-2 m-order-1 s-cross-center s-flex-gap-12px m-main-end flex-grow nowrap ${className}`}>
      {children}
    </div>
  );
};

ButtonContainerTableHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonContainerTableHeader;
