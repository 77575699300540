import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import WelcomeContainer from '../components/Wrappers/WelcomeContainer'
import FormInput from '../components/Atoms/Templates/FormInput'
import ForgotPasswordGraphic from '../static/images/stories/forgot-password.svg'
import SendMailGraphic from '../static/images/stories/guy-send-mail.svg'

const ForgotPassword = () => {
  const [isSentEmail, setIsSetEmail] = useState(true)

  return (
    <WelcomeContainer title="¿Olvidaste tu contraseña?">
      {/* Comienzo: recuperar contraseña*/}
      {!isSentEmail && (
        <>
          <p className="small s-mt-2 s-mb-0">
            Enviaremos a tu correo las indicaciones para poder recuperar tu cuenta
          </p>

          <div className="s-main-center">
            <img
              src={ForgotPasswordGraphic}
              alt="Sujeto que olvidó su contraseña. Seguramente es muy descuidado."
            />
          </div>

          <FormInput
            id="login-email"
            label="Correo electrónico"
            placeholder="juan@hotmail.com"
            type="email"
          />

          <div>
            <input
              type="submit"
              value="Recupera tu contraseña"
              className="button full s-mb-16px"
            />
            <p className="s-mb-8px small">
              ¿No tienes una cuenta? <Link to="/registro">Regístrate</Link>
            </p>
            <p className="s-mb-0 small">
              ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
            </p>
          </div>
        </>
      )}
      {/* Final: recuperar contraseñá */}

      {/* Comienzo: correo enviado */}
      {isSentEmail && (
        <>
          <p className="small s-mt-2 s-mb-0">
            No olvides revisar la bandeja de entrada o la carpeta de spam
          </p>
          <div className="s-main-center">
            <img
              src={SendMailGraphic}
              alt="Cartero enviando tu nueva contraseña. No la vayas a perder, esto no es un juego"
            />
          </div>

          <Link to="/login" className="button full">
            Entendido
          </Link>
        </>
      )}
      {/* Final: correo enviado */}
    </WelcomeContainer>
  )
}

export default ForgotPassword
