import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/accounting-interface'

export const generateAccountingInterface = payload => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.post(RESOURCE, payload, resolve, reject)
  })
}

export const getAccountingInterfacesByHashProcess = hash => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.get(`${RESOURCE}/processes/${hash}`, resolve, reject)
  })
}
