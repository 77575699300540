import React from 'react'
import {getAvatar} from '../../helpers/static-images'
import {fullName} from '../../helpers/string-format'
import {isEmpty, isFunction} from '../../helpers/utils'
import Icon from './Icon'
import UserAvatar from './Templates/UserAvatar'

const ChipEmployee = ({
  employee,
  onDelete,
  classColorFont = 's-color-blue-500',
  classColorBorder = 's-border-blue-400',
}) => {
  return (
    <div className={`s-cross-center big-radius s-pxy-4px ${classColorBorder}`}>
      <UserAvatar
        picture={
          isEmpty(employee.thumbnail)
            ? getAvatar(employee.contract_id || employee.id, employee.gender)
            : employee.thumbnail
        }
        size="small"
        className="s-mr-4px"
      />
      <span className={`small s-mr-4px ${classColorFont}`}>{fullName(employee)}</span>
      {isFunction(onDelete) && (
        <Icon
          svg="x-circle-fill"
          className={`cursor-pointer ${classColorFont}`}
          size="24px"
          onClick={onDelete}
        />
      )}
    </div>
  )
}

export default ChipEmployee
