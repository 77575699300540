import React, {useState, useEffect} from 'react'
import {isEmpty} from '../../../helpers/utils'
import SearchInput from '../../Atoms/Forms/SearchInput'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'
import Badge from '../../Atoms/Badge'
import Icon from '../../Atoms/Icon'
import ParameterSettingDetail from '../Tables/ParameterSettingDetail'
import { PARAM_NUMBER,PARAM_STRING,PARAM_BOOLEAN,PARAM_DATE,PARAM_ARRAY_NUMBER,PARAM_ARRAY_STRING } from '../../../helpers/parameter'

const MAP_COLOR = {
  [PARAM_NUMBER]: {label: 'Número', color: 's-bg-green'},
  [PARAM_STRING]: {label: 'Texto', color: 's-bg-text'},
  [PARAM_BOOLEAN]: {label: 'Booleano', color: 's-bg-red'},
  [PARAM_DATE]: {label: 'Fecha', color: 's-color-alt-red'},
  [PARAM_ARRAY_NUMBER]: {label: 'Lista de números', color: 's-bg-blue'},
  [PARAM_ARRAY_STRING]: {label: 'Lista de textos', color: 's-bg-alt-yellow'},
}

const GlobalParameterSettingPanelList = ({list}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filterList, setFilterList] = useState(list)

  useEffect(() => {
    setFilterList(list)
    setSearchValue('')
  }, [list])

  const handleSearch = e => {
    let value = e.target.value
    setSearchValue(value)

    if (isEmpty(value)) {
      setFilterList(list)
      return
    }

    value = value.toLowerCase()
    const newList = list.filter(
      p =>
        p.abbreviation.toLowerCase().includes(value) ||
        p.description.toLowerCase().includes(value) ||
        p.remarks.toLowerCase().includes(value)
    )
    setFilterList(newList)
  }

  return (
    <>
      <div className="s-main-justify s-mb-1">
        <p className="small">Consulta y edita los parámetros de nómina.</p>
        <div className="s-cross-center">
          <SearchInput
            className="s-mr-1 small"
            value={searchValue}
            onChange={handleSearch}
            placeholder="Busca aquí"
          />
        </div>
      </div>

      <Table
        heads={['Parámetro']}
        className="table-template"
        classNameTh="border-bottom-white-4"
        classNameHeader="sticky-element-to-header z-fixed"
      >
        {Array.isArray(filterList) &&
          filterList.map(p => (
            <TableRow key={p.id}>
              <div className="ed-grid gap-1 rows-gap">
                <div className="s-cross-center s-overflow-hidden">
                  <p className="smaller s-color-title s-mb-0 s-mr-8px">
                    {p.abbreviation}
                  </p>
                  <Badge
                    text={MAP_COLOR[p.data_type].label}
                    color={MAP_COLOR[p.data_type].color}
                    className="s-mr-1"
                  />
                  {!p.is_mandatory_employer && (
                    <div title="Administrado por nomineros" className="s-mr-1">
                      <Icon svg="nomineros" size="10" />
                    </div>
                  )}
                </div>
                <div className="smaller">
                  <PreviewMarkdown content={p?.remarks} />
                </div>

                <ParameterSettingDetail
                  parameterId={p.id}
                  paramType={p.data_type}
                  isMandatoryEmployer={p.is_mandatory_employer}
                />
              </div>
            </TableRow>
          ))}
      </Table>
    </>
  )
}

export default GlobalParameterSettingPanelList
