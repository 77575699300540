import React from 'react'
import {isEqual} from '../../helpers/utils'
import Tag from './Tag'
import {STATUS_PRE_PAYROLL} from '../../helpers/payroll'

const ProcessStatusIcon = ({status, className}) => {
  return (
    <div className={className}>
      {isEqual(status, STATUS_PRE_PAYROLL) ? (
        <Tag text="Abierto" color="green" bgColor="s-bg-light-green" />
      ) : (
        <Tag text="Cerrado" color="text" bgColor="s-bg-border" />
      )}
    </div>
  )
}

export default ProcessStatusIcon
