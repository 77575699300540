import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions as branchOffices} from '../redux/ducks/branchOffices'

const RESOURCE = '/branch-offices'

export const getBranchOffices = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    RESOURCE,
    response => dispatch(branchOffices.set(response)),
    error => console.error('branch offices:', error)
  )
}

export const createBranchOffice = (payload, callback) => dispatch => {
  const request = new RequestWithEmployerAuthorization()

  request.post(RESOURCE, payload, response => {
    dispatch(branchOffices.add(response))
    callback()
  })
}
