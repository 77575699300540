import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/salaries'

export const createSalary = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, callback, callbackErr)
}

export const updateSalary = (salaryID, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${salaryID}`, payload, callback, callbackErr)
}

export const deleteSalary = (contractID, salaryID, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${salaryID}/contracts/${contractID}`, callback, callbackErr)
}

export const getSalariesByContractID = (contractID, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/contracts/${contractID}`, callback, callbackErr)
}
