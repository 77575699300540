import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PayrollDashboardSkeleton = () => (
  <>
    <div className="ed-grid lg-grid-4 rows-gap">
      <div className="lg-cols-4">
        <Skeleton height={110} />
      </div>
      <div className="lg-cols-2 ed-grid rows-gap">
        <Skeleton height={150} />
        <Skeleton height={300} />
      </div>
      <div className="lg-cols-2">
        <Skeleton height={500} />
      </div>
    </div>
  </>
)

export default PayrollDashboardSkeleton
