import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import BankInformationItem from '../../../Atoms/Items/BankInformationItem'
import TableRow from '../../../Wrappers/TableRow'
import TableOptionsButton from '../../TableOptionsButton'
import {isEqual} from '../../../../helpers/utils'
import {capitalize} from '../../../../helpers/string-format'
import {WIRE_TRANSFER} from '../../../../helpers/payroll'
import {getFormatCurrency} from '../../../../helpers/format-number'
import Tag from '../../../Atoms/Tag'
import {approveDeposit, deleteDeposit} from '../../../../services/depositPayment'
import {notifyError, notifySuccessful} from '../../../../services/notification'
import ConfirmModal from '../../../Organisms/Modals/ConfirmModal'
import { downloadBankPlainFileByDepositID } from '../../../../services/bankPlainFiles'
import useObjectURL from '../../../../hooks/useObjectURL'

const REMOVE = "REMOVE", APPROVE = "APPROVE"

const ApprovalPaymentRow = ({
  depositPaymentID,
  processID,
  code,
  bankName,
  employeesNumber,
  paymentMethod,
  bankAccount,
  total,
  isPaid,
  employerSession,
  onDelete,
  onApprove,
}) => {
  const dispatch = useDispatch()
  const confirmModal = useRef()
  const downloadLinkDeposit = useRef()
  const [actionType, setActionType] = useState("")
  const {setObject, objectURL} = useObjectURL(null)

  const handleOpenConfirmDelete = () => {
    setActionType(REMOVE)
    confirmModal.current.openModal()
  }

  const handleOpenConfirmApproval = () => {
    setActionType(APPROVE)
    confirmModal.current.openModal()
  }

  const handleConfirmModal = () => {
    confirmModal.current.closeModal()

    if (isEqual(actionType, APPROVE)) {
      handleApproval()
      return
    } 

    handleDelete()
  }
  
  const handleDelete = () => {
    setActionType("")
    deleteDeposit(depositPaymentID, processID, response => {
      onDelete(depositPaymentID, employeesNumber)
      dispatch(notifySuccessful('Se ha eliminado correctamente el deposito'))
    })
  }
  
  const handleApproval = () => {
    setActionType("")
    approveDeposit(depositPaymentID, response => {
      onApprove(depositPaymentID, employeesNumber)
      dispatch(notifySuccessful(`El deposito ${code} ha sido aprobado correctamente`))
    })
  }

  const handleDownload = () => {
    downloadBankPlainFileByDepositID(
      depositPaymentID,
      response => {
        setObject(new Blob([response]))

        downloadLinkDeposit.current.click()
        setObject(null)
      },
      error => {
        dispatch(notifyError(error))
      }
    )
  }

  return (
    <TableRow>
      <p className="small code-font s-color-title">{code}</p>
      {/* NO usaremos UserAvatarList para el MVP */}
      {/* <UserAvatarList employees={employeesTest} /> */}
      <p className="m-right">{employeesNumber}</p>
      <p>{capitalize(paymentMethod)}</p>
      {isEqual(paymentMethod, WIRE_TRANSFER) ? (
        <BankInformationItem name={bankName} account={bankAccount} />
      ) : (
        <p></p>
      )}
      <p className="m-right">
        {getFormatCurrency(
          total,
          employerSession.code_iso_3,
          !employerSession.not_include_decimals_in_calculation
        )}
      </p>
      {isPaid ? (
        <Tag text="Pagado" color="green" />
      ) : (
        <button className="button micro" onClick={handleOpenConfirmApproval}>
          Autoriza el pago
        </button>
      )}
      <>
        {isPaid ? (
          <TableOptionsButton
            iconSee="download"
            textSee="Descargar"
            seeFunction={isEqual(paymentMethod, WIRE_TRANSFER) ? handleDownload : null}
          />
          ) : (
          <TableOptionsButton
            iconSee="download"
            textSee="Descargar"
            seeFunction={isEqual(paymentMethod, WIRE_TRANSFER) ? handleDownload : null}
            deleteFunction={handleOpenConfirmDelete}
          />
        )}

        {/* This link is hidden, we only use for download the deposit payment */}
        <a
          href={objectURL}
          download={`Deposito_${code}.txt`}
          ref={downloadLinkDeposit}
          className="hidden"
        >
          Descargar deposito de pago
        </a>

        <ConfirmModal
          ref={confirmModal}
          title={isEqual(actionType, APPROVE) ? 'Aprobar Deposito' : 'Eliminar Deposito'}
          content={getContentConfirmModal(actionType)}
          confirmFunction={handleConfirmModal}
        />
      </>
    </TableRow>
  )
}

function getContentConfirmModal(actionType) {
  const actionDesc = isEqual(actionType, APPROVE) ? 'aprobar' : 'eliminar'
  return `¿Estás seguro de ${actionDesc} el deposito?, recuerda que esta acción no se puede revetir.`
}

ApprovalPaymentRow.propTypes = {
  isApproved: PropTypes.bool,
}

ApprovalPaymentRow.defaultProps = {
  isApproved: false,
}

export default ApprovalPaymentRow
