import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {isEqual, isNull} from '../../../helpers/utils'
import Icon from '../../Atoms/Icon'
import ReactTimeAgo from 'react-time-ago'
import {
  findProcessStage,
  STAGE_PAYSHEET,
  STATUS_SUCCESSFUL,
} from '../../../helpers/payroll'
import {markNotificationUserAsRead} from '../../../services/notificationUsers'
import { processRoute } from '../../../config/routes'
import {FloatContainerContext} from '../../../contexts/contexts'
import {capitalize} from '../../../helpers/string-format'

const NotificationItemProcessRun = ({itemID, unread, content, createdAt}) => {
  const {setActive} = useContext(FloatContainerContext)
  const history = useHistory()
  const dispatch = useDispatch()

  const isSuccessful = isEqual(content.status, STATUS_SUCCESSFUL)

  const goToProcess = () => {
    setActive(false)

    history.push({
      pathname: processRoute(content?.year, content?.month, content?.hash),
      hash: findProcessStage(STAGE_PAYSHEET)?.hash,
    })
  }

  const handleOnClick = e => {
    if (unread) {
      dispatch(markNotificationUserAsRead(itemID, goToProcess))
      return
    }

    goToProcess()
  }

  return (
    <div className="s-cross-start nowrap" onClick={handleOnClick}>
      <div className="s-mr-8px">
        {isSuccessful ? (
          <Icon size="24px" color="var(--green-accent)" svg="circle-check-fill" />
        ) : (
          <Icon size="24px" color="var(--red-accent)" svg="information-fill" />
        )}
      </div>
      <div>
        <p className="small s-mb-0">
          El proceso de <span className="s-color-title">{content?.description}</span>{' '}
          calculado por{' '}
          <span className="s-color-title">
            {content?.is_user_exec ? 'ti' : capitalize(content.user_name)}
          </span>{' '}
          finalizó con {isSuccessful ? 'exito' : 'errores'}.
        </p>
        <p className="s-mb-0 smaller s-color-light-text">
          {!isNull(createdAt) && <ReactTimeAgo date={createdAt} />}
        </p>
      </div>
    </div>
  )
}

NotificationItemProcessRun.propTypes = {}

export default NotificationItemProcessRun
