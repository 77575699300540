import React, {useContext, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import NoveltyFormContainer from '../Containers/NoveltyFormContainer'
import SelectEmployeeInput from '../../Molecules/Selects/SelectEmployeeInput'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import Tooltip from '../../Atoms/Templates/Tooltip'
import TooltipContainer from '../Containers/TooltipContainer'
import Icon from '../../Atoms/Icon'
import PerPeriodItem from '../../Molecules/Items/PerPeriodItem'
import {isAnyEqual, isEqual, len} from '../../../helpers/utils'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {initialStateAction} from '../Tables/NoveltyTable'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {PayrollContext} from '../../../contexts/contexts'
import ValidatorForm from '../../../helpers/validator'
import {readBackendDate, writeBackendDate} from '../../../helpers/dates'
import {
  createVacationNovelty,
  updateVacationNovelty,
} from '../../../services/vacationNovelty'
import { ORIGIN_APP_NOMINEROS } from '../../../helpers/origin'

const formFields = {
  contract_id: 'Empleado',
  vacation_type_id: 'Tipo',
  begins_at: 'Inicio',
}

const rules = {
  contract_id: 'required|integer|not_in:0',
  vacation_type_id: 'required|integer|not_in:0',
  begins_at: 'required|date',
}

const VacationNoveltyForm = ({
  action,
  setAction,
  initialState,
  reloadNovelties,
  setReloadNovelties,
}) => {
  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)

  const isDisabled = isEqual(action.type, SHOW)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const vacationTypes = cacheGeneral.data?.vacation_types
  const {activeProcess} = useContext(PayrollContext)
  const [formData, setFormData] = useState(initialState)

  const handleOnSelectEmployeeInput = contractID => {
    setFormData(state => ({
      ...state,
      contract_id: Number(contractID),
    }))
  }

  const handleOnChangeVacationType = e => {
    const value = Number(e.target.value)
    const options = e.target.options
    const i = options.selectedIndex

    setFormData(state => ({
      ...state,
      vacation_type_id: value,
      vacation_is_in_money: isEqual(options[i].getAttribute('data-in-money'), 'true'),
      amount_days: '',
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date,
    }))
  }

  const handleOnChangeWorkingDays = e => {
    const value = e.target.value

    const pattern = formData.vacation_is_in_money
      ? /^\d{1,2}(\.(\d{1,2})?)?$/
      : /^\d{1,2}$/

    if (isEqual(value, '') || pattern.test(value)) {
      setFormData(state => ({
        ...state,
        amount_days: value,
      }))
    }
  }

  const handleCancelForm = () => setAction(initialStateAction)

  const handleSuccessForm = () => {
    if (isEqual(action.type, CREATE)) handleCreate()
    if (isEqual(action.type, EDIT)) handleEdit()
  }

  const handleValidate = () => {
    const payload = {
      contract_id: formData.contract_id,
      vacation_type_id: formData.vacation_type_id,
      begins_at: writeBackendDate(formData.begins_at),
      process_hash: activeProcess.hash,
      calendar_days: 0,
      working_days: Number(formData.amount_days),
      origin: ORIGIN_APP_NOMINEROS,
    }

    if (formData.vacation_is_in_money) {
      payload.calendar_days = Number(formData.amount_days)
      payload.working_days = 0
    }

    const validator = new ValidatorForm(payload, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      throw validator.errors()
    }

    if (isEqual(formData.amount_days, '')) {
      // eslint-disable-next-line
      throw 'Debes digitar una cantidad de días válida'
    }

    return payload
  }

  const handleCreate = () => {
    try {
      const payload = handleValidate()
      createVacationNovelty(payload, response => {
        setAction(initialStateAction)
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('Hemos creado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleEdit = () => {
    try {
      const payload = handleValidate()
      updateVacationNovelty(formData.id, payload, response => {
        setAction(initialStateAction)
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('Hemos Actualizado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const successButtonText = isEqual(action.type, CREATE)
    ? 'Agregar Vacaciones'
    : isEqual(action.type, EDIT)
    ? 'Editar Vacaciones'
    : 'Guardar'

  return (
    <NoveltyFormContainer
      cancelFunction={handleCancelForm}
      successFunction={handleSuccessForm}
      successButtonText={successButtonText}
      noveltyStatus={initialState.status}
    >
      <SelectEmployeeInput
        isRequired
        isDisabled={isAnyEqual(action.type, [EDIT, SHOW])}
        id="contract_id"
        name="contract_id"
        label="Empleado"
        placeholder="Busca por Identificación o nombre"
        onSelect={handleOnSelectEmployeeInput}
        initialValue={formData.employee_description}
      />

      <Select
        isRequired
        isDisabled={isDisabled}
        id="vacation_type"
        name="vacation_type"
        label="Tipo"
        placeholder="Selecciona un tipo de vacaciónes"
        onChange={handleOnChangeVacationType}
        value={formData.vacation_type_id}
      >
        {Array.isArray(vacationTypes) &&
          vacationTypes.map(vacationType => (
            <option
              key={vacationType.id}
              value={vacationType.id}
              data-in-money={vacationType.in_money}
            >
              {vacationType.description}
            </option>
          ))}
      </Select>

      <DatePickerInput
        isRequired
        disabled={isDisabled}
        id="begins_at"
        name="begins_at"
        label="Inicio"
        onChange={handleOnChangeBeginsAt}
        value={formData.begins_at}
        minDate={
          isEqual(activeProcess.application_pay_period_id, 0)
            ? readBackendDate(activeProcess.begins_at)
            : readBackendDate(activeProcess.begins_at_application_period)
        }
        maxDate={
          isEqual(activeProcess.application_pay_period_id, 0)
            ? readBackendDate(activeProcess.ends_at)
            : readBackendDate(activeProcess.ends_at_application_period)
        }
      />
      <div className="flex">
        <FormInput
          isRequired
          isDisabled={isDisabled}
          id="amount_days"
          name="amount_days"
          className="s-mr-1"
          label={`Cantidad de días ${
            formData.vacation_is_in_money ? 'a compensar' : 'hábiles'
          }`}
          placeholder={`${formData.vacation_is_in_money ? '1.25' : '1'}`}
          onChange={handleOnChangeWorkingDays}
          value={formData.amount_days}
        />

        {formData.vacation_is_in_money && (
          <TooltipContainer className="s-cross-start s-py-2px">
            <Icon
              svg="information"
              color="var(--blue-accent)"
              className="s-mr-4px cursor-pointer"
            />
            <Tooltip>
              <div className="whitespace-nowrap">
                <span>Art. 20 Ley 1429 de 2010</span>
                <p className="s-mb-0 smaller s-color-lighter-text">
                  Solicita mínimo la misma cantidad de días en descanso
                </p>
              </div>
            </Tooltip>
          </TooltipContainer>
        )}
      </div>

      {Array.isArray(initialState.vacation_per_periods) &&
        len(initialState.vacation_per_periods) > 1 && (
          <div className="m-cols-3 ed-grid rows-gap s-gap-1">
            <h4>Distribución de la novedad por periodos</h4>
            <div className="ed-grid s-grid-1 m-grid-3 rows-gap">
              {initialState.vacation_per_periods.map(novelty => (
                <PerPeriodItem key={novelty.id} perPeriodNovelty={novelty} />
              ))}
            </div>
          </div>
        )}
    </NoveltyFormContainer>
  )
}

export default VacationNoveltyForm
