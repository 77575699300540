import axios from 'axios'
import store from '../redux/store'
import {NOTIFICATION} from '../redux/ducks/notification'
import {closeSession} from './session'
import {isEmpty, isFunction, isString} from './utils'

export class Request {
  constructor(token = null) {
    // create an axios instance
    this.axios = axios.create({baseURL: process.env.REACT_APP_API_URL})
    this.hasAuthorization = false

    if (token) {
      this.hasAuthorization = true
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    // returns only the data or specific error of Go API
    this.axios.defaults.transformResponse = [
      function (data) {
        if (isString(data)) {
          try {
            if (isEmpty(data)) return data

            data = JSON.parse(data)
            if (data.errors) {
              return data.errors[0].message
            }

            return data.data
          } catch (e) {
            console.log('Server backend is off', e)
          }
        }

        return data
      },
    ]
  }

  async get(route, callback, callbackError) {
    try {
      const response = await this.axios.get(route)
      callback(response.data)
    } catch (error) {
      this.handleError(route, error, callbackError)
    }
  }

  async getWithCancelationSignal(route, cancelTokenSource, callback, callbackError) {
    try {
      const response = await this.axios.get(route, {
        cancelToken: cancelTokenSource.token,
      })

      callback(response.data)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message)
        return
      }

      this.handleError(route, error, callbackError)
    }
  }

  async getWithResponseObject(route, callback, callbackError) {
    try {
      const response = await this.axios.get(route)
      callback(response)
    } catch (error) {
      this.handleError(route, error, callbackError)
    }
  }

  async getFile(route, callback, callbackError) {
    try {
      const response = await this.axios.get(route, {
        // Los datos de esta petición se interpretan como datos binarios.
        responseType: 'blob',
      })

      callback(response.data, response.headers)
    } catch (error) {
      let data = await error.response.data.text()
      data = JSON.parse(data)
      error.response.data = data.errors[0].message

      this.handleError(route, error, callbackError)
    }
  }

  async generateFile(route, body, callback, callbackError) {
    try {
      const response = await this.axios.put(route, body, {
        // Los datos de esta petición se interpretan como datos binarios.
        responseType: 'blob',
      })

      callback(response.data, response.headers)
    } catch (error) {
      let data = await error.response.data.text()
      data = JSON.parse(data)
      error.response.data = data.errors[0].message

      this.handleError(route, error, callbackError)
    }
  }

  async post(route, body, callback, callbackError) {
    try {
      const response = await this.axios.post(route, body)
      callback(response.data)
    } catch (error) {
      this.handleError(route, error, callbackError)
    }
  }

  async put(route, body, callback, callbackError) {
    try {
      const response = await this.axios.put(route, body)
      callback(response.data)
    } catch (error) {
      this.handleError(route, error, callbackError)
    }
  }

  async delete(route, callback, callbackError, payload = null) {
    try {
      const response = await this.axios.delete(route, {data: payload})
      callback(response.data)
    } catch (error) {
      this.handleError(route, error, callbackError)
    }
  }

  handleError = (route, error, callbackError) => {
    if (this.hasAuthorization && error.response && (error.response.status === 401 || error.response.status === 403)) {
      closeSession()
      return
    }

    if (error.response) {
      if (isFunction(callbackError)) {
        callbackError(error.response.data)
        return
      }

      store.dispatch({
        type: NOTIFICATION,
        payload: {
          message: error.response.data,
          severity: 'error',
        },
      })
      return
    }

    if (error.request) {
      console.log('el servidor del API esta apagado')
      return
    }

    console.log('Error', route, error.message)
    return
  }
}

export class RequestWithAuthorization extends Request {
  constructor() {
    super(localStorage.getItem(process.env.REACT_APP_TOKEN_KEY))
  }
}

export class RequestWithEmployerAuthorization extends Request {
  constructor() {
    super(sessionStorage.getItem(process.env.REACT_APP_TOKEN_EMPLOYER_KEY))
  }
}
