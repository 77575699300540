import React from 'react'
import PropTypes from 'prop-types'

const PayslipContractItem = ({
  employeeName,
  identificationType,
  identificationNumber,
  contractHash,
}) => {
  return (
    <section className="s-mb-2 m-mb-4 s-center m-left">
      <p className="normal s-color-title s-mb-0">{employeeName}</p>
      <p className="smaller s-mb-0">
        <span className="s-mr-8px">
          {identificationType} {identificationNumber}
        </span>
        <span className="code-font s-color-blue">{contractHash}</span>
      </p>
    </section>
  )
}

PayslipContractItem.propTypes = {
  employeeName: PropTypes.string.isRequired,
  identificationType: PropTypes.string.isRequired,
  identificationNumber: PropTypes.string.isRequired,
  contractHash: PropTypes.string.isRequired,
}

PayslipContractItem.defaultProps = {
  employeeName: '',
  identificationType: '',
  identificationNumber: '',
  contractHash: '',
}

export default PayslipContractItem
