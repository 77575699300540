import React, {useContext, useRef} from 'react'
import {useSelector} from 'react-redux'
import FormInput from '../../Atoms/Templates/FormInput'
import Select from '../../Atoms/Forms/Select'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import DropContainer from '../../Molecules/DropContainer'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getLocalCache, getCacheKeyGeneral} from '../../../helpers/cache'
import {isNull, isEqual, stringToBool, isEmpty} from '../../../helpers/utils'
import {FormDataContext} from '../../../contexts/contexts'
import useObjectURL from '../../../hooks/useObjectURL'
import ModalCropImage from '../Modals/ModalCropImage'

const PersonalDataEmployeeForm = () => {
  const employerSession = useSelector(employerSelectors.getSession)

  const {formData, setFormData, updateFormField} = useContext(FormDataContext)
  const {setObject, objectURL} = useObjectURL(null)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const identifications = !isNull(cacheGeneral) && cacheGeneral.data?.identification_types

  const modalCrop = useRef()

  const handleOnChangeIdentificationType = e => {
    updateFormField(e)

    // reset the field identification_number and social security questions
    // and update the field is_document_alphanumeric and is_document_foreign
    const options = e.target.options
    const i = options.selectedIndex
    let isAlphanumeric = options[i].getAttribute('data-is-alphanumeric')
    let isForeign = options[i].getAttribute('data-is-foreign')

    setFormData(state => ({
      ...state,
      is_document_alphanumeric: stringToBool(isAlphanumeric),
      is_document_foreign: stringToBool(isForeign),
      identification_number: '',
      // reset social security questions:
      is_pensioner: false,
      is_foreigner_without_pension: false,
      is_colombian_living_abroad: false,
      has_colombian_living_abroad_pay_health: false,
      // show pension entity because is_pensioner and is_foreigner_without_pension was reseted
      entities: [
        ...state.entities.map(e => {
          if (e.abbreviation !== "AFP") return e
          return {
            ...e,
            is_show: true,
            social_security_entity_id: 0,
          }
        }),
      ],
    }))
  }

  const handleOnChangeNumber = e => {
    const value = e.target.value

    const pattern = /^[0-9]+$/
    // only accept numbers or '' when the user empty the input
    if (isEqual(value, '') || pattern.test(value)) updateFormField(e)
  }

  const handleOnChangePhoneNumber = e => {
    const value = e.target.value

    const pattern = /^\+?[0-9]{0,3}\s?[0-9]*$/
    // only accept numbers or '' when the user empty the input
    if (isEqual(value, '') || pattern.test(value)) updateFormField(e)
  }

  const handleOnChangeIdentificationNumber = e => {
    if (isEqual(formData.identification_type_id, 0)) return

    if (formData.is_document_alphanumeric) {
      updateFormField(e)
      return
    }

    handleOnChangeNumber(e)
  }

  const handleDropLogo = async acceptedFiles => {
    if (isEmpty(acceptedFiles)) return

    setObject(acceptedFiles[0])
    modalCrop.current.openModal()
  }

  const handleCropLogoComplete = image => {
    setObject(image)

    setFormData(state => ({
      ...state,
      object_picture: image,
    }))

    modalCrop.current.closeModal()
  }

  const handleCancelCropLogo = () => {
    setObject(null)
    modalCrop.current.closeModal()
  }

  return (
    <>
      <div className="form-grid ed-grid m-grid-2">
        <Select
          isRequired
          label="Tipo de identificación"
          name="identification_type_id"
          id="identification_type_id"
          placeholder
          value={formData.identification_type_id}
          onChange={handleOnChangeIdentificationType}
        >
          {Array.isArray(identifications) &&
            identifications.map(identification => (
              <option
                key={identification.id}
                value={identification.id}
                data-is-alphanumeric={identification.is_alphanumeric}
                data-is-foreign={identification.is_foreign}
              >
                {identification.description}
              </option>
            ))}
        </Select>

        <FormInput
          isRequired
          label="No. de identificatión"
          name="identification_number"
          id="identification_number"
          value={formData.identification_number}
          onChange={handleOnChangeIdentificationNumber}
          disabled={isEqual(formData.identification_type_id, 0)}
        />

        <FormInput
          isRequired
          label="Primer nombre"
          name="first_name"
          id="first_name"
          value={formData.first_name}
          onChange={updateFormField}
        />

        <FormInput
          label="Segundo nombre"
          name="middle_name"
          id="middle_name"
          value={formData.middle_name}
          onChange={updateFormField}
        />

        <FormInput
          isRequired
          label="Primer apellido"
          name="last_name"
          id="last_name"
          value={formData.last_name}
          onChange={updateFormField}
        />

        <FormInput
          label="Segundo apellido"
          name="surname"
          id="surname"
          value={formData.surname}
          onChange={updateFormField}
        />

        <Select
          label="Estado civil"
          name="marital_status"
          id="marital_status"
          placeholder="Elige un estado civil"
          value={formData.marital_status}
          onChange={updateFormField}
        >
          {['CASADO', 'DIVORCIADO', 'SOLTERO', 'UNION LIBRE', 'VIUDO'].map(
            maritalStatus => (
              <option key={maritalStatus} value={maritalStatus}>
                {maritalStatus}
              </option>
            )
          )}
        </Select>

        <FormInput
          type="email"
          label="Correo electrónico"
          placeholder="correo@example.com"
          name="email"
          id="email"
          value={formData.email}
          onChange={updateFormField}
        />

        <FormInput
          type="tel"
          label="Teléfono"
          placeholder="+057 7112233"
          name="phone"
          id="phone"
          value={formData.phone}
          onChange={handleOnChangePhoneNumber}
        />

        <FormInput
          type="tel"
          label="Celular"
          placeholder="+057 3119876543"
          name="mobile"
          id="mobile"
          value={formData.mobile}
          onChange={handleOnChangePhoneNumber}
        />

        <div className="m-cols-2 form-grid ed-grid m-grid-3">
          <DatePickerInput
            label="Fecha de nacimiento"
            name="birthdate"
            id="birthdate"
            value={formData.birthdate}
            onChange={date => setFormData(state => ({...state, birthdate: date}))}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <FormInput
            label="Lugar de nacimiento"
            name="birthplace"
            id="birthplace"
            placeholder="Bogotá"
            value={formData.birthplace}
            onChange={updateFormField}
          />

          <Select
            isRequired
            label="Género"
            name="gender"
            id="gender"
            placeholder
            value={formData.gender}
            onChange={updateFormField}
          >
            {['FEMENINO', 'MASCULINO'].map(gender => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </Select>
        </div>

        <FormInput
          label="Dirección"
          placeholder="CL 1 23 45"
          name="address"
          id="address"
          value={formData.address}
          onChange={updateFormField}
        />

        <DropContainer
          label="Fotografía"
          tiny
          id="employee_picture"
          handleFiles={handleDropLogo}
          file={objectURL}
        />
      </div>

      <p className="smaller s-main-center s-pt-4 s-mb-0">
        Los campos con <span className="s-color-blue">*</span> son obligatorios
      </p>

      {/* Modal Crop Image */}
      <ModalCropImage
        ref={modalCrop}
        title="Posiciona y redimensiona tu imagen"
        imageURL={objectURL}
        onCropImageComplete={handleCropLogoComplete}
        onCancel={handleCancelCropLogo}
      />
    </>
  )
}

export default PersonalDataEmployeeForm
