import React, {useState, useRef, useContext} from 'react'
import Table from '../../Wrappers/Table'
import OcassionalNoveltyRow from './Rows/OcassionalNoveltyRow'
import Proptypes from 'prop-types'
import Sort from '../Sort'
import SelectEmployeeInput from '../Selects/SelectEmployeeInput'
import SelectConceptInput from '../Selects/SelectConceptInput'
import {useSelector} from 'react-redux'
import {selectors as employerConceptSelectors} from '../../../redux/ducks/concepts'
import {getConceptsNoveltyByProcessType} from '../../../helpers/concepts'
import {PayrollContext} from '../../../contexts/contexts'
import {deleteOccasionalNovelty, generateOccasionalNoveltiesReport} from '../../../services/occasionalnovelty'
import {initialStateAction} from '../../Organisms/Tables/NoveltyTable'
import {useDispatch} from 'react-redux'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {isEmpty, isEqual} from '../../../helpers/utils'
import NoveltyEmptyState from '../../Organisms/Emptys/NoveltyEmptyState'
import {STATUS_HISTORY} from '../../../helpers/payroll'
import NoveltyImage from '../../../static/images/stories/occasional-novelty.svg'
import ActionButton from '../../Atoms/Templates/ActionButton'
import OccasionalNoveltyReportModal from '../../Organisms/Modals/OccasionalNoveltyReportModal'
import useObjectURL from '../../../hooks/useObjectURL'

const OccasionalNoveltyTable = ({
  setAction,
  novelties,
  setFilter,
  setSortOption,
  defaultSortOption,
  reloadNovelties,
  setReloadNovelties,
}) => {
  const noveltyConcepts = useSelector(employerConceptSelectors.getNoveltyConcepts)
  const ConceptByProcessType = useSelector(employerConceptSelectors.getProcessConcepts)
  const {activeProcess} = useContext(PayrollContext)
  const concepts = getConceptsNoveltyByProcessType(noveltyConcepts, ConceptByProcessType, activeProcess.process_type_id)

  const dispatch = useDispatch()

  const reportModal = useRef()
  const downloadLinkReport = useRef()
  const {setObject, objectURL} = useObjectURL(null)
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')

  const handleOnSelectConceptInput = conceptID => {
    setFilter({key: 'concept_id', value: conceptID})
  }

  const handleOnSelectEmployeeInput = contractID => {
    setFilter({key: 'contract_id', value: contractID})
  }

  const handleOnSelectSortOption = option => {
    setSortOption(option)
  }

  const handleOpenReportModal = () => {
    reportModal.current.openModal()
  }

  const handleOnDelete = noveltyID => {
    deleteOccasionalNovelty(noveltyID, response => {
      setAction(initialStateAction)
      setReloadNovelties(!reloadNovelties)
      dispatch(notifySuccessful('Hemos eliminado la novedad ocasional exitosamente'))
    })
  }

  const handleOnClickGenerateReport = (from, to) => {
    setIsLoadingDownloadReport(true)

    generateOccasionalNoveltiesReport(from, to)
      .then(({data, headers}) => {
        setObject(data)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
      })
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  return (
    <>
      <div className="m-cross-end s-flex-gap-12px flex-grow m-main-end s-column m-row s-px-2 s-pb-2 m-px-4 m-pb-4">
        <SelectEmployeeInput
          className="small"
          name="employee_id"
          id="employee_id"
          label="Empleado"
          placeholder="Busca por Identificación o nombre"
          onSelect={handleOnSelectEmployeeInput}
        />
        <SelectConceptInput
          className="small"
          name="concept_id"
          id="concept_id"
          label="Concepto"
          placeholder="Busca por código o nombre"
          onSelect={handleOnSelectConceptInput}
          concepts={concepts}
        />

        {/* Ordenar */}
        <div className="flex s-flex-gap-8px">
          <Sort
            options={[defaultSortOption, {key: 'concept_id', value: 'Concepto'}]}
            defaultSortOption={defaultSortOption}
            onSelect={handleOnSelectSortOption}
          />

          <ActionButton
            icon="download"
            onClick={handleOpenReportModal}
            className="s-border-border"
            isDisabled={isLoadingDownloadReport}
          />
        </div>
      </div>

      {isEmpty(novelties) ? (
        <NoveltyEmptyState
          title={isEqual(activeProcess?.status, STATUS_HISTORY) ? '¡Oh! no hay novedades' : 'Novedades Ocasionales'}
          description={
            isEqual(activeProcess?.status, STATUS_HISTORY) ? (
              <>
                Este proceso no tuvo <span className="s-color-text">novedades ocasionales</span>.
              </>
            ) : (
              <>
                Aún no tienes novedades, da click en <span className="s-color-text">Agrega una novedad</span> para
                comenzar.
              </>
            )
          }
          image={NoveltyImage}
        />
      ) : (
        <Table heads={['Empleado', 'Concepto', 'Cantidad', 'Valor', 'Acciones']}>
          {Array.isArray(novelties) &&
            novelties.map(novelty => (
              <OcassionalNoveltyRow
                key={novelty.id}
                novelty={novelty}
                setAction={setAction}
                onDelete={handleOnDelete}
              />
            ))}
        </Table>
      )}

      {/* Report modal */}
      <OccasionalNoveltyReportModal
        ref={reportModal}
        onGenerate={handleOnClickGenerateReport}
        initialBeginsAt={activeProcess.begins_at}
        initialEndsAt={activeProcess.ends_at}
      />

      {/* This link is hidden, we only use for download the payroll report */}
      <a ref={downloadLinkReport} href={objectURL} download={fileNameDownloaded} className="hidden">
        file downloaded
      </a>
    </>
  )
}

OccasionalNoveltyTable.propTypes = {
  novelties: Proptypes.array.isRequired,
}

OccasionalNoveltyTable.defaultProps = {
  novelties: [],
}

export default OccasionalNoveltyTable
