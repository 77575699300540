import React, {useEffect} from 'react'
import SettingHeader from '../Headers/SettingHeader'

const OrganizationalStructureSettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="lg-cols-2">
        <SettingHeader
          icon="rectangle-group"
          title="Estructura organizacional"
          description="Configura tu estructura organizacional"
          isComingSoon
        />
      </div>

      <div></div>
    </>
  )
}

export default OrganizationalStructureSettings
