import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types'
import Logotype from '../../static/images/logo/logo.svg'

const Logo = ({className, size}) => {
  return (
    <Link to="/"
      className={`nomineros-logo s-cross-center s-main-center ${className}`}>
      <img
        className={`${size.width} ${size.height}`}
        src={Logotype}
        alt="nomineros Logo"
      />
    </Link>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  })
}

Logo.defaultProps = {
  className: '',
  size: {
    width: '',
    height: '',
  }
}

export default Logo;
