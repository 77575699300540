import React, {useContext, useState} from 'react'
import {useSelector} from 'react-redux'
import SelectEmployeeInput from '../../Molecules/Selects/SelectEmployeeInput'
import FormInput from '../../Atoms/Templates/FormInput'
import NoveltyFormContainer from '../Containers/NoveltyFormContainer'
import {isAnyEqual, isEqual} from '../../../helpers/utils'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import SelectConceptInput from '../../Molecules/Selects/SelectConceptInput'
import ValidatorForm from '../../../helpers/validator'
import {PayrollContext} from '../../../contexts/contexts'
import {useDispatch} from 'react-redux'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {createOccasionalNovelty, updateOccasionalNovelty} from '../../../services/occasionalnovelty'
import {selectors as employerConceptSelectors} from '../../../redux/ducks/concepts'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import {getConceptsNoveltyByProcessType} from '../../../helpers/concepts'
import {initialStateAction} from '../Tables/NoveltyTable'
import TextArea from '../../Atoms/Forms/TextArea'
import {ORIGIN_APP_NOMINEROS} from '../../../helpers/origin'

const formFields = {
  contract_id: 'empleado',
  concept_id: 'concepto',
  quantity: 'cantidad',
  value: 'valor',
  notes: 'Notas u observaciones',
}

const rules = {
  contract_id: 'required|integer|not_in:0',
  concept_id: 'required|integer|not_in:0',
  pay_period_id: 'required|integer|not_in:0',
  quantity: 'required|numeric',
  value: 'required|numeric',
  notes: 'string',
}

const OccasionalNoveltyForm = ({action, setAction, initialState, reloadNovelties, setReloadNovelties}) => {
  const {activeProcess} = useContext(PayrollContext)
  const noveltyConcepts = useSelector(employerConceptSelectors.getNoveltyConcepts)
  const ConceptByProcessType = useSelector(employerConceptSelectors.getProcessConcepts)
  const configObj = useSelector(configProcessTypeSelectors.getObj)
  const configProcess = configObj[activeProcess?.process_type_code]

  const concepts = getConceptsNoveltyByProcessType(noveltyConcepts, ConceptByProcessType, activeProcess.process_type_id)

  const isDisabled = isEqual(action.type, SHOW)
  const [formData, setFormData] = useState(initialState)

  const dispatch = useDispatch()

  const handleChangeValue = e => {
    const valueField = e.target.value
    const pattern = /^[0-9]+\.?(\d{1,2})?$/

    if (isEqual(valueField, '') || pattern.test(valueField)) {
      setFormData(state => ({
        ...state,
        value: valueField,
        // reset state quantity
        quantity: 0,
      }))
    }
  }

  const handleChageQuantity = e => {
    const quantityField = e.target.value
    const pattern = /^[0-9]{1,3}(\.(\d{1,2})?)?$/

    if (isEqual(quantityField, '') || pattern.test(quantityField)) {
      setFormData(state => ({
        ...state,
        quantity: quantityField,
        // reset state value
        value: 0,
      }))
    }
  }

  const handleOnChangeInputText = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      [name]: value,
    }))
  }

  const handleOnSelectConceptInput = conceptID => {
    setFormData(state => ({
      ...state,
      concept_id: Number(conceptID),
    }))
  }

  const handleOnSelectEmployeeInput = contractID => {
    setFormData(state => ({
      ...state,
      contract_id: Number(contractID),
    }))
  }

  const handleCancelForm = () => {
    setAction(initialStateAction)
  }

  const handleSuccessForm = () => {
    if (isEqual(action.type, CREATE)) handleCreate()
    if (isEqual(action.type, EDIT)) handleEdit()
  }

  const handleValidate = () => {
    const payload = {
      contract_id: formData.contract_id,
      concept_id: formData.concept_id,
      pay_period_id: Number(activeProcess.pay_period_id),
      quantity: Number(formData.quantity),
      value: Number(formData.value),
      notes: formData.notes,
      origin: ORIGIN_APP_NOMINEROS,
    }

    // if the process has an application pay period id, we create the novelty with this period id
    if (!isEqual(activeProcess.application_pay_period_id, 0)) {
      payload.pay_period_id = activeProcess.application_pay_period_id
    }

    // always add the process_id if the process type has marked "has_novelty_by_process"
    if (configProcess?.config?.rules?.has_novelty_by_process) {
      payload.process_id = activeProcess.id
    }

    const validator = new ValidatorForm(payload, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      throw validator.errors()
    }

    if (
      (isEqual(payload.value, 0) && isEqual(payload.quantity, 0)) ||
      (!isEqual(payload.value, 0) && !isEqual(payload.quantity, 0))
    ) {
      // eslint-disable-next-line
      throw `Los campos ${formFields.quantity} y ${formFields.value}, no pueden ser cero simultaneamente`
    }

    return payload
  }

  const handleCreate = () => {
    try {
      const payload = handleValidate()
      createOccasionalNovelty(payload, response => {
        setAction(initialStateAction)
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('Hemos creado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleEdit = () => {
    try {
      const payload = handleValidate()
      updateOccasionalNovelty(formData.id, payload, response => {
        setAction(initialStateAction)
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('Hemos actualizado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const successButtonText = isEqual(action.type, CREATE)
    ? 'Agregar Novedad'
    : isEqual(action.type, EDIT)
    ? 'Editar Novedad'
    : 'Guardar'

  return (
    <NoveltyFormContainer
      cancelFunction={handleCancelForm}
      successFunction={handleSuccessForm}
      successButtonText={successButtonText}
    >
      <SelectEmployeeInput
        isRequired
        isDisabled={isAnyEqual(action.type, [EDIT, SHOW])}
        name="employee_id"
        id="employee_id"
        label="Empleado"
        placeholder="Busca por Identificación o nombre"
        onSelect={handleOnSelectEmployeeInput}
        initialValue={formData.employee_description}
      />

      <SelectConceptInput
        isRequired
        isDisabled={isDisabled}
        name="concept_id"
        id="concept_id"
        label="Concepto"
        placeholder="Busca por código o nombre"
        onSelect={handleOnSelectConceptInput}
        concepts={concepts}
        initialValue={formData.concept_description}
      />

      <FormInput
        isDisabled={isDisabled}
        isRequired
        name="value"
        id="value"
        label="Valor"
        placeholder="0.00"
        value={formData.value}
        onChange={handleChangeValue}
        align="right"
      />

      <FormInput
        isDisabled={isDisabled}
        isRequired
        name="quantity"
        id="quantity"
        label="Cantidad"
        placeholder="0.00"
        value={formData.quantity}
        onChange={handleChageQuantity}
        align="right"
      />

      <div className="s-order-1 m-order-initial m-rows-2 lg-rows-2 m-cols-2 lg-cols-2">
        <TextArea
          isDisabled={isDisabled}
          className="s-h-full"
          name="notes"
          id="notes"
          placeholder="Escribe una nota"
          value={formData.notes}
          onChange={handleOnChangeInputText}
          align="left"
          label="Notas u observaciones"
          textarea={{
            className: 's-h-full',
          }}
        />
      </div>
    </NoveltyFormContainer>
  )
}

export default OccasionalNoveltyForm
