import React, {useContext} from 'react'
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import Icon from '../Icon'
import {useMedia} from '../../../hooks/useMedia'
import {mediaToLg} from '../../../helpers/media-queries'
import {MainSidebarContext} from '../../../contexts/contexts'
import routes from '../../../config/routes'

const NavItem = ({text, icon, url, className, onClick, ...rest}) => {
  // Contexto para cerrar el menú en móvil
  const isMobile = useMedia(mediaToLg)
  const {setCollapsed} = useContext(MainSidebarContext)

  // Verifica si la página actual es la activa
  const router = useLocation()

  const isActive =
    url === '/' || url === ''
      ? router.pathname === url
      : router.pathname.startsWith(routes.payroll.base)
      ? url.includes(routes.payroll.base)
      : router.pathname.startsWith(url)

  // Cambia el ícono por uno relleno cuando el item está activo
  const customIcon = isActive ? `${icon}-solid` : icon

  const clickFunction = () => {
    onClick()
    // Valida si es celular para cerrar el menú después de seleccionar una página
    if (isMobile) setCollapsed(true)
  }

  return (
    <li
      onClick={clickFunction}
      className={`nav-item normal-radius s-color-text background-transition ${className} ${isActive && 'is-active'}`}
    >
      <span {...rest}>
        <Link to={url} className="s-color-current nowrap s-cross-center s-px-12px s-py-12px lg-py-8px decoration-none ">
          <Icon svg={customIcon} size="1rem" className="s-mr-8px" />
          <span className="whitespace-nowrap">{text}</span>
        </Link>
      </span>
    </li>
  )
}

NavItem.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
}

NavItem.defaultProps = {
  url: '',
  onClick: () => {},
}

export default NavItem
