import React, {useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import Employee from '../../Templates/Employee'
import TableOptionsButton from '../../TableOptionsButton'
import TableRow from '../../../Wrappers/TableRow'
import {PayrollContext} from '../../../../contexts/contexts'
import {isAnyEqual, isEqual} from '../../../../helpers/utils'
import {EDIT, SHOW, STATUS_HISTORY} from '../../../../helpers/payroll'
import {initialStateAction} from '../../../Organisms/Tables/NoveltyTable'
import {shortFormatDate} from '../../../../helpers/dates'
import DeleteModal from '../../../Organisms/Modals/DeleteModal'
import {capitalize} from '../../../../helpers/string-format'
import TooltipContainer from '../../../Organisms/Containers/TooltipContainer'
import Tooltip from '../../../Atoms/Templates/Tooltip'
import Icon from '../../../Atoms/Icon'
import { ORIGIN_INTEGRATION_NBC } from '../../../../helpers/origin'
import SAP_LOGO from '../../../../static/images/utils/SAP-logo.png'

const MAP_ORIGIN = {
  [ORIGIN_INTEGRATION_NBC]: {logo: SAP_LOGO}
}

const VacationNoveltyRow = ({novelty, setAction, onDelete}) => {
  const {activeProcess} = useContext(PayrollContext)
  const isHistory = isAnyEqual(STATUS_HISTORY, [activeProcess?.status, novelty?.status])
  const deleteModal = useRef()
  const originLogo = MAP_ORIGIN[novelty?.origin]?.logo

  const handleOpenDeleteModal = () => {
    deleteModal.current.openModal()
  }

  const handleSee = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setAction({
      type: SHOW,
      noveltyID: novelty.id,
    })
  }

  const handleEdit = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setAction({
      type: EDIT,
      noveltyID: novelty.id,
    })
  }

  const handleDelete = () => {
    handleOpenDeleteModal()
    setAction(initialStateAction)
  }

  const handleOnClick = () => {
    onDelete(novelty.id)
    setAction(initialStateAction)
    deleteModal.current.closeModal()
  }

  return (
    <>
      <TableRow>
        <Employee employee={novelty} />
        <div className="s-mb-0 s-cross-center">
          <span className={originLogo && "s-mr-8px"}>
            {capitalize(novelty?.vacation_type_description)}
          </span>
          { originLogo &&
            <img src={originLogo} alt="origen" style={{width: "2rem"}}/>  
          }          
        </div>
        <p className="s-mb-0">{shortFormatDate(novelty.begins_at)}</p>
        <p className="s-mb-0">{shortFormatDate(novelty.ends_at)}</p>
        <p className="s-mb-0 m-right">
          {novelty.working_days || novelty.calendar_days || 0}
        </p>
        <p className="s-mb-0 m-right">{novelty.non_working_days || 0}</p>

        <div className="flex s-cross-center">
          <div className="s-mr-1">
            {isHistory ? (
              <TableOptionsButton seeFunction={handleSee} />
            ) : (
              <TableOptionsButton
                deleteFunction={handleDelete}
                editFunction={handleEdit}
              />
            )}
          </div>

          {isEqual(STATUS_HISTORY, novelty.status) && (
            <TooltipContainer className="s-cross-center">
              <Icon
                svg="information"
                color="var(--blue-accent)"
                className="s-mr-4px cursor-pointer"
              />
              <Tooltip>
                <div className="whitespace-nowrap">
                  <span>Novedad en historico</span>
                  <p className="s-mb-0 smaller s-color-lighter-text">
                    No es posible modificarla
                  </p>
                </div>
              </Tooltip>
            </TooltipContainer>
          )}
        </div>
      </TableRow>
      <DeleteModal ref={deleteModal} deleteFunction={handleOnClick} />
    </>
  )
}

VacationNoveltyRow.propTypes = {
  novelty: PropTypes.shape({}).isRequired,
  setAction: PropTypes.func,
}

VacationNoveltyRow.defaultProps = {
  novelty: PropTypes.shape({}),
  setAction: () => {},
}

export default VacationNoveltyRow
