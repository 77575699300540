import React from 'react'
import PropTypes from 'prop-types'
import Container from './Container'

const PayrollHeaderWrapper = ({children, className}) => {
  return (
    <Container className={`payroll-header ${className}`}>
      <header className="s-main-justify s-cross-center s-flex-gap-12px">
        {children}
      </header>
    </Container>
  )
}

PayrollHeaderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

PayrollHeaderWrapper.defaultProps = {
  className: '',
}

export default PayrollHeaderWrapper
