import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {selectors as JobSelectors} from '../../../redux/ducks/jobs'
import {isEqual} from 'helpers/utils'
import Select from 'components/Atoms/Forms/Select'
import DatePickerInput from 'components/Molecules/Pickers/DatePickerInput'
import ValidatorForm from 'helpers/validator'
import {notifyError, notifySuccessful} from 'services/notification'
import {changeJob} from 'services/jobHistory'

const REPLACE_JOB = 'REPLACE_JOB'
const NEW_JOB = 'NEW_JOB'

const formFields = {
  update_type: 'Tipo de Actualización',
  is_update_current_job_id: '¿Actualiza cargo actual?',
  contract_id: 'Contrato',
  employer_job_id: 'Nuevo cargo',
  begins_at: 'Fecha Inicial Nuevo cargo',
}

const initialState = {
  update_type: REPLACE_JOB,
  is_update_current_job_id: true,
  contract_id: 0,
  employer_job_id: 0,
  employer_job_description: '', // only for logic
  begins_at: null,
}

const rules = {
  update_type: `required|in:${REPLACE_JOB},${NEW_JOB}`,
  is_update_current_job_id: 'boolean|required',
  contract_id: 'integer|required|not_in:0',
  employer_job_id: 'integer|required|not_in:0',
  begins_at: `date|required_if:update_type,${NEW_JOB}`,
}

const JobChangeForm = ({
  isResetData = false,
  contractID = 0,
  currentJob = '',
  hireDate = null,
  terminationDate = null,
  onClose = () => {},
  onUpdate = () => {},
}) => {
  const dispatch = useDispatch()
  const employerJobs = useSelector(JobSelectors.getAll).filter(
    j => j.description !== currentJob
  )

  const [formData, setFormData] = useState({...initialState, contract_id: contractID})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isResetData) return

    setFormData({...initialState, contract_id: contractID})
  }, [isResetData, contractID])

  const handleOnChangeJob = e => {
    const value = e.target.value

    const options = e.target.options
    const i = options.selectedIndex
    let descriptionJob = options[i].getAttribute('data-description')

    setFormData(state => ({
      ...state,
      employer_job_id: Number(value),
      employer_job_description: descriptionJob,
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date,
    }))
  }

  const handleChangeUpdateType = e => {
    const type = e.target.value

    setFormData(state => ({
      ...state,
      update_type: type,
      is_update_current_job_id: isEqual(type, REPLACE_JOB),
      begins_at: null,
    }))
  }

  const handleChangeJob = () => {
    const validator = new ValidatorForm(formData, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    setIsLoading(true)

    changeJob(
      formData,
      response => {
        onUpdate(state => ({
          ...state,
          employer_job: formData.employer_job_description,
        }))

        dispatch(notifySuccessful('El cargo ha sido actualizado correctamente 🎉'))
        setIsLoading(false)
        onClose()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  return (
    <form noValidate className="s-mb-0 ed-grid rows-gap form-grid">
      <div className="ed-grid m-grid-2 rows-gap">
        <Select
          isRequired
          label={formFields.update_type}
          name="update_type"
          id="update_type"
          placeholder
          value={formData.update_type}
          onChange={handleChangeUpdateType}
        >
          {[
            {
              code: REPLACE_JOB,
              description: 'Remplazar cargo actual (sobrescribir)',
            },
            {code: NEW_JOB, description: 'Agregar nuevo cargo (crea historial)'},
          ].map(opt => (
            <option key={opt.code} value={opt.code}>
              {opt.description}
            </option>
          ))}
        </Select>

        <Select
          isRequired
          label={formFields.employer_job_id}
          name="employer_job_id"
          id="employer_job_id"
          placeholder
          value={formData.employer_job_id}
          onChange={handleOnChangeJob}
        >
          {Array.isArray(employerJobs) &&
            employerJobs.map(j => (
              <option key={j.id} value={j.id} data-description={j.description}>
                {j.description}
              </option>
            ))}
        </Select>

        {isEqual(formData.update_type, NEW_JOB) && (
          <DatePickerInput
            isRequired
            label={formFields.begins_at}
            name="begins_at"
            id="begins_at"
            value={formData.begins_at}
            onChange={handleOnChangeBeginsAt}
            minDate={hireDate}
            maxDate={terminationDate}
          />
        )}
      </div>

      <p className="smaller s-center">
        Los campos con <span className="s-color-blue">*</span> son obligatorios
      </p>
      <div className="buttons-container">
        <button
          type="button"
          className="button ghost cancel s-order-3 m-order-1"
          onClick={onClose}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="button ghost s-order-1 m-order-3"
          onClick={handleChangeJob}
          disabled={isLoading}
        >
          {isLoading ? 'Actualizando...' : 'Actualizar'}
        </button>
      </div>
    </form>
  )
}

export default JobChangeForm
