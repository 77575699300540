import React, {useEffect} from 'react'
import PayrollParameterSettingsTab from './PayrollParameterSettingsTab'
import TabsWrapper from '../../Wrappers/TabsWrapper'
import SettingHeader from '../Headers/SettingHeader'

const PayrollParameterSettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
        <SettingHeader
          icon="clipboard-copy"
          title="Parametros de Nómina"
        />

      <div className="lg-cols-2">
        <TabsWrapper defaultActive={1}>
          <PayrollParameterSettingsTab />
        </TabsWrapper>
      </div>
    </>
  )
}

export default PayrollParameterSettings
