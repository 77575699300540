import React, {useRef, forwardRef, useState} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import RadioButton from '../../Atoms/Forms/RadioButton'
import MultiSelectEmployeeInput from '../../Molecules/Selects/MultiSelectEmployeeInput'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import ValidatorForm from '../../../helpers/validator'
import {useDispatch} from 'react-redux'
import {notifyError} from '../../../services/notification'
import {isEmpty, isEqual} from '../../../helpers/utils'

const TYPE_SELECTION = 'SELECTION'
const TYPE_ALL = 'ALL'

const initialState = {
  from: null,
  to: null,
  selection_type: TYPE_ALL,
  contract_ids: [],
}

const rules = {
  from: 'date|required',
  to: 'date|required|after_or_equal:from',
}

const fieldNames = {
  from: 'Desde',
  to: 'Hasta',
}

const HistoryReportModal = forwardRef(({onGenerate = () => {}}, ref) => {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState(initialState)
  const multiSelectRef = useRef()

  const handleGenerate = () => {
    const validator = new ValidatorForm(formData, rules)
    validator.setAttributeNames(fieldNames)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    if (isEqual(formData.selection_type, TYPE_SELECTION)) {
      formData.contract_ids = multiSelectRef.current.getContractIDs()

      if (isEmpty(formData.contract_ids)) {
        dispatch(notifyError('¡Upps! debes seleccionar al menos un empleado'))
        return
      }
    }

    onGenerate(formData.from, formData.to, formData.contract_ids)
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(initialState)
  }

  const handleOnChangeFrom = date => {
    setFormData(state => ({
      ...state,
      from: date,
    }))
  }

  const handleOnChangeTo = date => {
    setFormData(state => ({
      ...state,
      to: date,
    }))
  }

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
      // clean the contract list if the selection_type changed
      contract_ids: isEqual(name, 'selection_type') ? [] : state.contract_ids,
    }))
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Historial de Nómina</h3>
      <div className="ed-grid s-grid-2 rows-gap s-mb-1">
        <DatePickerInput
          isRequired
          label={fieldNames.from}
          name="from"
          id="from"
          value={formData.from}
          onChange={handleOnChangeFrom}
        />

        <DatePickerInput
          isRequired
          label={fieldNames.to}
          name="to"
          id="to"
          minDate={formData.from}
          value={formData.to}
          onChange={handleOnChangeTo}
        />

        <div className="s-cols-2 s-mb-1">
          <RadioButtonsWrapper label="Empleados" className="s-mb-1">
            <RadioButton
              name="selection_type"
              label="Todos"
              value={TYPE_ALL}
              onChange={updateRadioButton}
              checked={isEqual(formData.selection_type, TYPE_ALL)}
            />

            <RadioButton
              name="selection_type"
              label="Una selección"
              value={TYPE_SELECTION}
              onChange={updateRadioButton}
              checked={isEqual(formData.selection_type, TYPE_SELECTION)}
            />
          </RadioButtonsWrapper>

          {isEqual(formData.selection_type, TYPE_SELECTION) && (
            <MultiSelectEmployeeInput
              ref={multiSelectRef}
              placeholder="Escribe para buscar empleados"
            />
          )}
        </div>
      </div>

      <button className="button" onClick={handleGenerate}>
        Descargar
      </button>
    </ModalTemplate>
  )
})

export default HistoryReportModal
