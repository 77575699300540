import React, {useState} from 'react'
import {Router, Switch} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {history} from '../helpers/history'
import Public from './Routes/Public'
import Private from './Routes/Private'
import PrivateEmployer from './Routes/PrivateEmployer'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Register from '../pages/Register'
import ForgotPassword from '../pages/ForgotPassword'
import OAuth from '../pages/OAuth'
import NewPassword from '../pages/NewPassword'
import CompanyAbout from '../pages/CompanyAbout'
import SelectedCompany from '../pages/SelectedCompany'
import Notification from '../componets/Legacy/Notification'
import {actions as notificationActions} from '../redux/ducks/notification'
import routes from '../config/routes'
import HelpPanel from '../components/Organisms/Panels/HelpPanel'
import {isAnyEqual, isEqual, isUndefined} from '../helpers/utils'
import {URL_BASE_HELP} from '../helpers/routes'

const App = () => {
  const dispatch = useDispatch()
  const notification = useSelector(store => store.notificationReducer)
  const [isShowHelpPanel, setIsShowHelpPanel] = useState(false)
  const [slugHelp, setSlugHelp] = useState('')

  const handleClose = () => dispatch(notificationActions.clear())
  const handleCloseHelp = () => {
    setIsShowHelpPanel(false)
    setSlugHelp('')
  }

  const handleClickApp = e => {
    const target = e.target

    if (target.classList.contains('help-launcher')) {
      setSlugHelp('welcome')
      setIsShowHelpPanel(true)
      return
    }

    if (!isAnyEqual(target.tagName, ['A', 'svg'])) return

    if (isUndefined(target.dataset.href) || !target.dataset.href.startsWith(URL_BASE_HELP)) return

    setSlugHelp(target.dataset.href.slice(URL_BASE_HELP.length))
    setIsShowHelpPanel(true)
  }

  return (
    <div onClick={handleClickApp}>
      <Notification message={notification.message} severity={notification.severity} onClose={handleClose} />

      <HelpPanel isShow={isShowHelpPanel} onClose={handleCloseHelp} slug={slugHelp} onFinish={setSlugHelp} />

      <Router history={history}>
        {/*
          This Project use Nested Routing, you can find the nested routes inside for each component
        */}
        <Switch>
          {/* Public */}
          <Public path={routes.login} exact>
            <Login />
          </Public>
          <Public path={routes.registry} exact>
            <Register />
          </Public>
          <Public path={routes.forgotPassword} exact>
            <ForgotPassword />
          </Public>
          <Public path={routes.resetPassword} exact>
            <NewPassword />
          </Public>
          <Public path={routes.oauth} exact>
            <OAuth />
          </Public>

          {/* Private */}
          <Private path={routes.employers} exact>
            <SelectedCompany />
          </Private>
          <Private path={routes.newEmployer} exact>
            <CompanyAbout />
          </Private>

          {/* PrivateEmployer */}
          <PrivateEmployer path={routes.home}>
            <Home />
          </PrivateEmployer>
        </Switch>
      </Router>
    </div>
  )
}

export default App
