import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Container from '../../Wrappers/Container'
import ProcessPercentageItem from '../../Molecules/Items/ProcessPercentageItem'
import LastProcessEmptyState from '../Emptys/LastProcessEmptyState'
import { activeMonthRoute, processRoute } from '../../../config/routes'
import {isEmpty} from '../../../helpers/utils'
import {findProcessStage} from '../../../helpers/payroll'
import {capitalize} from '../../../helpers/string-format'
import {selectors as employerMonthSelector} from '../../../redux/ducks/employerMonth'
import IconBackground from '../../Atoms/IconBackground'

const DashboardLastProcess = ({processes}) => {
  const history = useHistory()
  const activeMonth = useSelector(employerMonthSelector.active)

  return (
    <Container className="m-cols-2">
      <header className="s-cross-center s-mb-2 m-mb-3">
        <div className='s-cross-center s-main-center'>
        <IconBackground
          svg="bolt"
          bgColor="s-bg-alt-red"
          color="white"
          className="s-mr-1"
          />
        <h3 className="s-mb-0">Últimos procesos</h3>
        </div>
        {!isEmpty(processes) && (
          <Link className="small s-to-right" to={activeMonthRoute(activeMonth)}>
            Ve todos los procesos
          </Link>
        )}
      </header>

      {isEmpty(processes) ? (
        <LastProcessEmptyState
          captionActionButton="Ir a Nómina"
          onClickActionButton={() => {
            history.push(activeMonthRoute(activeMonth))
          }}
        />
      ) : (
        <div className="ed-grid m-grid-2 s-gap-2 m-gap-3 row-gap">
          {processes.slice(0, 4).map(process => {
            const stage = findProcessStage(process.stage)
            const status = stage?.name ?? ''
            const percentage = stage.percentage ?? ''

            return (
              <ProcessPercentageItem
                key={process.id}
                description={process.description}
                type={capitalize(process.process_type_description)}
                status={process.is_paid_full ? 'Completado' : status}
                percetage={process.is_paid_full ? '100%' : percentage}
                onClick={() =>
                  history.push({
                    pathname: processRoute(process?.year, process?.month, process?.hash),
                    hash: findProcessStage(process.stage)?.hash,
                  })
                }
              />
            )
          })}
        </div>
      )}
    </Container>
  )
}

export default DashboardLastProcess
