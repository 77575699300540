import React, {useState, useContext, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {findProcessStage, PROCESS_TYPE_RUN_BY_MONTH_WITH_CLOSED_PERIODS, STAGE_PAYSHEET} from '../../../helpers/payroll'
import ValidatorForm from '../../../helpers/validator'
import {notifyError} from '../../../services/notification'
import {createProcess} from '../../../services/process'
import {processRoute} from '../../../config/routes'
import {PayrollContext} from '../../../contexts/contexts'
import FormInput from '../../Atoms/Templates/FormInput'
import {isAnyUndefined, isEmpty, isEqual, isUndefined} from '../../../helpers/utils'
import {capitalize} from '../../../helpers/string-format'
import {getMonth} from '../../../helpers/dates'
import WarningImg from '../../../static/images/stories/warning.svg'

const formFields = {
  process_type_code: 'Tipo de proceso',
  description: 'Nombre del proceso',
}

const rules = {
  process_type_code: `required|string`,
  description: 'required|string|min:5',
}

const initialStateFormData = {
  process_type_code: '',
  description: '',
}

const ProcessExecByMonthForm = ({data, handleCloseModal}) => {
  const [formData, setFormData] = useState(initialStateFormData)
  const dispatch = useDispatch()
  const history = useHistory()
  const {payrollDate} = useContext(PayrollContext)

  const [isDisable, setIsDisable] = useState(false)
  const activePeriods = data?.periods.filter(p => p.is_active)

  useEffect(() => {
    if (isAnyUndefined(data?.type, data?.periods)) return

    const somePeriod = data?.periods[0]?.pay_period
    if (isUndefined(somePeriod)) return

    setFormData(state => ({
      ...state,
      description: `${capitalize(data?.type?.description)} ${getMonth(somePeriod.month - 1)} ${somePeriod.year}`,
    }))
  }, [data])

  const handleOnChangeDescription = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      description: value,
    }))
  }

  const handleCreateProcess = () => {
    setIsDisable(true)

    const payload = {
      process_type_code: data?.type?.code,
      description: formData.description,
    }

    const validator = new ValidatorForm(payload, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      setIsDisable(false)
      return
    }

    createProcess(
      payload,
      response => {
        setIsDisable(false)
        handleCloseModal()
        history.push({
          pathname: processRoute(payrollDate?.year, payrollDate?.month, response?.hash),
          hash: findProcessStage(
            Array.isArray(data?.type?.config?.rules?.stages) ? data?.type?.config?.rules?.stages[0] : STAGE_PAYSHEET
          )?.hash,
        })
      },
      error => {
        setIsDisable(false)
        dispatch(notifyError(error))
      }
    )
  }

  if (data?.type?.config?.rules?.run?.is_unique && !isEmpty(data?.processes)) {
    return (
      <AlertProcess
        content={`¡Upps! solo puedes tener un proceso de ${capitalize(data?.type?.description)} en el mes.`}
      />
    )
  }

  if (
    isEqual(data?.type?.config?.rules?.run?.run_by, PROCESS_TYPE_RUN_BY_MONTH_WITH_CLOSED_PERIODS) &&
    !isEmpty(activePeriods)
  ) {
    return (
      <AlertProcess
        content={`¡Upps! solo puedes crear un proceso de ${capitalize(
          data?.type?.description
        )} cuando todos los periodos esten cerrados.`}
      />
    )
  }

  return (
    <div>
      <h3 className="s-center">{`Nuevo Proceso de ${capitalize(data?.type?.description)}`}</h3>
      <form className="ed-grid form-grid form s-mb-0">
        <FormInput
          label={formFields.description}
          placeholder="Nombre del proceso"
          isRequired
          value={formData.description}
          onChange={handleOnChangeDescription}
        />
        <div className="buttons-container">
          <button type="button" className="button cancel" onClick={handleCloseModal}>
            No, cancelar
          </button>
          <button type="button" className="button" onClick={handleCreateProcess} disabled={isDisable}>
            {isDisable ? 'Creando...' : 'Sí, crear'}
          </button>
        </div>
      </form>
    </div>
  )
}

const AlertProcess = ({content}) => {
  return (
    <div className="ed-grid s-grid-3 s-gap-2">
      <img src={WarningImg} alt="Warning" width="200px" height="200px" />
      <div className="s-cols-2 s-left s-cross-center">
        <p className="s-mb-0">{content}</p>
      </div>
    </div>
  )
}

export default ProcessExecByMonthForm
