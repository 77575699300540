import React from 'react'
import FormStep from '../Atoms/FormStep'

const NewEmployeeSteps = () => {
  return (
    <div className="l-block">
      <div className="t3 s-center s-mb-16px m-mb-24px">Nuevo empleado</div>
      <div className="s-main-center">
        <FormStep number={1} text="Datos personales" />
        <FormStep number={2} text="Contrato" />
        <FormStep number={3} text="Seguridad social" />
        <FormStep number={4} text="Pago" />
        <FormStep number={5} text="Impuestos" />
      </div>
    </div>
  )
}

export default NewEmployeeSteps
