import React from 'react'
import PropTypes from 'prop-types'
import { accumulations } from '../../../helpers/base'
import PreviewMarkdown from '../PreviewMarkdown'
import Tag from '../../Atoms/Tag'

const Base = ({base, onSelect}) => {
  return (
    <article className="s-mb-0" onClick={() => onSelect(base.id || base.base_id)}>
      <div className='s-cross-center'>
        <span className="s-mr-8px small code-font s-color-light-text">{base.base}</span>

        <Tag
          text={accumulations[base.accumulation_type]}
          color="green"
          bgColor="s-bg-light-green"
        />
      </div>

      <PreviewMarkdown content={base.description} className="smaller" />
    </article>
  )
}

Base.propTypes = {
  base: PropTypes.shape({
    id: PropTypes.number,
    base_id: PropTypes.number,
    base: PropTypes.string,
    description: PropTypes.string,
    accumulation_type: PropTypes.string,
  }),
  onSelect: PropTypes.func,
}

Base.defaultProps = {
  base: {
    id: 0,
    base_id: 0,
    base: '',
    description: '',
    accumulation_type: '',
  },
  onSelect: () => {},
}

export default Base
