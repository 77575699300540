import React, {useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import EmployeesPanel from '../Panels/EmployeesPanel'
import useFetchWithConditions from '../../../hooks/useFetchWithConditions'
import {getEmployees} from '../../../services/employees'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getLocalCache, getCacheKeyGeneral} from '../../../helpers/cache'
import {useRouteMatch, useHistory} from 'react-router-dom'
import routes from '../../../config/routes'
import {isEqual, isNull} from '../../../helpers/utils'
import {capitalize} from '../../../helpers/string-format'
import {getEmployeeMaster} from '../../../services/payrollReports'
import useObjectURL from '../../../hooks/useObjectURL'
import {notifyError} from '../../../services/notification'
import EmployeeMasterReportModal from '../Modals/EmployeeMasterReportModal'

const EmployeesSection = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {path} = useRouteMatch()
  const employerSession = useSelector(employerSelectors.getSession)

  const defaultSortOption = {key: 'first_name', value: 'Nombre'}
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractStatus = !isNull(cacheGeneral) && cacheGeneral.data?.contract_statuses

  const {setObject, objectURL} = useObjectURL(null)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const downloadLinkReport = useRef()
  const reportModal = useRef()

  const {
    data,
    loading,
    page,
    setPage,
    isLastPage,
    setSortOption,
    setFilterOption,
    setSearchValue,
  } = useFetchWithConditions(defaultSortOption, 20, getEmployees)

  const redirectToRootEmployeePage = () => history.push(path)

  const handleAddEmployee = () => {
    history.push(`${path}${routes.employees.new}`)
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleOpenEmployeeMasterReportModal = () => {
    reportModal.current.openModal()
  }

  const handleOnClickGenerateReport = statuses => {
    setIsLoadingDownloadReport(true)

    getEmployeeMaster(
      statuses,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  const handleSelectEmployee = employee => {
    history.push(`${path}${routes.employees.query}/${employee.employee_hash}`)
  }

  const handleChangeSort = option => {
    redirectToRootEmployeePage()
    setSortOption(option)
  }

  const handleChangeFilter = option => {
    redirectToRootEmployeePage()
    setFilterOption(option)
  }

  const handleSearchEmployee = value => {
    setSearchValue(value)
  }

  const handlerScrollEnd = () => {
    if (isLastPage) return

    setPage(page + 1)
  }

  return (
    <>
      <EmployeesPanel
        employees={data}
        loading={loading && isEqual(page, 1)} // only show skeleton if the page is equal to 1
        tag={{descriptionField: 'contract_status', colorField: 'contract_status_color'}}
        limitEmployees={100}
        defaultSortOption={defaultSortOption}
        sortOptions={[defaultSortOption, {key: 'last_name', value: 'Apellido'}]}
        filterOptions={
          Array.isArray(contractStatus)
            ? contractStatus.map(status => ({
                key: status.id,
                value: capitalize(status.description),
              }))
            : []
        }
        showAddEmployeeButton
        onClickAddEmployee={handleAddEmployee}
        onClickDownloadReport={handleOpenEmployeeMasterReportModal}
        isLoadingDownloadReport={isLoadingDownloadReport}
        onSelectEmployee={handleSelectEmployee}
        onChangeFilter={handleChangeFilter}
        onChangeSort={handleChangeSort}
        onChangeSearch={handleSearchEmployee}
        onScrollEnd={handlerScrollEnd}
      />

      <EmployeeMasterReportModal
        ref={reportModal}
        onGenerate={handleOnClickGenerateReport}
      />

      {/* This link is hidden, we only use for download the report */}
      <a
        ref={downloadLinkReport}
        href={objectURL}
        download={fileNameDownloaded}
        className="hidden"
      >
        file downloaded
      </a>
    </>
  )
}

export default EmployeesSection
