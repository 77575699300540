import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/deposit-payments'

export const createDeposit = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(
    RESOURCE,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const deleteDeposit = (depositID, processID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/processes/${processID}/deposit-payments/${depositID}`, response => callback(response))
}

export const approveDeposit = (depositID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/approve/${depositID}`, null, response => callback(response))
}

export const getDepositsByProcessID = (processID, cancelTokenSource, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.getWithCancelationSignal(`${RESOURCE}/processes/${processID}`, cancelTokenSource, response =>
    callback(response)
  )
}

export const getSummaryDepositsByProcessID = (processID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/summary/processes/${processID}`, response => callback(response))
}
