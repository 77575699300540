import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions as areas} from '../redux/ducks/areas'

const RESOURCE = '/organization-structs'

export const getAreas = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    RESOURCE,
    response => dispatch(areas.set(!Array.isArray(response) ? [] : response)),
    error => console.error('organization-structs:', error)
  )
}
