export const READ = 'READ'
export const UNREAD = 'UNREAD'

// Notification user types
export const NOTIFICATION_PROCESS_RUN = 'PROCESS_RUN'
export const NOTIFICATION_PROCESS_APPROVE = 'PROCESS_APPROVE'
export const NOTIFICATION_PROCESS_FATAL = 'PROCESS_FATAL'
export const NOTIFICATION_CLOSED_PERIOD = 'CLOSED_PERIOD'
export const NOTIFICATION_CLOSE_MONTH = 'CLOSED_MONTH'
export const NOTIFICATION_ELECTRONIC_PAYROLL = 'ELECTRONIC_PAYROLL'
export const NOTIFICATION_RUNNER_DEBUGGER = 'RUNNER_DEBUGGER'
