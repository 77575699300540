import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {isAuthenticated} from '../../helpers/session'
import {redirectRoute} from '../routes'

const Private = ({children, ...rest}) => {
  if (!isAuthenticated()) {
    return <Redirect to={redirectRoute.public} />
  }

  return <Route {...rest}>{children}</Route>
}

export default Private
