import React from 'react';
import ModalTemplate from "../../Wrappers/ModalTemplate";
import FormInput from "../../Atoms/Templates/FormInput";

const PaymentAuthorizationModal = props => {
  return (
    <ModalTemplate
      className="payment-authorization-modal s-center"
    >
      <h3>Autorización de pago</h3>
      <p>Para autorizar el pago es necesario que ingrese el código <span className="s-color-blue">SF54F</span></p>
      <FormInput
        className="l-block s-to-center"
        placeholder="Código"
      />
      <div className="buttons-container">
        <button className="button cancel">Cancelar</button>
        <button className="button"
          disabled>Autoriza el pago
        </button>
      </div>
    </ModalTemplate>
  );
};

export default PaymentAuthorizationModal;
