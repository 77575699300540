import React, {useState, useEffect, useContext} from 'react'
import {isEmpty} from '../../../helpers/utils'
import EditTextButton from '../../Atoms/Buttons/EditTextButton'
import SeeTextButton from '../../Atoms/Buttons/SeeTextButton'
import Icon from '../../Atoms/Icon'
import SearchInput from '../../Atoms/Forms/SearchInput'
import Concept from '../../Molecules/Templates/Concept'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import {EDIT, SHOW} from '../../../helpers/payroll'
import {TabsWrapperContext} from '../../../contexts/contexts'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'
import {getProcessTypesByConceptID} from '../../../services/processTypeConcept'
import {getBasesOfAConcept} from '../../../services/baseConcept'

const ConceptSettingPanelList = ({list, setFormData, setDefaultFormData}) => {
  const {setTabActive} = useContext(TabsWrapperContext)

  const [searchValue, setSearchValue] = useState('')
  const [filterList, setFilterList] = useState(list)

  useEffect(() => {
    setFilterList(list)
    setSearchValue('')
  }, [list])

  const handleSearch = e => {
    let value = e.target.value
    setSearchValue(value)

    if (isEmpty(value)) {
      setFilterList(list)
      return
    }

    value = value.toLowerCase()
    const newList = list.filter(
      c =>
        c.code.toLowerCase().includes(value) ||
        c.description.toLowerCase().includes(value)
    )
    setFilterList(newList)
  }

  const handleEdit = concept => {
    getProcessTypesByConceptID(concept.id, processTypes => {
      getBasesOfAConcept(concept.id, bases => {
        setFormData({
          action: EDIT,
          data: concept,
          processes: [...processTypes],
          bases: [...bases],
        })

        setTabActive(2)
        window.scrollTo({top: 0, behavior: 'smooth'})
      })
    })
  }

  const handleShow = concept => {
    getProcessTypesByConceptID(concept.id, processType => {
      getBasesOfAConcept(concept.id, bases => {
        setFormData({
          action: SHOW,
          data: concept,
          processes: [...processType],
          bases: [...bases],
        })

        setTabActive(2)
        window.scrollTo({top: 0, behavior: 'smooth'})
      })
    })
  }

  const handleNew = () => {
    setDefaultFormData()
    setTabActive(2)
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <>
      <div className="s-main-justify s-mb-1">
        <p className="small">Crea, edita y consulta los conceptos de nómina.</p>
        <div className="s-cross-center">
          <SearchInput
            className="s-mr-1 small"
            value={searchValue}
            onChange={handleSearch}
            placeholder="Busca por nombre o código"
          />
          <button className="button small" onClick={handleNew}>
            Nuevo
          </button>
        </div>
      </div>
      <Table
        heads={['Concepto', 'Acciones']}
        className="table-template"
        classNameTh="border-bottom-white-4"
        classNameHeader="sticky-element-to-header z-fixed"
      >
        {Array.isArray(filterList) &&
          filterList.map(c => (
            <TableRow key={c.id}>
              <div className="ed-grid gap-1 rows-gap">
                <div className="s-cross-start s-overflow-hidden">
                  <Concept concept={c} />
                  <div className="s-cross-center">
                    {c.is_standard && (
                      <div title="Concepto estándar" className="s-mr-1">
                        <Icon svg="nomineros" size="10" />
                      </div>
                    )}
                    {c.is_override && (
                      <div title="Personalizado">
                        <span role="img" aria-label="img">
                          🔖
                        </span>
                      </div>
                    )}
                    {c.is_blocked && (
                      <div title="Bloqueado">
                        <span role="img" aria-label="img">
                          🔒
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="smaller s-overflow-hidden">
                  <PreviewMarkdown content={c?.note?.substr(0, 500)} />
                </div>
              </div>
              <div>
                <EditTextButton onClick={() => handleEdit(c)} />
                <SeeTextButton onClick={() => handleShow(c)} />
              </div>
            </TableRow>
          ))}
      </Table>
    </>
  )
}

export default ConceptSettingPanelList
