import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions as jobs} from '../redux/ducks/jobs'

const resource = '/employer-jobs'

export const getJobs = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    resource,
    response => dispatch(jobs.set(response)),
    error => console.error('employer jobs:', error)
  )
}

export const createJob = payload => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.post(resource, payload, response => dispatch(jobs.add(response)))
}

export const updateJob = (id, payload) => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${resource}/${id}`, payload, response => dispatch(jobs.edit(response)))
}

export const deleteJob = id => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${resource}/${id}`, response => dispatch(jobs.remove(id)))
}
