import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useParams, useHistory} from 'react-router-dom'
import EmployeesHeader from '../Headers/EmployeesHeader'
import PersonaInformationData from '../Panels/PersonaInformationData'
import PaymentDataPanel from '../Panels/PaymentDataPanel'
import SocialSecurityPanel from '../Panels/SocialSecurityPanel'
import ContractPanel from '../Panels/ContractPanel'
import RetentionPanel from '../Panels/RetentionPanel'
import NotesContractPanel from '../Panels/NotesContractPanel'
import {getEmployeeByHash} from '../../../services/employees'
import {isEqual, isUndefined} from '../../../helpers/utils'
import routes from '../../../config/routes'
import {notifyError} from '../../../services/notification'
import EmployeeInformationGridSkeleton from '../Skeleton/EmployeeInformationGridSkeleton'

const EmployeeInformationGrid = () => {
  const {hash} = useParams()
  const history = useHistory()

  const dispatch = useDispatch()
  const [employee, setEmployee] = useState({})
  const [contractSelected, setContractSelected] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})

    const handleFailureFetch = error => {
      setLoading(false)
      history.push(routes.employees.base)
      dispatch(notifyError(error))
    }

    const handleSuccessfulFetch = response => {
      if (isEqual(response, '')) {
        handleFailureFetch('¡Upps! el empleado no existe.')
        return
      }

      setEmployee(response)
      setContractSelected(response.contracts[0])
      setLoading(false)
    }

    setLoading(true)
    getEmployeeByHash(hash, handleSuccessfulFetch, handleFailureFetch)
  }, [hash]) // eslint-disable-line

  return loading ? (
    <EmployeeInformationGridSkeleton />
  ) : // @ts-ignore
  isUndefined(employee.employee) ? null : (
    <>
      <div className="lg-cols-2">
        <EmployeesHeader
          employee={employee}
          contractSelected={contractSelected}
          onChangeContractSelected={setContractSelected}
          onUpdate={setEmployee}
        />
      </div>

      <div>
        <div className="ed-grid row-gap">
          {/* @ts-ignore */}
          <PersonaInformationData data={employee.employee} onUpdate={setEmployee} />
          <SocialSecurityPanel data={contractSelected} onUpdate={setContractSelected}/>
          <NotesContractPanel data={contractSelected} onUpdate={setContractSelected} />
        </div>
      </div>

      <div>
        <div className="ed-grid row-gap">
          <ContractPanel data={contractSelected} onUpdate={setContractSelected} />
          <PaymentDataPanel data={contractSelected} onUpdate={setContractSelected} />
          <RetentionPanel data={contractSelected} onUpdate={setContractSelected} />
        </div>
      </div>
    </>
  )
}

export const validIdentificationParam = (identification, identiTypes) => {
  if (!Array.isArray(identiTypes)) return {isValid: false}
  if (identification.length < 3) return {isValid: false}

  const documentType = identification.slice(0, 2).toUpperCase()
  const documentNumber = identification.slice(2, identification.length)
  const found = identiTypes.filter(ident => isEqual(ident.abbreviation, documentType))

  if (!isEqual(found.length, 1)) return {isValid: false}
  if (!found[0].is_alphanumeric && isNaN(Number(documentNumber))) return {isValid: false}

  return {isValid: true, type: documentType, number: documentNumber}
}

export default EmployeeInformationGrid
