import React from 'react'
import Skeleton from 'react-loading-skeleton'

const EmployeeInformationGridSkeleton = () => (
  <>
    <div className="lg-cols-2">
      <Skeleton height={100} />
    </div>
    <div className="lg-cols-2 ed-grid lg-grid-2 rows-gap">
      <div className="s-cols-1 ed-grid rows-gap">
        <Skeleton height={200} />
        <Skeleton height={300} />
        <Skeleton height={300} />
      </div>
      <div>
        <div className="ed-grid rows-gap">
          <Skeleton height={400} />
          <Skeleton height={300} />
        </div>
      </div>
    </div>
  </>
)

export default EmployeeInformationGridSkeleton
