import Validator from 'validatorjs'
Validator.useLang('es')

class ValidatorForm {
  constructor(data, rules) {
    this.validation = new Validator(data, rules, {
      // add custom messages when the ES language not contains a message for the rule
      // https://github.com/mikeerickson/validatorjs/blob/master/src/lang/es.js
      array: 'El campo :attribute no es una lista de valores.',
      date: 'El campo :attribute no tiene un formato de fecha valido.',
      required_with: 'el campo :attribute es requerido cuando :field no esta vacío.',
      required_if: 'el campo ":attribute" es requerido.',
      after_or_equal:
        'la fecha de :attribute debe ser igual o posterior a la fecha de :after_or_equal.',
      before_or_equal:
        'la fecha de :attribute debe ser igual o anterior a la fecha de :before_or_equal.',
      max: {
        numeric: 'El campo :attribute no debe ser mayor a :max.',
        string: 'El campo :attribute no debe ser mayor que :max caracteres.',
      },
      min: {
        numeric: 'El campo :attribute no debe ser menor a :min.',
        string: 'El campo :attribute debe contener al menos :min caracteres.',
      },
    })
  }

  setAttributeNames(objAttributes) {
    return this.validation.setAttributeNames(objAttributes)
  }

  fails() {
    return this.validation.fails()
  }

  passes() {
    return this.validation.passes()
  }

  errors() {
    let errors = this.validation.errors.all()
    let messages = ''

    for (const field in errors) {
      messages += errors[field].reduce((msgFinal, msg) => (msgFinal += msg + '\n'), '')
    }

    return messages
  }
}

export default ValidatorForm
