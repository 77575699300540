import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import App from './config/App'
import store from './redux/store'
import TimeAgo from 'javascript-time-ago'
import './styles/styles.scss'

import es from 'javascript-time-ago/locale/es.json'
TimeAgo.addDefaultLocale(es)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
