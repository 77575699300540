import React, {useState} from 'react'
import {EmployeeContext} from '../../contexts/contexts'

const EmployeeWrapper = ({children}) => {
  const [findEmployeeValue, setFindEmployeeValue] = useState('')
  const [findEmployeeID, setFindEmployeeID] = useState(0)

  return (
    <EmployeeContext.Provider
      value={{
        findEmployeeValue,
        setFindEmployeeValue,
        findEmployeeID,
        setFindEmployeeID,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  )
}

export default EmployeeWrapper
