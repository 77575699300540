import React, {useState, useEffect, useContext} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import PhonePanel from '../components/Wrappers/PhonePanel'
import TranslatePhonePanel from '../components/Wrappers/TranslatePhonePanel'
import SummaryPayslipPanel from '../components/Organisms/Sections/SummaryPayslipPanel'
import PayslipSection from '../components/Organisms/Panels/PayslipSection'
import EmployeesPayrollSection from '../components/Organisms/Sections/EmployeesPayrollSection'
import {useParams} from 'react-router-dom'
import PayslipEmptyState from '../components/Organisms/Emptys/PayslipEmptyState'
import {getPayslipSummary} from '../services/payslip'
import Errors from './Errors'
import {isEmpty, isEqual, isFunction} from '../helpers/utils'
import PayslipSkeleton from '../components/Organisms/Skeleton/PayslipSkeleton'
import {QUERY_PARAM_PAYSLIP, processRoute} from '../config/routes'
import EmployeeWrapper from '../components/Wrappers/EmployeeWrapper'
import {PayrollContext} from '../contexts/contexts'
import {findProcessStage, STAGE_PAYSHEET, STATUS_HISTORY} from '../helpers/payroll'
import {useMedia} from '../hooks/useMedia'
import {mediaFromM} from '../helpers/media-queries'

const Payslip = () => {
  const fromM = useMedia(mediaFromM)
  const {hash} = useParams()
  const {hash: hashLocation, search} = useLocation()
  const {activeProcess} = useContext(PayrollContext)
  const history = useHistory()

  const [currentQueryParams, setCurrentQueryParams] = useState(null)
  const [payrollSummary, setPayrollSummary] = useState([])

  // initial state of isLoading must be TRUE, for render always the skeleton, so when the
  // component  is mount this can request the payslip summary and it sets if has errors or
  // not, then  only until this moment the load finished and render the component of the
  // <UserPaylip/> or <Errors/>, this for avoid Memory Leaks when this component is mounted
  // with a complete route  to a specific payslip,
  // e.g: "/nominas/2023-11/procesos/JtUqJuycsjodxw9qQz7r-/?comprobante=qUcCVJTeL1H65qACdarz8"
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(true)
  const [hasErrors, setHasErrors] = useState(false)

  // only when the component is mountend we load the skeleton
  useEffect(() => {
    // only when the summary is load we update the isLoading so we avoid Memory Leaks
    loadPayslipSummary(() => setIsLoadingSkeleton(false))
  }, []) // eslint-disable-line

  // when the tab is #nomina then checks there are errors if the process isn't HISTORY
  useEffect(() => {
    if (isEqual(activeProcess.status, STATUS_HISTORY)) {
      return
    }

    if (!isEqual(hashLocation, findProcessStage(STAGE_PAYSHEET)?.hash)) {
      return
    }

    loadPayslipSummary()
  }, [hashLocation, activeProcess])

  useEffect(() => {
    setCurrentQueryParams(new URLSearchParams(search))
  }, [search])

  const loadPayslipSummary = callback => {
    getPayslipSummary(
      hash,
      response => {
        setHasErrors(!isEmpty(response?.process_alerts))
        setPayrollSummary({...response})
        if (isFunction(callback)) callback()
      },
      error => {
        console.error('getPayslipSummary():', error)
      }
    )
  }

  return isLoadingSkeleton ? (
    <PayslipSkeleton />
  ) : (
    <>
      {hasErrors ? (
        <Errors errors={payrollSummary.process_alerts} />
      ) : (
        <main className="ed-grid rows-gap">
          <PhonePanel
            container={{
              className: 'ed-grid m-grid-2 lg-grid-3 s-gap-0 m-gap-2 lg-gap-4',
            }}
            onClickComeBack={() => {
              history.push({
                pathname: processRoute(activeProcess.year, activeProcess.month, hash),
                hash: findProcessStage(STAGE_PAYSHEET)?.hash,
              })
            }}
          >
            <EmployeeWrapper>
              <div className="ed-grid rows-gap s-gap-2 m-gap-4">
                <EmployeesPayrollSection />
              </div>

              <TranslatePhonePanel className="ed-grid rows-gap lg-cols-2 s-gap-2 m-gap-4 s-cross-start mt-none">
                {fromM && <SummaryPayslipPanel data={payrollSummary} />}

                {currentQueryParams?.has(QUERY_PARAM_PAYSLIP) ? (
                  <PayslipSection
                    onUpdateSummary={loadPayslipSummary}
                    contractHash={currentQueryParams?.get(QUERY_PARAM_PAYSLIP)}
                  />
                ) : (
                  <PayslipEmptyState />
                )}
              </TranslatePhonePanel>
            </EmployeeWrapper>
          </PhonePanel>
        </main>
      )}
    </>
  )
}

export default Payslip
