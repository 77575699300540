import React from 'react'
import PayslipImg from '../../../static/images/stories/payslip.svg'
import Container from '../../Wrappers/Container'

const ElectronicPayrollDocumentEmptyState = () => {
  return (
    <Container className="s-column s-center s-cross-center s-main-center">
      <div className="l-block" />
      <img
        className="s-mb-2"
        src={PayslipImg}
        alt="Documento nómina electrónica"
        width="200px"
        height="200px"
      />
      <h3>¡Vaya! esto se ve un poco vacío</h3>
      <p className="s-color-light-text">
        Selecciona un empleado y su nómina electrónica aparecerá aquí.
      </p>
    </Container>
  )
}

export default ElectronicPayrollDocumentEmptyState
