import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions} from '../redux/ducks/employerMonth'

const RESOURCE = '/employer-months'

export const getEmployerMonthActive = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${RESOURCE}/active`,
    response => dispatch(actions.set(response)),
    error => console.error('employer month active:', error)
  )
}

export const closeEmployerMonth = (ID, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()

  request.put(`${RESOURCE}/${ID}/close`, null, callback, callbackError)
}

export const setMonthActive = monthActive => {
  return actions.set(monthActive)
}
