import React, {useState, useContext, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { TabsWrapperContext } from '../../../contexts/contexts'
import {getGlobalParameters} from '../../../services/processParameter'
import { notifyError } from '../../../services/notification'
import TabTemplateBasic from '../../Atoms/Templates/TabTemplateBasic'
import PanelTemplate from '../../Wrappers/PanelTemplate'
import GlobalParameterSettingPanel from '../Panels/GlobalParameterSettingPanel'
import ParameterSettingSkeleton from '../Skeleton/ParameterSettingSkeleton'
import { isEmpty } from '../../../helpers/utils'

const PayrollParameterSettingsTab = () => {
  const dispatch = useDispatch()
  const {setTabActive} = useContext(TabsWrapperContext)

  const [parameterList, setParameterList] = useState([])
  const [customParameterList, setCustomParameterList] = useState([])

  useEffect(()=>{
    fetchGlobalParametersList()
    fetchCustomParametersList()
  }, [])


  const fetchGlobalParametersList = () => {
    getGlobalParameters(response => {
      setParameterList(response)
    })
  }

  const fetchCustomParametersList = () => {
    console.log('fetchCustomParametersList')
  }

  const handleOnClickTab = index => setTabActive(index)

  return (
    <>
      <div className="s-py-1">
        <div className="tab-container s-cross-center">
          {[
            {id: 1, name: 'Globales'},
            // {id: 2, name: 'Personalizados'},
          ].map(t => (
            <TabTemplateBasic
              key={t.id}
              index={t.id}
              className="tab-item"
              onClick={() => handleOnClickTab(t.id)}
            >
              {t.name}
            </TabTemplateBasic>
          ))}
        </div>
      </div>

      <PanelTemplate index={1} onlyHidden>
          {isEmpty(parameterList) ? (
            <ParameterSettingSkeleton />
          ) : (
            <GlobalParameterSettingPanel
              list={parameterList}
            />
          )}
      </PanelTemplate>

      <PanelTemplate index={2} onlyHidden>
      <h1>Este es el otro tab</h1>
      </PanelTemplate>
    </>
  )
}

export default PayrollParameterSettingsTab
