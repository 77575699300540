import React from 'react'
import {Link} from 'react-router-dom'
import OAuthButton from '../components/Atoms/Buttons/OAuthButton'
import GoogleIcon from '../static/images/utils/google.svg'
import FormInput from '../components/Atoms/Templates/FormInput'
import PasswordInput from '../components/Atoms/Forms/PasswordInput'
import WelcomeContainer from '../components/Wrappers/WelcomeContainer'
import {signUp} from '../services/user'
import {notifyError} from '../services/notification'
import {useDispatch} from 'react-redux'
import Recaptcha from 'react-recaptcha'
import ValidatorForm from '../helpers/validator'

const API_URL = process.env.REACT_APP_API_URL

const Register = () => {
  const dispatch = useDispatch()
  let recaptchaInstance

  const executeCaptcha = () => {
    recaptchaInstance.reset()
    recaptchaInstance.execute()
  }

  const submit = form => {
    const data = {
      name: form.name.value,
      email: form.email.value,
      password: form.password.value,
      // captcha,
    }
    const rules = {name: 'required', email: 'required|email', password: 'required'}
    const validation = new ValidatorForm(data, rules)

    if (validation.fails()) {
      dispatch(notifyError(validation.errors()))
      return
    }

    dispatch(signUp(data))
  }

  const verifyCallback = captcha => {
    console.log(captcha)
    document.getElementById('form-register').submit()
  }

  return (
    <WelcomeContainer
      title="Empieza a administrar tus nóminas"
      id="form-register"
      submit={submit}
    >
      {/* Botones para iniciar sesión por OAuth*/}
      <div className="ed-grid">
        <OAuthButton
          icon={GoogleIcon}
          src={`${API_URL}oauth?type=google`}
          content="Registrate con Google"
        />
      </div>

      {/* Datos del usuario */}
      <FormInput
        id="register-name"
        name="name"
        label="Nombre completo"
        placeholder="Juan Pérez Rodríguez"
        type="text"
      />

      <FormInput
        id="login-email"
        name="email"
        label="Correo electrónico"
        placeholder="juan@hotmail.com"
        type="email"
      />

      <div>
        <PasswordInput className="s-mb-8px" />
        <p className="s-mb-0 smaller s-left">
          Al registrarte aceptas los <a href="#">términos y condiciones</a>
        </p>
      </div>

      <div>
        <button type="button" onClick={executeCaptcha} className="button full s-mb-12px">
          Regístrate
        </button>
        <p className="s-mb-0 small">
          ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
        </p>
      </div>
      <Recaptcha
        ref={e => (recaptchaInstance = e)}
        sitekey="6LdGQOoUAAAAAAOL262Z8brASqGsx5nG71vKdmfY"
        size="invisible"
        verifyCallback={verifyCallback}
      />
    </WelcomeContainer>
  )
}

export default Register
