import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/process-type-concepts'

export const getProcessTypesByConceptID = (conceptID, callback) => {
  const request = new RequestWithEmployerAuthorization()

  request.get(`${RESOURCE}/concepts/${conceptID}`, callback)
}

export const appendConceptIDToProcessType = (payload, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()

  request.put(`${RESOURCE}/append`, payload, callback, callbackError)
}

export const removeConceptIDOfProcessType = (processTypeID, conceptID, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()

  request.delete(
    `${RESOURCE}/process-types/${processTypeID}/concepts/${conceptID}`,
    callback,
    callbackError
  ) 
}
