import React, {forwardRef, useState} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import RadioButton from '../../Atoms/Forms/RadioButton'
import Checkbox from '../../Atoms/Forms/Checkbox'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import {useSelector, useDispatch} from 'react-redux'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {notifyError} from '../../../services/notification'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {capitalize} from '../../../helpers/string-format'

const TYPE_SELECTION = 'SELECTION'
const TYPE_ALL = 'ALL'

const initialState = {
  selection_type: TYPE_ALL,
  statuses_index: [],
}

const EmployeeMasterReportModal = forwardRef(({onGenerate = () => {}}, ref) => {
  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractStatus = !isNull(cacheGeneral) && cacheGeneral.data?.contract_statuses

  const [formData, setFormData] = useState(initialState)

  const handleGenerate = () => {
    let statusesList = []

    if (isEqual(formData.selection_type, TYPE_SELECTION)) {
      if (isEmpty(formData.statuses_index)) {
        dispatch(notifyError('¡Upps! debes seleccionar al menos un estado'))
        return
      }

      statusesList = formData.statuses_index.map(i =>
        contractStatus[i].description.toUpperCase()
      )
    }

    onGenerate(statusesList)
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(initialState)
  }

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
      // clean the statuses_index list if the selection_type changed
      statuses_index: isEqual(name, 'selection_type') ? [] : state.statuses_index,
    }))
  }

  const handleOnChangeStatusContract = (ix, isChecked) => {
    if (isChecked) {
      formData.statuses_index.push(ix)
      return
    }

    formData.statuses_index = formData.statuses_index.filter(i => i !== ix)
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Maestro Empleados</h3>
      <div className="s-mb-4">
        <div className="ed-grid s-mb-2">
          <RadioButtonsWrapper label="Estados de contracto">
            <RadioButton
              name="selection_type"
              label="Todos"
              value={TYPE_ALL}
              onChange={updateRadioButton}
              checked={isEqual(formData.selection_type, TYPE_ALL)}
            />

            <RadioButton
              name="selection_type"
              label="Una selección"
              value={TYPE_SELECTION}
              onChange={updateRadioButton}
              checked={isEqual(formData.selection_type, TYPE_SELECTION)}
            />
          </RadioButtonsWrapper>
        </div>

        {isEqual(formData.selection_type, TYPE_SELECTION) && (
          <div className="ed-grid s-grid-2 m-grid-3 rows-gap ">
            {Array.isArray(contractStatus) &&
              contractStatus.map((s, ix) => (
                <Checkbox
                  key={s.id}
                  label={capitalize(s.description)}
                  onChange={e => handleOnChangeStatusContract(ix, e.target.checked)}
                />
              ))}
          </div>
        )}
      </div>

      <button className="button" onClick={handleGenerate}>
        Descargar
      </button>
    </ModalTemplate>
  )
})

export default EmployeeMasterReportModal
