import React, {useState, useContext} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import Proptypes from 'prop-types'
import {isNull, isEmpty, isEqual} from '../../../helpers/utils'
import Select from '../../Atoms/Forms/Select'
import Table from '../../Wrappers/Table'
import SelectEmployeeInput from '../Selects/SelectEmployeeInput'
import Sort from '../Sort'
import VacationNoveltyRow from './Rows/VacationNoveltyRow'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {deleteVacationNovelty} from '../../../services/vacationNovelty'
import {initialStateAction} from '../../Organisms/Tables/NoveltyTable'
import {notifySuccessful} from '../../../services/notification'
import {capitalize} from '../../../helpers/string-format'
import NoveltyEmptyState from '../../Organisms/Emptys/NoveltyEmptyState'
import {PayrollContext} from '../../../contexts/contexts'
import {STATUS_HISTORY} from '../../../helpers/payroll'
import NoveltyImage from '../../../static/images/stories/vacation.svg'

const VacationNoveltyTable = ({
  setAction,
  novelties,
  setFilter,
  setSortOption,
  defaultSortOption,
  reloadNovelties,
  setReloadNovelties,
}) => {
  const dispatch = useDispatch()
  const {activeProcess} = useContext(PayrollContext)
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const vacationTypes = !isNull(cacheGeneral) && cacheGeneral.data?.vacation_types
  vacationTypes.unshift({id: 0, description: 'TODOS'})

  const [vacationTypeID, setVacationTypeID] = useState(0)

  const handleOnSelectEmployeeInput = contractID => {
    setFilter({key: 'contract_id', value: contractID})
  }

  const handleOnChangeVacationType = e => {
    const value = e.target.value
    setVacationTypeID(value)
    setFilter({key: 'vacation_type_id', value: value})
  }

  const handleOnSelectSortOption = option => {
    setSortOption(option)
  }

  const handleOnDelete = noveltyID => {
    deleteVacationNovelty(noveltyID, response => {
      setAction(initialStateAction)
      setReloadNovelties(!reloadNovelties)
      dispatch(notifySuccessful('Hemos eliminado la novedad exitosamente'))
    })
  }

  return (
    <>
      <div className="m-cross-end s-flex-gap-12px flex-grow m-main-end s-column m-row s-px-2 s-pb-2 m-px-4 m-pb-4">
        <SelectEmployeeInput
          className="small"
          name="employee_id"
          id="employee_id"
          label="Empleado"
          placeholder="Busca por Identificación o nombre"
          onSelect={handleOnSelectEmployeeInput}
        />

        <Select
          className="small"
          id="vacation_type"
          name="vacation_type"
          label="Tipo"
          placeholder
          onChange={handleOnChangeVacationType}
          value={vacationTypeID}
        >
          {Array.isArray(vacationTypes) &&
            vacationTypes.map(vacationType => (
              <option key={vacationType.id} value={vacationType.id}>
                {capitalize(vacationType.description)}
              </option>
            ))}
        </Select>

        <Sort
          options={[defaultSortOption, {key: 'vacation_type_id', value: 'Tipo'}]}
          defaultSortOption={defaultSortOption}
          onSelect={handleOnSelectSortOption}
        />
      </div>
      {isEmpty(novelties) ? (
        <NoveltyEmptyState
          title={
            isEqual(activeProcess?.status, STATUS_HISTORY)
              ? '¡Oh! no hay novedades'
              : 'Vacaciones'
          }
          description={
            isEqual(activeProcess?.status, STATUS_HISTORY) ? (
              <>
                Este proceso no tuvo novedades de{' '}
                <span className="s-color-text">vacaciones</span>.
              </>
            ) : (
              <>
                Aún no tienes novedades, da click en{' '}
                <span className="s-color-text">Agrega una novedad</span> para comenzar.
              </>
            )
          }
          image={NoveltyImage}
        />
      ) : (
        <Table
          heads={['Empleado', 'Tipo', 'Inicio', 'Fin', 'Hábil', 'No hábil', 'Acciones']}
        >
          {Array.isArray(novelties) &&
            novelties.map(novelty => (
              <VacationNoveltyRow
                novelty={novelty}
                key={novelty.id}
                setAction={setAction}
                onDelete={handleOnDelete}
              />
            ))}
        </Table>
      )}
    </>
  )
}

VacationNoveltyTable.propTypes = {
  novelties: Proptypes.array.isRequired,
}

VacationNoveltyTable.defaultProps = {
  novelties: [],
}

export default VacationNoveltyTable
