import React from 'react'
import {URL_BASE_HELP} from '../../../helpers/routes'
import TooltipContainer from '../../Organisms/Containers/TooltipContainer'
import Icon from '../Icon'
import Tooltip from '../Templates/Tooltip'

const HelpMeButton = ({title = '', slug = '', className = ''}) => {
  return (
    <TooltipContainer className={`s-cross-center ${className}`}>
      <Icon
        svg="help"
        color="var(--title-color)"
        size="20"
        className="cursor-pointer"
        data-href={`${URL_BASE_HELP}${slug}`}
      />

      {title && (
        <Tooltip>
          <div className="whitespace-nowrap">
            <span>{title}</span>
          </div>
        </Tooltip>
      )}
    </TooltipContainer>
  )
}

export default HelpMeButton
