import {createDuck} from 'redux-duck'
import {SIGN_IN_EMPLOYER, SIGN_OUT_EMPLOYER} from './employer'

const duck = createDuck('app/pay-periods')

// Define action types
export const SET_PAY_PERIODS = duck.defineType('SET_PAY_PERIODS')

// Define action creators
export const actions = {
  set: duck.createAction(SET_PAY_PERIODS),
}

// Define selectors
export const selectors = {
  getAll: store => store.payPeriodsReducer.all,
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_PAY_PERIODS]: (state, {payload}) => ({
      ...state,
      all: [...payload],
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => initialState,
    [SIGN_OUT_EMPLOYER]: (state, {payload}) => initialState,
  },
  initialState
)
