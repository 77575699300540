import React, {useState, useContext} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {findProcessStage, STAGE_PAYSHEET} from '../../../helpers/payroll'
import ValidatorForm from '../../../helpers/validator'
import {notifyError} from '../../../services/notification'
import {createProcess} from '../../../services/process'
import {getMonth, readBackendDate} from '../../../helpers/dates'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {processRoute} from '../../../config/routes'
import {PayrollContext} from '../../../contexts/contexts'
import WarningImg from '../../../static/images/stories/warning.svg'
import FormInput from '../../Atoms/Templates/FormInput'
import {getPeriodRangeAndFrequency} from '../../../helpers/period'
import Select from '../../Atoms/Forms/Select'
import {capitalize} from '../../../helpers/string-format'

const formFields = {
  process_type_code: 'Tipo de proceso',
  pay_frequency_id: 'Periodo',
  description: 'Nombre del proceso',
}

const rules = {
  process_type_code: `required|string`,
  pay_frequency_id: 'required|integer|not_in:0',
  description: 'required|string|min:5',
}

const initialStateFormData = {
  process_type_code: '',
  description: '',
  pay_frequency_id: 0,
}

const ProcessExecByFrequencyForm = ({data, handleCloseModal}) => {
  const [formData, setFormData] = useState(initialStateFormData)
  const dispatch = useDispatch()
  const history = useHistory()
  const {payrollDate} = useContext(PayrollContext)

  const [isDisable, setIsDisable] = useState(false)
  const [hasProcessUnique, setHasProcessUnique] = useState(false)
  const activePeriods = data?.periods.filter(p => p.is_active)

  const handleOnChangeFrequency = e => {
    const value = e.target.value
    const options = e.target.options
    const i = options.selectedIndex

    setFormData(state => ({
      ...state,
      pay_frequency_id: Number(value),
      description: options[i].getAttribute('data-suggested-description'),
    }))

    if (
      data?.type?.config?.rules?.run?.is_unique &&
      !isEmpty(
        data?.processes.filter(
          p =>
            isEqual(p.pay_frequency_id, Number(value)) &&
            isEqual(p.pay_period_id, Number(options[i].getAttribute('data-period-id')))
        )
      )
    ) {
      setHasProcessUnique(true)
      return
    }

    setHasProcessUnique(false)
  }

  const handleOnChangeDescription = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      description: value,
    }))
  }

  const getDescriptionProcess = period => {
    const b = readBackendDate(period.pay_period.begins_at)
    const e = readBackendDate(period.pay_period.ends_at)

    if (isNull(b) || isNull(e)) return ''

    return `${capitalize(data?.type?.description)} ${getMonth(b.getMonth())} ${b
      .getDate()
      .toString()
      .padStart(2, '0')} al ${e.getDate()} del ${e.getFullYear()}`
  }

  const handleCreateProcess = () => {
    setIsDisable(true)

    const payload = {
      process_type_code: data?.type?.code,
      pay_frequency_id: formData.pay_frequency_id,
      description: formData.description,
    }

    const validator = new ValidatorForm(payload, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      setIsDisable(false)
      return
    }

    createProcess(
      payload,
      response => {
        setIsDisable(false)
        handleCloseModal()
        history.push({
          pathname: processRoute(payrollDate?.year, payrollDate?.month, response?.hash),
          hash: findProcessStage(
            Array.isArray(data?.type?.config?.rules?.stages) ? data?.type?.config?.rules?.stages[0] : STAGE_PAYSHEET
          )?.hash,
        })
      },
      error => {
        setIsDisable(false)
        dispatch(notifyError(error))
      }
    )
  }

  if (isEmpty(activePeriods)) {
    return <AlertProcess content={`¡Upps! no hay periodos activos para crear el proceso.`} />
  }

  return (
    <div>
      <h3 className="s-center">{`Nuevo Proceso de ${capitalize(data?.type?.description)}`}</h3>
      <form className="ed-grid form-grid form s-mb-0">
        <div className="ed-grid form-grid">
          <Select
            isRequired
            label={formFields.pay_frequency_id}
            name="pay_frequency_id"
            placeholder
            value={formData.pay_frequency_id}
            onChange={handleOnChangeFrequency}
          >
            {Array.isArray(activePeriods) &&
              activePeriods.map(period => (
                <option
                  key={period.pay_frequency.id}
                  value={period.pay_frequency.id}
                  data-period-id={period.pay_period.id}
                  data-suggested-description={getDescriptionProcess(period)}
                >
                  {getPeriodRangeAndFrequency(
                    period.pay_period.begins_at,
                    period.pay_period.ends_at,
                    period.pay_frequency.description
                  )}
                </option>
              ))}
          </Select>

          {!hasProcessUnique && (
            <FormInput
              label={formFields.description}
              placeholder="Nombre del proceso"
              isRequired
              value={formData.description}
              onChange={handleOnChangeDescription}
            />
          )}
        </div>

        {hasProcessUnique ? (
          <AlertProcess
            content={`¡Upps! solo puedes tener un proceso de ${capitalize(data?.type?.description)} en el periodo.`}
          />
        ) : (
          <div className="buttons-container">
            <button type="button" className="button cancel" onClick={handleCloseModal}>
              No, cancelar
            </button>
            <button type="button" className="button" onClick={handleCreateProcess} disabled={isDisable}>
              {isDisable ? 'Creando...' : 'Sí, crear'}
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

const AlertProcess = ({content}) => {
  return (
    <div className="ed-grid s-grid-3 s-gap-2">
      <img src={WarningImg} alt="Warning" width="200px" height="200px" />
      <div className="s-cols-2 s-left s-cross-center">
        <p className="s-mb-0">{content}</p>
      </div>
    </div>
  )
}

export default ProcessExecByFrequencyForm
