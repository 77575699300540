import React, {useContext} from 'react'
import {FloatContainerContext} from '../../contexts/contexts'
import {isEmpty, isEqual} from '../../helpers/utils'
import Base from './Templates/Base'

const FloatBaseList = ({bases = [], onSelect = () => {}, activeIndex = 0}) => {
  const {setActive} = useContext(FloatContainerContext)

  const handleOnClick = index => {
    setActive(false)
    onSelect(index)
  }

  return (
    <div className={`float-container size-m s-column s-flex-gap-12px`}>
      {isEmpty(bases) ? (
        <div className="s-cross-center smaller s-color-light-text">No hay resultados</div>
      ) : (
        Array.isArray(bases) &&
        bases.map((base, index) => (
          <div
            className={`hover-change blue normal-radius cursor-pointer s-px-6px s-py-4px ${
              isEqual(index, activeIndex) && 'is-active'
            }`}
            key={base.id}
          >
            <Base base={base} onSelect={() => handleOnClick(index)} />
          </div>
        ))
      )}
    </div>
  )
}

export default FloatBaseList
