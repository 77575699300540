import React from 'react'
import Container from '../../Wrappers/Container'
import PayrollSummaryItem from '../../Molecules/Items/PayrollSummaryItem'
import CarouselGrid from '../../Wrappers/CarouselGrid'
import Skeleton from 'react-loading-skeleton'

const SummaryPaymentsPanel = ({summary}) => {
  return !summary ? (
    <Skeleton height={150} />
  ) : (
    <Container>
      <header className="s-main-justify s-flex-gap-12px s-mb-16px">
        <h3 className="s-mb-0">Resumen de pagos</h3>
      </header>
      <CarouselGrid
        breakpoints={{
          lg: 4,
          m: 3,
          s: 2,
        }}
      >
        <PayrollSummaryItem
          icon="users"
          title="Total de empleados"
          content={`${summary?.total_employees}`}
        />
        <PayrollSummaryItem
          icon="clipboard-copy"
          title="Empleados por pagar"
          content={`${summary?.employees_payable}`}
        />
        <PayrollSummaryItem
          icon="clipboard-list"
          title="Empleados listos para pagar"
          content={`${summary?.employees_with_deposit}`}
        />
        <PayrollSummaryItem
          icon="clipboard-check"
          title="Empleados pagados"
          content={`${summary?.employees_paid}`}
        />
      </CarouselGrid>
    </Container>
  )
}

export default SummaryPaymentsPanel
