import React from 'react'
import {deleteEmployerNote} from '../../services/employerNotes'
import PropTypes from 'prop-types'
import UserAvatar from '../Atoms/Templates/UserAvatar'
import EditTextButton from '../Atoms/Buttons/EditTextButton'
import Icon from '../Atoms/Icon'
import {isEqual} from '../../helpers/utils'
import {shortFormatDate} from '../../helpers/dates'
import PreviewMarkdown from './PreviewMarkdown'

const PayrollNote = ({note, onDelete, onEdit, userID}) => {
  const deleteNote = () => {
    deleteEmployerNote(note.id, () => onDelete(note.id))
  }

  return (
    <article className="payroll-note s-mb-2 normal-radius s-bg-body s-px-2 s-pt-2 s-pb-0">
      <header className="s-main-justify nowrap s-mb-1">
        <div className="s-cross-center">
          <div className="s-w-32px s-h-32px s-bg-text circle-radius s-cross-center s-main-center s-mr-1">
            <Icon svg="chat-bubble" size="24px" color="white" />
          </div>
          <div className="overflow-ellipsis">
            <p className="s-mb-0 normal overflow-ellipsis">{note.title}</p>
            <span className="s-mb-0 s-color-light-text smaller">
              {shortFormatDate(note.created_at)}
            </span>
          </div>
        </div>
        {isEqual(note?.user_id, userID) && (
          <Icon svg="cross" size="16px" className="cursor-pointer" onClick={deleteNote} />
        )}
      </header>

      <PreviewMarkdown content={note.description} />

      <footer className="s-main-justify border-top s-py-1">
        <div className="s-cross-center">
          <UserAvatar picture={note.picture} userName={note.name} className="s-mr-1" />
          <p className="s-mb-0 small s-color-light-text">{note.name}</p>
        </div>

        {isEqual(note?.user_id, userID) && (
          <EditTextButton onClick={() => onEdit(note)} />
        )}
      </footer>
    </article>
  )
}

PayrollNote.propTypes = {
  note: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  userID: PropTypes.number.isRequired,
}

PayrollNote.defaultProps = {
  note: {title: '', description: ''},
  onDelete: () => {},
  onEdit: () => {},
  userID: 0,
}

export default PayrollNote
