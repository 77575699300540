import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/employee-relations'

export const getEmployees = (queryParams, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}?${queryParams}`, callback, callbackErr)
}

export const getEmployeeByHash = (hash, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${RESOURCE}/employees/${hash}`,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const getEmployeesHaveNotBeenPaid = (processID, filterPaymentMethod, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${RESOURCE}/employees/have-not-been-paid/${processID}?payment-method=${filterPaymentMethod}`,
    response => callback(response)
  )
}

export const createEmployee = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(
    RESOURCE,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const updateBasicInformationEmployee = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(
    `${RESOURCE}/basic-information/employees/${payload.id}`,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const updateContract = (contractID, payload, callback, callbackErr) => {  
  const request = new RequestWithEmployerAuthorization()
  request.put(
    `${RESOURCE}/contracts/${contractID}`,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const updatePaymentMethod = (contractID, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(
    `${RESOURCE}/payment-methods/contracts/${contractID}`,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const updateTax = (contractID, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(
    `${RESOURCE}/taxes/contracts/${contractID}`,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const updateNotes = (contractID, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(
    `${RESOURCE}/notes/contracts/${contractID}`,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const getEmployeesBirthday = (month, queryParams, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/birthdays/${month}?${queryParams}`, callback, callbackErr)
}

export const getEmployeesByHashProcess = (
  processHash,
  limit,
  page,
  sort,
  search,
  callback
) => {
  const request = new RequestWithEmployerAuthorization()
  const URL = `${RESOURCE}/processes/${processHash}?limit=${limit}&page=${page}&sort=${sort}&search=${search}`
  request.get(URL, response => {
    callback(response)
  })
}
