import React, {useEffect, useState, useContext} from 'react'
import PropTypes from 'prop-types'
import RecurrentNoveltyTable from '../../Molecules/Tables/RecurrentNoveltyTable'
import NoveltyContainer from '../Containers/NoveltyContainer'
import RecurrentNoveltyForm from '../Forms/RecurrentNoveltyForm'
import {isEqual, isNull} from '../../../helpers/utils'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import useFetchNoveltiesWithConditions from '../../../hooks/useFetchNoveltiesWithConditions'
import {PayrollContext} from '../../../contexts/contexts'
import {
  getRecurrentHistoryNoveltyByIDAndProcessID,
  getRecurrentNoveltyByID,
} from '../../../services/recurrentnovelty'
import {fullName} from '../../../helpers/string-format'
import {readBackendDate} from '../../../helpers/dates'
import {getRecurrentNoveltiesByHashProcess} from '../../../services/recurrentnovelty'
import LoadMoreButton from '../../Atoms/Buttons/LoadMoreButton'

const initialState = {
  id: 0,
  concept_id: 0,
  concept_description: '',
  contract_id: 0,
  employee_description: '',
  value: '0',
  begins_at: '',
  ends_at: '',
  is_override: false,
  is_custom_formula: false,
  application_period_sequence: '',
  standard_behavior: '',
  formula: '',
  notes: '',
  history_formula: '',
  history_amount: '',
  has_history: false,
}

const RecurrentNovelty = ({action, setAction}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dataForm, setDataForm] = useState(initialState)
  const {activeProcess} = useContext(PayrollContext)
  const [resizeFormReason, setResizeFormReason] = useState('')
  const defaultSortOption = {key: 'contract_id', value: 'Empleado'}

  useEffect(() => {
    setIsOpen(false)

    if (isNull(action.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(action.type, CREATE)) {
      setDataForm(initialState)
      setIsOpen(true)
      return
    }

    if (isEqual(action.type, EDIT)) {
      getRecurrentNoveltyByID(
        action.noveltyID,
        response => {
          setDataForm({
            id: response.id,
            concept_id: response.concept_id,
            concept_description: response.concept_description,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            value: response.value,
            begins_at: readBackendDate(response.begins_at),
            ends_at: readBackendDate(response.ends_at),
            is_override: response.is_override,
            is_custom_formula: response.is_custom_formula,
            application_period_sequence: response.application_period_sequence,
            standard_behavior: response.standard_behavior,
            formula: response.formula,
            notes: response.notes,
            history_formula: '',
            history_amount: '',
            has_history: response.has_history,
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }

    if (isEqual(action.type, SHOW)) {
      getRecurrentHistoryNoveltyByIDAndProcessID(
        action.noveltyID,
        activeProcess.id,
        response => {
          setDataForm({
            id: response.id,
            concept_id: response.concept_id,
            concept_description: response.concept_description,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            value: response.value,
            begins_at: readBackendDate(response.begins_at),
            ends_at: readBackendDate(response.ends_at),
            is_override: response.is_override,
            is_custom_formula: response.is_custom_formula,
            application_period_sequence: response.application_period_sequence,
            standard_behavior: response.standard_behavior,
            formula: response.formula,
            notes: response.notes,
            history_formula: response.history_formula,
            history_amount: response.history_amount,
            has_history: response.has_history,
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }
  }, [action, activeProcess.id])

  const {
    novelties,
    page,
    isLastPage,
    isLoading,
    reloadNovelties,
    setFilter,
    setSortOption,
    setPage,
    setReloadNovelties,
  } = useFetchNoveltiesWithConditions(
    activeProcess.hash,
    defaultSortOption,
    5,
    getRecurrentNoveltiesByHashProcess
  )

  const handlerOnClickAddPage = () => {
    if (isLastPage) return
    setPage(page + 1)
  }

  return (
    <>
      <NoveltyContainer
        isOpenForm={isOpen}
        resizeFormReason={resizeFormReason}
        form={
          <RecurrentNoveltyForm
            initialState={dataForm}
            action={action}
            setAction={setAction}
            setResizeFormReason={setResizeFormReason}
            setReloadNovelties={setReloadNovelties}
            reloadNovelties={reloadNovelties}
          />
        }
        table={
          <RecurrentNoveltyTable
            setAction={setAction}
            novelties={novelties}
            setFilter={setFilter}
            setSortOption={setSortOption}
            defaultSortOption={defaultSortOption}
            setReloadNovelties={setReloadNovelties}
            reloadNovelties={reloadNovelties}
          />
        }
      />
      {!isLastPage && !isLoading && (
        <LoadMoreButton
          description="Carga más novedades"
          onClick={handlerOnClickAddPage}
        />
      )}
    </>
  )
}

RecurrentNovelty.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.oneOf([SHOW, CREATE, EDIT, null]),
    noveltyID: PropTypes.number,
  }),
  setAction: PropTypes.func,
}

RecurrentNovelty.defaultProps = {
  action: {type: null, noveltyID: 0},
  setAction: () => {},
}

export default RecurrentNovelty
