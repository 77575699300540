import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import LeaveNoveltyTable from '../../Molecules/Tables/LeaveNoveltyTable'
import NoveltyContainer from '../Containers/NoveltyContainer'
import LeaveNoveltyForm from '../Forms/LeaveNoveltyForm'
import {isAnyEqual, isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import useFetchNoveltiesWithConditions from '../../../hooks/useFetchNoveltiesWithConditions'
import {PayrollContext} from '../../../contexts/contexts'
import {
  getLeaveNoveltiesByHashProcess,
  getLeaveNoveltyByID,
} from '../../../services/leaveNovelty'
import LoadMoreButton from '../../Atoms/Buttons/LoadMoreButton'
import {readBackendDate} from '../../../helpers/dates'
import {fullName} from '../../../helpers/string-format'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'

const initialState = {
  contract_id: 0,
  employee_description: '',
  leave_type_id: 0,
  begins_at: '',
  ends_at: '',
  days: 0,
  is_extends: false,
  extends_leave_id: 0,
  description: '',
  medical_diagnosis: '',
  support_code: '',
  process_hash: '',
  has_medical_leave: false,
}

const LeaveNovelty = ({action, setAction}) => {
  const employerSession = useSelector(employerSelectors.getSession)

  const [isOpen, setIsOpen] = useState(false)
  const [dataForm, setDataForm] = useState(initialState)
  const {activeProcess} = useContext(PayrollContext)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const leaveTypes = !isNull(cacheGeneral) && cacheGeneral.data?.leave_types
  const defaultSortOption = {key: 'contract_id', value: 'Empleado'}

  const handleHasMedicalLeave = leaveNoveltyID => {
    const result = leaveTypes.filter(
      leaveType =>
        isEqual(leaveType.id, Number(leaveNoveltyID)) &&
        !leaveType.description.includes('INCAPACIDAD')
    )
    return isEmpty(result)
  }

  useEffect(() => {
    setIsOpen(false)

    if (isNull(action.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(action.type, CREATE)) {
      setDataForm(initialState)
      setIsOpen(true)
      return
    }

    if (isAnyEqual(action.type, [EDIT, SHOW])) {
      getLeaveNoveltyByID(
        action.noveltyID,
        response => {
          setDataForm({
            id: response.id,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            leave_type_id: response.leave_type_id,
            begins_at: readBackendDate(response.begins_at),
            ends_at: readBackendDate(response.ends_at),
            days: response.days,
            is_extends: response.is_extends,
            extends_leave_id: response.extends_leave_id,
            description: response.description,
            medical_diagnosis: response.medical_diagnosis,
            support_code: response.support_code,
            has_medical_leave: handleHasMedicalLeave(response.leave_type_id),
            status: response.status,
            leave_per_periods: response.leave_per_periods,
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }
  }, [action]) // eslint-disable-line

  const {
    novelties,
    page,
    isLastPage,
    isLoading,
    reloadNovelties,
    setFilter,
    setSortOption,
    setPage,
    setReloadNovelties,
  } = useFetchNoveltiesWithConditions(
    activeProcess.hash,
    defaultSortOption,
    5,
    getLeaveNoveltiesByHashProcess
  )

  const handlerOnClickAddPage = () => {
    if (isLastPage) return
    setPage(page + 1)
  }

  return (
    <>
      <NoveltyContainer
        isOpenForm={isOpen}
        form={
          <LeaveNoveltyForm
            initialState={dataForm}
            action={action}
            setAction={setAction}
            reloadNovelties={reloadNovelties}
            setReloadNovelties={setReloadNovelties}
            handleHasMedicalLeave={handleHasMedicalLeave}
          />
        }
        table={
          <LeaveNoveltyTable
            setAction={setAction}
            novelties={novelties}
            setFilter={setFilter}
            setSortOption={setSortOption}
            defaultSortOption={defaultSortOption}
            setReloadNovelties={setReloadNovelties}
            reloadNovelties={reloadNovelties}
          />
        }
      />
      {!isLastPage && !isLoading && (
        <LoadMoreButton
          description="Carga más novedades"
          onClick={handlerOnClickAddPage}
        />
      )}
    </>
  )
}

LeaveNovelty.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.oneOf([SHOW, CREATE, EDIT, null]),
    noveltyID: PropTypes.number,
  }),
  setAction: PropTypes.func,
}

LeaveNovelty.defaultProps = {
  action: {type: null, noveltyID: 0},
  setAction: () => {},
}

export default LeaveNovelty
