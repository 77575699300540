import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {FloatContainerContext} from '../../contexts/contexts'
import {isEmpty, isEqual} from '../../helpers/utils'

const FloatMunicipalityList = ({list = [], onSelect = () => {}, activeIndex = 0}) => {
  const {setActive} = useContext(FloatContainerContext)

  const handleOnClick = index => {
    setActive(false)
    onSelect(index)
  }

  return (
    Array.isArray(list) && (
      <div className="float-container s-column s-flex-gap-12px size-m">
        {isEmpty(list) ? (
          <div className="s-cross-center smaller s-color-light-text">
            Escribe para buscar
          </div>
        ) : (
          Array.isArray(list) &&
          list.map((m, index) => (
            <div
              key={m.id}
              onClick={() => handleOnClick(index)}
              className={`hover-change blue normal-radius cursor-pointer s-px-6px s-py-4px ${
                isEqual(index, activeIndex) && 'is-active'
              }`}
            >
              {m.description}
            </div>
          ))
        )}
      </div>
    )
  )
}

FloatMunicipalityList.propTypes = {
  list: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
}

FloatMunicipalityList.defaultProps = {
  list: [],
  onSelect: () => {},
}

export default FloatMunicipalityList
