import React from 'react'
import PropTypes from 'prop-types'
import DefaultImage from '../../../static/images/stories/investment-data.svg'

const NoveltyEmptyState = ({title, description, className, image}) => {
  return (
    <div className={`s-column s-center s-cross-center s-main-center ${className} s-mb-4`}>
      <div className="l-block" />
      <img className="s-mb-0" src={image} alt="Novedades" width="200" height="200" />
      <h3>{title}</h3>
      <p className="s-color-light-text">{description}</p>
    </div>
  )
}

NoveltyEmptyState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
  className: PropTypes.string,
}

NoveltyEmptyState.defaultProps = {
  title: '',
  description: '',
  image: DefaultImage,
  className: '',
}

export default NoveltyEmptyState
