import {createDuck} from 'redux-duck'
import {SIGN_IN_EMPLOYER, SIGN_OUT_EMPLOYER} from './employer'

const duck = createDuck('app/process-type-config')

// Define action types
export const SET_PROCESS_TYPE_CONFIG = duck.defineType('SET_PROCESS_TYPE_CONFIG')

// Define action creators
export const actions = {
  set: duck.createAction(SET_PROCESS_TYPE_CONFIG),
}

// Define selectors
export const selectors = {
  getAll: store => store.processTypeConfigReducer.all,
  getObj: store => store.processTypeConfigReducer.obj,
}

function createObj(payload) {
  let result = {}
  if (!Array.isArray(payload)) return result

  payload.forEach(p => {
    result[p.code] = p
  })

  return result
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_PROCESS_TYPE_CONFIG]: (state, {payload}) => ({
      ...state,
      all: payload,
      obj: createObj(payload),
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => initialState,
    [SIGN_OUT_EMPLOYER]: (state, {payload}) => initialState,
  },
  initialState
)
