import React from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from '../../../helpers/utils'
import NumbersProcess from '../../../static/images/stories/numbers-process.svg'

const LastProcessEmptyState = ({
  title,
  subtitle,
  captionActionButton,
  onClickActionButton,
  isDisableActionButton,
  className,
  buttonSize,
}) => {
  return (
    <div
      className={`s-column s-center s-cross-center s-main-center m-90 s-to-center ${className}`}
    >
      <img
        className="s-mb-16px"
        src={NumbersProcess}
        alt="Un chico haciendo un proceso"
        width="200px"
        height="200px"
      />
      <p className="small s-mb-16px s-color-light-text">
        {title}
        <br />
        {subtitle}
      </p>

      {!isEmpty(captionActionButton) && (
        <button
          className={`button ${buttonSize}`}
          type="button"
          onClick={onClickActionButton}
          disabled={isDisableActionButton}
        >
          {captionActionButton}
        </button>
      )}
    </div>
  )
}

LastProcessEmptyState.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  captionActionButton: PropTypes.string,
  onClickActionButton: PropTypes.func,
  isDisableActionButton: PropTypes.bool,
  className: PropTypes.string,
  buttonSize: PropTypes.string,
}

LastProcessEmptyState.defaultProps = {
  title: 'Vaya, aún no has creado procesos.',
  subtitle: '¿Qué tal si empiezas ahora?',
  captionActionButton: '',
  onClickActionButton: () => {},
  isDisableActionButton: false,
  className: '',
  buttonSize: 'small',
}

export default LastProcessEmptyState
