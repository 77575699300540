import {isEqual, isUndefined} from './utils'
import AvatarMan1 from '../static/images/utils/man-1.svg'
import AvatarMan2 from '../static/images/utils/man-2.svg'
import AvatarMan3 from '../static/images/utils/man-3.svg'
import AvatarWoman1 from '../static/images/utils/woman-1.svg'
import AvatarWoman2 from '../static/images/utils/woman-2.svg'
import AvatarWoman3 from '../static/images/utils/woman-3.svg'
import StoreGraphic1 from '../static/images/utils/store-1.svg'
import StoreGraphic2 from '../static/images/utils/store-2.svg'
import StoreGraphic3 from '../static/images/utils/store-3.svg'

export const getAvatar = (id, gender) => {
  if (isUndefined(id) || isUndefined(gender)) return AvatarMan1

  const module = id % 3
  if (isEqual(gender.toUpperCase(), 'FEMENINO')) {
    return getAvatarWoman(module)
  }

  return getAvatarMan(module)
}

const getAvatarMan = module => {
  switch (module) {
    case 1:
      return AvatarMan1
    case 0:
      return AvatarMan2
    default:
      return AvatarMan3
  }
}

const getAvatarWoman = module => {
  switch (module) {
    case 1:
      return AvatarWoman1
    case 0:
      return AvatarWoman2
    default:
      return AvatarWoman3
  }
}

export const getStoreGraphic = id => {
  const module = id % 3

  switch (module) {
    case 1:
      return StoreGraphic1
    case 0:
      return StoreGraphic2
    default:
      return StoreGraphic3
  }
}
