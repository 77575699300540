import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'
import RadioButton from '../../Atoms/Forms/RadioButton'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import {FormDataContext} from '../../../contexts/contexts'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {isEqual, isNull} from '../../../helpers/utils'
import {COLOMBIA_CODE} from '../../../helpers/country'
import {getLocalCache, getCacheKeyGeneral} from '../../../helpers/cache'

const RetentionEmployeeForm = () => {
  const employerSession = useSelector(employerSelectors.getSession)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const identifications = !isNull(cacheGeneral) && cacheGeneral.data?.identification_types

  const {formData, setFormData, updateFormField, updateFormFieldTextToNumber} = useContext(FormDataContext)

  const handleOnChangeTaxProcedure = e => {
    updateFormField(e)

    // if procedure change to 1 reset the rate taxes to zero
    if (isEqual(e.target.value, '1')) setFormData(state => ({...state, rate_taxes: 0}))
  }

  const handleOnChangeIsTaxDependent = e => {
    updateFormField(e)

    const value = e.target.value
    setFormData(state => ({...state, is_tax_dependents_raw: value ? 'TRUE' : 'FALSE'}))
  }

  return (
    <div>
      {!isEqual(employerSession.code_iso_3, COLOMBIA_CODE) ? (
        <div className="s-cols-1 m-cols-2 lg-cols-3 s-px-6px s-py-8px s-mb-0 s-center small s-color-light-text s-bg-body-alt">
          <span role="img" aria-label="" className="s-mr-8px">
            💡
          </span>
          <span>No necesitas registrar información de impuestos para este contrato.</span>
        </div>
      ) : (
        <>
          <div className="form-grid ed-grid m-grid-2 lg-grid-3">
            <Select
              isRequired
              label="Método de Retención"
              name="method_taxes"
              id="method_taxes"
              placeholder
              value={formData.method_taxes}
              onChange={handleOnChangeTaxProcedure}
            >
              <option value="1">Procedimiento 1</option>
              <option value="2">Procedimiento 2</option>
            </Select>

            {isEqual(formData.method_taxes, '2') && (
              <FormInput
                isRequired
                label="% de retención"
                name="rate_taxes"
                id="rate_taxes"
                placeholder="% RTF"
                type="number"
                min="0.00"
                step=".01"
                value={formData.rate_taxes}
                onChange={updateFormField}
              />
            )}

            <FormInput
              label="Deducible de vivienda"
              name="tax_relief_living_place"
              id="tax_relief_living_place"
              placeholder="Deducible"
              align="right"
              value={formData.tax_relief_living_place}
              onChange={updateFormFieldTextToNumber}
            />

            <FormInput
              label="Deducible medicina pagada"
              name="tax_relief_health"
              id="tax_relief_health"
              placeholder="Deducible medicina"
              align="right"
              value={formData.tax_relief_health}
              onChange={updateFormFieldTextToNumber}
            />

            <RadioButtonsWrapper label="Deducible dependiente">
              <RadioButton
                name="is_tax_dependents"
                label="Sí"
                value={true}
                onChange={handleOnChangeIsTaxDependent}
                checked={isEqual(formData.is_tax_dependents, true)}
              />
              <RadioButton
                name="is_tax_dependents"
                label="No"
                value={false}
                onChange={handleOnChangeIsTaxDependent}
                checked={isEqual(formData.is_tax_dependents, false)}
              />
            </RadioButtonsWrapper>

            {isEqual(formData.is_tax_dependents, true) && (
              <div className="m-cols-2 lg-cols-3">
                <p className="s-color-title normal s-center">Información del Dependiente</p>
                <div className="form-grid ed-grid m-grid-3 s-mb-3">
                  <Select
                    isRequired
                    label="Tipo de identificación"
                    name="dependent_identification_type_id"
                    id="dependent_identification_type_id"
                    placeholder
                    value={formData.dependent_identification_type_id}
                    onChange={updateFormField}
                  >
                    {Array.isArray(identifications) &&
                      identifications.map(identification => (
                        <option key={identification.id} value={identification.id}>
                          {identification.description}
                        </option>
                      ))}
                  </Select>
                  <FormInput
                    isRequired
                    label="No. de identificatión"
                    name="dependent_identification_number"
                    id="dependent_identification_number"
                    value={formData.dependent_identification_number}
                    onChange={updateFormField}
                  />
                  <FormInput
                    isRequired
                    label="Parentesco"
                    name="dependent_relationship_type"
                    id="dependent_relationship_type"
                    value={formData.dependent_relationship_type}
                    onChange={updateFormField}
                  />
                </div>

                <div className="form-grid ed-grid m-grid-4">
                  <FormInput
                    isRequired
                    label="Primer nombre"
                    name="dependent_first_name"
                    id="dependent_first_name"
                    value={formData.dependent_first_name}
                    onChange={updateFormField}
                  />

                  <FormInput
                    label="Segundo nombre"
                    name="dependent_middle_name"
                    id="dependent_middle_name"
                    value={formData.dependent_middle_name}
                    onChange={updateFormField}
                  />

                  <FormInput
                    isRequired
                    label="Primer apellido"
                    name="dependent_last_name"
                    id="dependent_last_name"
                    value={formData.dependent_last_name}
                    onChange={updateFormField}
                  />

                  <FormInput
                    label="Segundo apellido"
                    name="dependent_surname"
                    id="dependent_surname"
                    value={formData.dependent_surname}
                    onChange={updateFormField}
                  />
                </div>
              </div>
            )}
          </div>

          <p className="smaller s-main-center s-pt-4 s-mb-0">
            Los campos con <span className="s-color-blue">*</span> son obligatorios
          </p>
        </>
      )}
    </div>
  )
}

export default RetentionEmployeeForm
