import React from 'react'
import Loader from '../../Atoms/Loader'

const FullLoader = ({hasSolidColor, isAbsolute, className, ...rest}) => {
  return (
    <div
      className={`full-loader s-cross-center s-main-center ${
        hasSolidColor && 'solid-overlay'
      }
      ${isAbsolute && 'absolute'}
      ${className}`}
      {...rest}
    >
      <Loader />
    </div>
  )
}

export default FullLoader
