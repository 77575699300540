import React from 'react';
import PropTypes from 'prop-types';
import Icon from "../Icon";

const CarouselButton = props => {
  const {icon, onClick} = props

  return (
    <div
      role="button"
      className="cursor-pointer select-none s-color-light-text s-cross-center flex-none"
      onClick={onClick}
    >
      <Icon
        size="1.75rem"
        svg={icon}
      />
    </div>
  );
};

CarouselButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CarouselButton;
