import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import FormInput from 'components/Atoms/Templates/FormInput'
import DatePickerInput from 'components/Molecules/Pickers/DatePickerInput'
import {shortBackendWeekDays, readBackendDate} from 'helpers/dates'
import Select from 'components/Atoms/Forms/Select'
import {selectors as branchOfficeSelector} from 'redux/ducks/branchOffices'
import {selectors as areaSelectors} from 'redux/ducks/areas'
import {selectors as payPeriodsSelector} from 'redux/ducks/payPeriods'
import {selectors as dimensionSelectors} from 'redux/ducks/dimension'
import SelectPlaceLaborMunicipalityInput from 'components/Molecules/Selects/SelectPlaceLaborMunicipalityInput'
import {isEmpty, isEqual, isNull, isUndefined} from 'helpers/utils'
import TreeSelect from 'components/Atoms/Forms/TreeSelect'
import Checkbox from 'components/Atoms/Forms/Checkbox'
import ValidatorForm from 'helpers/validator'
import {notifyError, notifySuccessful, notifyWarning} from 'services/notification'
import {updateContract} from 'services/employees'
import {getDescriptions} from './logic'
import ContractState from './contract-state'
import {
  KEY_BRANCH_OFFICE_ID,
  KEY_PLACE_LABOR_MUNICIPALITY_ID,
  KEY_ORGANIZATIONAL_STRUCTURE_ID,
  KEY_HIRE_DATE,
  KEY_EXPIRATION_DATE,
  KEY_TRIAL_DATE,
  KEY_PAY_FREQUENCY_ID,
  KEY_ALTERNATE_CODE,
  KEY_MONTH_HOURS,
  _KEY_REST_DAYS,
  LABELS,
  RULES,
} from './const'

const ContractBasicDataForm = ({data, isResetData, onCancel, onUpdate, contractTypes}) => {
  const dispatch = useDispatch()
  const branchOffices = useSelector(branchOfficeSelector.getAll)
  const payPeriods = useSelector(payPeriodsSelector.getAll)
  const dimensions = useSelector(dimensionSelectors.getList)
  const isExtensible = contractTypes.find(c => c.id === data.contract.contract_type_id)?.is_extensible

  // we use a state for the areas with a deep copy because we update these areas for set the
  // current area of the contract and we want not to change the original state of redux,
  // besides the Tree component uses the hook memo and we want not unexpected behaviors.
  const [areas] = useState(deepCopy(useSelector(areaSelectors.getAll)))

  const initialState = new ContractState(
    data,
    branchOffices.find(b => isEqual(b.id, data?.contract[KEY_BRANCH_OFFICE_ID]))?.department_id
  )

  const [hasHireDateWarning, setHireDateWarning] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState(initialState.getPlainObject())

  useEffect(() => {
    if (!isResetData) return

    setFormData(initialState.getPlainObject())
  }, [isResetData])

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }
  const handleInputChangeNumber = event => {
    setFormData({
      ...formData,
      [event.target.name]: Number(event.target.value),
    })
  }

  const handleOnChangeDate = (field, date) => {
    setFormData({
      ...formData,
      [field]: date,
    })
  }

  const handleOnChangeBranchOffice = e => {
    const value = Number(e.target.value)
    const options = e.target.options
    const i = options.selectedIndex
    let departmentID = options[i].getAttribute('data-department-id')

    setFormData(state => ({
      ...state,
      [KEY_BRANCH_OFFICE_ID]: value,
      branch_office_department: Number(departmentID),
      [KEY_PLACE_LABOR_MUNICIPALITY_ID]: 0,
    }))
  }

  const handleOnSelectMunicipalityInput = (municipalityID, municipalityDescription) => {
    setFormData(state => ({
      ...state,
      [KEY_PLACE_LABOR_MUNICIPALITY_ID]: Number(municipalityID),
      municipality_description: municipalityDescription,
    }))
  }

  const handleOnChangeArea = area => {
    if (isNull(area)) {
      setFormData(state => ({
        ...state,
        [KEY_ORGANIZATIONAL_STRUCTURE_ID]: '',
      }))
      return
    }

    setFormData(state => ({
      ...state,
      [KEY_ORGANIZATIONAL_STRUCTURE_ID]: area?.value,
      area: area?.label,
    }))
  }

  const handleOnChangeHireDate = date => {
    const currentDate = readBackendDate(data?.contract[KEY_HIRE_DATE]).toISOString()
    const newDate = isNull(date) ? null : date.toISOString()

    setHireDateWarning(currentDate !== newDate)
    setFormData({
      ...formData,
      [KEY_HIRE_DATE]: date,
    })
  }

  const handleOnChangePayFrequency = e => {
    const value = Number(e.target.value)
    const options = e.target.options
    const i = options.selectedIndex
    let description = options[i].getAttribute('data-description')

    setFormData({
      ...formData,
      [KEY_PAY_FREQUENCY_ID]: value,
      pay_frequency: description,
    })
  }

  const handleOnChangeRestDay = (e, index) => {
    const target = e.target
    const value = target.checked
    let newRestDay = formData[_KEY_REST_DAYS]
    newRestDay[index] = value

    setFormData(state => ({
      ...state,
      [_KEY_REST_DAYS]: newRestDay,
    }))
  }

  const updateFormFieldDimension = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      dimensions: {
        ...state.dimensions,
        [name]: value,
      },
    }))
  }

  const handleUpdateContract = () => {
    const validator = new ValidatorForm(formData, RULES)
    validator.setAttributeNames(LABELS)

    let globalErr = []
    if (validator.fails()) {
      globalErr.push(validator.errors())
    }

    // we are creating a copy of formData for work with this copy and assign it the prototype the InitialState,
    // so we can work with its methods
    const copyFormData = Object.setPrototypeOf({...formData}, ContractState.prototype)

    if (!copyFormData.isValidExpirationDate(isExtensible)) {
      globalErr.push(`El campo ${LABELS[KEY_EXPIRATION_DATE]} es obligatorio para el tipo de contrato`)
    }

    const msgValidateRestDay = copyFormData.validateRestDays()
    if (!isNull(msgValidateRestDay)) {
      globalErr.push(msgValidateRestDay)
    }

    // validate dimensions
    if (Array.isArray(dimensions)) {
      dimensions.forEach(record => {
        if (
          record.dimension.is_mandatory &&
          (isUndefined(copyFormData.dimensions[record.dimension.code]) ||
            isEmpty(copyFormData.dimensions[record.dimension.code]))
        ) {
          globalErr.push(`El campo ${record.dimension.description} es requerido.`)
        }
      })
    }

    if (globalErr.length > 0) {
      dispatch(notifyError(globalErr.join('\n')))
      return
    }

    const payload = copyFormData.preparePayload(initialState)
    if (isEmpty(Object.keys(payload))) {
      dispatch(notifyWarning('No realizaste ningún cambio en el contrato.'))
      return
    }

    setIsLoading(true)

    updateContract(
      data.contract.id,
      payload,
      response => {
        onUpdate(state => ({
          ...state,
          ...getDescriptions(payload, copyFormData),
          contract: {...state.contract, ...payload},
        }))

        dispatch(notifySuccessful('El contrato ha sido actualizado correctamente 🎉'))
        setIsLoading(false)
        onCancel()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  return (
    <form noValidate className="s-mb-0 ed-grid rows-gap form-grid">
      <div className="ed-grid m-grid-3 rows-gap">
        <Select
          isRequired
          label={LABELS[KEY_BRANCH_OFFICE_ID]}
          name={KEY_BRANCH_OFFICE_ID}
          id={KEY_BRANCH_OFFICE_ID}
          placeholder
          value={formData[KEY_BRANCH_OFFICE_ID]}
          onChange={handleOnChangeBranchOffice}
        >
          {Array.isArray(branchOffices) &&
            branchOffices.map(branchOffice => (
              <option key={branchOffice.id} value={branchOffice.id} data-department-id={branchOffice.department_id}>
                {branchOffice.description}
              </option>
            ))}
        </Select>
        <SelectPlaceLaborMunicipalityInput
          isRequired
          label={LABELS[KEY_PLACE_LABOR_MUNICIPALITY_ID]}
          name={KEY_PLACE_LABOR_MUNICIPALITY_ID}
          id={KEY_PLACE_LABOR_MUNICIPALITY_ID}
          placeholder="Escribe para buscar"
          departmentID={formData.branch_office_department}
          onSelect={handleOnSelectMunicipalityInput}
          defaultValue={data?.municipality_description}
        />
        <TreeSelect
          isRequired
          label={LABELS[KEY_ORGANIZATIONAL_STRUCTURE_ID]}
          data={loadDFSAreas(areas, data?.contract[KEY_ORGANIZATIONAL_STRUCTURE_ID])}
          id="area"
          placeholder
          onChange={handleOnChangeArea}
        />
        <DatePickerInput
          isRequired
          id={KEY_HIRE_DATE}
          name={KEY_HIRE_DATE}
          label={LABELS[KEY_HIRE_DATE]}
          value={formData[KEY_HIRE_DATE]}
          onChange={handleOnChangeHireDate}
        />
        {isExtensible && (
          <DatePickerInput
            isRequired
            id={KEY_EXPIRATION_DATE}
            name={KEY_EXPIRATION_DATE}
            label={LABELS[KEY_EXPIRATION_DATE]}
            minDate={readBackendDate(data?.contract[KEY_HIRE_DATE])}
            value={formData[KEY_EXPIRATION_DATE]}
            onChange={date => handleOnChangeDate(KEY_EXPIRATION_DATE, date)}
          />
        )}
        <DatePickerInput
          id={KEY_TRIAL_DATE}
          name={KEY_TRIAL_DATE}
          label={LABELS[KEY_TRIAL_DATE]}
          minDate={readBackendDate(data?.contract[KEY_HIRE_DATE])}
          value={formData[KEY_TRIAL_DATE]}
          onChange={date => handleOnChangeDate(KEY_TRIAL_DATE, date)}
        />
        <Select
          isRequired
          id={KEY_PAY_FREQUENCY_ID}
          name={KEY_PAY_FREQUENCY_ID}
          label={LABELS[KEY_PAY_FREQUENCY_ID]}
          placeholder="female"
          value={formData[KEY_PAY_FREQUENCY_ID]}
          onChange={handleOnChangePayFrequency}
        >
          {Array.isArray(payPeriods) &&
            !isUndefined(payPeriods[0]?.pay_frequency) &&
            payPeriods.map(payPeriod => (
              <option
                key={payPeriod.pay_frequency.id}
                value={payPeriod.pay_frequency.id}
                data-description={payPeriod.pay_frequency.description}
              >
                {payPeriod.pay_frequency.description}
              </option>
            ))}
        </Select>
        <FormInput
          placeholder="00000"
          id={KEY_ALTERNATE_CODE}
          name={KEY_ALTERNATE_CODE}
          label={LABELS[KEY_ALTERNATE_CODE]}
          value={formData[KEY_ALTERNATE_CODE]}
          onChange={handleInputChange}
        />
        <FormInput
          isRequired
          id={KEY_MONTH_HOURS}
          name={KEY_MONTH_HOURS}
          label={LABELS[KEY_MONTH_HOURS]}
          type="number"
          min="8"
          max="240"
          step="8"
          placeholder="240"
          value={formData[KEY_MONTH_HOURS]}
          onChange={handleInputChangeNumber}
        />

        {Array.isArray(dimensions) &&
          dimensions.map(record => (
            <Select
              isRequired={record.dimension.is_mandatory}
              key={record.dimension.id}
              label={record.dimension.description}
              name={record.dimension.code}
              id={record.dimension.code}
              placeholder="Selecciona una opción"
              value={formData.dimensions?.[record.dimension.code]}
              onChange={updateFormFieldDimension}
            >
              {Array.isArray(record.values) &&
                record.values.map(value => (
                  <option key={value.id} value={value.code}>
                    {value.code} - {value.description}
                  </option>
                ))}
            </Select>
          ))}
      </div>

      <div>
        <div className="form-item s-mb-6px">
          <label className="required">{LABELS[_KEY_REST_DAYS]}</label>
        </div>
        <div className="ed-grid s-grid-4 m-grid-7 rows-gap">
          {Array.isArray(shortBackendWeekDays) &&
            shortBackendWeekDays.map((day, index) => (
              <Checkbox
                key={index}
                name={day}
                label={day}
                onChange={e => handleOnChangeRestDay(e, index)}
                checked={formData[_KEY_REST_DAYS][index]}
              />
            ))}
        </div>
      </div>

      <div className="s-column s-cross-center">
        {hasHireDateWarning && (
          <p className="s-center s-bg-yellow s-py-1 s-px-2 normal-radius s-mb-1 small">
            <span role="img" aria-label="warning">
              ⚠️
            </span>{' '}
            <span className="s-color-title">¡Cuidado!</span> cambiaste la fecha de inicio de contrato, si continuas y la
            fecha pertenece a un periodo cerrado tus datos historicos de nómina serán incorrectos.
          </p>
        )}

        <p className="smaller">
          Los campos con <span className="s-color-blue">*</span> son obligatorios
        </p>
        <div className="buttons-container">
          <button type="button" className="button ghost cancel s-order-3 m-order-1" onClick={onCancel}>
            Cancelar
          </button>
          <button
            type="button"
            className="button ghost s-order-1 m-order-3"
            onClick={handleUpdateContract}
            disabled={isLoading}
          >
            {isLoading ? 'Actualizando...' : 'Actualizar'}
          </button>
        </div>
      </div>
    </form>
  )
}

const loadDFSAreas = (nodes, targetValue) => {
  for (const node of nodes) {
    const updated = updateDefaultValue(node, targetValue)
    if (updated) return nodes
  }

  return nodes
}

const updateDefaultValue = (node, targetValue) => {
  if (node.value === targetValue) {
    node.checked = true
    return true
  }

  if (node.children) {
    for (const child of node.children) {
      const updated = updateDefaultValue(child, targetValue)
      if (updated) {
        return true
      }
    }
  }

  return false
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export default ContractBasicDataForm
