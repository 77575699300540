import {createDuck} from 'redux-duck'
import {READ} from '../../helpers/notification-user'
import {isEqual} from '../../helpers/utils'
import {SIGN_OUT_EMPLOYER, SIGN_IN_EMPLOYER} from './employer'

// notification pagination
export const NOTIFICATION_LIMIT_SIZE = 20
export const NOTIFICATION_DEFAULT_PAGE = 1

const duck = createDuck('app/notification-users')

// Define action types
export const SET_NOTIFICATION_USER = duck.defineType('SET_NOTIFICATION_USER')
export const ADD_NOTIFICATION_USER = duck.defineType('ADD_NOTIFICATION_USER')
export const MARK_AS_READ_NOTIFICATION_USER = duck.defineType(
  'MARK_AS_READ_NOTIFICATION_USER'
)
export const MARK_ALL_AS_READ_NOTIFICATION_USER = duck.defineType(
  'MARK_ALL_AS_READ_NOTIFICATION_USER'
)
export const SET_HAS_NEW_NOTIFICATION_USER = duck.defineType('SET_HAS_NOTIFICATION_USER')

// Define action creators
export const actions = {
  set: duck.createAction(SET_NOTIFICATION_USER),
  // add action must be only used by the Websocket
  add: duck.createAction(ADD_NOTIFICATION_USER),
  markAsRead: duck.createAction(MARK_AS_READ_NOTIFICATION_USER),
  markAllAsRead: duck.createAction(MARK_ALL_AS_READ_NOTIFICATION_USER),
  setHasNewNotification: duck.createAction(SET_HAS_NEW_NOTIFICATION_USER),
}

// Define selectors
export const selectors = {
  getAll: store => store.notificationUsersReducer.all,
  getHasNewNotification: store => store.notificationUsersReducer.has_new_notification,
  getHasNewNotificationTimestamp: store => store.notificationUsersReducer.timestamp_notification,
  getHasNextPage: store => store.notificationUsersReducer.has_next_page,
  getNextPage: store => store.notificationUsersReducer.next_page,
}

const initialState = {
  has_next_page: true,
  next_page: NOTIFICATION_DEFAULT_PAGE,
  all: [],
  has_new_notification: false,
  timestamp_notification: 0,
}

// Define reducer
export default duck.createReducer(
  {
    [SET_NOTIFICATION_USER]: (state, {payload}) => ({
      ...state,
      all: [...state.all, ...payload],
      has_next_page: isEqual(payload.length, NOTIFICATION_LIMIT_SIZE),
      next_page: state.next_page + 1,
    }),
    // ADD_NOTIFICATION_USER is only used by the websocket
    [ADD_NOTIFICATION_USER]: (state, {payload}) => ({
      ...state,
      all: [payload, ...state.all],
      has_new_notification: true,
      timestamp_notification: Date.now(),
    }),
    [MARK_AS_READ_NOTIFICATION_USER]: (state, {payload}) => ({
      ...state,
      all: [
        ...state.all.map(notification => {
          if (notification.id !== payload) return notification

          notification.status = READ
          return notification
        }),
      ],
    }),
    [MARK_ALL_AS_READ_NOTIFICATION_USER]: (state, {payload}) => ({
      ...state,
      all: [...state.all.map(notification => ({...notification, status: READ}))],
    }),
    [SET_HAS_NEW_NOTIFICATION_USER]: (state, {payload}) => ({
      ...state,
      has_new_notification: payload,
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => initialState,
    [SIGN_OUT_EMPLOYER]: (state, {payload}) => initialState,
  },
  initialState
)
