import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {PayrollContext} from '../../../contexts/contexts'
import {isAnyEqual} from '../../../helpers/utils'
import {STATUS_HISTORY} from '../../../helpers/payroll'

const NoveltyFormContainer = props => {
  const {cancelFunction, successFunction, successButtonText, children, noveltyStatus} =
    props

  const {activeProcess} = useContext(PayrollContext)
  const isHistory = isAnyEqual(STATUS_HISTORY, [activeProcess?.status, noveltyStatus])

  return (
    <form className="form ed-grid m-grid-2 lg-grid-3 form-grid">
      {children}
      <div className="m-cols-2 lg-cols-3 s-order-2">
        <div className="s-main-center s-flex-gap-24px">
          <button onClick={cancelFunction} className="button cancel" type="button">
            {isHistory ? 'Cerrar' : 'Cancelar'}
          </button>

          {!isHistory && (
            <button onClick={successFunction} className="button" type="button">
              {successButtonText}
            </button>
          )}
        </div>
      </div>
    </form>
  )
}

NoveltyFormContainer.propTypes = {
  cancelButtonText: PropTypes.string,
  successButtonText: PropTypes.string,
  successFunction: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  noveltyStatus: PropTypes.string,
}

NoveltyFormContainer.defaultProps = {
  cancelFunction: () => {},
  successButtonText: 'Guardar',
  successFunction: () => {},
  noveltyStatus: '',
}

export default NoveltyFormContainer
