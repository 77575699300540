import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/process-headers'

export const getProcessOfActiveMonth = callback => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/active-months`, callback, error => {
    console.error('payroll processes:', error)
  })
}

export const getByHash = (hash, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/hashes/${hash}`, callback, error => {
    console.error('process by hash: ', hash, error)
  })
}

export const markAsPaidFull = (ID, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/mark-as-paid-full/${ID}`, null, callback, callbackErr)
}
