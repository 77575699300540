import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import UserAvatar from '../../Atoms/Templates/UserAvatar'
import ReactTimeAgo from 'react-time-ago'
import { payrollDateRoute } from '../../../config/routes'
import {FloatContainerContext} from '../../../contexts/contexts'
import {getFullPeriodDescription} from '../../../helpers/period'
import {markNotificationUserAsRead} from '../../../services/notificationUsers'

const NotificationItemClosedPeriod = ({itemID, unread, content, createdAt}) => {
  const {setActive} = useContext(FloatContainerContext)
  const history = useHistory()
  const dispatch = useDispatch()

  const goToPayrollDashboard = () => {
    setActive(false)

    const date = content?.closed_period_begins_at.split('-')
    history.push(payrollDateRoute({year: date[0], month: date[1]}))
  }

  const handleOnClick = e => {
    if (unread) {
      dispatch(markNotificationUserAsRead(itemID, goToPayrollDashboard))
      return
    }

    goToPayrollDashboard()
  }

  return (
    <div className="s-cross-start nowrap" onClick={handleOnClick}>
      <div className="s-mr-8px">
        <UserAvatar picture={content.user_picture} userName={content.user_name} />
      </div>
      <div>
        <p className="small s-mb-0">
          <span className="s-color-title">{content.user_name}</span> cerró el{' '}
          <span className="s-color-title">
            {getFullPeriodDescription(
              content.closed_period_begins_at,
              content.closed_period_ends_at
            )}
          </span>
          .
        </p>
        <p className="s-mb-0 smaller s-color-light-text">
          <ReactTimeAgo date={createdAt} />
        </p>{' '}
      </div>
    </div>
  )
}

NotificationItemClosedPeriod.propTypes = {}

export default NotificationItemClosedPeriod
