import React, {useEffect} from 'react'
import ProfileHeader from '../components/Organisms/Headers/ProfileHeader'
import ProfileForm from '../components/Organisms/Forms/ProfileForm'

const Profile = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main className="s-to-center m-60">
      <div className="ed-grid rows-gap">
        <ProfileHeader />
        <ProfileForm />
      </div>
    </main>
  )
}

export default Profile
