import React, {useEffect, useState, useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import OccasionalNoveltyTable from '../../Molecules/Tables/OccasionalNoveltyTable'
import NoveltyContainer from '../Containers/NoveltyContainer'
import OccasionalNoveltyForm from '../Forms/OccasionalNoveltyForm'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {fullName} from '../../../helpers/string-format'
import {isAnyEqual, isEqual, isNull} from '../../../helpers/utils'
import {getOccasionalNoveltyByID} from '../../../services/occasionalnovelty'
import {getOccasionalNoveltiesByHashProcess} from '../../../services/occasionalnovelty'
import {PayrollContext} from '../../../contexts/contexts'
import useFetchNoveltiesWithConditions from '../../../hooks/useFetchNoveltiesWithConditions'
import LoadMoreButton from '../../Atoms/Buttons/LoadMoreButton'
import OccasionalNoveltyBulk from '../Modals/OccasionalNoveltyBulk'

const initialState = {
  id: 0,
  concept_id: 0,
  concept_description: '',
  contract_id: 0,
  employee_description: '',
  pay_period_id: 0,
  process_id: 0,
  quantity: '0',
  value: '0',
  notes: '',
}

const OccasionalNovelty = ({action, setAction, signalBulk}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dataForm, setDataForm] = useState(initialState)
  const {activeProcess} = useContext(PayrollContext)
  const defaultSortOption = {key: 'contract_id', value: 'Empleado'}

  const bulkModal = useRef()

  useEffect(() => {
    setIsOpen(false)

    if (isNull(action.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(action.type, CREATE)) {
      setDataForm(initialState)
      setIsOpen(true)
      return
    }

    if (isAnyEqual(action.type, [EDIT, SHOW])) {
      getOccasionalNoveltyByID(
        action.noveltyID,
        response => {
          setDataForm({
            id: response.id,
            concept_id: response.concept_id,
            concept_description: response.concept_description,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            pay_period_id: response.pay_period_id,
            process_id: response.process_id,
            quantity: response.quantity,
            value: response.value,
            notes: response.notes,
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }
  }, [action])

  useEffect(() => {
    if (isNull(signalBulk)) return

    bulkModal.current.openModal()
  }, [signalBulk])

  const {
    novelties,
    page,
    isLastPage,
    isLoading,
    reloadNovelties,
    setFilter,
    setSortOption,
    setPage,
    setReloadNovelties,
  } = useFetchNoveltiesWithConditions(activeProcess.hash, defaultSortOption, 5, getOccasionalNoveltiesByHashProcess)

  const handlerOnClickAddPage = () => {
    if (isLastPage) return
    setPage(page + 1)
  }

  return (
    <>
      <NoveltyContainer
        isOpenForm={isOpen}
        form={
          <OccasionalNoveltyForm
            initialState={dataForm}
            action={action}
            setAction={setAction}
            setReloadNovelties={setReloadNovelties}
            reloadNovelties={reloadNovelties}
          />
        }
        table={
          <OccasionalNoveltyTable
            setAction={setAction}
            novelties={novelties}
            setFilter={setFilter}
            setSortOption={setSortOption}
            defaultSortOption={defaultSortOption}
            setReloadNovelties={setReloadNovelties}
            reloadNovelties={reloadNovelties}
          />
        }
      />
      {!isLastPage && !isLoading && (
        <LoadMoreButton description="Carga más novedades" onClick={handlerOnClickAddPage} />
      )}

      {/* Bulk modal */}
      <OccasionalNoveltyBulk
        ref={bulkModal}
        reloadNovelties={reloadNovelties}
        setReloadNovelties={setReloadNovelties}
      />
    </>
  )
}

OccasionalNovelty.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.oneOf([SHOW, CREATE, EDIT, null]),
    noveltyID: PropTypes.number,
  }),
  setAction: PropTypes.func,
}

OccasionalNovelty.defaultProps = {
  action: {type: null, noveltyID: 0},
  setAction: () => {},
}

export default OccasionalNovelty
