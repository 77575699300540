import {decode} from 'jsonwebtoken'

const TOKEN_EMPLOYER_KEY = process.env.REACT_APP_TOKEN_EMPLOYER_KEY

export const getEmployerTokenData = () => decode(sessionStorage.getItem(TOKEN_EMPLOYER_KEY))

export const calculateDV = nit => {
  const serie = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71]
  const len = nit.length
  let dv = 0

  for (let i = 0; i < len; i++) {
    let seriePosition = len - i - 1
    dv += nit.substr(i, 1) * serie[seriePosition]
  }

  const module11 = dv % 11
  if (module11 > 1) {
    return 11 - module11
  }
  return module11
}

const mapAspectRatios = {
  '1:1': 'ratio-1-1',
  '3:2': 'ratio-3-2',
  '4:3': 'ratio-4-3',
  '3:4': 'ratio-3-4',
  '16:9': 'ratio-16-9',
  '18:6': 'ratio-18-6',
}

export const getClassAspectRatio = ratio => {
  const classCSS = mapAspectRatios[ratio]

  if (!classCSS) {
    return 'ratio-4-3'
  }

  return classCSS
}
