import {RequestWithEmployerAuthorization} from '../helpers/request'
import {isNull} from '../helpers/utils'
import {actions as accountingInterfaceConfig} from '../redux/ducks/accountingInterfaceConfigurations'

const RESOURCE = '/accounting-interface-configurations'

export const getAccountingInterfaceConfigurations = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    RESOURCE,
    response => {
      dispatch(accountingInterfaceConfig.set(isNull(response) ? [] : response))
      dispatch(accountingInterfaceConfig.setObj(getAccountingInterfaceObj(response)))
    },
    error => console.error('accounting-interface-configurations:', error)
  )
}

const getAccountingInterfaceObj = configs => {
  let objMapping = {}

  if (!Array.isArray(configs)) return objMapping

  configs.forEach(record => (objMapping[record.id] = record))

  return objMapping
}
