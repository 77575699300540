import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import Container from '../../Wrappers/Container'
import CarouselGrid from '../../Wrappers/CarouselGrid'
import PayrollSummaryItem from '../../Molecules/Items/PayrollSummaryItem'
import Skeleton from 'react-loading-skeleton'
import { regenerateDIANRejectedElectronicPayroll, resendFailedElectronicPayroll } from '../../../services/electronicPayroll'
import { notifyError, notifySuccessful } from '../../../services/notification'

const SummaryElectronicPayrollPanel = ({data, year, month, onUpdateSummaryPromise}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleRetry = () => {
    const payload = {year: year, month: month}
    setIsLoading(true)

    resendFailedElectronicPayroll(payload)
      .then(() => onUpdateSummaryPromise())
      .then(() => dispatch(notifySuccessful("¡Listo! los documentos fueron reenviados, revisa su nuevo estado.")))
      .catch(error => dispatch(notifyError(error)))
      .finally(() => setIsLoading(false))
  }

  const handleRegenerate = () => {
    const payload = {year: year, month: month}
    setIsLoading(true)

    regenerateDIANRejectedElectronicPayroll(payload)
      .then(() => onUpdateSummaryPromise())
      .then(notifySuccessful("¡Listo! se han creado nuevos documentos, revisalos."))
      .catch(error => dispatch(notifyError(error)))
      .finally(() => setIsLoading(false))
  }

  return !data ? (
    <Skeleton height={120} />
  ) : (
    <Container>
      <h3>Resumen general</h3>
      <CarouselGrid
        breakpoints={{
          lg: 5,
          m: 3,
          s: 2,
        }}
      >
        <PayrollSummaryItem
          title="Borradores"
          icon="document"
          content={`${data?.DRAFT || 0}`}
        />
        <PayrollSummaryItem
          title="En proceso"
          icon="rocket"
          content={`${data?.IN_PROCESS || 0}`}
        >
          { (data?.IN_PROCESS && data?.IN_PROCESS > 0) && <div className='loading blue micro'></div>}
        </PayrollSummaryItem>
        <PayrollSummaryItem
          title="Aceptados"
          icon="check"
          content={`${data?.ACCEPTED || 0}`}
        />
        <PayrollSummaryItem
          title="Fallidos"
          icon="cross"
          content={`${data?.FAILED || 0}`}
        >
          { data?.FAILED &&
            <button onClick={handleRetry} className='button micro ghost' disabled={isLoading}>Reenvia</button>
          }
        </PayrollSummaryItem>
        <PayrollSummaryItem
          title="Rechazados"
          icon="cross"
          content={`${data?.REJECTED || 0}`}
        >
          { data?.REJECTED &&
            <button onClick={handleRegenerate} className='button micro ghost' disabled={isLoading}>Repreparar</button>
          }
        </PayrollSummaryItem>
      </CarouselGrid>
    </Container>
  )
}

export default SummaryElectronicPayrollPanel
