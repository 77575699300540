import React from 'react'
import PropTypes from 'prop-types'
import {useMedia} from '../../hooks/useMedia'
import {mediaFromM} from '../../helpers/media-queries'
import {TableTemplateContext} from '../../contexts/contexts'

const Table = props => {
  const {heads, children, className, classNameTh, classNameHeader} = props
  const fromM = useMedia(mediaFromM)

  return (
    <TableTemplateContext.Provider value={{heads}}>
      <table className={`table ${className}`}>
        {fromM && (
          <thead className={classNameHeader}>
            <tr>
              {heads.map((_, i) => (
                <th key={i} className={classNameTh}>
                  {heads[i]}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>{children}</tbody>
      </table>
    </TableTemplateContext.Provider>
  )
}

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  heads: PropTypes.array.isRequired,
}

Table.defaultProps = {
  className: '',
}

export default Table
