import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions} from '../redux/ducks/workplaces'

const resource = '/workplaces'

export const getWorkplaces = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${resource}`,
    response => dispatch(actions.set(response)),
    error => console.error('workplaces:', error)
  )
}
