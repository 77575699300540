import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useContext} from 'react'
import NoveltyFormContainer from '../Containers/NoveltyFormContainer'
import SelectEmployeeInput from '../../Molecules/Selects/SelectEmployeeInput'
import Select from '../../Atoms/Forms/Select'
import TextArea from '../../Atoms/Forms/TextArea'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import FormInput from '../../Atoms/Templates/FormInput'
import RadioButton from '../../Atoms/Forms/RadioButton'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import {isAnyEqual, isEqual, len} from '../../../helpers/utils'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {initialStateAction} from '../Tables/NoveltyTable'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {PayrollContext} from '../../../contexts/contexts'
import ValidatorForm from '../../../helpers/validator'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {readBackendDate, writeBackendDate} from '../../../helpers/dates'
import {
  createAbsenceNovelty,
  updateAbsenceNovelty,
} from '../../../services/absenceNovelties'
import PerPeriodItem from '../../Molecules/Items/PerPeriodItem'
import { ORIGIN_APP_NOMINEROS } from '../../../helpers/origin'
import SelectConceptInput from '../../Molecules/Selects/SelectConceptInput'
import {selectors as employerConceptSelectors} from '../../../redux/ducks/concepts'

const formFields = {
  contract_id: 'empleado',
  absence_type_id: 'tipo de ausentismo',
  concept_id: 'concepto',
  begins_at: 'inicio',
  ends_at: 'finalización',
  code: 'código',
  note: 'nota',
  has_discount_rest_day: '¿Descuenta dominical?',
  can_pause_contract: '¿Interrumpe contrato?',
}

const rules = {
  contract_id: 'required|integer|not_in:0',
  absence_type_id: 'required|integer|not_in:0',
  concept_id: 'required|integer|not_in:0',
  begins_at: 'required|date',
  ends_at: 'required|date|after_or_equal:begins_at',
  code: 'string',
  note: 'string',
  has_discount_rest_day: 'required|boolean',
  can_pause_contract: 'required|boolean',
}

const AbsenceNoveltyForm = ({
  action,
  setAction,
  initialState,
  reloadNovelties,
  setReloadNovelties,
}) => {
  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)
  const absConceptSetting = useSelector(employerConceptSelectors.getAbsenceConceptSetting)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const {activeProcess} = useContext(PayrollContext)
  const absenceTypes = cacheGeneral.data?.absence_types
  const isDisabled = isEqual(action.type, SHOW)
  const [formData, setFormData] = useState(initialState)

  const handleOnSelectEmployeeInput = contractID => {
    setFormData(state => ({
      ...state,
      contract_id: Number(contractID),
    }))
  }

  const handleOnSelectConceptInput = conceptID => {
    const absenceTypeID = Number(absConceptSetting.types[conceptID])
    const absenceType = absenceTypes.find(a => a.id === absenceTypeID)

    setFormData(state => ({
      ...state,
      concept_id: Number(conceptID),
      absence_type_id: absenceTypeID  || 0,
      is_discount: absenceType?.is_discount || false,
      has_discount_rest_day: absenceType?.is_discount || false,
      can_pause_contract: absenceType?.can_pause_contract || false,
    }))
  }

  const handleOnChangeInputText = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date,
    }))
  }

  const handleOnChangeEndsAt = date => {
    setFormData(state => ({
      ...state,
      ends_at: date,
    }))
  }

  const handleOnChangeBoolean = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: isEqual(value, 'true'),
    }))
  }

  const handleCancelForm = () => setAction(initialStateAction)

  const handleSuccessForm = () => {
    if (isEqual(action.type, CREATE)) handleCreate()
    if (isEqual(action.type, EDIT)) handleEdit()
  }

  const handleValidate = () => {
    const payload = {
      contract_id: Number(formData.contract_id),
      absence_type_id: Number(formData.absence_type_id),
      concept_id: Number(formData.concept_id),
      begins_at: writeBackendDate(formData.begins_at),
      ends_at: writeBackendDate(formData.ends_at),
      code: formData.code,
      note: formData.note,
      has_discount_rest_day: formData.has_discount_rest_day,
      can_pause_contract: formData.can_pause_contract,
      process_hash: activeProcess.hash,
      origin: ORIGIN_APP_NOMINEROS,
    }

    const validator = new ValidatorForm(payload, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      throw validator.errors()
    }

    return payload
  }

  const handleCreate = () => {
    try {
      const payload = handleValidate()
    
      createAbsenceNovelty(payload, response => {
        setAction(initialStateAction)
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('Hemos creado el ausentismo exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleEdit = () => {
    try {
      const payload = handleValidate()
      updateAbsenceNovelty(formData.id, payload, response => {
        setAction(initialStateAction)
        setReloadNovelties(!reloadNovelties)
        dispatch(notifySuccessful('Hemos Actualizado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const successButtonText = isEqual(action.type, CREATE)
    ? 'Agregar Ausencia'
    : isEqual(action.type, EDIT)
    ? 'Editar Ausencia'
    : 'Guardar'

  return (
    <NoveltyFormContainer
      cancelFunction={handleCancelForm}
      successFunction={handleSuccessForm}
      successButtonText={successButtonText}
      noveltyStatus={initialState.status}
    >
      <SelectEmployeeInput
        isRequired
        isDisabled={isAnyEqual(action.type, [EDIT, SHOW])}
        id="contract_id"
        name="contract_id"
        label="Empleado"
        placeholder="Busca por Identificación o nombre"
        onSelect={handleOnSelectEmployeeInput}
        initialValue={formData.employee_description}
      />

      <SelectConceptInput
        isRequired
        isDisabled={isDisabled}
        name="concept_id"
        id="concept_id"
        label="Concepto"
        placeholder="Busca por código o nombre"
        onSelect={handleOnSelectConceptInput}
        concepts={absConceptSetting.list}
        initialValue={formData.concept_description}
      />

      <DatePickerInput
        isRequired
        disabled={isDisabled}
        minDate={
          isEqual(activeProcess.application_pay_period_id, 0)
            ? readBackendDate(activeProcess.begins_at)
            : readBackendDate(activeProcess.begins_at_application_period)
        }
        maxDate={
          isEqual(activeProcess.application_pay_period_id, 0)
            ? readBackendDate(activeProcess.ends_at)
            : readBackendDate(activeProcess.ends_at_application_period)
        }
        label="Inicio"
        id="begins_at"
        name="begins_at"
        value={formData.begins_at}
        onChange={handleOnChangeBeginsAt}
      />

      <DatePickerInput
        isRequired
        disabled={isDisabled}
        label="Finalización"
        id="ends_at"
        name="ends_at"
        openToDate={isEqual(formData.ends_at, '') ? formData.begins_at : formData.ends_at}
        minDate={formData.begins_at}
        onChange={handleOnChangeEndsAt}
        value={formData.ends_at}
      />

      <FormInput
        isDisabled={isDisabled}
        id="code"
        name="code"
        label="Código"
        placeholder="000000"
        onChange={handleOnChangeInputText}
        value={formData.code}
      />

      <TextArea
        className="s-h-full lg-x-3 lg-y-1 s-rows-2 s-order-1 m-cols-2 lg-cols-1"
        isDisabled={isDisabled}
        id="note"
        name="note"
        label="Nota y observaciones"
        onChange={handleOnChangeInputText}
        value={formData.note}
        textarea={{
          className: 's-h-full',
        }}
      />

      {formData.is_discount && (
        <div className="s-main-center m-main-start">
          <RadioButtonsWrapper label="¿Descuenta dominical?">
            <RadioButton
              disabled={isDisabled}
              name="has_discount_rest_day"
              label="Sí"
              onChange={handleOnChangeBoolean}
              value={true}
              checked={isEqual(formData.has_discount_rest_day, true)}
            />
            <RadioButton
              disabled={isDisabled}
              name="has_discount_rest_day"
              label="No"
              onChange={handleOnChangeBoolean}
              value={false}
              checked={isEqual(formData.has_discount_rest_day, false)}
            />
          </RadioButtonsWrapper>
        </div>
      )}

      {formData.is_discount && (
        <div className="s-main-center m-main-start">
          <RadioButtonsWrapper label="¿Interrumpe contrato?">
            <RadioButton
              disabled={isDisabled}
              name="can_pause_contract"
              label="Sí"
              onChange={handleOnChangeBoolean}
              value={true}
              checked={isEqual(formData.can_pause_contract, true)}
            />
            <RadioButton
              disabled={isDisabled}
              name="can_pause_contract"
              label="No"
              onChange={handleOnChangeBoolean}
              value={false}
              checked={isEqual(formData.can_pause_contract, false)}
            />
          </RadioButtonsWrapper>
        </div>
      )}

      {Array.isArray(initialState.absence_per_periods) &&
        len(initialState.absence_per_periods) > 1 && (
          <div className="m-cols-3 ed-grid rows-gap s-gap-1">
            <h4>Distribución de la novedad por periodos</h4>
            <div className="ed-grid s-grid-1 m-grid-3 rows-gap">
              {initialState.absence_per_periods.map(novelty => (
                <PerPeriodItem key={novelty.id} perPeriodNovelty={novelty} />
              ))}
            </div>
          </div>
        )}
    </NoveltyFormContainer>
  )
}

export default AbsenceNoveltyForm
