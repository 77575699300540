import React from 'react'
import PropTypes from 'prop-types'

const BankInformationItem = ({name, type, account}) => {
  return (
    <div>
      <p className="s-mb-0 s-color-light-text small">{name}</p>
      <p className="s-mb-0 s-color-lighter-text smaller">
        {type ? `${type} - ${account}` : account}
      </p>
    </div>
  )
}

BankInformationItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  account: PropTypes.string.isRequired,
}

export default BankInformationItem
