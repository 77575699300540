import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions as processType} from '../redux/ducks/processTypeConfig'

const resource = '/process-types'

export const getProcessTypesActive = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    resource,
    response => {
      dispatch(processType.set(response))},
    error => console.error('process type:', error)
  )
}
