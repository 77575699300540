import React from 'react'
import {
  NOTIFICATION_PROCESS_RUN,
  NOTIFICATION_CLOSE_MONTH,
  NOTIFICATION_CLOSED_PERIOD,
  NOTIFICATION_PROCESS_APPROVE,
  NOTIFICATION_PROCESS_FATAL,
  NOTIFICATION_ELECTRONIC_PAYROLL,
} from '../../../helpers/notification-user'
import {isEqual} from '../../../helpers/utils'
import NotificationItemClosedPeriod from './NotificationItemClosedPeriod'
import NotificationItemCloseMonth from './NotificationItemCloseMonth'
import NotificationItemProcessApprove from './NotificationItemProcessApprove'
import NotificationItemProcessFatal from './NotificationItemProcessFatal'
import NotificationItemProcessRun from './NotificationItemProcessRun'
import NotificationItemElectronicPayroll from './NotificationItemElectronicPayroll'

const NotificationItem = ({itemID, unread, type, content, createdAt}) => {
  return (
    <article
      className={`notification-item s-pxy-4px s-mb-0 normal-radius hover-change cursor-pointer ${
        unread && 's-bg-blue-50'
      }`}
    >
      {isEqual(type, NOTIFICATION_PROCESS_RUN) && (
        <NotificationItemProcessRun
          itemID={itemID}
          unread={unread}
          content={content}
          createdAt={createdAt}
        />
      )}

      {isEqual(type, NOTIFICATION_PROCESS_APPROVE) && (
        <NotificationItemProcessApprove
          itemID={itemID}
          unread={unread}
          content={content}
          createdAt={createdAt}
        />
      )}

      {isEqual(type, NOTIFICATION_PROCESS_FATAL) && (
        <NotificationItemProcessFatal
          itemID={itemID}
          unread={unread}
          content={content}
          createdAt={createdAt}
        />
      )}

      {isEqual(type, NOTIFICATION_CLOSED_PERIOD) && (
        <NotificationItemClosedPeriod
          itemID={itemID}
          unread={unread}
          content={content}
          createdAt={createdAt}
        />
      )}

      {isEqual(type, NOTIFICATION_CLOSE_MONTH) && (
        <NotificationItemCloseMonth
          itemID={itemID}
          unread={unread}
          content={content}
          createdAt={createdAt}
        />
      )}

      {isEqual(type, NOTIFICATION_ELECTRONIC_PAYROLL) && (
        <NotificationItemElectronicPayroll
          itemID={itemID}
          unread={unread}
          content={content}
          createdAt={createdAt}
        />
      )}
    </article>
  )
}

NotificationItem.propTypes = {}

export default NotificationItem
