import React, {useEffect} from 'react'
import SettingHeader from '../Headers/SettingHeader'

const ProcessCalculateSettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="lg-cols-2">
        <SettingHeader icon="calculator" title="Procesos de cálculo" isComingSoon />
      </div>

      <div></div>
    </>
  )
}

export default ProcessCalculateSettings
