import React from 'react'
import PropTypes from 'prop-types'
import HeroIcons from '../../static/icons/HeroIcons'

const Icon = React.memo(({className, svg, size, color, onClick, ...rest}) => {
  const icon = HeroIcons[svg] || HeroIcons.default
  return (
    icon && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        className={className}
        fill={color}
        onClick={onClick}
        {...rest}
      >
        {icon}
      </svg>
    )
  )
})

Icon.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  svg: PropTypes.string.isRequired,
}

Icon.defaultProps = {
  size: '1em',
  className: '',
  color: 'currentColor',
  onClick: () => {},
}

export default Icon
