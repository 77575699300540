import React from 'react'
import Employee from '../../../static/images/stories/employees.svg'
import Container from '../../Wrappers/Container'

const EmployeesEmptyState = () => {
  return (
    <Container className="lg-cols-2 s-column s-center s-cross-center s-main-center">
      <div className="l-block" />
      <img
        className="s-mb-2"
        src={Employee}
        alt="Empleados"
        width="200px"
        height="200px"
      />
      <h3>¡Vaya! esto se ve un poco vacío</h3>
      <p className="s-color-light-text">
        Puedes agregar un nuevo empleado o selecciona uno de la lista para verlo aquí.
      </p>
    </Container>
  )
}

export default EmployeesEmptyState
