import React from 'react'
import {getFormatCurrency, getFormatNumber} from '../../helpers/format-number'
import {shortFormatDate} from '../../helpers/dates'
import AccordionTemplate from '../Wrappers/AccordionTemplate'
import AccordionWrapper from '../Wrappers/AccordionWrapper'
import PreviewMarkdown from '../Molecules/PreviewMarkdown'
import Tag from '../Atoms/Tag'
import {isBoolean, isEmpty, isEqual, isObject, isUndefined} from '../../helpers/utils'
import PanelTemplate from '../Wrappers/PanelTemplate'
import TabItem from '../Atoms/TabItem'
import TabsWrapper from '../Wrappers/TabsWrapper'
import {capitalize} from '../../helpers/string-format'

const TRANSLATION = {
  BIMONTHLY: 'Quincenal',
  MONTHLY: 'Mensual',
  QUARTERLY: 'Trimestral',
  BIANNUALLY_TOTAL: 'Semestre total',
  BIANNUALLY: 'Semestral',
  ANNUAL: 'Anual',
  'LAST-360-DAYS': 'Últimos 360 días',
  'LAST-360-DAYS-FROM-PREVIOUS-MONTH': 'Últimos 360 días desde mes anterior',
}

const GROUP_CONCEPTS = [
  {id: 1, description: 'Nómina', types: ['INGRESO', 'DESCUENTO']},
  {id: 2, description: 'Provisiones', types: ['PROVISION']},
  {
    id: 3,
    description: 'Conceptos Especiales',
    types: ['INGRESO INTERNO', 'DESCUENTO INTERNO', 'NO OPERA'],
  },
]

const TYPE_CONCEPTS = {
  INGRESO: 'Ingresos',
  DESCUENTO: 'Descuentos',
  'INGRESO INTERNO': 'Ingresos Puente',
  'DESCUENTO INTERNO': 'Descuentos Puente',
  PROVISION: 'Provisiones',
  'NO OPERA': 'Conceptos Especiales',
}

const UserPayslipInformation = props => {
  const groups = GROUP_CONCEPTS.filter(g => !isEmpty(g.types.filter(t => !isEmpty(props?.Data?.concepts[t]))))

  return (
    <TabsWrapper defaultActive={Array.isArray(groups) ? groups[0].id : 1}>
      <hr />
      <div className="s-mb-1 s-cross-center s-main-center">
        {Array.isArray(groups) &&
          groups.map(g => (
            <TabItem key={g.id} index={g.id} className="s-mr-1">
              {g.description}
            </TabItem>
          ))}
      </div>
      {Array.isArray(groups) &&
        groups.map(g => (
          <PanelTemplate key={g.id} index={g.id}>
            {g.types.map(
              t =>
                Array.isArray(props?.Data?.concepts[t]) && (
                  <section key={t} className="s-mb-1 s-bg-blue-50 normal-radius s-pxy-2">
                    <h4 className="s-mb-8px">{TYPE_CONCEPTS[t]}</h4>
                    <AccordionWrapper defaultActive={999}>
                      {Array.isArray(props?.Data?.concepts[t]) &&
                        props?.Data?.concepts[t].map((concept, index) => {
                          return (
                            <AccordionTemplate
                              index={index}
                              className="s-bg-body normal-radius s-pxy-1 s-mb-1 small"
                              key={concept.id}
                            >
                              <div className="s-cross-center cursor-pointer">
                                <div className="dropdown-icon s-mr-1">▾</div>
                                <span className="s-mr-1 s-color-title">{`${concept.code} - ${concept.description}:`}</span>
                                {concept.quantity && (
                                  <Tag
                                    text={`Cant: ${getFormatNumber(concept.quantity)}`}
                                    color="text"
                                    bgColor="s-bg-border"
                                    className="s-mr-1"
                                  />
                                )}

                                {shortFormatDate(concept.begins_at) && (
                                  <Tag
                                    text={`${shortFormatDate(concept.begins_at)} al ${shortFormatDate(
                                      concept.ends_at
                                    )}`}
                                    color="text"
                                    bgColor="s-bg-border"
                                    className="s-mr-1"
                                  />
                                )}

                                <Tag
                                  text={`Total: ${getFormatCurrency(
                                    concept.amount,
                                    props.employerSession?.code_iso_3,
                                    !props.employerSession?.not_include_decimals_in_calculation
                                  )}`}
                                  color="blue"
                                  bgColor="s-bg-light-blue"
                                />
                              </div>
                              <div className="s-pxy-1">
                                <PreviewMarkdown content={getDetail(concept?.detail)} />
                              </div>
                            </AccordionTemplate>
                          )
                        })}
                    </AccordionWrapper>
                  </section>
                )
            )}
          </PanelTemplate>
        ))}
    </TabsWrapper>
  )
}

function getDetail(detail) {
  if (!detail) return

  const {variables, tables} = getVariables(detail?.argumentos)

  return `
  ${getSpreadsheets(tables)}
  ${getSalaryAVG(detail?.argumentos['promedio_salario'])}
  ${getBases(detail?.argumentos['bases'])}
  ${variables}
  ${getFormula(detail?.formula)}`
}

function getFormula(formula) {
  return '#### 🧪 Formula:\n```js\n' + formula + '\n```\n'
}

function getVariables(args) {
  let tables = []
  let argYml = ''
  Object.entries(args).forEach(([key, value]) => {
    // Discard bases and average salaries
    if (key === 'bases' || key === 'promedio_salario') return

    // include the tables
    if (isObject(value) && value.hasOwnProperty('columns')) {
      tables.push({key, value})
      return
    }

    if (isBoolean(value)) {
      value = value ? 'VERDADERO' : 'FALSO'
    }

    argYml += `${key}: ${value}\n`
  })

  return {
    variables: `#### 🧩 Variables:\n\`\`\`yaml\n${argYml}\`\`\`\n`,
    tables: tables,
  }
}

function getSalaryAVG(salaries) {
  if (isUndefined(salaries) || isEmpty(salaries) || !Array.isArray(salaries)) return ''

  let tables = ''

  salaries.forEach(s => {
    const variation = s.VariationLastNDays ? `(Variación: ${s.VariationLastNDays})` : ''

    let table = `**Promedio ${TRANSLATION[s.Range]}**: ${variation} \`Resultado: ${getFormatNumber(
      s.Result
    )}\`\n\n${getSalaryTable(s)}\n\n`
    tables += table
  })

  return `#### 💵 Promedios de salario:\n\n ${tables}\n\n`
}

function getSalaryTable(data) {
  if (isEmpty(data.Salaries)) return ''

  let rows = ''

  data.Salaries.forEach(s => {
    rows += `| ${getFormatNumber(s.Salary)} | ${s.BeginsAt} | ${s.EndsAt} | ${getFormatNumber(
      s.Days
    )} | ${getFormatNumber(s.Accumulated)} |\n`
  })

  return `
  | Salario | Fecha Inicial | Fecha Final | Días | Acumulado |
  | :--- | ---: | ---: | ---: | ---: |
  ${rows}| | | **Total** | **${getFormatNumber(data.TotalDays)}** | **${getFormatNumber(data.TotalAccumulated)}** |
  |  |  |  | **Promedio** | **${getFormatNumber(data.Average)}** |
  `
}

function getBases(bases) {
  if (isUndefined(bases) || isEmpty(bases) || !Array.isArray(bases)) return ''

  let tables = ''

  bases.forEach(base => {
    let table = `**${base.Name}**: (${TRANSLATION[base.Accumulation]}) \`Total: ${getFormatNumber(
      base.Total
    )}\`\n\n${getTable(base.Concepts, base.Total)}\n\n`

    tables += table
  })

  return `#### 🛠 Bases:\n\n ${tables}\n\n`
}

function getTable(concepts, total) {
  const conceptNames = Object.keys(concepts)
  if (isEmpty(conceptNames)) return ''

  const columns = getColumnsBase(conceptNames, concepts)

  const header = `| Concepto | ${columns.join(' |')} | Acumulado | Días | Total |`
  let rows = ''

  conceptNames.forEach(n => {
    const values = []
    columns.forEach(col => {
      values.push(getFormatNumber(concepts[n][col]))
    })

    rows += `| *${n}* | ${values.join(' |')} | ${getFormatNumber(concepts[n]['accumulated'])} | ${
      concepts[n]['days_average'] || ''
    } | **${getFormatNumber(concepts[n]['total'])}** |\n`
  })

  return `
  ${header}
  | :--- |${' ---: |'.repeat(columns.length + 3)}
  ${rows}| | ${' |'.repeat(columns.length)} | **Total** | **${getFormatNumber(total)}** |
  `
}

function getColumnsBase(conceptNames, concepts) {
  // we using a Set for gets a list of unique columns
  const list = new Set()

  conceptNames.forEach(n => {
    const keys = Object.keys(concepts[n])
    keys.forEach(k => list.add(k))
  })

  list.delete('accumulated')
  list.delete('days_average')
  list.delete('total')

  const columns = Array.from(list)

  columns.sort((a, b) => {
    const [yearA, monthA] = a.split('-')
    const [yearB, monthB] = b.split('-')

    if (Number(yearA) !== Number(yearB)) {
      return Number(yearA) > Number(yearB) ? 1 : -1
    }

    return Number(monthA) > Number(monthB) ? 1 : -1
  })

  return columns
}

function getSpreadsheets(tables) {
  if (isEmpty(tables)) return ''

  let spreadsheets = ''

  tables.forEach(t => {
    let spreadsheet = `**${t.key}**: \n\n${getSpreadsheet(t.value)}\n\n`

    spreadsheets += spreadsheet
  })

  return `#### 🧮 Hojas de cálculo:\n\n ${spreadsheets}\n\n`
}

function getSpreadsheet(table) {
  const header = `| ${table.columns.map(c => capitalize(c)).join(' |')} |`
  let rows = ''

  table.rows.forEach(r => {
    rows += `| ${r.map((col, ix) => (isEqual(ix, 0) ? `*${col}*` : col)).join(' |')} |\n`
  })

  const t = `
  ${header}
  | :--- |${' ---: |'.repeat(table.columns.length - 1)}
  ${rows}
  `

  return t
}

export default UserPayslipInformation
