import React from 'react'
import Badge from '../../Atoms/Badge'
import {getFormatCurrency} from '../../../helpers/format-number'
import { COLOMBIA_CODE } from '../../../helpers/country'

const ElectronicPayrollDocument = ({NIE="NIE000", tagName="ConceptName", values=[]}) => {
  return (
    <div className='border-bottom s-py-1 s-color-light-text'>
      <div className='s-cross-center small'>
        <Badge text={NIE} color='s-bg-text' className="s-mr-1" size="s"/>
        <Badge text={tagName} isGhost className="s-mr-1" size="s"/>
      </div>
      <div className='small'>
        { Array.isArray(values) && values.map((value, ix) => (
        <span key={ix}><span className='s-color-text smaller'> | {value?.title}:</span> {
          value?.isCurrency ? getFormatCurrency(value?.content, COLOMBIA_CODE, false) : value?.content
        }</span>
        ))} |
      </div>
    </div>
  )
}

export default ElectronicPayrollDocument
