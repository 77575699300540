import React from 'react'
import EmptyPaint from '../../../static/images/stories/empty-paint.svg'

const MonthProgressEmptyState = () => {
  return (
    <div className="s-column s-cross-center s-main-center s-center">
      <img
        className="s-80 s-mb-16px"
        src={EmptyPaint}
        alt="Pintor en blanco"
        width="200px"
        height="200px"
      />
      <p className="small s-mb-0 s-color-light-text">
        Aún no has realizado algún movimiento. <br />
        Tranquilo, tómate tu tiempo.
      </p>
    </div>
  )
}

export default MonthProgressEmptyState
