import {createDuck} from 'redux-duck'

const duck = createDuck('app/notification')

// Define action types
export const NOTIFICATION = duck.defineType('NOTIFICATION')
export const NOTIFICATION_CLEAR = duck.defineType('NOTIFICATION_CLEAR')

// Define action creators
export const actions = {
  set: duck.createAction(NOTIFICATION),
  clear: duck.createAction(NOTIFICATION_CLEAR),
}

// Define selectors
export const selectors = {
  get: store => store.notificationReducer,
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [NOTIFICATION]: (state, {payload}) => ({...payload}),
    [NOTIFICATION_CLEAR]: (state, {payload}) => ({}),
  },
  initialState
)
