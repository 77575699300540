export const isUndefined = value => typeof value === 'undefined'
export const isAnyUndefined = (...args) => args.some(arg => isUndefined(arg))
export const isAllUndefined = (...args) => args.every(arg => isUndefined(arg))

export const isNull = value => value === null
export const isEqual = (value1, value2) => value1 === value2
export const isAnyEqual = (value, args) => args.some(arg => isEqual(value, arg))

export const isString = value => typeof value === 'string' || value instanceof String
export const isNumber = value => typeof value === 'number' || value instanceof Number
export const isBoolean = value => typeof value === 'boolean' || value instanceof Boolean
export const isObject = value => (value !== null && typeof value === 'object') || value instanceof Object
export const isFunction = value =>
  typeof value === 'function' || value instanceof Function
export const isDate = value => value instanceof Date

export const len = value => {
  if (Array.isArray(value) || isString(value)) {
    return value.length
  }

  return 0
}

export const isEmpty = value => {
  if (isNull(value) || isUndefined(value)) return true

  if (isObject(value)) return Object.keys(value).length === 0

  if (!isString(value) && !Array.isArray(value)) return false

  return len(value) === 0
}

export const isJSON = value => {
  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }
  return true
}

export const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const getURLImage = route => {
  if (isEmpty(route)) {
    return ''
  }

  // if the route has schema or is the static image
  if (route.startsWith('http') || route.startsWith('/static')) {
    return route
  }
  return `${process.env.REACT_APP_S3_SERVER}/${route}`
}

export const stringToBool = bool => {
  if (isEqual(bool, 'true')) return true
  if (isEqual(bool, 'false')) return false

  return null
}

export const randomID = length => {
  var result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const stringToHashNumber = (string) => {
  let hash = 0

  if (string.length === 0) return hash

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }

  return hash
}
