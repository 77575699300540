import React from 'react'
import {useHistory} from 'react-router-dom'
import Container from '../components/Wrappers/Container'
import CompletedImage from '../static/images/stories/completed.svg'
import {STAGE_PAYSHEET, findProcessStage} from '../helpers/payroll'

const PayslipCalculationCompleted = ({setEnabled}) => {
  const history = useHistory()

  const showResult = () => {
    // Hack 😅. We always move the user to an non-existent page for force update the #nomina page.
    history.replace({hash: '#reload'})

    setEnabled(false)

    setTimeout(function () {
      history.replace({hash: findProcessStage(STAGE_PAYSHEET)?.hash})
    }, 100)
  }

  return (
    <Container className="lg-cols-2 s-column s-center s-cross-center s-main-center s-to-center">
      <div className="l-block" />
      <img className="s-mb-2" src={CompletedImage} alt="Completed" width="200px" height="200px" />
      <h3>¡Cálculo completado!</h3>
      <p className="s-color-light-text">
        <span className="s-mr-1">Ya puedes revisar el cálculo de tu proceso</span>
        <button type="button" className="button small ghost" onClick={showResult}>
          Aquí
        </button>
      </p>
    </Container>
  )
}

export default PayslipCalculationCompleted
