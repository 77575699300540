import React, {useState, useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import IconPanelContainer from '../Containers/IconPanelContainer'
import BranchOfficeInformationSection from '../Sections/BranchOfficeInformationSection'
import AddButton from '../../Atoms/Buttons/AddButton'
import BranchOfficeItem from '../../Molecules/Items/BranchOfficeItem'
import BranchOfficeModal from '../Modals/BranchOfficeModal'
import {selectors as branchOfficeSelectors} from '../../../redux/ducks/branchOffices'
import {isEmpty, isUndefined, isAnyUndefined, isEqual} from '../../../helpers/utils'
import {getStoreGraphic} from '../../../helpers/static-images'
import BranchOfficeEmptyState from '../Emptys/BranchOfficeEmptyState'

const BranchOfficePanel = () => {
  const branchOffices = useSelector(branchOfficeSelectors.getAll)
  const [selected, setSelected] = useState()
  const modal = useRef()

  const handleOpenModal = () => modal.current.openModal()

  useEffect(() => {
    if (!isUndefined(branchOffices)) setSelected(branchOffices[0])
  }, [branchOffices])

  return (
    <div>
      <IconPanelContainer icon="home" title="Sucursales">
        {isAnyUndefined(branchOffices, selected) || isEmpty(branchOffices) ? (
          <BranchOfficeEmptyState size="small" />
        ) : (
          <>
            <BranchOfficeInformationSection
              description={selected.description}
              address={selected.address}
              phone={selected.phone}
              image={getStoreGraphic(selected.id)}
              badge={selected.code}
              ccf={selected.ccf_name}
            />
            <div className="ed-grid s-gap-1 rows-gap">
              <AddButton onClick={handleOpenModal} text="Agrega una sucursal" />
              {branchOffices.map(branchOffice => (
                <BranchOfficeItem
                  key={branchOffice.id}
                  description={branchOffice.description}
                  address={branchOffice.address}
                  image={getStoreGraphic(branchOffice.id)}
                  badge={branchOffice.code}
                  isSelected={isEqual(selected.id, branchOffice.id)}
                  onClick={() => {
                    setSelected(branchOffice)
                  }}
                />
              ))}
            </div>
          </>
        )}
      </IconPanelContainer>

      <BranchOfficeModal ref={modal} />
    </div>
  )
}

export default BranchOfficePanel
