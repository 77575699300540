import React, {forwardRef, useState} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import PropTypes from 'prop-types'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'
import {isEqual} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {useDispatch} from 'react-redux'
import {notifyError, notifySuccessful} from '../../../services/notification'
import RadioButton from '../../Atoms/Forms/RadioButton'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import {createEmployerBank, updateEmployerBank} from '../../../services/employerBanks'
import {
  fieldNamesEmployeePayment,
  rulesEmployeePayment,
  rulesEmployeePaymentEmail,
} from 'helpers/employee'

const NewBankModal = forwardRef(
  ({onAdd, onEdit, banksCache, formData, setFormData}, ref) => {
    const dispatch = useDispatch()

    const [isDisable, setIsDisable] = useState(false)

    const handleCloseModal = () => {
      ref.current.closeModal()
    }

    const handleCreateAccount = () => {
      setIsDisable(true)

      let rules = {...rulesEmployeePayment}

      if (isEqual(formData.account_type, 'EMAIL')) {
        rules = {...rulesEmployeePaymentEmail}
      }

      const validator = new ValidatorForm(formData, rules)
      validator.setAttributeNames(fieldNamesEmployeePayment)

      if (validator.fails()) {
        dispatch(notifyError(validator.errors()))
        setIsDisable(false)
        return
      }

      createEmployerBank(
        formData,
        response => {
          onAdd(response)
          dispatch(notifySuccessful('Hemos creado la cuenta bancaria exitosamente'))
          setIsDisable(false)
          handleCloseModal()
        },
        error => {
          setIsDisable(false)
          dispatch(notifyError(error))
        }
      )
    }

    const handleUpdateAccount = () => {
      setIsDisable(true)

      let rules = {...rulesEmployeePayment}

      if (isEqual(formData.account_type, 'EMAIL')) {
        rules = {...rulesEmployeePaymentEmail}
      }

      const validator = new ValidatorForm(formData, rules)
      validator.setAttributeNames(fieldNamesEmployeePayment)

      if (validator.fails()) {
        dispatch(notifyError(validator.errors()))
        setIsDisable(false)
        return
      }

      updateEmployerBank(
        formData.id,
        formData,
        response => {
          onEdit(response)
          dispatch(notifySuccessful('Hemos actualizado la cuenta bancaria exitosamente'))
          setIsDisable(false)
          handleCloseModal()
        },
        error => {
          setIsDisable(false)
          dispatch(notifyError(error))
        }
      )
    }

    const handleOnChangebank = e => {
      const value = e.target.value
      setFormData(state => ({
        ...state,
        bank_id: Number(value),
      }))
    }

    const handleOnChangeAccountNumber = e => {
      const value = e.target.value

      if (isEqual(formData.account_type, 'EMAIL')) {
        setFormData(state => ({
          ...state,
          account_number: value,
        }))
        return
      }

      const pattern = /^[0-9]+$/
      if (isEqual(value, '') || pattern.test(value)) {
        setFormData(state => ({
          ...state,
          account_number: value,
        }))
      }
    }

    const handleOnChangeAccountType = e => {
      const value = e.target.value
      setFormData(state => ({
        ...state,
        account_type: value,
        account_number: '',
      }))
    }

    const handleOnChangeIsActive = () => {
      setFormData(state => ({
        ...state,
        is_active: !formData.is_active,
      }))
    }

    return (
      <ModalTemplate ref={ref}>
        <h3 className="s-center s-mb-24px">
          {isEqual(formData.id, 0)
            ? 'Nueva Cuenta Bancaria'
            : 'Actualizar Cuenta Bancaria'}
        </h3>
        <form className="s-mb-0 ed-grid rows-gap form-grid">
          <Select
            id="bank"
            label="Banco"
            isRequired
            placeholder
            onChange={handleOnChangebank}
            value={formData.bank_id}
          >
            {Array.isArray(banksCache) &&
              banksCache.map(bank => (
                <option key={bank.id} value={bank.id}>
                  {bank.short_name}
                </option>
              ))}
          </Select>

          <Select
            id="account_type"
            label="Tipo de Cuenta"
            isRequired
            placeholder
            onChange={handleOnChangeAccountType}
            value={formData.account_type}
          >
            {['CUENTA DE AHORROS', 'CUENTA CORRIENTE', 'EMAIL'].map(accountType => (
              <option key={accountType} value={accountType}>
                {accountType}
              </option>
            ))}
          </Select>

          <FormInput
            placeholder="1234567890"
            label="No. de cuenta"
            id="account_number"
            name="account_number"
            isRequired
            onChange={handleOnChangeAccountNumber}
            value={formData.account_number}
          />

          <RadioButtonsWrapper label="¿Cuenta activa?">
            <RadioButton
              name="is_active"
              label="Sí"
              value={true}
              onChange={handleOnChangeIsActive}
              checked={isEqual(formData.is_active, true)}
            />
            <RadioButton
              name="is_active"
              label="No"
              value={false}
              onChange={handleOnChangeIsActive}
              checked={isEqual(formData.is_active, false)}
            />
          </RadioButtonsWrapper>

          <div className="buttons-container">
            <button className="button cancel" onClick={handleCloseModal} type="button">
              Cancelar
            </button>

            {isEqual(formData.id, 0) ? (
              <button
                className="button"
                type="button"
                onClick={handleCreateAccount}
                disabled={isDisable}
              >
                {isDisable ? 'Creando...' : 'Crear cuenta'}
              </button>
            ) : (
              <button
                className="button"
                type="button"
                onClick={handleUpdateAccount}
                disabled={isDisable}
              >
                {isDisable ? 'Actualizando...' : 'Actualizar cuenta'}
              </button>
            )}
          </div>
        </form>
      </ModalTemplate>
    )
  }
)

export default NewBankModal

NewBankModal.propTypes = {
  banksCache: PropTypes.array,
  formDataToUpdate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bank_id: PropTypes.number.isRequired,
    account_number: PropTypes.string.isRequired,
    account_type: PropTypes.string.isRequired,
    is_active: PropTypes.bool,
  }),
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

NewBankModal.defaultProps = {
  banksCache: [],
  formDataToUpdate: {
    id: 0,
    bank_id: 0,
    account_number: '',
    account_type: '',
    is_active: true,
  },
  onAdd: () => {},
  onEdit: () => {},
  setFormDataToUpdate: () => {},
}
