import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import CropImage from '../../Molecules/CropImage'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const ModalCropImage = forwardRef(
  ({title, imageURL, onCropImageComplete, onCancel}, ref) => {
    return (
      <ModalTemplate ref={ref} isLockDefaultClose>
        <CropImage
          title={title}
          image={imageURL}
          onCropImageComplete={onCropImageComplete}
          onCancel={onCancel}
        />
      </ModalTemplate>
    )
  }
)

ModalCropImage.propTypes = {
  title: PropTypes.string.isRequired,
  imageURL: PropTypes.string,
  onCropImageComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

ModalCropImage.defaultProps = {
  title: 'Posiciona y redimensiona tu imagen',
  imageURL: '',
  onCropImageComplete: () => {},
  onCancel: () => {},
}

export default ModalCropImage
