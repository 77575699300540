import { useState, useEffect } from 'react'

const useObjectURL = initialObject => {
  const [objectURL, setObjectURL] = useState(null)
  const [object, setObject] = useState(initialObject)

  useEffect(() => {
    if (!object) return

    const url = URL.createObjectURL(object)
    setObjectURL(url)

    return () => {
      // Make sure to revoke the logo uri to avoid memory leaks
      URL.revokeObjectURL(url)
      setObjectURL(null)
    }
  }, [object])

  return {
    objectURL,
    object,
    setObject,
  }
}

export default useObjectURL
