import {useState, useEffect} from 'react'

export const useClickOutside = (ref) => {
  const [clickOutside, setClickOutside] = useState(false)

  const handleClick = e => {
    if (ref.current) {
      setClickOutside(!ref.current.contains(e.target))
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClick)

    return () => {
      document.body.removeEventListener('click', handleClick)
    }
  }, [clickOutside])

  return clickOutside
}
