import React, {useState, useRef, forwardRef, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getCacheKeyGeneral, getLocalCache} from 'helpers/cache'
import {CREATE, EDIT} from 'helpers/payroll'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import SalaryChangeForm from '../../Organisms/Forms/SalaryChangeForm'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import NoveltyContainer from '../Containers/NoveltyContainer'
import {readBackendDate, shortFormatDate} from 'helpers/dates'
import {deleteSalary, getSalariesByContractID} from 'services/salaries'
import {isEqual, isNull, isUndefined, len} from 'helpers/utils'
import {notifyError, notifySuccessful} from 'services/notification'
import PreviewMarkdown from 'components/Molecules/PreviewMarkdown'
import {getFormatCurrency} from 'helpers/format-number'
import ConfirmModal from './ConfirmModal'
import Icon from 'components/Atoms/Icon'

export const initialStateAction = {
  type: null,
  contractID: 0,
  current: null,
  previous: null,
}

const SalaryEditModal = forwardRef(({data, onUpdate}, ref) => {
  const dispatch = useDispatch()
  const confirmModal = useRef()

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [salaries, setSalaries] = useState([])
  const [action, setAction] = useState(initialStateAction)
  const [deleteID, setDeleteID] = useState(0)

  const employerSession = useSelector(employerSelectors.getSession)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))

  const allSalaryTypes = !isNull(cacheGeneral) && cacheGeneral.data?.salaries_types
  const salaryTypesIDs =
    !isNull(cacheGeneral) &&
    cacheGeneral.data?.contract_type_salary_type?.filter(r =>
      isEqual(r.contract_type_id, data?.contract?.contract_type_id)
    )

  const salaryTypes =
    !isNull(cacheGeneral) &&
    cacheGeneral.data?.salaries_types?.filter(r =>
      salaryTypesIDs.some(s => isEqual(s.salary_type_id, r.id))
    )

  useEffect(() => {
    if (isUndefined(data?.contract?.id)) return

    getSalariesByContractID(
      data?.contract?.id,
      response => setSalaries(response),
      error => dispatch(notifyError(error))
    )
    setAction(initialStateAction)
  }, [data.contract.id])

  const handleCancel = () => setIsOpenForm(false)

  const handleNewSalary = () => {
    setAction({
      type: CREATE,
      contractID: data?.contract?.id,
      current: null,
      previous: salaries[salaries.length - 1], // the last salary
    })

    setIsOpenForm(true)
  }

  const handleEditSalary = ix => {
    setAction({
      type: EDIT,
      contractID: data?.contract?.id,
      current: salaries[ix],
      previous: isEqual(ix, 0) ? null : salaries[ix - 1],
    })

    setIsOpenForm(true)
  }

  const handleSuccessful = response => {
    handleRefresh()

    // Update status of the contract in <EmployeeInformationGrid/>
    onUpdate(state => ({
      ...state,
      salary: response.value,
      salary_type: salaryTypes.find(s => isEqual(s.id, response.salary_type_id))
        ?.description,
    }))
  }

  const handleOpenConfirmDelete = salaryID => {
    setDeleteID(salaryID)
    confirmModal.current.openModal()
  }

  const handleDelete = () => {
    confirmModal.current.closeModal()

    deleteSalary(
      data?.contract?.id,
      deleteID,
      response => {
        setDeleteID(0)
        dispatch(notifySuccessful('El salario ha sido eliminado correctamente 🎉'))

        // when the new register is created or when the record is edited we prefer query the backend
        // for get all salary contracts again because Backend do changes in various registers with a specific logic
        getSalariesByContractID(
          data?.contract?.id,
          response => {
            setSalaries(response)

            const lastSalary = response[response.length - 1]

            // Update status of the contract in <EmployeeInformationGrid/>
            onUpdate(state => ({
              ...state,
              salary: lastSalary.value,
              salary_type: salaryTypes.find(s => isEqual(s.id, lastSalary.salary_type_id))
                ?.description,
            }))
          },
          error => dispatch(notifyError(error))
        )
      },
      error => {
        setDeleteID(0)
        dispatch(notifyError(error))
      }
    )
  }

  const handleRefresh = () => {
    setAction(initialStateAction)
    setIsOpenForm(false)

    // when the new register is created or when the record is edited we prefer query the backend
    // for get all salary contracts again because Backend do changes in various registers with a specific logic
    getSalariesByContractID(
      data?.contract?.id,
      response => setSalaries(response),
      error => dispatch(notifyError(error))
    )
  }

  const handleOnChangeOpenModal = isOpen => {
    if (isOpen) return

    setIsOpenForm(false)
  }

  return (
    <>
      <ModalTemplate ref={ref} className="jumbo" onChangeOpen={handleOnChangeOpenModal}>
        <h3 className="s-left s-mb-0">Salarios</h3>
        <p className="small s-color-light-text s-mb-24px">
          Consulta, agrega, actualiza o elimina los salarios del contrato.
        </p>
        <div className="s-main-end s-mb-1">
          {!isOpenForm && (
            <button
              type="button"
              className="button s-order-1 m-order-3 small"
              onClick={handleNewSalary}
            >
              <div className="s-cross-center s-main-center">
                <Icon svg="plus" className="s-mr-4px" />
                <span className="overflow-ellipsis">Nuevo</span>
              </div>
            </button>
          )}
        </div>

        <NoveltyContainer
          isOpenForm={isOpenForm}
          form={
            <SalaryChangeForm
              salaryTypes={salaryTypes}
              hireDate={readBackendDate(data?.contract?.hire_date)}
              terminationDate={readBackendDate(data?.contract?.termination_date)}
              onCancel={handleCancel}
              onSuccessful={handleSuccessful}
              action={action}
            />
          }
          table={
            <Table
              heads={[
                'Tipo',
                'Valor',
                'Fecha Inicial',
                'Fecha Final',
                '¿Actual?',
                'Observaciones',
                '',
              ]}
              classNameTh="m-center"
              className="table-template small whitespace-nowrap"
            >
              {Array.isArray(salaries) &&
                salaries.map((salary, ix) => (
                  <TableRow key={salary.id}>
                    <p className="m-center">
                      {
                        allSalaryTypes.find(s => isEqual(s.id, salary.salary_type_id))
                          .description
                      }
                    </p>
                    <p className="s-color-blue m-right">
                      {getFormatCurrency(
                        salary.value,
                        employerSession.code_iso_3,
                        !employerSession.not_include_decimals_in_calculation
                      )}
                    </p>
                    <p className="m-center">{shortFormatDate(salary.begins_at)}</p>
                    <p className="m-center">{shortFormatDate(salary.ends_at)}</p>
                    <p className="m-center">{salary.is_current ? 'Si' : 'No'}</p>
                    <div className="whitespace-break-spaces">
                      <PreviewMarkdown content={salary.note} />
                    </div>
                    <p className="s-cross-center">
                      {salary.is_current && (
                        <>
                          <Icon
                            svg="edit"
                            className="s-mr-1 cursor-pointer s-color-blue blue"
                            onClick={() => handleEditSalary(ix)}
                          />
                          {len(salaries) > 1 && (
                            <Icon
                              svg="trash"
                              className="s-color-red red cursor-pointer"
                              onClick={() => handleOpenConfirmDelete(salary.id)}
                            />
                          )}
                        </>
                      )}
                    </p>
                  </TableRow>
                ))}
            </Table>
          }
        />
      </ModalTemplate>

      <ConfirmModal
        ref={confirmModal}
        title="Eliminar salario"
        content="¿Estás seguro de eliminar el salario?, recuerda que esta acción no se puede revetir."
        confirmFunction={handleDelete}
      />
    </>
  )
})

export default SalaryEditModal
