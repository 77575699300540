import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

// mount/unmount animations with hooks
// source: https://czaplinski.io/blog/super-easy-animation-with-react-hooks/
const Animation = ({
  show,
  animationIn,
  animationOut,
  children,
  className,
  style,
  duration,
}) => {
  const [shouldRender, setRender] = useState(show)

  useEffect(() => {
    if (show) setRender(true)
  }, [show])

  const onAnimationEnd = () => {
    if (!show) setRender(false)
  }

  return (
    shouldRender && (
      <div
        className={className}
        style={{
          ...style,
          animation: `${show ? animationIn : animationOut} ${duration}`,
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  )
}

Animation.propTypes = {
  animationIn: PropTypes.string,
  animationOut: PropTypes.string,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
  duration: PropTypes.string,
}

Animation.defaultProps = {
  className: '',
  duration: 'var(--min-transition-time)',
}

export default Animation
