import React from 'react'
import Badge from '../../Atoms/Badge'
import {capitalize} from '../../../helpers/string-format'

const BranchOfficeItem = ({isSelected, description, address, image, badge, onClick}) => {
  return (
    <article
      className={`s-mb-0 s-cross-start nowrap s-px-6px s-py-4px normal-radius hover-change cursor-pointer ${
        isSelected && 'is-active blue'
      }`}
      onClick={onClick}
    >
      <img
        className="flex-none s-w-32px s-h-32px s-mr-8px"
        src={image}
        alt="imagen de sucursal"
      />
      <div>
        <div className="s-cross-center nowrap">
          <p className="small s-mb-0 s-mr-8px">{capitalize(description)}</p>
          <Badge text={badge} />
        </div>
        <p className="s-mb-0 smaller s-color-light-text">{address}</p>
      </div>
    </article>
  )
}

export default BranchOfficeItem
