import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/employer-reports'

export const generateEmployerReport = payload => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.generateFile(RESOURCE, payload, (response, headers) => resolve({response, headers}), reject)
  })
}
