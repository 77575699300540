import React, {useContext} from 'react'
import {PhonePanelContext} from '../../contexts/contexts'
import {useMedia} from '../../hooks/useMedia'
import {mediaToM} from '../../helpers/media-queries'
import Proptypes from 'prop-types'
import SettingOption from './Templates/SettingOption'

const SidebarSetting = ({isActive, option, onSelectOption}) => {
  const {setActive} = useContext(PhonePanelContext)
  const toTablet = useMedia(mediaToM)

  const activePhonePanel = () => {
    if (toTablet) setActive(true)
  }

  return (
    <div
      onClick={activePhonePanel}
      className={`hover-change blue normal-radius cursor-pointer s-px-6px s-py-4px ${
        isActive && 'is-active'
      }`}
    >
      <SettingOption option={option} onSelectOption={onSelectOption} />
    </div>
  )
}

SidebarSetting.propTypes = {
  option: Proptypes.object.isRequired,
  onSelectOption: Proptypes.func,
  isActive: Proptypes.bool,
}

SidebarSetting.defaultProps = {
  employee: {},
  onSelectOption: () => {},
  isActive: false,
}

export default SidebarSetting
