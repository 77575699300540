import React from 'react'
import Skeleton from 'react-loading-skeleton'

const UserPayslipInformationSkeleton = () => (
  <>
    <Skeleton height={800} />
  </>
)

export default UserPayslipInformationSkeleton
