import React, {useState} from 'react'
import {PayrollContext} from '../../contexts/contexts'

const PayrollWrapper = ({children}) => {
  const [payrollDate, setPayrollDate] = useState(null)
  const [activeProcess, setActiveProcess] = useState(null)

  return (
    <PayrollContext.Provider
      value={{
        payrollDate,
        setPayrollDate,
        activeProcess,
        setActiveProcess,
      }}
    >
      {children}
    </PayrollContext.Provider>
  )
}

export default PayrollWrapper
