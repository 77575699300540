import React from 'react'
import PropTypes from 'prop-types'
import {useMedia} from '../../hooks/useMedia'
import {mediaToM} from '../../helpers/media-queries'
import ActionButton from '../Atoms/Templates/ActionButton'
import SearchInput from '../Atoms/Forms/SearchInput'
import {isEmpty} from '../../helpers/utils'

const SearchInputButton = ({id, value, onChange}) => {
  const toM = useMedia(mediaToM)

  return toM ? (
    <SearchInput className="small s-w-full" id={id} value={value} onChange={onChange} />
  ) : (
    <div
      className={`search-input-button m-relative m-w-32px m-h-32px ${
        isEmpty(value) ? '' : 'not-empty'
      }`}
    >
      <SearchInput
        className="m-absolute m-top-0 m-right-0 small"
        id={id}
        value={value}
        onChange={onChange}
      />

      <label className="m-relative" htmlFor={id}>
        <ActionButton className="m-to-right" icon="search" />
        {/* Contenedor invisible para que el botón pueda hacer focus al input */}
        <div className="m-mb-0 m-bg-red m-w-32px m-h-32px m-absolute m-top-0 m-left-0 m-right-0 cursor-pointer opacity-0" />
      </label>
    </div>
  )
}

SearchInputButton.propTypes = {
  id: PropTypes.string.isRequired,
}

export default SearchInputButton
