import React, {useState} from 'react';
import MainHeader from "./Headers/MainHeader";
import MainSidebar from "./MainSidebar";
import {MainSidebarContext} from "../../contexts/contexts";
import {useBodyClass} from "../../hooks/useBodyClass";
import {useMedia} from "../../hooks/useMedia";
import {mediaToLg} from "../../helpers/media-queries";

const MainNavigation = () => {
  const isMobile = useMedia(mediaToLg)
  // Estado para cerrar el menú (por defecto está cerrado para celulares)
  const [collapsed, setCollapsed] = useState(isMobile)

  useBodyClass(collapsed, 'collapsed-sidebar')
  return (
    <MainSidebarContext.Provider value={{collapsed, setCollapsed}}>
      <MainHeader/>
      <MainSidebar/>
    </MainSidebarContext.Provider>
  );
};

export default MainNavigation;
