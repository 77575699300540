import React from 'react'
import EmployeesPanel from '../Panels/EmployeesPanel'
import useFetchWithConditions from '../../../hooks/useFetchWithConditions'
import {getPayslipEmployees} from '../../../services/payslip'
import {history} from '../../../helpers/history'
import {useParams, useLocation} from 'react-router-dom'
import {isEqual} from '../../../helpers/utils'
import {QUERY_PARAM_PAYSLIP} from '../../../config/routes'

const EmployeesPayrollSection = () => {
  let {hash} = useParams()
  const {hash: hashLocation, search} = useLocation()

  const defaultSortOption = {key: 'first_name', value: 'Nombre'}

  const handleGetPayslipEmployees = (queryParams, callback, callbackErr) => {
    getPayslipEmployees(hash, queryParams, callback, callbackErr)
  }

  const {
    data,
    loading,
    page,
    setPage,
    isLastPage,
    setSortOption,
    setFilterOption,
    setSearchValue,
  } = useFetchWithConditions(defaultSortOption, 20, handleGetPayslipEmployees)

  const unSelectEmployee = () => {
    const newQueryParams = new URLSearchParams(search)
    newQueryParams.delete(QUERY_PARAM_PAYSLIP)

    history.replace({
      search: `?${newQueryParams.toString()}`,
      hash: hashLocation,
    })

    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleSelectEmployee = employee => {
    const newQueryParams = new URLSearchParams(search)
    newQueryParams.set(QUERY_PARAM_PAYSLIP, employee.contract_hash)

    history.replace({
      search: `?${newQueryParams.toString()}`,
      hash: hashLocation,
    })

    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleChangeSort = key => {
    unSelectEmployee()
    setSortOption(key)
  }

  const handleChangeFilter = key => {
    unSelectEmployee()
    setFilterOption(key)
  }

  const handleSearchEmployee = value => {
    setSearchValue(value)
  }

  const handlerScrollEnd = () => {
    if (isLastPage) return

    unSelectEmployee()
    setPage(page + 1)
  }

  return (
    <EmployeesPanel
      employees={data}
      loading={loading && isEqual(page, 1)} // only show skeleton if the page is equal to 1
      limitEmployees={100}
      defaultSortOption={defaultSortOption}
      sortOptions={[defaultSortOption, {key: 'last_name', value: 'Apellido'}]}
      filterOptions={[]}
      onSelectEmployee={handleSelectEmployee}
      onChangeFilter={handleChangeFilter}
      onChangeSort={handleChangeSort}
      onChangeSearch={handleSearchEmployee}
      onScrollEnd={handlerScrollEnd}
    />
  )
}

export default EmployeesPayrollSection
