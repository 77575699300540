import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'

const Tag = props => {
  const {text, color, bgColor, hasCircle, className, icon} = props
  return (
    <div className={`tag flex ${className}`}>
      <div
        className={`s-px-4px s-py-2px small-radius s-cross-center nowrap ${
          bgColor ? bgColor : `s-bg-light-${color}`
        }`}
      >
        {icon && (
          <Icon svg={icon} className="s-mr-1"/>
        )}
        {hasCircle && (
          <div className={`s-h-6px s-w-6px circle-radius s-mr-2px s-bg-${color}`} />
        )}
        <p className={`s-mb-0 smaller s-color-${color}`}>{text}</p>
      </div>
    </div>
  )
}

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  hasCircle: PropTypes.bool,
}

export default Tag
