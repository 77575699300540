import React, {useState, forwardRef} from 'react'
import {useDispatch} from 'react-redux'
import TextArea from '../../Atoms/Forms/TextArea'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import {notifyError} from 'services/notification'
import {updateNotes} from 'services/employees'

const SocialSecurityContractEditModal = forwardRef(({formData, setFormData, onUpdate}, ref) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const updateFormField = e => {
    let value = e.target.value
    const name = e.target.name

    setFormData(state => ({...state, contract: {...state.contract, [name]: value}}))
  }

  const handleCancel = () => {
    ref.current.closeModal()
  }

  const handleUpdateNotes = () => {
    const payload = {
      notes: formData.contract.notes,
    }

    setIsLoading(true)

    updateNotes(
      formData.contract.id,
      payload,
      response => {
        setIsLoading(false)

        onUpdate(state => ({
          ...state,
          contract: {...state.contract, notes: response.notes},
        }))

        handleCancel()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  return (
    <ModalTemplate ref={ref} className="large">
      <h3 className="s-left s-mb-0">Seguridad Social</h3>
      <p className="small s-color-light-text s-mb-24px">
        Actualiza la información de seguridad social del contrato
      </p>
      <form className="s-mb-0">
        <div className="form-grid ed-grid l-block">
          <TextArea
            className="s-h-full"
            name="notes"
            id="notes"
            placeholder="Escribe una nota"
            value={formData.contract.notes}
            onChange={updateFormField}
            align="left"
            label="Notas u observaciones"
            textarea={{
              className: 's-h-full',
            }}
            heightSize="l"
          />
        </div>

        <div className="s-column s-cross-center">
          <div className="s-column m-row m-main-center s-flex-gap-16px s-100">
            <button
              type="button"
              className="button ghost cancel s-order-3 m-order-1"
              onClick={handleCancel}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="button ghost s-order-1 m-order-3"
              onClick={handleUpdateNotes}
              disabled={isLoading}
            >
              {isLoading ? 'Actualizando...' : 'Actualizar'}
            </button>
          </div>
        </div>
      </form>
    </ModalTemplate>
  )
})

export default SocialSecurityContractEditModal
