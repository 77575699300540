import React, {useEffect, useState} from 'react'
import TableRow from '../../../Wrappers/TableRow'
import Checkbox from '../../../Atoms/Forms/Checkbox'
import Employee from '../../Templates/Employee'
import BankInformationItem from '../../../Atoms/Items/BankInformationItem'
import {useMedia} from '../../../../hooks/useMedia'
import {mediaFromM} from '../../../../helpers/media-queries'
import {getFormatCurrency} from '../../../../helpers/format-number'
import {isEqual} from '../../../../helpers/utils'
import {capitalize} from '../../../../helpers/string-format'
import {WIRE_TRANSFER} from '../../../../helpers/payroll'

const PaymentsRow = ({isActive, employee, append, remove, employerSession}) => {
  const fromM = useMedia(mediaFromM)

  const [checked, setChecked] = useState(false)

  // update always the row check with the status isActive.
  useEffect(() => {
    setChecked(isActive)
  }, [isActive])

  const handleOnChecked = e => {
    const isChecked = e.target.checked

    if (isChecked) {
      append(employee.contract_id, employee.net_pay)
      return
    }

    remove(employee.contract_id, employee.net_pay)
  }

  return fromM ? (
    <TableRow isActive={checked}>
      <Checkbox onChange={handleOnChecked} checked={checked} />
      <Employee employee={employee} />
      <p>{capitalize(employee.payment_method)}</p>
      {isEqual(employee.payment_method, WIRE_TRANSFER) && (
        <BankInformationItem
          name={employee.bank_name}
          type={capitalize(employee.account_type)}
          account={employee.account_number}
        />
      )}
      <p className="m-right">
        {getFormatCurrency(
          employee.net_pay,
          employerSession.code_iso_3,
          !employerSession.not_include_decimals_in_calculation
        )}
      </p>
    </TableRow>
  ) : (
    <tr className={checked ? 'active' : undefined}>
      <td>
        <label className="flex nowrap s-flex-gap-16px">
          <Checkbox onChange={handleOnChecked} checked={checked} />
          <div>
            <Employee employee={employee} />
            <p>{capitalize(employee.payment_method)}</p>
            {isEqual(employee.payment_method, WIRE_TRANSFER) && (
              <BankInformationItem
                name={employee.bank_name}
                type={capitalize(employee.account_type)}
                account={employee.account_number}
              />
            )}
            <p>
              {getFormatCurrency(
                employee.net_pay,
                employerSession.code_iso_3,
                !employerSession.not_include_decimals_in_calculation
              )}
            </p>
          </div>
        </label>
      </td>
    </tr>
  )
}

PaymentsRow.propTypes = {}

export default PaymentsRow
