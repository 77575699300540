import React from 'react'
import PropTypes from 'prop-types'
import CoinsImage from '../../../static/images/stories/coins.svg'

const PaymentsEmptyState = ({className, description}) => {
  return (
    <div
      className={`s-column s-center s-cross-center s-main-center s-pxy-16px m-pxy-32px m-90 s-to-center ${className}`}
    >
      <img
        className="s-mb-16px"
        src={CoinsImage}
        alt="Payments"
        width="200px"
        height="200px"
      />
      <div className="small s-mb-16px s-color-light-text">{description}</div>
    </div>
  )
}

PaymentsEmptyState.propTypes = {
  className: PropTypes.string,
}

PaymentsEmptyState.defaultProps = {
  className: '',
}

export default PaymentsEmptyState
