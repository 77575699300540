import React, {useState} from 'react'
import AccordionTemplate from '../../Wrappers/AccordionTemplate'
import { getParameterEmployerDetailByID, getParameterStandardDetailByID } from '../../../services/processParameter'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import {shortFormatDate} from '../../../helpers/dates'
import { PARAM_STRING, getFormatParameter } from '../../../helpers/parameter'

const ParameterSettingDetail = ({
  paramType= PARAM_STRING,
  parameterId = 0,
  isMandatoryEmployer = false,
}) => {
  const [isFetch, setIsFetch] = useState(false)
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [detail, setDetail] = useState([])

  const handleOnClick = () => {
    setIsOpenDetail(!isOpenDetail)

    if (isFetch) return

    if (isMandatoryEmployer) {
      getParameterEmployerDetailByID(parameterId, response => {
        setDetail(response)
        setIsFetch(true)
      })

      return
    }

    getParameterStandardDetailByID(parameterId, response => {
      setDetail(response)
      setIsFetch(true)
    })
  }

  return (
    <AccordionTemplate isOpen={false} index={1} isContentsLoaded={isFetch}>
      <div className="s-cross-center cursor-pointer s-color-blue" onClick={handleOnClick}>
        <div className="dropdown-icon s-mr-1">▾</div>
        <p className="smaller">{isOpenDetail ? 'Mostrar menos' : 'Mostrar más'}</p>
      </div>

      <Table
        heads={['Fecha Inicial', 'Fecha Final', '	¿Es fijo?', 'Valor']}
        className="small whitespace-nowrap"
        classNameTh="s-bg-title s-color-body-alt border-xy s-center"
      >
        {Array.isArray(detail) &&
          detail.map(d => (
            <TableRow key={d.id}>
              <p className="m-center">{shortFormatDate(d?.begins_at)}</p>
              <p className="m-center">{shortFormatDate(d?.ends_at)}</p>
              <p className="m-center">{d?.is_fixed ? 'Si' : 'No'}</p>
              <p className="m-right">{getFormatParameter(paramType, d?.value)}</p>
            </TableRow>
          ))}
      </Table>
    </AccordionTemplate>
  )
}

export default ParameterSettingDetail
