import React, {useEffect} from 'react'
import SettingHeader from '../Headers/SettingHeader'

const AccountingSettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="lg-cols-2">
        <SettingHeader
          icon="clipboard-check"
          title="Contabilidad"
          description="Configura tu interfaz contable"
          isComingSoon
        />
      </div>

      <div>
      </div>
    </>
  )
}

export default AccountingSettings
