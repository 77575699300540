import {createDuck} from 'redux-duck'
import {SIGN_IN_EMPLOYER, SIGN_OUT_EMPLOYER} from './employer'

const duck = createDuck('app/branch-offices')

// Define action types
export const SET_BRANCH_OFFICES = duck.defineType('SET_BRANCH_OFFICES')
export const ADD_BRANCH_OFFICE = duck.defineType('ADD_BRANCH_OFFICE')

// Define action creators
export const actions = {
  set: duck.createAction(SET_BRANCH_OFFICES),
  add: duck.createAction(ADD_BRANCH_OFFICE),
}

// Define selectors
export const selectors = {
  getAll: store => store.branchOfficesReducer.all,
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_BRANCH_OFFICES]: (state, {payload}) => ({
      ...state,
      all: [...payload],
    }),
    [ADD_BRANCH_OFFICE]: (state, {payload}) => ({
      ...state,
      all: [...state.all, payload],
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => initialState,
    [SIGN_OUT_EMPLOYER]: (state, {payload}) => initialState,
  },
  initialState
)
