import {isEmpty, isEqual} from './utils'

export const titleFunds = {
  EPS: 'EPS',
  AFP: 'Pensiones',
  CES: 'Cesantías',
  CCF: 'Caja compensación',
  ARL: 'Riesgos profesionales',
  EPS_PERU: 'EPS',
  AFP_PERU: 'Pensiones',
  CTS_PERU: 'Fondo CTS',
}

export const pensionerTypes = [
  {id: 1, description: "POR VEJEZ, JUBILACIÓN O INVALIDEZ"},
  {id: 3, description: "NO COTIZA PENSIÓN POR EDAD"},
  {id: 4, description: "CON REQUISITOS CUMPLIDOS PARA PENSIÓN"},
  {id: 5, description: "RECIBIÓ INDEMNIZACIÓN O DEVOLUCIÓN DE SALDOS"},
  {id: 6, description: "TIENE RÉGIMEN EXCEPTUADO DE PENSIONES"},
  {id: 9, description: "CON MESADA SUPERIOR A 25 SMLMV"},
]

export const hasFoundContractType = (
  foundName,
  contractTypeID,
  contractTypeEntityType
) => {
  if (!Array.isArray(contractTypeEntityType)) return false

  const result = contractTypeEntityType.filter(
    row =>
      isEqual(row.contract_type_id, contractTypeID) &&
      isEqual(row.abbreviation.toUpperCase(), foundName.toUpperCase())
  )

  return !isEmpty(result)
}

export const hasFounds = (contractTypeID, contractTypeEntityType) => {
  if (!Array.isArray(contractTypeEntityType)) return false

  const result = contractTypeEntityType.filter(row =>
    isEqual(row.contract_type_id, contractTypeID)
  )

  return !isEmpty(result)
}

export const isIntern = (contractTypeID, contractTypes) => {
  if (!Array.isArray(contractTypes)) return false

  const result = contractTypes.filter(
    row =>
      isEqual(row.id, contractTypeID) && row.description.toUpperCase().includes('SENA')
  )

  return !isEmpty(result)
}

export const hasCesantiasFound = (salaryTypeID, salaryTypes) => {
  if (!Array.isArray(salaryTypes)) return false
  if (isEqual(salaryTypes.length, 0)) return false

  const result = salaryTypes.filter(
    row =>
      isEqual(row.id, salaryTypeID) && row.description.toUpperCase().includes('NORMAL')
  )

  return !isEmpty(result)
}

export const makeMapFound = (contractTypeID, contractTypeEntityType) => { 
  if (!Array.isArray(contractTypeEntityType)) return []

  let records = contractTypeEntityType.filter(
      t => isEqual(t.contract_type_id, contractTypeID) && t.has_entity_per_contract
    ) || []
  
  return records.map(r =>({...r, social_security_entity_id: 0, is_show: true}))
}
