import React from 'react'
import PropTypes from 'prop-types'
import {useMedia} from '../../hooks/useMedia'
import {mediaFromM} from '../../helpers/media-queries'
import QRCode from 'react-qr-code'

const ElectronicPayrollDocumentHeader = ({businessName, nit, dv, title, cune, children}) => {
  const fromM = useMedia(mediaFromM)

  return (
    <section className="s-cross-center s-main-center m-main-justify s-mb-2 m-mb-4">
      <div className="s-column s-center m-left s-color-title">
        <span className="t2">{businessName}</span>
        {fromM && (
          <div className="s-column s-center m-left s-mb-2 m-mb-4">
            <span className="small">
              {nit} - {dv}
            </span>
            <span className="small">{title}</span>
          </div>
        )}
        {children}
      </div>
      <div className="from-m">
        <QRCode
          value={cune}
          size={180}
          fgColor={cune ? '#000000' : '#F6F9FB'}
        />
      </div>
    </section>
  )
}

ElectronicPayrollDocumentHeader.propTypes = {
  cune: PropTypes.string,
  businessName: PropTypes.string.isRequired,
  nit: PropTypes.string.isRequired,
  dv: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

ElectronicPayrollDocumentHeader.defaultProps = {
  cune: '',
  businessName: '',
  nit: '',
  dv: '',
  title: 'Documento electrónico',
}

export default ElectronicPayrollDocumentHeader
