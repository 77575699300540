import React from 'react'
import PropTypes from 'prop-types'

const RadioButtonsWrapper = ({children, label, isRequired, className}) => {
  return (
    <div className={`form-item ${isRequired && 'required'} ${className}`}>
      {label && <label>{label}</label>}
      <div className="s-cross-center s-flex-gap-32px flex-grow">{children}</div>
    </div>
  )
}

RadioButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

RadioButtonsWrapper.defaultProps = {}

export default RadioButtonsWrapper
