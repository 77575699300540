import React, {useState, useContext, forwardRef, useImperativeHandle} from 'react'
import FormInput from '../../Atoms/Templates/FormInput'
import FloatContainer from '../../Wrappers/FloatContainer'
import FloatEmployeeList from '../FloatEmployeeList'
import {isAnyEqual, isEmpty, isEqual, isNull, isUndefined, len} from '../../../helpers/utils'
import {getEmployeesByHashProcess} from '../../../services/employees'
import {PayrollContext} from '../../../contexts/contexts'
import ChipEmployee from '../../Atoms/ChipEmployee'

const MultiSelectEmployeeInput = forwardRef(
  (
    {
      id = 'employee_id',
      name = 'employee',
      label = '',
      placeholder = 'Escribe para buscar',
      initialValue = [],
      isRequired = false,
      isDisabled = false,
      ...rest
    },
    ref
  ) => {
    const {activeProcess} = useContext(PayrollContext)

    const [inputValue, setInputValue] = useState('')
    const [contractsSelected, setContractsSelected] = useState(initialValue)
    const [list, setList] = useState([])
    const [listIndex, setListIndex] = useState({active: 0, max: 0})

    useImperativeHandle(ref, () => {
      return {
        getContractIDs: () => getContractIDs(),
      }
    })

    const getContractIDs = () => {
      if (!Array.isArray(contractsSelected)) return []

      return contractsSelected.map(c => c.contract_id)
    }

    const handleFilter = searchValue => {
      getEmployeesByHashProcess(activeProcess.hash, 10, 1, 'first_name', searchValue, response => {
        if (isEmpty(contractsSelected)) {
          const size = isEmpty(response) ? 0 : len(response) - 1
          setList(response)
          setListIndex({active: 0, max: size})
          return
        }

        // we ignore the contracts ids that already was selected
        const filterList = response.filter(e =>
          isUndefined(contractsSelected.find(c => isEqual(c.contract_id, e.contract_id)))
        )

        const size = isEmpty(filterList) ? 0 : len(filterList) - 1
        setList(filterList)
        setListIndex({active: 0, max: size})
      })
    }

    const handleOnChangeInput = newValue => {
      setInputValue(newValue)

      if (isEmpty(newValue)) {
        setList([])
        setListIndex({active: 0, max: 0})
        return
      }

      handleFilter(newValue)
    }

    const handleOnSelect = newIndex => {
      if (isEmpty(list)) return

      const ix = isNull(newIndex) ? listIndex.active : newIndex
      const employeeSelected = list[ix]

      setInputValue('')
      setList([])
      setListIndex({active: 0, max: 0})
      setContractsSelected(state => [...state, employeeSelected])
    }

    const handleKeyPress = e => {
      if (!isAnyEqual(e.key, ['Enter', 'ArrowUp', 'ArrowDown'])) return

      e.preventDefault()

      if (isEqual(e.key, 'Enter')) {
        handleOnSelect(null)
        return
      }

      moveIndexPosition(e.key)
    }

    const moveIndexPosition = move => {
      setListIndex(currentIndex => {
        if (isEqual(currentIndex?.active, 0) && isEqual(move, 'ArrowUp')) {
          return currentIndex
        }

        if (isEqual(currentIndex?.active, currentIndex?.max) && isEqual(move, 'ArrowDown')) {
          return currentIndex
        }

        const newActiveIndex = isEqual(move, 'ArrowDown') ? ++currentIndex.active : --currentIndex.active

        return {active: newActiveIndex, max: currentIndex?.max}
      })
    }

    const deleteContractSelected = contractID => {
      setContractsSelected(state => [...state.filter(c => c.contract_id !== contractID)])
    }

    return (
      <div>
        <FloatContainer container={{className: 's-relative z-tooltip'}} isInputTheContainer>
          <FormInput
            type="search"
            isRequired={isRequired}
            label={label}
            name={name}
            id={id}
            placeholder={placeholder}
            value={inputValue}
            onChange={e => {
              handleOnChangeInput(e.target.value)
            }}
            autoComplete="off"
            isDisabled={isDisabled}
            onKeyDown={handleKeyPress}
            className="s-mb-1"
            {...rest}
          />
          {!isDisabled && (
            <FloatEmployeeList employees={list} onSelectEmployee={handleOnSelect} activeIndex={listIndex?.active} />
          )}
        </FloatContainer>
        <div className="s-cross-center s-main-center s-overflow-auto" style={{gap: '0.3rem', maxHeight: '10rem'}}>
          {Array.isArray(contractsSelected) &&
            contractsSelected.map(c => (
              <ChipEmployee
                key={c.contract_id}
                employee={c}
                classColorFont="s-color-blue-500"
                classColorBorder="s-border-blue-400"
                onDelete={() => deleteContractSelected(c.contract_id)}
              />
            ))}
        </div>
      </div>
    )
  }
)

export default MultiSelectEmployeeInput
