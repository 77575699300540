import React from 'react'
import ActionTextButton from '../Templates/ActionTextButton'

const CloseTextButton = ({text, color, className, isDisable, onClick}) => {
  return (
    <ActionTextButton
      className={`s-color-${color} ${color} ${className}`}
      onClick={onClick}
      icon="lock-open"
      text={text}
      isDisable={isDisable}
    />
  )
}

export default CloseTextButton
