import React, {useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import TableRow from '../../../Wrappers/TableRow'
import Employee from '../../Templates/Employee'
import TableOptionsButton from '../../TableOptionsButton'
import {PayrollContext} from '../../../../contexts/contexts'
import {EDIT, SHOW, STATUS_HISTORY} from '../../../../helpers/payroll'
import {isEqual} from '../../../../helpers/utils'
import {initialStateAction} from '../../../Organisms/Tables/NoveltyTable'
import Concept from '../../Templates/Concept'
import {shortFormatDate} from '../../../../helpers/dates'
import DeleteModal from '../../../Organisms/Modals/DeleteModal'
import TooltipContainer from '../../../Organisms/Containers/TooltipContainer'
import Icon from '../../../Atoms/Icon'
import Tooltip from '../../../Atoms/Templates/Tooltip'

const RecurrentNoveltyRow = ({setAction, novelty, onDelete}) => {
  const {activeProcess} = useContext(PayrollContext)
  const isHistory = isEqual(activeProcess?.status, STATUS_HISTORY)
  const deleteModal = useRef()

  const handleOpenDeleteModal = () => {
    deleteModal.current.openModal()
  }

  const handleSee = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setAction({
      type: SHOW,
      noveltyID: novelty.id,
    })
  }

  const handleEdit = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setAction({
      type: EDIT,
      noveltyID: novelty.id,
    })
  }

  const handleDelete = () => {
    handleOpenDeleteModal()
    setAction(initialStateAction)
  }

  const handleOnClick = () => {
    onDelete(novelty.id)
    setAction(initialStateAction)
    deleteModal.current.closeModal()
  }

  return (
    <>
      <TableRow>
        <Employee employee={novelty} />
        <Concept concept={novelty} />
        <p className="s-mb-0 m-right">
          {isHistory ? novelty.history_amount : novelty.value}
        </p>
        <p className="s-mb-0">{shortFormatDate(novelty.begins_at)}</p>
        <p className="s-mb-0">{shortFormatDate(novelty.ends_at)}</p>

        <div className="flex s-cross-center">
          <div className="s-mr-1">
            {isHistory ? (
              <TableOptionsButton seeFunction={handleSee} />
            ) : (
              <TableOptionsButton
                deleteFunction={handleDelete}
                editFunction={handleEdit}
              />
            )}
          </div>

          {/* Only show the information bubble if the process isn't history and the 
            novelty is history, because if the process is history it isn't necessary 
            notify to user that the novelty recurrence has history */}
          {!isHistory && novelty.has_history && (
            <TooltipContainer className="s-cross-center">
              <Icon
                svg="information"
                color="var(--blue-accent)"
                className="s-mr-4px cursor-pointer"
              />
              <Tooltip>
                <div className="whitespace-nowrap">
                  <span>La Novedad tiene historico</span>
                </div>
              </Tooltip>
            </TooltipContainer>
          )}
        </div>
      </TableRow>
      <DeleteModal ref={deleteModal} deleteFunction={handleOnClick} />
    </>
  )
}

RecurrentNoveltyRow.propTypes = {
  novelty: PropTypes.shape({}).isRequired,
  setAction: PropTypes.func,
}

RecurrentNoveltyRow.defaultProps = {
  novelty: PropTypes.shape({}),
  setAction: () => {},
}

export default RecurrentNoveltyRow
