import React, {useState, forwardRef, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import LastProcessEmptyState from '../Emptys/LastProcessEmptyState'
import {generateAccountingInterface, getAccountingInterfacesByHashProcess} from '../../../services/accountingInterface'
import {notifyError} from '../../../services/notification'
import Table from '../../Wrappers/Table'
import TableRow from '../../Wrappers/TableRow'
import Icon from '../../Atoms/Icon'
import {generateAccountingInterfaceFile} from '../../../services/accountingInterfaceFile'
import useObjectURL from 'hooks/useObjectURL'
import ConfirmModal from './ConfirmModal'

const AccountingInterfaceModal = forwardRef(
  (
    {process, accountingInterfaceConfig, accountingInterfaceConfigObj, accountingInterfaces, onFinish = () => {}},
    ref
  ) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingFile, setIsLoadingFile] = useState(false)
    const [loadingFileId, setLoadingFileId] = useState(0)
    const downloadLinkReport = useRef()
    const {setObject, objectURL} = useObjectURL(null)
    const [fileNameDownloaded, setFileNameDownloaded] = useState('')
    const confirmModal = useRef()

    const openConfirmModal = () => {
      confirmModal.current.openModal()
    }

    const handleGenerate = () => {
      if (!Array.isArray(accountingInterfaceConfig) || isEmpty(accountingInterfaceConfig)) return

      setIsLoading(true)
      const requests = []

      // add the request to the array for every configuration
      accountingInterfaceConfig.forEach(accountingInterfaceConfig => {
        const payload = {
          accounting_interface_configuration_id: accountingInterfaceConfig.id,
          process_hashes: [process?.hash],
          year: process?.year,
          month: process?.month,
        }

        requests.push(generateAccountingInterface(payload))
      })

      // generate the interface of the process for each configuration
      Promise.all(requests)
        .then(() => {
          // when all the interfaces are generated, get the interfaces of the process
          return getAccountingInterfacesByHashProcess(process?.hash)
        })
        .then(response => onFinish(response)) // call onFinish with the interfaces
        .catch(error => dispatch(notifyError(error)))
        .finally(() => setIsLoading(false))
    }

    const handleReGenerate = () => {
      confirmModal.current.closeModal()
      handleGenerate()
    }

    const handleDownloadFile = (recordId, configId) => {
      if (isLoadingFile) return

      setIsLoadingFile(true)
      setLoadingFileId(recordId)
      const payload = {
        account_interface_configuration_id: configId,
        process_hashs: [process?.hash],
        year: process?.year,
        month: process?.month,
      }

      generateAccountingInterfaceFile(
        payload,
        (response, headers) => {
          setObject(response)
          setFileNameDownloaded(headers['x-file-name'])

          downloadLinkReport.current.click()

          // after of clicked then reset the state
          setObject(null)
          setFileNameDownloaded('')
          setIsLoadingFile(false)
          setLoadingFileId(0)
        },
        error => {
          dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
          setIsLoadingFile(false)
          setLoadingFileId(0)
        }
      )
    }

    if (isEmpty(accountingInterfaceConfig)) {
      return
    }

    return (
      <ModalTemplate ref={ref} className="s-center medium">
        <h3 className="s-mb-4">Interfaz Contable</h3>

        {isLoading && (
          <div className="ed-grid s-main-center">
            <div>
              <span className="loading blue"></span>
            </div>
            <span>Generando...</span>
          </div>
        )}

        {!isLoading && (isNull(accountingInterfaces) || isEmpty(accountingInterfaces)) && (
          <LastProcessEmptyState
            title="Vaya, aún no has generado la interfaz contable"
            subtitle="¿Qué tal si lo haces ahora?"
            captionActionButton="Generar"
            onClickActionButton={handleGenerate}
            buttonSize="normal"
            isDisableActionButton={isLoading}
          />
        )}

        {!isLoading && !isEmpty(accountingInterfaces) && Array.isArray(accountingInterfaces) && (
          <div className="ed-grid rows-gap">
            <Table heads={['Nombre', 'Acciones']} classNameTh="m-center" className="table-template whitespace-nowrap">
              {accountingInterfaces.map((record, ix) => (
                <TableRow key={record.id}>
                  <div className="m-center">
                    <p>{accountingInterfaceConfigObj[record?.accounting_interface_configuration_id].description}</p>
                    <p className="code-font small">{record.id}</p>
                  </div>
                  <p className="s-main-center s-cross-center">
                    <Icon
                      svg="download"
                      className={`s-color-blue blue ${isLoadingFile ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                      onClick={() => handleDownloadFile(record.id, record?.accounting_interface_configuration_id)}
                    />
                    {isEqual(loadingFileId, record.id) && isLoadingFile && (
                      <span className="loading blue small s-mr-1"></span>
                    )}
                  </p>
                </TableRow>
              ))}
            </Table>

            <div>
              <button className="button normal" type="button" onClick={openConfirmModal} disabled={isLoading}>
                Regenerar
              </button>
            </div>
          </div>
        )}

        {/* This link is hidden, we only use for download the files */}
        <a ref={downloadLinkReport} href={objectURL} download={fileNameDownloaded} className="hidden">
          file downloaded
        </a>

        <ConfirmModal
          ref={confirmModal}
          confirmFunction={handleReGenerate}
          title="Regenerar Interfaz Contable"
          content="¿Estás seguro de continuar?, esta acción sobreescribirá la información actual."
        />
      </ModalTemplate>
    )
  }
)

export default AccountingInterfaceModal
