import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import TabTemplate from './Templates/TabTemplate'
import {TabsWrapperContext} from '../../contexts/contexts'

const FormStep = ({number, text, isError, onClick}) => {
  const {tabActive} = useContext(TabsWrapperContext)

  return (
    <TabTemplate
      index={number}
      className={`form-step s-w-24px s-main-center s-relative cursor-pointer 
      ${number === tabActive && 'is-current'} ${isError && 'is-error'}`}
      disabled={number > tabActive}
      onClick={onClick}
    >
      <div className="s-cross-center s-main-center s-mb-2px circle s-w-12px s-h-12px lg-w-24px lg-h-24px circle-radius s-color-white s-bg-blue smaller s-mb-0">
        <div className="from-lg">
          <span className="number hidden">{number}</span>
        </div>
        <Icon className="from-lg check" svg="check" />
      </div>
      <p className="text s-mb-0 s-color-blue smaller s-center">{text}</p>
    </TabTemplate>
  )
}

FormStep.propTypes = {
  isError: PropTypes.bool.isRequired,
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
}

FormStep.defaultProps = {
  isError: false,
}

export default FormStep
