import React from 'react'
import Container from '../components/Wrappers/Container'
import UserAvatar from '../components/Atoms/Templates/UserAvatar'
import {capitalize} from '../helpers/string-format'
import PaysheetImage from '../static/images/stories/paysheet.svg'

const PayslipCalculating = ({processLock}) => {
  return (
    <Container className="lg-cols-2 s-column s-center s-cross-center s-main-center s-to-center">
      <div className="l-block" />
      <img className="s-mb-2" src={PaysheetImage} alt="Calculando" width="200px" height="200px" />
      <h3 className="s-cross-center">
        <span className="loading blue small s-mr-1"></span> Calculando...
      </h3>

      <div className="s-color-light-text flex s-cross-center s-main-center s-mb-1">
        <span className="s-px-1">Esto no tardará mucho, te avisaremos cuando </span>
        <div className="header-user-avatar s-cross-center s-bg-border s-pr-1">
          <UserAvatar className="lg-mr-8px" picture={processLock.user_picture} userName={processLock.user_name} />
          <span className="small s-mb-0">{capitalize(processLock.user_name)} </span>
        </div>
        <span className="s-pl-1"> termine.</span>
      </div>
    </Container>
  )
}

export default PayslipCalculating
