import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/leaves'

export const GetBeforeByContractID = (contractID, beginsAt, leaveTypeID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    `${RESOURCE}/contracts/${contractID}?before=${beginsAt}&leave-type-id=${leaveTypeID}`,
    callback
  )
}
