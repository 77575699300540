import React, {useState} from 'react'
import {FormDataContext} from '../../contexts/contexts'
import {isEqual} from '../../helpers/utils'

const FormDataWrapper = ({defaultData, children}) => {
  const [formData, setFormData] = useState(defaultData)

  const updateFormField = e => {
    const target = e.target
    let value = isEqual(target.type, 'checkbox') ? target.checked : target.value
    const name = target.name

    if (name.endsWith('_id')) value = Number(value)
    if (isEqual(target.type, 'number')) value = Number(value)

    if (isEqual(target.type, 'radio') && isEqual(value, 'true')) value = true
    if (isEqual(target.type, 'radio') && isEqual(value, 'false')) value = false

    setFormData(state => ({...state, [name]: value}))
  }

  const updateFormFieldTextToNumber = e => {
    const value = e.target.value
    const name = e.target.name

    const pattern = /^[0-9]+$/
    // only accept numbers or '' when the user empty the input
    if (isEqual(value, '')) {
      setFormData(state => ({...state, [name]: ''}))
    }

    if (pattern.test(value)) {
      setFormData(state => ({...state, [name]: Number(value)}))
    }
  }

  return (
    <FormDataContext.Provider
      value={{
        formData,
        setFormData,
        updateFormField,
        updateFormFieldTextToNumber,
      }}
    >
      {children}
    </FormDataContext.Provider>
  )
}

export default FormDataWrapper
