import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {useDropzone} from 'react-dropzone'
import Icon from '../Atoms/Icon'
import {isEmpty, isEqual, len} from '../../helpers/utils'

const DropContainer = props => {
  const {className, label, id, tiny, typeFiles, limitFiles, handleFiles, file, icon} = props
  const fileType = typeFiles.includes('image') ? 'imagen' : 'archivo'

  const onDrop = useCallback(
    acceptedFiles => {
      handleFiles(acceptedFiles)
    },
    [handleFiles]
  )

  const {fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: typeFiles,
    maxFiles: limitFiles,
  })

  const fileRejectionItems = () => {
    if (isEmpty(fileRejections)) return null

    if (len(fileRejections) > limitFiles) {
      return `¡Upps! suelta máximo ${limitFiles} archivo(s)`
    }

    return `¡Upps! solo archivos ${typeFiles} son permitidos`
  }

  return (
    <div
      {...getRootProps()}
      className={`drop-container form-item ${className} ${tiny && 'tiny'} 
      ${isDragActive && 'is-drag'}
      ${file && 'is-uploaded'}
      `}
    >
      <div className="container s-color-light-text cursor-pointer s-pxy-24px s-bg-body-alt normal-radius">
        {!file ? (
          <Icon className="icon" svg={icon} size="4rem" />
        ) : isEqual(fileType, 'imagen') ? (
          <img src={file} alt="Imagen" className="file" />
        ) : (
          <Icon className="icon" svg={icon} size="4rem" />
        )}
        <p className="small s-mb-0">
          {file
            ? `${isEqual(fileType, 'imagen') ? 'Imagen seleccionada' : 'Archivo seleccionado'} con éxito`
            : isDragActive
            ? `Suelta aquí tu ${fileType}`
            : fileRejectionItems() || `Arrastra y suelta tu ${fileType}`}
        </p>
        <input data-testid="drop-input-file" {...getInputProps()} id={id} type="file" className="hidden" />
      </div>
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  )
}

DropContainer.propTypes = {
  className: PropTypes.string,
  tiny: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  typeFiles: PropTypes.string,
  limitFiles: PropTypes.number,
  handleFiles: PropTypes.func.isRequired,
  icon: PropTypes.string,
}

DropContainer.defaultProps = {
  tiny: false,
  className: '',
  typeFiles: 'image/jpeg, image/png',
  limitFiles: 1,
  handleFiles: () => {},
  icon: 'picture',
}

export default DropContainer
