import { shortFormatDate } from './dates'
import {getFormatNumber} from './format-number'

// parameter types
export const PARAM_NUMBER = 'NUMBER'
export const PARAM_STRING = 'STRING'
export const PARAM_BOOLEAN = 'BOOLEAN'
export const PARAM_DATE = 'DATE'
export const PARAM_ARRAY_NUMBER = 'ARRAY_NUMBER'
export const PARAM_ARRAY_STRING = 'ARRAY_STRING'

export const getFormatParameter = (paramType, value) => {
  if (paramType === PARAM_NUMBER && value.includes('.')) return value.replace(".",",")

  if (paramType === PARAM_NUMBER && !value.includes(".")) return getFormatNumber(Number(value))

  if (paramType === PARAM_BOOLEAN) return value === 'true' ? 'Si' : 'No'

  if (paramType === PARAM_DATE) return shortFormatDate(value)
  
  if (paramType === PARAM_ARRAY_NUMBER || paramType === PARAM_ARRAY_STRING)
     return `| ${value.replace(',', ' | ')} |`

  return value
}
