import {history} from '../helpers/history'
import {closeSession} from '../helpers/session'
import {
  Request,
  RequestWithAuthorization,
  RequestWithEmployerAuthorization,
} from '../helpers/request'
import {actions as notificationActions} from '../redux/ducks/notification'
import {actions as userActions} from '../redux/ducks/user'
import {redirectRoute} from '../config/routes'

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY

export const signIn = credentials => dispatch => {
  const request = new Request()

  request.post('/login', credentials, response => {
    // it save token in localStorage for persist the token when of page is reloaded
    localStorage.setItem(TOKEN_KEY, response.token)
    history.push(redirectRoute.private)

    return dispatch(userActions.signIn(response.user))
  })
}

export const signOut = () => dispatch => {
  closeSession()
  return dispatch(userActions.signOut())
}

export const signUp = body => dispatch => {
  const request = new Request()
  request.post('/users-client/register', body, response => {
    history.push(redirectRoute.public)

    return dispatch(
      notificationActions.set({
        severity: 'success',
        message: 'Tu cuenta ha sido creada por favor inicia sesión',
      })
    )
  })
}

export const getUserData = () => dispatch => {
  const request = new RequestWithAuthorization()
  request.get('/users/me', response => dispatch(userActions.set(response)))
}

export const updateProfile = (payload, callback, callbackError) => dispatch => {
  const request = new RequestWithAuthorization()

  request.put(
    '/users/me',
    payload,
    response => {
      dispatch(
        userActions.edit({
          email: response.email,
          name: response.name,
          picture: response.picture,
        })
      )
      callback()
    },
    callbackError
  )
}

export const getUsersByEmployerID = callback => {
  const request = new RequestWithEmployerAuthorization()
  request.get('/users', callback)
}
