import React from 'react'
import PaymentImg from '../../../static/images/stories/online-transactions.svg'
import Container from '../../Wrappers/Container'

const StagePaymentEmptyState = () => {
  return (
    <Container className="lg-cols-2 s-column s-center s-cross-center s-main-center s-to-center">
      <div className="l-block" />
      <img className="s-mb-2" src={PaymentImg} alt="Pagos" width="200px" height="200px" />
      <h3>¡Uy! aún no tienes Pagos</h3>
      <p className="s-color-light-text">
        Primero {''}
        <span className="s-color-text">Calcula</span> y{' '}
        <span className="s-color-text">Cierra</span> el proceso para pagar aquí a tus
        empleados.
      </p>
    </Container>
  )
}

export default StagePaymentEmptyState
