import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/employer-notes'

export const createEmployerNote = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(
    RESOURCE,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const deleteEmployerNote = (id, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${id}`, response => callback(response))
}

export const updateEmployerNote = (id, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(
    `${RESOURCE}/${id}`,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}
