import React, {useState, useRef} from 'react'
import UserAvatar from 'components/Atoms/Templates/UserAvatar'
import ModalCropImage from 'components/Organisms/Modals/ModalCropImage'
import { isEmpty } from 'helpers/utils'
import {getAvatar} from 'helpers/static-images'
import useObjectURL from 'hooks/useObjectURL'
import { uploaderAvatar } from 'services/uploader'

const AvatarEdit = ({thumbnail = '', employeeID = 0, gender = 'MASCULINO', classes = '', onUpdate = () =>{}}) => {
  const {setObject, objectURL} = useObjectURL(null)
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const fileInputRef = useRef()
  const modalCrop = useRef()

  const handleImageClick = () => {
    if (isLoading) return // not load a new image if currently is running an upload

    fileInputRef.current.click()
  }

  const handleImageUpload = event => {
    const file = event.target.files[0]

    if (file) {
      setObject(file)
      modalCrop.current.openModal()
    }
  }

  const handleCropLogoComplete = image => {
    modalCrop.current.closeModal()
    setIsLoading(true)

    uploaderAvatar(
      image,
      response => {
        onUpdate({
          picture: response[0].name_file,
          thumbnail: response[1].name_file,
        })
        
        setObject(null)
        setInputValue('')
        setIsLoading(false)
      },
      error => {
        setObject(null)
        setInputValue('')
        setIsLoading(false)
        console.error(error)
      }
    )
  }

  const handleCancelCropLogo = () => {
    setObject(null)
    setInputValue("")
    modalCrop.current.closeModal()
  }

  return (
    <>
      <UserAvatar
        className={`${isLoading ? 'is-loading' : 'cursor-pointer'} ${classes}`}
        picture={isEmpty(thumbnail) ? getAvatar(employeeID, gender) : thumbnail}
        onClick={handleImageClick}
      />

      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        accept="image/*"
        className="hidden"
        value={inputValue}
        onChange={handleImageUpload}
      />

      {/* Modal Crop Image */}
      <ModalCropImage
        ref={modalCrop}
        title="Posiciona y redimensiona tu imagen"
        imageURL={objectURL}
        onCropImageComplete={handleCropLogoComplete}
        onCancel={handleCancelCropLogo}
      />
    </>
  )
}

export default AvatarEdit
