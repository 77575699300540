// Websocket close event codes
// https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
export const WS_STATUS_CLOSE_NORMAL_CLOSURE = 1000
export const WS_STATUS_CLOSE_GOING_AWAY = 1001
export const WS_STATUS_CLOSE_PROTOCOL_ERROR = 1002
export const WS_STATUS_CLOSE_UNSUPPORTED_DATA = 1003
export const WS_STATUS_CLOSE_NO_STATUS_RECEIVED = 1005
export const WS_STATUS_CLOSE_ABNORMAL_CLOSURE = 1006
export const WS_STATUS_CLOSE_INVALID_FRAME_PAYLOAD_DATA = 1007
export const WS_STATUS_CLOSE_POLICY_VIOLATION = 1008
export const WS_STATUS_CLOSE_MESSAGE_TOO_BIG = 1009
export const WS_STATUS_CLOSE_MANDATORY_EXTENSION = 1010
export const WS_STATUS_CLOSE_INTERNAL_SERVERERR = 1011
export const WS_STATUS_CLOSE_SERVICE_RESTART = 1012
export const WS_STATUS_CLOSE_TRY_AGAIN_LATER = 1013
export const WS_STATUS_CLOSE_TLS_HANDSHAKE = 1015
