import React from 'react'
import {getURLImage} from '../../helpers/utils'
import DefaultLogo from '../../static/images/logo/isotipo.svg'
import PropTypes from 'prop-types'
import {useMedia} from '../../hooks/useMedia'
import {mediaFromM} from '../../helpers/media-queries'
import {getClassAspectRatio} from '../../helpers/employer'

const PayslipReportHeader = ({picture, businessName, nit, dv, title, aspectRatio}) => {
  const fromM = useMedia(mediaFromM)

  // if the source is an image broken then render a standard avatar
  const handleImgError = e => {
    e.target.src = DefaultLogo
  }

  return (
    <section className="s-cross-center s-main-center m-main-justify s-mb-2 m-mb-4">
      <div className="from-m">
        {picture ? (
          <div className={`logo-container ${getClassAspectRatio(aspectRatio)}`}>
            <img src={getURLImage(picture)} onError={handleImgError} alt="Logo empresa" />
          </div>
        ) : (
          <div className="s-bg-blue small-radius s-cross-center s-main-center s-w-64px s-h-64px">
            <p className="t1 s-color-white s-mb-0">{businessName.substring(0, 1).toUpperCase()}</p>
          </div>
        )}
      </div>
      <div className="s-column s-center m-right s-color-title">
        <span className="t2">{businessName}</span>
        {fromM && (
          <>
            <span className="small">
              {nit} - {dv}
            </span>
            <span className="small">{title}</span>
          </>
        )}
      </div>
    </section>
  )
}

PayslipReportHeader.propTypes = {
  picture: PropTypes.string,
  businessName: PropTypes.string.isRequired,
  nit: PropTypes.string.isRequired,
  dv: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  aspectRatio: PropTypes.string,
}

PayslipReportHeader.defaultProps = {
  picture: '',
  businessName: '',
  nit: '',
  dv: '',
  title: 'Comprobante de pago',
  aspectRatio: '4:3',
}

export default PayslipReportHeader
