import React, {useState, useContext} from 'react'
import {useSelector} from 'react-redux'
import Select from '../../Atoms/Forms/Select'
import TreeSelect from '../../Atoms/Forms/TreeSelect'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import FormInput from '../../Atoms/Templates/FormInput'
import RadioButton from '../../Atoms/Forms/RadioButton'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import SelectPlaceLaborMunicipalityInput from '../../Molecules/Selects/SelectPlaceLaborMunicipalityInput'
import TextArea from '../../Atoms/Forms/TextArea'
import {FormDataContext} from '../../../contexts/contexts'
import {selectors as branchOfficeSelector} from '../../../redux/ducks/branchOffices'
import {selectors as payPeriodsSelector} from '../../../redux/ducks/payPeriods'
import {selectors as jobSelector} from '../../../redux/ducks/jobs'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {selectors as areaSelectors} from '../../../redux/ducks/areas'
import {selectors as dimensionSelectors} from '../../../redux/ducks/dimension'
import {getLocalCache, getCacheKeyGeneral} from '../../../helpers/cache'
import {isNull, isUndefined, stringToBool, isEqual, isDate} from '../../../helpers/utils'
import {addDays, addMonths, diffDays} from '../../../helpers/dates'
import {hasCesantiasFound, isIntern, makeMapFound} from '../../../helpers/socialsecurity'
import {PERU_CODE} from 'helpers/country'

const ContractEmployeeForm = () => {
  const {formData, setFormData, updateFormField, updateFormFieldTextToNumber} = useContext(FormDataContext)

  const branchOffices = useSelector(branchOfficeSelector.getAll)
  const payPeriods = useSelector(payPeriodsSelector.getAll)
  const jobs = useSelector(jobSelector.getAll)
  const areas = useSelector(areaSelectors.getAll)
  const employerSession = useSelector(employerSelectors.getSession)
  const dimensions = useSelector(dimensionSelectors.getList)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractTypes = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types
  const salariesTypes = !isNull(cacheGeneral) && cacheGeneral.data?.salaries_types
  const contractAndSalaryTypes = !isNull(cacheGeneral) && cacheGeneral.data?.contract_type_salary_type
  const contractTypeEntityType = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types_entity_types

  const [allowSalaryTypes, setAllowSalaryTypes] = useState([])

  const handleOnChangeContractType = e => {
    updateFormField(e)

    // Update the field is_contract_extensible
    // and reset the field expiration_date and social security founds
    const value = e.target.value
    const options = e.target.options
    const i = options.selectedIndex
    let isExtensible = options[i].getAttribute('data-is-extensible')

    setFormData(state => ({
      ...state,
      is_contract_extensible: stringToBool(isExtensible),
      expiration_date: null,
      salary_type_id: 0,
      // reset social security founds:
      workplace_id: 0,
      entities: makeMapFound(Number(value), contractTypeEntityType),
      // reset social security questions:
      is_pensioner: false,
      is_foreigner_without_pension: false,
      is_colombian_living_abroad: false,
      has_colombian_living_abroad_pay_health: false,

      // calculate trial_period_date
      trial_period_date: isIntern(Number(value), contractTypes)
        ? null // intern must be null
        : !isNull(formData.hire_date)
        ? calculateTrialPeriodDate(formData.hire_date, formData.expiration_date, formData.is_contract_extensible) // calculate if has hire_date
        : formData.trial_period_date, // default trial_period_date value
    }))

    setAllowSalaryTypes(
      !contractAndSalaryTypes ? [] : contractAndSalaryTypes.filter(r => isEqual(r.contract_type_id, Number(value)))
    )
  }

  const handleOnChangeSalaryType = e => {
    updateFormField(e)

    const value = e.target.value

    // show the cesantias found if exists
    if (hasCesantiasFound(Number(value), salariesTypes)) {
      setFormData(state => ({
        ...state,
        entities: [
          ...state.entities.map(e => {
            if (e.abbreviation !== 'CES') return e

            return {...e, is_show: true, is_required: true}
          }),
        ],
      }))

      return
    }

    // hide the Cesantias found if exists
    setFormData(state => ({
      ...state,
      entities: [
        ...state.entities.map(e => {
          if (e.abbreviation !== 'CES') return e

          return {...e, is_show: false, is_required: false}
        }),
      ],
    }))
  }

  const handleOnChangeHireDate = date => {
    setFormData(state => ({
      ...state,
      hire_date: date,
      // calculate trial_period_date
      trial_period_date: calculateTrialPeriodDate(date, formData.expiration_date, formData.is_contract_extensible),
    }))
  }

  const handleOnChangeExpirationDate = date => {
    setFormData(state => ({
      ...state,
      expiration_date: date,
      // calculate trial_period_date
      trial_period_date: calculateTrialPeriodDate(formData.hire_date, date, formData.is_contract_extensible),
    }))
  }

  const handleOnChangeBranchOffice = e => {
    updateFormField(e)

    // Update the field branch_office_department
    const options = e.target.options
    const i = options.selectedIndex
    let departmentID = options[i].getAttribute('data-department-id')

    setFormData(state => ({
      ...state,
      branch_office_department: Number(departmentID),
      place_labor_municipality_id: 0,
    }))
  }

  const handleOnSelectMunicipalityInput = municipalityID => {
    setFormData(state => ({
      ...state,
      place_labor_municipality_id: Number(municipalityID),
    }))
  }

  const handleOnChangeArea = area => {
    if (isNull(area)) {
      setFormData(state => ({
        ...state,
        organizational_struct_id: '',
      }))
      return
    }

    setFormData(state => ({
      ...state,
      organizational_struct_id: area?.value,
    }))
  }

  const updateFormFieldDimension = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      dimensions: {
        ...state.dimensions,
        [name]: value,
      },
    }))
  }

  return (
    <>
      <div className="form-grid ed-grid m-grid-2 lg-grid-3">
        <Select
          isRequired
          label="Tipo de contrato"
          name="contract_type_id"
          id="contract_type_id"
          placeholder
          value={formData.contract_type_id}
          onChange={handleOnChangeContractType}
        >
          {Array.isArray(contractTypes) &&
            contractTypes.map(type => (
              <option key={type.id} value={type.id} data-is-extensible={type.is_extensible}>
                {type.description}
              </option>
            ))}
        </Select>
        <Select
          isRequired
          label="Sucursal"
          name="branch_office_id"
          id="branch_office_id"
          placeholder
          value={formData.branch_office_id}
          onChange={handleOnChangeBranchOffice}
        >
          {Array.isArray(branchOffices) &&
            branchOffices.map(branchOffice => (
              <option key={branchOffice.id} value={branchOffice.id} data-department-id={branchOffice.department_id}>
                {branchOffice.description}
              </option>
            ))}
        </Select>

        <SelectPlaceLaborMunicipalityInput
          isRequired
          label="Ciudad de trabajo"
          name="place_labor_municipality_id"
          id="place_labor_municipality_id"
          placeholder="Escribe para buscar"
          departmentID={formData.branch_office_department}
          onSelect={handleOnSelectMunicipalityInput}
        />

        <TreeSelect isRequired label="Área" data={areas} id="area" placeholder onChange={handleOnChangeArea} />

        <Select
          isRequired
          label="Cargo"
          name="job_id"
          id="job_id"
          placeholder
          value={formData.job_id}
          onChange={updateFormField}
        >
          {Array.isArray(jobs) &&
            jobs.map(job => (
              <option key={job.id} value={job.id}>
                {job.description}
              </option>
            ))}
        </Select>

        <DatePickerInput
          isRequired
          label="Inicio de contrato"
          name="hire_date"
          id="hire_date"
          value={formData.hire_date}
          onChange={handleOnChangeHireDate}
        />

        {formData.is_contract_extensible && (
          <DatePickerInput
            isRequired
            label="Finalización de contrato"
            name="expiration_date"
            id="expiration_date"
            minDate={formData.hire_date}
            value={formData.expiration_date}
            onChange={handleOnChangeExpirationDate}
          />
        )}

        {!isIntern(formData.contract_type_id, contractTypes) && (
          <DatePickerInput
            label="Finalización período de prueba"
            name="trial_period_date"
            id="trial_period_date"
            minDate={addDays(formData.hire_date, 1)}
            value={formData.trial_period_date}
            onChange={date => setFormData(state => ({...state, trial_period_date: date}))}
          />
        )}

        <Select
          isRequired
          label="Frecuencia de pago"
          name="pay_frequency_id"
          id="pay_frequency_id"
          placeholder="female"
          value={formData.pay_frequency_id}
          onChange={updateFormField}
        >
          {Array.isArray(payPeriods) &&
            !isUndefined(payPeriods[0]?.pay_frequency) &&
            payPeriods.map(payPeriod => (
              <option key={payPeriod.pay_frequency.id} value={payPeriod.pay_frequency.id}>
                {payPeriod.pay_frequency.description}
              </option>
            ))}
        </Select>

        <RadioButtonsWrapper label="¿Trabaja el Sábado?">
          <RadioButton
            name="is_labor_saturday"
            label="Sí"
            value={true}
            onChange={updateFormField}
            checked={isEqual(formData.is_labor_saturday, true)}
          />
          <RadioButton
            name="is_labor_saturday"
            label="No"
            value={false}
            onChange={updateFormField}
            checked={isEqual(formData.is_labor_saturday, false)}
          />
        </RadioButtonsWrapper>

        <Select
          isRequired
          label="Tipo de salario"
          name="salary_type_id"
          id="salary_type_id"
          placeholder
          value={formData.salary_type_id}
          onChange={handleOnChangeSalaryType}
        >
          {Array.isArray(allowSalaryTypes) &&
            allowSalaryTypes.map(a => {
              let description = null

              if (Array.isArray(salariesTypes)) {
                const type = salariesTypes.filter(t => isEqual(t.id, a.salary_type_id))
                if (isEqual(type.length, 1)) {
                  description = type[0].description
                }
              }

              return (
                <option key={a.salary_type_id} value={a.salary_type_id}>
                  {description}
                </option>
              )
            })}
        </Select>

        <FormInput
          isRequired
          label="Salario"
          name="salary"
          id="salary"
          placeholder="0,000,000"
          align="right"
          autoComplete="nope"
          value={formData.salary}
          onChange={updateFormFieldTextToNumber}
        />

        <FormInput
          label="Código alterno de empresa"
          name="alternate_code"
          id="alternate_code"
          placeholder="00000"
          value={formData.alternate_code}
          onChange={updateFormField}
        />

        <FormInput
          isRequired
          label="Horas trabajadas al mes"
          name="month_hours"
          id="month_hours"
          type="number"
          min="8"
          max="240"
          step="1"
          placeholder="235"
          value={formData.month_hours}
          onChange={updateFormField}
        />

        {isEqual(employerSession.code_iso_3, PERU_CODE) && (
          <RadioButtonsWrapper label="¿Tiene asignación familiar?">
            <RadioButton
              name="has_family_allowance"
              label="Sí"
              value={true}
              onChange={updateFormField}
              checked={isEqual(formData.has_family_allowance, true)}
            />
            <RadioButton
              name="has_family_allowance"
              label="No"
              value={false}
              onChange={updateFormField}
              checked={isEqual(formData.has_family_allowance, false)}
            />
          </RadioButtonsWrapper>
        )}

        {Array.isArray(dimensions) &&
          dimensions.map(record => (
            <Select
              isRequired={record.dimension.is_mandatory}
              key={record.dimension.id}
              label={record.dimension.description}
              name={record.dimension.code}
              id={record.dimension.code}
              placeholder="Selecciona una opción"
              value={formData.dimensions?.[record.dimension.code]}
              onChange={updateFormFieldDimension}
            >
              {Array.isArray(record.values) &&
                record.values.map(value => (
                  <option key={value.id} value={value.code}>
                    {value.code} - {value.description}
                  </option>
                ))}
            </Select>
          ))}

        <div className="s-order-1 m-order-initial m-rows-2 lg-rows-2 m-cols-3 lg-cols-3">
          <TextArea
            className="s-h-full"
            name="notes"
            id="notes"
            placeholder="Escribe una nota"
            value={formData.notes}
            onChange={updateFormField}
            align="left"
            label="Notas u observaciones"
            textarea={{
              className: 's-h-full',
            }}
          />
        </div>
      </div>
      <p className="smaller s-main-center s-pt-4 s-mb-0">
        Los campos con <span className="s-color-blue">*</span> son obligatorios
      </p>
    </>
  )
}

export const calculateTrialPeriodDate = (hireDate, expirationDate, isExtensible) => {
  if (!isDate(hireDate)) return null

  const MAX_TWO_MONTHS_IN_DAYS = 60
  const FRACTIONS = 5
  const MAX_LIMIT = MAX_TWO_MONTHS_IN_DAYS * FRACTIONS
  const daysDurationContrat = diffDays(hireDate, expirationDate)
  const exceedLimit = daysDurationContrat > MAX_LIMIT

  if (!isExtensible || exceedLimit) return addMonths(hireDate, 2)

  if (!isDate(expirationDate)) return hireDate
  const plusDays = Math.ceil(daysDurationContrat / FRACTIONS)

  return addDays(hireDate, plusDays)
}

export default ContractEmployeeForm
