import {RequestWithEmployerAuthorization} from '../helpers/request'
import {isEqual} from '../helpers/utils'

const RESOURCE = '/process-locks'

export const getActiveProcessLock = callback => {
  const request = new RequestWithEmployerAuthorization()

  request.getWithResponseObject(
    RESOURCE,
    response => {
      if (isEqual(response.status, 204)) {
        callback(null)
        return
      }

      callback(response.data)
    },
    error => {
      console.error('process locks:', error)
    }
  )
}
