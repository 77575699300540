import {isEmpty, isEqual, isNull} from './utils'

export const GENERAL = 'GENERAL'
export const BANKS = 'BANKS'
export const SOCIAL_SECURITY = 'SOCIAL_SECURITY'

export const caches = [GENERAL, BANKS, SOCIAL_SECURITY]

export const getLocalCache = cacheName => {
  let cache = localStorage.getItem(cacheName)

  try {
    cache = JSON.parse(cache)
  } catch (e) {
    return null
  }

  return cache
}

export const getCacheKeyGeneral = countryCodeISO3 => `${countryCodeISO3}:${GENERAL}`
export const getCacheKeyBanks = countryCodeISO3 => `${countryCodeISO3}:${BANKS}`
export const getCacheKeySocialSecurity = countryCodeISO3 =>
  `${countryCodeISO3}:${SOCIAL_SECURITY}`

export const getIdentificationTypeByID = (id, countryCodeISO3) => {
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(countryCodeISO3))
  const identifications = !isNull(cacheGeneral) && cacheGeneral.data?.identification_types

  if (!Array.isArray(identifications)) return ''

  const data = identifications.filter(identification => isEqual(identification.id, id))
  if (isEmpty(data)) return ''

  return data[0]?.abbreviation
}
