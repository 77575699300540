import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {isEqual, isNull, isUndefined} from 'helpers/utils'
import Select from 'components/Atoms/Forms/Select'
import MonthYearPickerInput from 'components/Molecules/Pickers/MonthYearPickerInput'
import ValidatorForm from 'helpers/validator'
import {notifyError, notifySuccessful, notifyWarning} from 'services/notification'
import {CREATE, EDIT} from 'helpers/payroll'
import {readBackendDate, startDateOfNextMonth} from 'helpers/dates'
import { createEmployeeSocialSecurityEntity, updateEmployeeSocialSecurityEntity } from 'services/employeeSocialSecurityEntities'

const formFields = {
  contract_id: 'ID del contrato',
  social_security_entity_id: 'Fondo',
  begins_at: 'Fecha Inicial',
}

const initialState = {
  id: 0,
  contract_id: 0,
  social_security_entity_id: 0,
  begins_at: null,
}

const rules = {
  contract_id: 'integer|required|not_in:0',
  social_security_entity_id: 'integer|required|not_in:0',
  begins_at: 'date|required',
}

const SocialSecurityFundChangeForm = ({
  fundType = {id: 0, code: ''},
  funds=[],
  hireDate = null,
  terminationDate = null,
  onCancel = () => {},
  onSuccessful = () => {},
  action = null,
}) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [filterFunds, setFilterFunds] = useState(funds)

  useEffect(() => {
    if (isUndefined(action) || isNull(action) || isNull(action.type)) return

    if (!isNull(action.previous)) {
      setFilterFunds(funds.filter(f => f.id !== action.previous.social_security_entity_id))
    } else {
      setFilterFunds(funds)
    }

    if (isEqual(action.type, CREATE)) {
      setFormData({...initialState, contract_id: action.contractID})
      return
    }

    // action EDIT
    setFormData({...action.current, begins_at: readBackendDate(action.current.begins_at)})
  }, [action])

  const handleOnChangeNumber = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: Number(value),
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date,
    }))
  }

  const handleOnClick = () => {
    const validator = new ValidatorForm(formData, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    // we always set the endsAt as Null because backend not required this field, if we send it
    // the backend response with error
    formData.ends_at = null
    formData.entity_type_id = fundType?.id

    setIsLoading(true)

    if (isEqual(action.type, CREATE)) {
      handleCreate()
      return
    }

    handleEdit()
  }

  const handleCreate = () => {
    createEmployeeSocialSecurityEntity(
      formData,
      response => {
        onSuccessful(response)
        dispatch(notifySuccessful('El fondo ha sido creado correctamente 🎉'))
        setIsLoading(false)
        onCancel()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  const handleEdit = () => {
    if (isEqual(action.current.social_security_entity_id, formData.social_security_entity_id)) {
      dispatch(notifyWarning("No realizaste ningún cambio"))
      setIsLoading(false)
      return
    }

    updateEmployeeSocialSecurityEntity(
      formData.id,
      formData,
      response => {
        onSuccessful(response)
        dispatch(notifySuccessful('El fondo ha sido actualizado correctamente 🎉'))
        setIsLoading(false)
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  return (
    <form noValidate className="s-mb-2 ed-grid rows-gap form-grid">
      <div className="ed-grid m-grid-3 rows-gap">
        {/* TODO no mostrar el mismo fondo del registro actual porque se podría crear el mismo */}
        <Select
          isRequired
          label={formFields.social_security_entity_id}
          name="social_security_entity_id"
          id="social_security_entity_id"
          placeholder
          value={formData.social_security_entity_id}
          onChange={handleOnChangeNumber}
          className="m-cols-2"
        >
          {Array.isArray(filterFunds) &&
            filterFunds.map(f => (
              <option key={f.id} value={f.id}>
                {f.short_name}
              </option>
            ))}
        </Select>

        <MonthYearPickerInput
          isRequired
          label={formFields.begins_at}
          name="begins_at"
          id="begins_at"
          value={formData.begins_at}
          onChange={handleOnChangeBeginsAt}
          minDate={
            isEqual(action.type, EDIT)
              ? hireDate :
            isEqual(action.type, CREATE) ?
               startDateOfNextMonth(action.previous.begins_at)
               : null
          }
          maxDate={terminationDate}
          disabled={isEqual(action.type, EDIT)}
        />
      </div>

      <div className="ed-grid s-grid-1 rows-gap s-center">
        <p className="smaller s-mb-0 s-py-1">
          Los campos con <span className="s-color-blue">*</span> son obligatorios
        </p>

        <div className="buttons-container">
          <button
            type="button"
            className="button ghost cancel s-order-3 m-order-1"
            onClick={onCancel}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="button ghost s-order-1 m-order-3"
            onClick={handleOnClick}
            disabled={isLoading}
          >
            {isLoading
              ? isEqual(action.type, CREATE)
                ? 'Creando...'
                : 'Editando...'
              : isEqual(action.type, CREATE)
              ? 'Crear'
              : 'Editar'}
          </button>
        </div>
      </div>
    </form>
  )
}

export default SocialSecurityFundChangeForm
