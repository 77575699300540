import React, {useState, useContext, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import FormStep from '../../Atoms/FormStep'
import PanelTemplate from '../../Wrappers/PanelTemplate'
import FormInput from '../../Atoms/Templates/FormInput'
import Select from '../../Atoms/Forms/Select'
import SelectPlaceLaborMunicipalityInput from '../../Molecules/Selects/SelectPlaceLaborMunicipalityInput'
import DropContainer from '../../Molecules/DropContainer'
import Checkbox from '../../Atoms/Forms/Checkbox'
import MonthPickerInput from '../../Molecules/Pickers/MonthPickerInput'
import YearPickerInput from '../../Molecules/Pickers/YearPickerInput'
import {redirectRoute} from '../../../config/routes'
import {calculateDV} from '../../../helpers/employer'
import {TabsWrapperContext} from '../../../contexts/contexts'
import {isEmpty, isEqual, isBoolean, isNull} from '../../../helpers/utils'
import {useDispatch} from 'react-redux'
import {notifyError} from '../../../services/notification'
import {createNewEmployer} from '../../../services/employer'
import {uploaderLogo} from '../../../services/uploader'
import ValidatorForm from '../../../helpers/validator'
import useObjectURL from '../../../hooks/useObjectURL'
import FullPageLoader from '../FullPageLoader'
import ModalCropImage from '../Modals/ModalCropImage'
import {
  getLocalCache,
  getCacheKeySocialSecurity,
  getCacheKeyGeneral,
} from '../../../helpers/cache'
import {COLOMBIA_CODE} from '../../../helpers/country'
import { capitalize } from '../../../helpers/string-format'

const formFields = {
  nit: 'No. de identificación tributaria',
  dv: 'DV',
  business_name: 'Razón social',
  short_name: 'Sigla o Nombre corto',
  web: 'Sitio web',
  frequencies: 'Frecuencia de pago',
  date_period: 'Fecha de inicio para liquidar',
  'branch_office.code': 'Código',
  'branch_office.description': 'Nombre Sucursal',
  'branch_office.department_id': 'Departamento',
  'branch_office.municipality_id': 'Ciudad',
  'branch_office.address': 'Dirección',
  'branch_office.phone': 'Teléfono',
  'branch_office.ccf_id': 'CCF',
  'arl.social_security_entity_id': 'ARL',
}

const CompanyAboutForm = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {object, setObject, objectURL} = useObjectURL(null)
  const {tabActive, setTabActive} = useContext(TabsWrapperContext)
  
  const founds = getLocalCache(getCacheKeySocialSecurity(COLOMBIA_CODE))
  const arls = !isNull(founds) && founds?.data?.filter(f => isEqual(f.entity, 'ARL'))[0]?.data
  const ccfs = !isNull(founds) && founds?.data?.filter(f => isEqual(f.entity, 'CCF'))[0]?.data
  const [ccfList, setCcfList] = useState([])

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(COLOMBIA_CODE))
  const departments = !isNull(cacheGeneral) && cacheGeneral?.data?.departments

  const modal = useRef()
  const [hasLoader, setHasLoader] = useState(false)
  const [dataForm, setDataForm] = useState({
    nit: '',
    dv: '',
    businessName: '',
    short_name: '',
    web: '',
    isBimonthly: false,
    isMonthly: false,
    yearPeriod: new Date(),
    monthPeriod: new Date(),
    arl: 0,
    office_code: 'S0001',
    office_description: 'Sucursal principal',
    office_department_id: 0,
    office_municipality_id: 0,
    office_address: '',
    office_phone: '',
    office_ccf_id: 0,
  })

  const updateDataForm = e => {
    const target = e.target
    const value = isEqual(target.type, 'checkbox') ? target.checked : target.value
    const name = target.name

    setDataForm(state => ({...state, [name]: value}))
  }

  const handleChangeNIT = e => {
    const re = /^[0-9]+$/
    // only accept numbers or '' when the user empty the input
    if (isEqual(e.target.value, '') || re.test(e.target.value)) updateDataForm(e)
  }

  const handleChangeDepartment = e => {
    const value = Number(e.target.value)

    setDataForm(state => ({
      ...state,
      office_department_id: value,
      office_municipality_id: 0,
      office_ccf_id: 0,
    }))

    setCcfList(!ccfs? []: ccfs.filter(ccf => isEqual(ccf.department_id, value)))
  }

  const handleOnSelectMunicipalityInput = municipalityID => {
    setDataForm(state => ({
      ...state,
      office_municipality_id: Number(municipalityID),
    }))
  }

  const handleBlurNit = () =>
    setDataForm(state => ({...state, dv: calculateDV(dataForm.nit)}))

  const handleDropLogo = async acceptedFiles => {
    if (isEmpty(acceptedFiles)) return

    setObject(acceptedFiles[0])
    modal.current.openModal()
  }

  const handleCropLogoComplete = image => {
    setObject(image)
    modal.current.closeModal()
  }

  const handleCancelCropLogo = () => {
    setObject(null)
    modal.current.closeModal()
  }

  const handleLeftButton = e => {
    // [Cancel]. Event in step 1, return the employers page
    if (isEqual(tabActive, 1)) {
      history.push(redirectRoute.private)
      return
    }

    // [Back]. Event in step 2 or 3, return the previous step
    setTabActive(tabActive-1)
  }

  const handleRightButton = e => {
    // [Next]. Event in step 1 or 2
    if (tabActive < 3) {
      setTabActive(tabActive+1)
      return
    }

    // [Create]. Event in step 3, request for create employer
    createEmployer()
  }

  const createEmployer = async () => {
    if (!dataForm.isBimonthly && !dataForm.isMonthly) {
      dispatch(notifyError(`El campo "${formFields.frequencies}" es requerido.`))
      return
    }

    const payload = getPayload(dataForm)
    const validator = getValidatorForm(payload)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    if (!isNull(object)) {
      setHasLoader(true)
      uploaderLogo(object, response => {
        payload.picture = response[0].name_file
        payload.thumbnail = response[1].name_file

        setHasLoader(false)
        dispatch(createNewEmployer(payload))
      })

      return
    }

    dispatch(createNewEmployer(payload))
  }

  return hasLoader ? (
    <FullPageLoader />
  ) : (
    <>
      <div className="l-block s-cross-center s-main-center">
        <FormStep number={1} text="Información" />
        <FormStep number={2} text="Sucursal" />
        <FormStep number={3} text="Preferencias" />
      </div>
      <div className="ed-grid m-grid-4">
        <div className="m-x-2 m-cols-2 n-container">
          <form className="s-mb-0">
            {/* Inicio de los campos de información */}
            <PanelTemplate
              index={1}
              className="ed-grid s-grid-4 row-gap form-grid s-mb-2"
              onlyHidden
            >
              <FormInput
                className="s-cols-3"
                isRequired
                name="nit"
                id="nit"
                label={formFields.nit}
                placeholder="900 111 222"
                value={dataForm.nit}
                onChange={handleChangeNIT}
                onBlur={handleBlurNit}
              />
              <FormInput
                isDisabled
                label="DV"
                id="dv"
                placeholder="0"
                value={dataForm.dv}
              />
              <FormInput
                className="s-cols-4"
                isRequired
                name="businessName"
                id="business-name"
                label={formFields.business_name}
                placeholder="Razón social de tu empresa"
                value={dataForm.businessName}
                onChange={updateDataForm}
              />
              <FormInput
                className="s-cols-4 m-cols-2"
                isRequired
                name="short_name"
                id="short_name"
                label={formFields.short_name}
                placeholder="tu sigla, marca o nombre corto"
                value={dataForm.short_name}
                onChange={updateDataForm}
              />
              <FormInput
                className="s-cols-4 m-cols-2"
                label={formFields.web}
                name="web"
                id="web"
                placeholder="tuweb.com"
                value={dataForm.web}
                onChange={updateDataForm}
              />
              <DropContainer
                id="drop-container-employer"
                label="Logo de la empresa (64 x 64)"
                className="s-cols-4"
                handleFiles={handleDropLogo}
                file={objectURL}
              />
            </PanelTemplate>

            {/* Fin de los campos de información */}

            {/* Inicio de los campos de la sucursal */}

            <PanelTemplate
              index={2}
              className="ed-grid s-grid-4 row-gap form-grid s-mb-2"
              onlyHidden
            >
              <FormInput
                isRequired
                className=""
                label={formFields['branch_office.code']}
                name="office_code"
                id="office_code"
                value={dataForm.office_code}
                onChange={updateDataForm}
              />

              <FormInput
                isRequired
                className="s-cols-3"
                label={formFields['branch_office.description']}
                name="office_description"
                id="office_description"
                value={dataForm.office_description}
                onChange={updateDataForm}
                placeholder="Nombre sucursal principal"
              />

              <Select
                isRequired
                id="office_department_id"
                name="office_department_id"
                label={formFields['branch_office.department_id']}
                placeholder="Selecciona un departamento"
                onChange={handleChangeDepartment}
                value={dataForm.office_department_id}
                className="s-cols-4 m-cols-2"
              >
                {Array.isArray(departments) &&
                  departments.map(department => (
                    <option key={department.id} value={department.id}>
                      {department.description}
                    </option>
                  ))}
              </Select>

              <SelectPlaceLaborMunicipalityInput
                isRequired
                label={formFields['branch_office.municipality_id']}
                name="office_municipality_id"
                id="office_municipality_id"
                placeholder="Escribe para buscar"
                departmentID={dataForm.office_department_id}
                onSelect={handleOnSelectMunicipalityInput}
                className="s-cols-4 m-cols-2"
              />

              <FormInput
                className="s-cols-4 m-cols-2"
                label={formFields['branch_office.phone']}
                name="office_phone"
                id="office_phone"
                placeholder="tu teléfono"
                value={dataForm.office_phone}
                onChange={updateDataForm}
              />

              <FormInput
                className="s-cols-4 m-cols-2"
                name="office_address"
                id="office_address"
                label={formFields['branch_office.address']}
                placeholder="tu dirección"
                value={dataForm.office_address}
                onChange={updateDataForm}
              />

              <Select
                isRequired
                id="office_ccf_id"
                name="office_ccf_id"
                label={formFields['branch_office.ccf_id']}
                placeholder="Selecciona una CCF"
                onChange={updateDataForm}
                value={dataForm.office_ccf_id}
                className="s-cols-4 m-cols-2"
              >
                {Array.isArray(ccfList) &&
                  ccfList.map(ccf => (
                    <option key={ccf.id} value={ccf.id}>
                      {capitalize(ccf.short_name)}
                    </option>
                  ))}
              </Select>

              <Select
                isRequired
                id="arl"
                name="arl"
                label={formFields['arl.social_security_entity_id']}
                placeholder="Selecciona una ARL"
                onChange={updateDataForm}
                value={dataForm.arl}
                className="s-cols-4 m-cols-2"
              >
                {Array.isArray(arls) &&
                  arls.map(arl => (
                    <option key={arl.id} value={arl.id}>
                      {capitalize(arl.short_name)}
                    </option>
                  ))}
              </Select>
            </PanelTemplate>

            {/* Fin de los campos de la sucursal */}

            {/* Inicio de los campos de preferencias */}

            <PanelTemplate
              index={3}
              className="ed-grid s-grid-4 row-gap form-grid s-mb-2"
              onlyHidden
            >
              <div className="s-cols-2">
                <div className="form-item s-mb-6px">
                  <label className="required">{formFields.frequencies}</label>
                </div>
                <Checkbox
                  className="s-main-center"
                  name="isBimonthly"
                  label="Quincenal"
                  value={dataForm.isBimonthly}
                  onChange={updateDataForm}
                />
              </div>

              <Checkbox
                className="s-cols-2 s-main-center s-cross-end"
                name="isMonthly"
                label="Mensual"
                value={dataForm.isMonthly}
                onChange={updateDataForm}
              />

              <MonthPickerInput
                isRequired
                className="s-cols-2"
                id="company-month"
                data-testid="month-picker"
                label={formFields.date_period}
                name="monthPeriod"
                value={dataForm.monthPeriod}
                onChange={date => setDataForm(state => ({...state, monthPeriod: date}))}
              />

              <YearPickerInput
                className="s-cols-2 s-main-end"
                isRequired
                data-testid="year-picker"
                name="yearPeriod"
                minDate={new Date(2020, 1, 1)}
                value={dataForm.yearPeriod}
                onChange={date => setDataForm(state => ({...state, yearPeriod: date}))}
              />
            </PanelTemplate>
            {/* Fin de los campos de preferencias */}

            <div className="s-cols-4">
              <p className="s-mb-24px s-color-light-text smaller">
                Los campos con <span className="s-color-blue">*</span> son obligatorios
              </p>
              <button
                className={`button ghost s-mr-16px ${isEqual(tabActive, 1) && 'cancel'}`}
                type="button"
                onClick={handleLeftButton}
              >
                {isEqual(tabActive, 1) ? 'Cancelar' : 'Regresar'}
              </button>
              <button className="button ghost" onClick={handleRightButton} type="button">
                {tabActive < 3 ? 'Siguiente' : 'Crear Empresa'}
              </button>
            </div>
          </form>
        </div>
      </div>

      <ModalCropImage
        ref={modal}
        title="Posiciona y redimensiona tu logo"
        imageURL={objectURL}
        onCropImageComplete={handleCropLogoComplete}
        onCancel={handleCancelCropLogo}
      />
    </>
  )
}

export const getFrecuencies = (isBimonthly, isMonthly) => {
  if (!isBoolean(isBimonthly) || !isBoolean(isMonthly)) {
    return null
  }

  return isBimonthly && isMonthly
    ? ['QUINCENAL', 'MENSUAL']
    : !isBimonthly && !isMonthly
    ? []
    : isBimonthly
    ? ['QUINCENAL']
    : ['MENSUAL']
}

const getPayload = data => ({
  nit: data.nit,
  dv: data.dv.toString(),
  business_name: data.businessName,
  short_name: data.short_name,
  web: data.web,
  frequencies: getFrecuencies(data.isBimonthly, data.isMonthly),
  month: data.monthPeriod.getMonth() + 1,
  year: data.yearPeriod.getFullYear(),
  country_code_iso_3: 'COL',
  not_include_decimals_in_calculation: true,
  branch_office: {
    code: data.office_code,
    description: data.office_description,
    department_id: Number(data.office_department_id),
    municipality_id: Number(data.office_municipality_id),
    address: data.office_address,
    phone: data.office_phone,
    ccf_id: Number(data.office_ccf_id),
  },
  arl: {
    social_security_entity_id: Number(data.arl),
  },
})

const getValidatorForm = payload => {
  const rules = {
    nit: 'required',
    dv: 'required|string|max:1',
    business_name: 'required|string',
    short_name: 'required|string|max:17',
    web: 'present',
    frequencies: 'required|array|in:QUINCENAL,MENSUAL',
    month: 'required|integer|max:12',
    year: 'required|integer|min:2021|max:2090',
    branch_office: {
      code: 'required|string|max:10',
      description: 'required|string|max:100',
      department_id: 'integer|required|not_in:0',
      municipality_id: 'integer|required|not_in:0',
      address: 'string',
      phone: 'string',
      ccf_id: 'integer|required|not_in:0',
    },
    arl: {
      social_security_entity_id: 'integer|required|not_in:0',
    },
  }

  const validator = new ValidatorForm(payload, rules)

  validator.setAttributeNames(formFields)

  return validator
}

export default CompanyAboutForm
