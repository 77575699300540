import React from 'react';
import Animation from "../Templates/Animation";

const FadePopUp = ({children, show, className}) => {
  return (
    <Animation
      className={className}
      show={show}
      animationOut="fade-pop-up-out"
      animationIn="fade-pop-up-in"
    >
      {children}
    </Animation>
  );
};

export default FadePopUp;
