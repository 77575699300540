import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ParameterSettingSkeleton = () => (
  <div className="lg-cols-2">
    <Skeleton height={800} />
  </div>
)

export default ParameterSettingSkeleton
