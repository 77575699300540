import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import CompanyAvatar from '../Avatars/CompanyAvatar'

const SquareCompanyItem = ({name, addButton, thumbnail, ...rest}) => {
  return (
    <article
      className={`square-company-item cursor-pointer s-to-center s-mb-0 ${
        addButton && 'add-button'
      }`}
      {...rest}
    >
      {addButton ? (
        <div className="square s-hover-bg-alt-blue background-transition s-cross-center s-main-center normal-radius s-mb-8px">
          <Icon svg="plus" size="2rem" color="var(--blue-600)" />
        </div>
      ) : (
        <div
          className={`square s-cross-center s-main-center normal-radius background-transition s-mb-8px ${
            thumbnail
              ? 's-bg-white s-hover-bg-body-alt'
              : 's-bg-blue s-hover-bg-alt-blue '
          }`}
        >
          <CompanyAvatar
            name={name}
            src={thumbnail}
            size="s-w-48px s-h-48px"
            tn="t1"
            classColorText="s-bg-transparent"
          />
        </div>
      )}
      <p className="s-mb-0 small overflow-ellipsis">{addButton ? 'Nueva' : name}</p>
    </article>
  )
}

SquareCompanyItem.propTypes = {
  name: PropTypes.string,
}

SquareCompanyItem.defaultProps = {
  name: 'nomineros',
}

export default SquareCompanyItem
