import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PayslipSkeleton = () => (
  <div className="ed-grid rows-gap">
    <div>
      <Skeleton height={200} />
    </div>
    <div className="ed-grid m-grid-2 lg-grid-3 s-gap-0 m-gap-2 lg-gap-4">
      <div>
        <Skeleton height={800} />
      </div>
      <div className="lg-cols-2 ed-grid rows-gap">
        <div>
          <Skeleton height={200} />
        </div>
        <div>
          <Skeleton height={600} />
        </div>
      </div>
    </div>
  </div>
)

export default PayslipSkeleton
