/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {FloatContainerContext} from '../../../contexts/contexts'

const DropdownItem = ({text, isActive, onClick}) => {
  const {setActive} = useContext(FloatContainerContext)

  const clickFunction = e => {
    e.preventDefault()
    onClick()
    setActive(false)
  }

  return (
    <a
      onClick={e => clickFunction(e)}
      href="#"
      className={`small decoration-none flex whitespace-nowrap s-hover-color-blue color-transition ${
        isActive ? 'is-active s-color-blue' : 's-color-light-text'
      }`}
    >
      {text}
    </a>
  )
}

DropdownItem.propTypes = {
  text: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

DropdownItem.defaultProps = {
  isActive: false,
  onClick: () => {},
  text: '',
}

export default DropdownItem
