import React, {useState, useRef} from 'react'
import EmployeeInformationContainer from '../Containers/EmployeeInformationContainer'
import NotesContractPanelEmptyState from '../Emptys/NotesContractPanelEmptyState'
import {isEmpty} from '../../../helpers/utils'
import NotesContractEditModal from '../Modals/NotesContractEditModal'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'

const NotesContractPanel = ({data, onUpdate}) => {
  const [formData, setFormData] = useState(data)

  const editModal = useRef()

  const handleOnEdit = () => {
    setFormData(data)
    editModal.current.openModal()
  }

  return (
    <>
      <EmployeeInformationContainer
        title="Notas u observaciones"
        icon="clipboard-list"
        columns={1}
        editFunction={handleOnEdit}
      >
        {isEmpty(data.contract.notes) ? (
          <NotesContractPanelEmptyState className="s-cols-2" />
        ) : (
          <PreviewMarkdown content={data.contract.notes} />
        )}
      </EmployeeInformationContainer>
      <NotesContractEditModal
        ref={editModal}
        formData={formData}
        setFormData={setFormData}
        onUpdate={onUpdate}
      />
    </>
  )
}

export default NotesContractPanel
