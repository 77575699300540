import {createDuck} from 'redux-duck'

const duck = createDuck('app/employer')

// Define action types
export const SET_EMPLOYERS = duck.defineType('SET_EMPLOYERS')
export const SIGN_IN_EMPLOYER = duck.defineType('SIGN_IN_EMPLOYER')
export const SIGN_OUT_EMPLOYER = duck.defineType('SIGN_OUT_EMPLOYER')
export const NEW_EMPLOYER = duck.defineType('NEW_EMPLOYER')

// Define action creators
export const actions = {
  set: duck.createAction(SET_EMPLOYERS),
  signIn: duck.createAction(SIGN_IN_EMPLOYER),
  signOut: duck.createAction(SIGN_OUT_EMPLOYER),
  new: duck.createAction(NEW_EMPLOYER),
}

// Define selectors
export const selectors = {
  getAll: store => store.employerReducer.all,
  getSession: store => store.employerReducer.session,
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_EMPLOYERS]: (state, {payload}) => ({
      ...state,
      all: [
        ...payload.sort((a, b) =>
          a.business_name.toUpperCase() < b.business_name.toUpperCase() ? -1 : 1
        ),
      ],
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => ({
      ...state,
      session: state.all.filter(employer => employer.id === payload)[0],
    }),
    [SIGN_OUT_EMPLOYER]: (state, action) => {
      const {session, ...stateWithoutSession} = state
      return {
        ...stateWithoutSession,
      }
    },
    [NEW_EMPLOYER]: (state, {payload}) => {
      return {
        ...state,
        all: [...state.all, payload].sort((a, b) =>
          a.business_name.toUpperCase() < b.business_name.toUpperCase() ? -1 : 1
        ),
      }
    },
  },
  initialState
)
