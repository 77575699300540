import {createDuck} from 'redux-duck'
import {SIGN_IN_EMPLOYER, SIGN_OUT_EMPLOYER} from './employer'

const duck = createDuck('app/dimensions')

// Define action types
export const SET_DIMENSIONS = duck.defineType('SET_DIMENSIONS')
export const SET_DIMENSIONS_MAPPING = duck.defineType('SET_DIMENSIONS_MAPPING')

// Define action creators
export const actions = {
  set: duck.createAction(SET_DIMENSIONS),
  setMapping: duck.createAction(SET_DIMENSIONS_MAPPING),
}

// Define selectors
export const selectors = {
  getList: store => store.dimensionReducer.list,
  getMapping: store => store.dimensionReducer.mapping,
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_DIMENSIONS]: (state, {payload}) => ({
      ...state,
      list: payload,
    }),
    [SET_DIMENSIONS_MAPPING]: (state, {payload}) => ({
      ...state,
      mapping: payload,
    }),
    [SIGN_IN_EMPLOYER]: (state, {payload}) => initialState,
    [SIGN_OUT_EMPLOYER]: (state, {payload}) => initialState,
  },
  initialState
)
