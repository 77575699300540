import React from 'react'
import {useSelector} from 'react-redux'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import Container from '../../Wrappers/Container'
import CarouselGrid from '../../Wrappers/CarouselGrid'
import PayrollSummaryItem from '../../Molecules/Items/PayrollSummaryItem'
import {getFormatCurrency} from '../../../helpers/format-number'
import Skeleton from 'react-loading-skeleton'

const SummaryPayslipPanel = ({data}) => {
const employerSession = useSelector(employerSelectors.getSession)

  return !data?.total_employees ? (
    <Skeleton height={120} />
  ) : (
    <Container>
      <h3>Resumen general</h3>
      <CarouselGrid
        breakpoints={{
          lg: 3,
          m: 2,
          s: 2,
        }}
      >
        <PayrollSummaryItem
          title="Total empleados"
          icon="user"
          content={`${data?.total_employees}`}
        />
        <PayrollSummaryItem
          title="Total a pagar"
          icon="cash"
          content={`${getFormatCurrency(
            data?.total_to_pay,
            employerSession.code_iso_3,
            !employerSession.not_include_decimals_in_calculation
          )}`}
        />
      </CarouselGrid>
    </Container>
  )
}

export default SummaryPayslipPanel
