import Badge from '../../Atoms/Badge'
import React from 'react'
import PropTypes from 'prop-types'
import BankGraphic from '../../../static/images/utils/bank-1.svg'
import DropdownItem from '../../Atoms/Items/DropdownItem'
import DropdownButton from '../Dropsdown/DropdownButton'
import {isNull} from '../../../helpers/utils'
import {capitalize} from '../../../helpers/string-format'

const BankItem = ({bankItem, banksCache, onEdit}) => {
  const bank = banksCache.find(bank => bank.id === bankItem.bank_id)

  return (
    <>
      <article className="flex nowrap s-mb-0">
        <img src={BankGraphic} className="s-mr-8px flex-none" alt="bank item" />
        <div>
          <p className="s-mb-0 small">{!isNull(bank) && bank.short_name}</p>
          <div className="s-cross-start ">
            <p className="s-mb-0 smaller s-color-light-text">{bankItem.account_number}</p>
          </div>
          <div className="s-cross-start ">
            <p className="s-mb-0 smaller s-color-light-text s-mr-8px">
              {capitalize(bankItem.account_type)}
            </p>
            <Badge
              text={bankItem.is_active ? 'Activo' : 'Inactivo'}
              color={bankItem.is_active ? 's-bg-blue' : 's-bg-red'}
            />
          </div>
        </div>
        <DropdownButton className="s-to-right" icon="vertical-dots">
          <DropdownItem
            text="Editar"
            onClick={() => {
              onEdit(bankItem)
            }}
          />
        </DropdownButton>
      </article>
    </>
  )
}

export default BankItem

BankItem.propTypes = {
  banksCache: PropTypes.array,
  bankItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bank_id: PropTypes.number.isRequired,
    account_number: PropTypes.string,
    account_type: PropTypes.string.isRequired,
    is_active: PropTypes.bool,
  }),
  onEdit: PropTypes.func.isRequired,
}

BankItem.defaultProps = {
  banksCache: [],
  bankItem: {
    id: 0,
    bank_id: 0,
    account_number: '',
    account_type: '',
    is_active: true,
  },
  onEdit: () => {},
}
