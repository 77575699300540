export const AVERAGE = "AVERAGE"
export const ACCUMULATED = 'ACCUMULATED'

export const baseConceptClassifications = {
  [ACCUMULATED]: 'ACUMULADO',
  [AVERAGE]: 'PROMEDIO',
}

export const listbaseConceptClassification = [
  {code: ACCUMULATED, description: baseConceptClassifications[ACCUMULATED]},
  {code: AVERAGE, description: baseConceptClassifications[AVERAGE]},
]

// accumulations
export const ACCUM_MONTHLY = 'MONTHLY'
export const ACCUM_BIMONTHLY = 'BIMONTHLY'
export const ACCUM_QUARTERLY = 'QUARTERLY'
export const ACCUM_BIANNUALLY = 'BIANNUALLY'
export const ACCUM_ANNUAL = 'ANNUAL'
export const ACCUM_LAST_360_DAYS = 'LAST-360-DAYS'
export const ACCUM_LAST_360_DAYS_FROM_PREVIOUS_MONTH = 'LAST-360-DAYS-FROM-PREVIOUS-MONTH'
export const ACCUM_BIANNUALLY_TOTAL = 'BIANNUALLY_TOTAL'

export const accumulations = {
  [ACCUM_MONTHLY]: 'MENSUAL',
  [ACCUM_BIMONTHLY]: 'QUINCENAL',
  [ACCUM_QUARTERLY]: 'TRIMESTRAL',
  [ACCUM_BIANNUALLY]: 'SEMESTRAL',
  [ACCUM_ANNUAL]: 'ANUAL',
  [ACCUM_LAST_360_DAYS]: '360 DIAS',
  [ACCUM_LAST_360_DAYS_FROM_PREVIOUS_MONTH]: '360 DIAS MES ANTERIOR',
  [ACCUM_BIANNUALLY_TOTAL]: 'TOTAL SEMESTRE',
}
