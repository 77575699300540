import React, {useContext, useEffect, useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import Checkbox from '../../Atoms/Forms/Checkbox'
import PaymentsRow from './Rows/PaymentsRow'
import Table from '../../Wrappers/Table'
import {useMedia} from '../../../hooks/useMedia'
import {mediaToM, mediaFromM} from '../../../helpers/media-queries'
import {PayrollContext} from '../../../contexts/contexts'
import {getEmployeesHaveNotBeenPaid} from '../../../services/employees'
import useFilterAndSortTable from '../../../hooks/useFilterAndSortTable'
import SummaryPaymentsTableSection from '../../Organisms/Sections/SummaryPaymentsTableSection'
import {WIRE_TRANSFER} from '../../../helpers/payroll'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getLocalCache, getCacheKeyBanks} from '../../../helpers/cache'
import ButtonContainerTableHeader from '../../Organisms/Containers/ButtonContainerTableHeader'
import SearchInputButton from '../SearchInputButton'
import Sort from '../Sort'
import Filter, {ALL_OPTION} from '../Filter'
import PaymentsEmptyState from '../../Organisms/Emptys/PaymentsEmptyState'
import PaySummaryModal from '../../Organisms/Modals/PaySummaryModal'
import Skeleton from 'react-loading-skeleton'

const DEFAULT_SORT_OPTION = {key: 'first_name', value: 'Nombre'}
const DEFAULT_SEARCH_COLS = [
  'identification_number',
  'first_name',
  'middle_name',
  'last_name',
  'surname',
  'payment_method',
  'bank_name',
]

const EmployeesPaymentsTable = ({setActiveOptionPage}) => {
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheBanks = getLocalCache(getCacheKeyBanks(employerSession.code_iso_3))
  const banks = !isNull(cacheBanks) && cacheBanks.data?.banks

  const [employeesToPay, setEmployeesToPay] = useState(0)
  const [totalNetPay, setTotalNetPay] = useState(0)
  const [contractsIDs, setContractIDs] = useState([])
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(WIRE_TRANSFER)
  const [employees, setEmployees] = useState([])

  const toM = useMedia(mediaToM)
  const fromM = useMedia(mediaFromM)

  const {activeProcess} = useContext(PayrollContext)
  const modalCreateDepositPayment = useRef()

  useEffect(() => {
    // if the payment Method is WIRE_TRANSFER only query tb, other wise query all methods
    // ch, ef or tb, because the employer can pay an employee in CHECK or EFECTY in any
    // moment independelly the payment method setting in its contract
    const filter = isEqual(paymentMethod, WIRE_TRANSFER) ? 'tb' : ''

    getEmployeesHaveNotBeenPaid(activeProcess?.id, filter, r => {
      setEmployees(isEmpty(r) ? [] : r)
    })
  }, [activeProcess, paymentMethod])

  const {data, setSortOption, appendFilter, searchValue, setSearchValue, loading} =
    useFilterAndSortTable(employees, DEFAULT_SORT_OPTION, DEFAULT_SEARCH_COLS)

  const appendContract = (id, netPay) => {
    setContractIDs(state => [...state, id])
    setEmployeesToPay(employeesToPay + 1)
    setTotalNetPay(totalNetPay + netPay)
  }

  const removeContract = (id, netPay) => {
    if (isAllChecked) setIsAllChecked(false)

    setContractIDs(state => [...state.filter(i => i !== id)])
    setEmployeesToPay(employeesToPay - 1)
    setTotalNetPay(totalNetPay - netPay)
  }

  const handleOnCheckedIsAll = e => {
    const isChecked = e.target.checked
    setIsAllChecked(isChecked)

    if (isChecked) {
      setEmployeesToPay(employees.length)
      setTotalNetPay(employees.reduce((total, e) => total + e.net_pay, 0))
      setContractIDs(employees.map(e => e.contract_id))

      return
    }

    setEmployeesToPay(0)
    setTotalNetPay(0)
    setContractIDs([])
  }

  const handleGenerateDepositPay = () => {
    modalCreateDepositPayment.current.openModal()
  }

  const handlePaymentMethod = method => {
    // clear the filter BANK if the payment method isn't WIRE_TRANSFER
    if (!isEqual(method, WIRE_TRANSFER)) appendFilter(getOptionFilter('BANK', ALL_OPTION))

    // clear the filter PAYMENT_METHOD if  is WIRE_TRANSFER
    if (isEqual(method, WIRE_TRANSFER))
      appendFilter(getOptionFilter('PAYMENT_METHOD', ALL_OPTION))

    setPaymentMethod(method)

    // reset state when payment method change
    setEmployeesToPay(0)
    setTotalNetPay(0)
    setContractIDs([])
    setIsAllChecked(false)
  }

  const handleSearch = e => {
    const value = e.target.value
    setSearchValue(value)
  }

  const handleChangeSort = option => {
    setSortOption(option)
  }

  const handleChangeFilterPaymentMethod = option => {
    appendFilter(getOptionFilter('PAYMENT_METHOD', option))
  }

  const handleChangeFilterBank = option => {
    appendFilter(getOptionFilter('BANK', option))
  }

  return (
    <>
      <div className="m-cross-end s-flex-gap-12px flex-grow m-main-end s-column m-row s-px-2 s-pb-2 m-px-4 m-pb-4">
        <ButtonContainerTableHeader>
          {/* Búsqueda*/}
          <SearchInputButton
            id="payroll-search"
            value={searchValue}
            onChange={handleSearch}
          />
          <Sort
            options={[
              {key: 'last_name', value: 'Apellido'},
              {key: 'bank_name', value: 'Banco'},
              {key: 'account_type', value: 'Cuenta'},
              {key: 'payment_method', value: 'Método de pago'},
              {key: 'net_pay', value: 'Neto a Pagar'},
              DEFAULT_SORT_OPTION,
            ]}
            defaultSortOption={DEFAULT_SORT_OPTION}
            onSelect={handleChangeSort}
            showContent={fromM}
          />

          {!isEqual(paymentMethod, WIRE_TRANSFER) && (
            <Filter
              label="Método de Pago"
              options={[
                {
                  key: 'payment_method|TRANSFERENCIA BANCARIA',
                  value: 'TRANSFERENCIA BANCARIA',
                },
                {
                  key: 'payment_method|CHEQUE',
                  value: 'CHEQUE',
                },
                {
                  key: 'payment_method|EFECTIVO',
                  value: 'EFECTIVO',
                },
              ]}
              onSelect={handleChangeFilterPaymentMethod}
              showContent={fromM}
            />
          )}

          {isEqual(paymentMethod, WIRE_TRANSFER) && (
            <Filter
              label="Banco"
              options={
                Array.isArray(banks) &&
                banks.map(bank => ({
                  key: `bank_name|${bank.short_name}`,
                  value: bank.short_name,
                }))
              }
              onSelect={handleChangeFilterBank}
              showContent={fromM}
            />
          )}
        </ButtonContainerTableHeader>

        <button
          type="button"
          className="s-order-1 m-order-2 button small"
          disabled={isEqual(employeesToPay, 0)}
          onClick={handleGenerateDepositPay}
        >
          Paga a estos empleados
        </button>
      </div>
      <SummaryPaymentsTableSection
        employeesCount={employeesToPay}
        totalNetPay={totalNetPay}
        paymentMethod={paymentMethod}
        setPaymentMethod={handlePaymentMethod}
        employerSession={employerSession}
      />

      {loading ? (
        <Skeleton height={400} />
      ) : isEmpty(data) ? (
        <PaymentsEmptyState
          description="¡Vaya! no encontramos ningún empleado con estos filtros de busqueda, intenta con
        otros."
        />
      ) : (
        <Table
          className="odd"
          heads={[
            <Checkbox checked={isAllChecked} onChange={handleOnCheckedIsAll} />,
            'Empleado',
            'Método de Pago',
            'Cuenta de banco',
            'Neto a pagar',
          ]}
        >
          {toM && (
            <tr>
              <td>
                <label className="s-cross-center nowrap s-py-8px">
                  <Checkbox checked={isAllChecked} onChange={handleOnCheckedIsAll} />
                  <p className="s-mb-0">Selecciona a todos los empleados</p>
                </label>
              </td>
            </tr>
          )}

          {data.map(employee => (
            <PaymentsRow
              key={employee.contract_id}
              employee={employee}
              employerSession={employerSession}
              append={appendContract}
              remove={removeContract}
              isActive={
                contractsIDs.find(c => isEqual(c, employee.contract_id)) ? true : false
              }
            />
          ))}
        </Table>
      )}

      <PaySummaryModal
        ref={modalCreateDepositPayment}
        contractsIDs={contractsIDs}
        employeesToPay={employeesToPay}
        totalNetPay={totalNetPay}
        paymentMethod={paymentMethod}
        banks={banks}
        setActiveOptionPage={setActiveOptionPage}
      />

      {/* TODO for the MVP the table employees has not pagination */}
      {/* <LoadMoreButton /> */}
    </>
  )
}

function getOptionFilter(filterName, option) {
  if (isEqual(ALL_OPTION.key, option.key)) return {...option, filterName}

  let key = option.key
  let value = option.value

  key = key.split('|')[0].trim()
  value = value.includes(':') ? value.split(':')[1].trim() : value

  return {filterName, key, value}
}

export default EmployeesPaymentsTable
