import React, {useState, forwardRef} from 'react'
import JobChangeForm from '../../Organisms/Forms/JobChangeForm'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import {readBackendDate} from 'helpers/dates'

const JobEditModal = forwardRef(({data, onUpdate}, ref) => {
  const [isResetData, setIsResetData] = useState(false)

  const handleCancel = () => {
    ref.current.closeModal()
  }

  const handleOnChangeOpenModal = isOpen => {
    if (isOpen) return

    // reset state when the modal is closed
    setIsResetData(true)
  }

  return (
    <ModalTemplate ref={ref} className="large" onChangeOpen={handleOnChangeOpenModal}>
      <h3 className="s-left s-mb-0">Cargos</h3>
      <p className="small s-color-light-text s-mb-24px">
        Actualiza el cargo del contrato.
      </p>
      <JobChangeForm
        isResetData={isResetData}
        contractID={data?.contract?.id}
        hireDate={readBackendDate(data?.contract?.hire_date)}
        terminationDate={readBackendDate(data?.contract?.termination_date)}
        onClose={handleCancel}
        onUpdate={onUpdate}
        currentJob={data?.employer_job}
      />
    </ModalTemplate>
  )
})

export default JobEditModal
