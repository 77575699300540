import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import Icon from '../../Atoms/Icon'
import {FloatContainerContext} from '../../../contexts/contexts'
import {isFunction} from '../../../helpers/utils'
import Tag from '../../Atoms/Tag'

const CategoryTypeItem = ({
  icon,
  type,
  caption,
  isActive,
  isSelected,
  isDisabled,
  isComingSoon,
  onClick,
}) => {
  const {setActive} = useContext(FloatContainerContext)

  const handleOnClick = isDisabled => {
    if (isDisabled) return
    setActive(false)
    if (isFunction(onClick)) onClick()
  }

  return (
    <div
      onClick={() => {
        handleOnClick(isDisabled)
      }}
      className={`novelty-type-item hover-change normal-radius s-px-8px s-py-4px cursor-pointer 
      ${isActive && 'is-active'} ${isDisabled && 'is-disabled'}`}
    >
      <header className="header s-color-title s-cross-center nowrap">
        <Icon svg={icon} className="s-mr-4px" />
        <div className="s-mb-0 s-mr-8px flex">
          <span className="s-mr-1">{type}</span>
          {isComingSoon && <Tag text="⭐ Muy Pronto" color="blue" />}
        </div>
        {isSelected && <Icon svg="down" size="12px" />}
      </header>
      <p className="s-mb-0 s-color-light-text smaller">{caption}</p>
    </div>
  )
}

CategoryTypeItem.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
}

CategoryTypeItem.defaultProps = {
  isActive: false,
  isSelected: false,
}

export default CategoryTypeItem
