import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions as concepts} from '../redux/ducks/concepts'

const RESOURCE = '/absence-type-concepts'

export const getAbsenceTypeConcepts = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  
  request.get(
    RESOURCE,
    response => {
      dispatch(concepts.setAbsenceTypeConcept(response))
    },
    error => console.error('absence type concepts:', error)
  )
}
