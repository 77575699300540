import {RequestWithAuthorization} from '../helpers/request'

const RESOURCE = '/help-articles'

export const getBySlug = (slug, callback) => {
  const request = new RequestWithAuthorization()

  request.get(`${RESOURCE}/${slug}`, callback)
}

export const searchHelpArticles = (q, callback) => {
  const request = new RequestWithAuthorization()

  request.get(`${RESOURCE}/search?q=${q}`, callback)
}
