import React from 'react'
import Container from '../../Wrappers/Container'
import GlobalParameterSettingPanelList from './GlobalParameterSettingPanelList'

const GlobalParameterSettingPanel = ({
  list,
}) => {

  return (
    <Container>
      <GlobalParameterSettingPanelList list={list} />
    </Container>
  )
}

export default GlobalParameterSettingPanel
