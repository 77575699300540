/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from 'react'
import {FloatContainerContext} from '../../../contexts/contexts'
import Icon from '../Icon'

const SelectionButtonItem = ({icon, text, onClick}) => {
  const {setActive} = useContext(FloatContainerContext)

  const clickFunction = e => {
    e.preventDefault()
    onClick()
    setActive(false)
  }

  return (
    <div
      onClick={e => clickFunction(e)}
      className="whitespace-nowrap cursor-pointer s-px-1 s-py-1 s-hover-bg-body normal-radius"
    >
      <div className="s-cross-center nowrap">
        {icon && <Icon svg={icon} className="s-mr-4px" />}
        <span>{text}</span>
      </div>
    </div>
  )
}

export default SelectionButtonItem
