import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import ReactTimeAgo from 'react-time-ago'
import { processRoute } from '../../../config/routes'
import {FloatContainerContext} from '../../../contexts/contexts'
import {markNotificationUserAsRead} from '../../../services/notificationUsers'
import Icon from '../../Atoms/Icon'
import {ACTION_RUN, findProcessStage, STAGE_PAYSHEET} from '../../../helpers/payroll'
import {isEqual} from '../../../helpers/utils'

const NotificationItemProcessFatal = ({itemID, unread, content, createdAt}) => {
  const {setActive} = useContext(FloatContainerContext)
  const history = useHistory()
  const dispatch = useDispatch()

  const goToProcess = () => {
    setActive(false)

    history.push({
      pathname: processRoute(content?.year, content?.month, content?.hash),
      hash: findProcessStage(STAGE_PAYSHEET)?.hash,
    })
  }

  const handleOnClick = e => {
    if (unread) {
      dispatch(markNotificationUserAsRead(itemID, goToProcess))
      return
    }

    goToProcess()
  }

  return (
    <div className="s-cross-start nowrap" onClick={handleOnClick}>
      <div className="s-mr-8px">
        <Icon size="24px" color="var(--red-accent)" svg="information-fill" />
      </div>
      <div>
        <p className="small s-mb-0">
          <span role="img" aria-label="emoji">
            🤦
          </span>{' '}
          Tuvimos un problema cuando intentabamos{' '}
          {isEqual(content.action, ACTION_RUN) ? 'calcular' : 'cerrar'} el proceso{' '}
          <span className="small code-font s-color-title">{content.hash}</span>,
          contactanos para ayudarte.
        </p>
        <p className="s-mb-0 smaller s-color-light-text">
          <ReactTimeAgo date={createdAt} />
        </p>{' '}
      </div>
    </div>
  )
}

NotificationItemProcessFatal.propTypes = {}

export default NotificationItemProcessFatal
