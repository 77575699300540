import React, {useState, useContext} from 'react'
import FormInput from '../../Atoms/Templates/FormInput'
import FloatContainer from '../../Wrappers/FloatContainer'
import FloatEmployeeList from '../FloatEmployeeList'
import {isAnyEqual, isEmpty, isEqual, isNull, len} from '../../../helpers/utils'
import {getEmployeesByHashProcess} from '../../../services/employees'
import {PayrollContext} from '../../../contexts/contexts'
import {fullName} from '../../../helpers/string-format'

const SelectEmployeeInput = ({
  id = 'employee_id',
  name = 'employee',
  label = 'Empleado',
  placeholder = 'Escribe para buscar',
  initialValue = '',
  isRequired = false,
  isDisabled = false,
  onSelect = () => {},
  ...rest
}) => {
  const {activeProcess} = useContext(PayrollContext)

  const [value, setValue] = useState(initialValue)
  const [list, setList] = useState([])
  const [listIndex, setListIndex] = useState({active: 0, max: 0})

  const handleFilter = searchValue => {
    getEmployeesByHashProcess(activeProcess.hash, 5, 1, 'first_name', searchValue, response => {
      const size = isEmpty(response) ? 0 : len(response) - 1

      setList(response)
      setListIndex({active: 0, max: size})
    })
  }

  const handleOnChangeInput = newValue => {
    setValue(newValue)

    // clear the employee because the user change the input value and not to selected
    // a new employee from the list
    onSelect(0)

    if (isEmpty(newValue)) {
      setList([])
      setListIndex({active: 0, max: 0})
      return
    }

    handleFilter(newValue)
  }

  const handleKeyPress = e => {
    if (!isAnyEqual(e.key, ['Enter', 'ArrowUp', 'ArrowDown'])) return

    e.preventDefault()

    if (isEqual(e.key, 'Enter')) {
      handleOnSelect(null)
      return
    }

    moveIndexPosition(e.key)
  }

  const moveIndexPosition = move => {
    setListIndex(currentIndex => {
      if (isEqual(currentIndex?.active, 0) && isEqual(move, 'ArrowUp')) {
        return currentIndex
      }

      if (isEqual(currentIndex?.active, currentIndex?.max) && isEqual(move, 'ArrowDown')) {
        return currentIndex
      }

      const newActiveIndex = isEqual(move, 'ArrowDown') ? ++currentIndex.active : --currentIndex.active

      return {active: newActiveIndex, max: currentIndex?.max}
    })
  }

  const handleOnSelect = newIndex => {
    if (isEmpty(list)) return

    const ix = isNull(newIndex) ? listIndex.active : newIndex
    const employeeSelected = list[ix]

    setList([employeeSelected])
    setListIndex({active: 0, max: 0})
    setValue(fullName(employeeSelected))
    onSelect(employeeSelected.contract_id)
  }

  return (
    <div>
      <FloatContainer container={{className: 's-relative z-tooltip'}} isInputTheContainer>
        <FormInput
          type="search"
          isRequired={isRequired}
          label={label}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            handleOnChangeInput(e.target.value)
          }}
          autoComplete="off"
          isDisabled={isDisabled}
          onKeyDown={handleKeyPress}
          {...rest}
        />
        {!isDisabled && (
          <FloatEmployeeList employees={list} onSelectEmployee={handleOnSelect} activeIndex={listIndex?.active} />
        )}
      </FloatContainer>
    </div>
  )
}

export default SelectEmployeeInput
