import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

const AddButton = ({text, onClick, isDisable}) => {
  return (
    <div
      onClick={onClick}
      className={`s-pxy-8px s-cross-center nowrap normal-radius ${
        isDisable
          ? 's-color-light-text cursor-not-allowed s-bg-border'
          : 's-color-blue cursor-pointer hover-change blue'
      }`}
    >
      <Icon svg="circle-plus" className="flex-none s-mr-6px s-mt-2px" size="1.25em" />
      <p className="s-mb-0 whitespace-nowrap">{text}</p>
    </div>
  )
}

AddButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisable: PropTypes.bool,
}

export default AddButton
