import React from 'react'
import PropTypes from 'prop-types'

const SIZES = {
  xs: "smallest",
  s: "small",
  m: "normal",
}

const Badge = ({text, color, isGhost, className, size}) => {
  

  return (
    <p
      className={`badge s-cross-center s-main-center s-mb-0 ${SIZES[size]} small-radius s-px-4px ${color} ${
        isGhost ? 's-bg-white border-xy' : 's-color-white'
      } ${className}`}
    >
      {text}
    </p>
  )
}

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
}

Badge.defaultProps = {
  color: 's-bg-blue',
  className: '',
  text: '',
  size: "xs"
}

export default Badge
