import React, {forwardRef} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import ProcessExecByFrequencyForm from '../Forms/ProcessExecByFrequencyForm'
import ProcessExecByMonthForm from '../Forms/ProcessExecByMonthForm'
import ProcessExecSettlementForm from '../Forms/ProcessExecSettlementForm'
import {PROCESS_EXEC_BY_FREQUENCY, PROCESS_EXEC_BY_MONTH, PROCESS_EXEC_SETTLEMENT} from '../../../helpers/payroll'
import {isEqual} from '../../../helpers/utils'

const NewProcessModal = forwardRef(({data}, ref) => {
  const handleCloseModal = () => {
    ref.current !== null && ref.current.closeModal()
  }

  return (
    <ModalTemplate ref={ref} className="medium">
      <div className="ed-grid form-grid">
        {isEqual(data?.type?.config?.executor, PROCESS_EXEC_BY_FREQUENCY) && (
          <ProcessExecByFrequencyForm data={data} handleCloseModal={handleCloseModal} />
        )}

        {isEqual(data?.type?.config?.executor, PROCESS_EXEC_SETTLEMENT) && (
          <ProcessExecSettlementForm data={data} handleCloseModal={handleCloseModal} />
        )}

        {isEqual(data?.type?.config?.executor, PROCESS_EXEC_BY_MONTH) && (
          <ProcessExecByMonthForm data={data} handleCloseModal={handleCloseModal} />
        )}
      </div>
    </ModalTemplate>
  )
})

export default NewProcessModal
