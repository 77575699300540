import React from 'react'
import IconPanelContainer from './IconPanelContainer'
import PropTypes from 'prop-types'

const EmployeeInformationContainer = ({
  title,
  icon,
  columns,
  additionalAction, 
  editFunction,
  children,
}) => {
  return (
    <IconPanelContainer
      icon={icon}
      title={title}
      additionalAction={additionalAction}
      editFunction={editFunction}
      container={{
        className: `ed-grid s-grid-${columns} row-gap s-gap-12px`,
      }}
    >
      {children}
    </IconPanelContainer>
  )
}

EmployeeInformationContainer.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  editFunction: PropTypes.any,
  additionalAction: PropTypes.node,
  columns: PropTypes.number,
}

EmployeeInformationContainer.defaultProps = {
  title: '',
  icon: '',
  editFunction: undefined,
  columns: 2,
}

export default EmployeeInformationContainer
