import {isZeroDate, readBackendDate, writeBackendDate} from 'helpers/dates'
import {
  KEY_BRANCH_OFFICE_ID,
  KEY_PLACE_LABOR_MUNICIPALITY_ID,
  KEY_ORGANIZATIONAL_STRUCTURE_ID,
  KEY_HIRE_DATE,
  KEY_MONTH_HOURS,
  KEY_EXPIRATION_DATE,
  KEY_TRIAL_DATE,
  KEY_PAY_FREQUENCY_ID,
  KEY_ALTERNATE_CODE,
  _KEY_REST_DAYS,
  KEY_DIMENSIONS,
} from './const'
import {isAnyEqual, isBoolean, isEqual, isNull, isNumber, isObject, isString, isUndefined} from 'helpers/utils'

const NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DATE = 'DATE',
  OBJECT = 'OBJECT'

const FIELDS = [
  {key: KEY_BRANCH_OFFICE_ID, type: NUMBER, isRequired: true},
  {key: KEY_PLACE_LABOR_MUNICIPALITY_ID, type: NUMBER, isRequired: true},
  {key: KEY_ORGANIZATIONAL_STRUCTURE_ID, type: TEXT, isRequired: false},
  {key: KEY_HIRE_DATE, type: DATE, isRequired: true},
  {key: KEY_MONTH_HOURS, type: NUMBER, isRequired: true},
  {key: KEY_EXPIRATION_DATE, type: DATE, isRequired: false},
  {key: KEY_TRIAL_DATE, type: DATE, isRequired: false},
  {key: KEY_PAY_FREQUENCY_ID, type: NUMBER, isRequired: true},
  {key: KEY_ALTERNATE_CODE, type: TEXT, isRequired: false},
  {key: KEY_DIMENSIONS, type: OBJECT, isRequired: false},
]

export default class ContractState {
  constructor(data, branchOfficeDepartmentID) {
    if (!isNumber(branchOfficeDepartmentID)) {
      throw new Error('the branchOfficeID is NaN')
    }

    if (isUndefined(data?.contract)) {
      throw new Error('the data not contain the property contract')
    }

    FIELDS.forEach(field => {
      const value = data?.contract[field.key]
      this.validateFieldType(field.key, field.type, value, field.isRequired)
      this[field.key] = isEqual(field.type, DATE)
        ? readBackendDate(value)
        : isEqual(field.type, OBJECT)
        ? this.getPlainObject(value)
        : value
    })

    this.setRestDays(data?.contract?.mandatory_rest_day, data?.contract?.additional_rest_day)
    this.branch_office_department = branchOfficeDepartmentID

    // descriptions fields
    this.area = data?.area || ''
    this.municipality_description = data?.municipality_description || ''
    this.pay_frequency = data?.pay_frequency || ''
  }

  validateFieldType(name, type, value, isRequired) {
    if (!isAnyEqual(type, [NUMBER, TEXT, DATE, OBJECT])) {
      throw Error(`the data type ${type} is not supported`)
    }

    if (isEqual(type, NUMBER) && !isNumber(value)) {
      throw Error(`the field ${name} is not a NUMBER`)
    }

    if (isEqual(type, TEXT) && !isString(value)) {
      throw Error(`the field ${name} is not a TEXT`)
    }

    if (isEqual(type, DATE) && isRequired && isNull(readBackendDate(value))) {
      throw Error(`the field ${name} is not a DATE`)
    }

    if (isEqual(type, OBJECT) && !isObject(value)) {
      throw Error(`the field ${name} is not an OBJECT`)
    }
  }

  setRestDays(mandatoryIx = 0, additionalIx = 0) {
    if (!isNumber(mandatoryIx) || !isNumber(mandatoryIx)) {
      throw Error('the indexes for rest days must be numbers')
    }

    // [Sun, Mon, Tue, Wed, Thu, Fri, Sat]
    let restDays = [false, false, false, false, false, false, false]
    restDays[mandatoryIx] = true

    if (additionalIx > 0 && additionalIx <= 6) restDays[additionalIx] = true

    this[_KEY_REST_DAYS] = restDays
  }

  isValidExpirationDate(isExtensible) {
    if (!isBoolean(isExtensible)) {
      throw Error('the isExtensible value must be a boolean')
    }

    if (!isExtensible) return true

    return !(
      isNull(this[KEY_EXPIRATION_DATE]) ||
      isUndefined(this[KEY_EXPIRATION_DATE]) ||
      isZeroDate(this[KEY_EXPIRATION_DATE])
    )
  }

  calculateRestDays() {
    return this[_KEY_REST_DAYS].reduce((total, isRestDay) => (isRestDay ? total + 1 : total), 0)
  }

  validateRestDays() {
    const totalRestDays = this.calculateRestDays()

    if (totalRestDays < 1) return 'Mínimo debes tener 1 día de descanso'
    if (totalRestDays > 2) return 'Máximo puedes tener 2 días de descanso'

    return null
  }

  restDaysAreEqual(initialState) {
    if (this[_KEY_REST_DAYS].length !== initialState.length) return false

    for (let i = 0; i < this[_KEY_REST_DAYS].length; i++) {
      if (this[_KEY_REST_DAYS][i] !== initialState[i]) return false
    }

    return true
  }

  preparePayload(initialState) {
    let payload = {}

    FIELDS.forEach(field => {
      const initialStateValue = isEqual(field.type, DATE)
        ? initialState?.[field.key] && initialState?.[field.key].toISOString()
        : isEqual(field.type, OBJECT)
        ? JSON.stringify(initialState?.[field.key])
        : initialState?.[field.key]

      const thisStateValue = isEqual(field.type, DATE)
        ? this?.[field.key] && this?.[field.key].toISOString()
        : isEqual(field.type, OBJECT)
        ? JSON.stringify(this?.[field.key])
        : this?.[field.key]

      if (initialStateValue !== thisStateValue) {
        payload[field.key] = isEqual(field.type, DATE) ? writeBackendDate(this?.[field.key]) : this?.[field.key]
      }
    })

    if (!this.restDaysAreEqual(initialState?.[_KEY_REST_DAYS])) {
      const indexRestDay = this?.[_KEY_REST_DAYS]
        .map((isRestDay, ix) => (isRestDay ? ix : null))
        .filter(isRestDay => isRestDay !== null)

      if (indexRestDay.length === 1) {
        payload.mandatory_rest_day = indexRestDay[0]
        // zero in the field additional_rest_day means as not apply and must be set in the db as null
        payload.additional_rest_day = 0
      }

      if (indexRestDay.length === 2) {
        payload.mandatory_rest_day = indexRestDay[0]
        payload.additional_rest_day = indexRestDay[1]

        // If the additional_rest_day has the index 0 (Sunday), we
        // swap them for ensure us of mandatory_rest_day will be Sunday
        if (payload.additional_rest_day === 0) {
          payload.additional_rest_day = payload.mandatory_rest_day
          payload.mandatory_rest_day = 0
        }
      }
    }

    return payload
  }

  // gets a copy of the instance this, however if it receives a inputObject create the copy for that object
  getPlainObject(inputObj) {
    const result = {}
    const srcObj = isUndefined(inputObj) ? this : inputObj

    for (const key in srcObj) {
      if (typeof srcObj[key] !== 'function') {
        result[key] = srcObj[key]
      }
    }

    return result
  }
}
