import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/employer-banks'

export const getEmployerBanks = callback => {
  const request = new RequestWithEmployerAuthorization()
  request.get(RESOURCE, response => callback(response))
}

export const createEmployerBank = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(
    RESOURCE,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}

export const updateEmployerBank = (id, payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(
    `${RESOURCE}/${id}`,
    payload,
    response => callback(response),
    error => callbackErr(error)
  )
}
