import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../Atoms/Icon'

const SettingOption = ({option, onSelectOption}) => {
  return (
    <article
      className="flex s-mb-0 s-cross-center nowrap"
      onClick={() => onSelectOption(option)}
    >
      <Icon svg={option.icon} className="s-mr-8px" size="24"/>
      <div className="overflow-ellipsis s-100">
        <p className="small s-mb-0 flex s-cross-center nowrap">
          <span className="s-mr-8px overflow-ellipsis">{option.name}</span>
        </p>

        <div className="s-cross-center smaller s-color-light-text">
          <p className="s-mb-0 s-mr-8px">{option.description}</p>
        </div>
      </div>
    </article>
  )
}

SettingOption.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  onSelectOption: PropTypes.func,
}

SettingOption.defaultProps = {
  option: {
    name: '',
    description: '',
    icon: 'settings',
  },
  onSelectOption: () => {},
}

export default SettingOption
