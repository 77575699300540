import React, {useEffect} from 'react'
import PayrollConceptSettingsTab from './PayrollConceptSettingsTab'
import TabsWrapper from '../../Wrappers/TabsWrapper'
import SettingHeader from '../Headers/SettingHeader'

const PayrollConceptSettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SettingHeader title="Conceptos de Nómina" icon="bolt" />

      <div className="lg-cols-2">
        <TabsWrapper defaultActive={1}>
          <PayrollConceptSettingsTab />
        </TabsWrapper>
      </div>
    </>
  )
}

export default PayrollConceptSettings
