import React, {forwardRef, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {isEmpty, isEqual} from '../../../helpers/utils'
import {notifyError} from '../../../services/notification'
import RadioButton from '../../Atoms/Forms/RadioButton'
import MultiSelectEmployeeInput from '../../Molecules/Selects/MultiSelectEmployeeInput'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'

const TYPE_SELECTION = 'SELECTION'
const TYPE_ALL = 'ALL'
const TYPE_PENDING = 'PENDING'

const initialState = {
  send_type: TYPE_PENDING,
  contract_ids: [],
}

const PayslipSendModal = forwardRef(({onSend = () => {}}, ref) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initialState)
  const multiSelectRef = useRef()

  const handleSend = () => {
    if (isEqual(formData.send_type, TYPE_SELECTION)) {
      formData.contract_ids = multiSelectRef.current.getContractIDs()

      if (isEmpty(formData.contract_ids)) {
        dispatch(notifyError('¡Upps! debes seleccionar al menos un empleado'))
        return
      }
    }

    onSend(formData)
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(initialState)
  }

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
      // clean the contract list if the send_type changed
      contract_ids: isEqual(name, 'send_type') ? [] : state.contract_ids,
    }))
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Enviar comprobantes</h3>
      <div className="ed-grid s-grid-1 rows-gap s-mb-2">
        <div>
          <RadioButtonsWrapper label="Empleados" className="s-mb-1">
            <RadioButton
              name="send_type"
              label="Todos"
              value={TYPE_ALL}
              onChange={updateRadioButton}
              checked={isEqual(formData.send_type, TYPE_ALL)}
            />

            <RadioButton
              name="send_type"
              label="Pendientes de envio"
              value={TYPE_PENDING}
              onChange={updateRadioButton}
              checked={isEqual(formData.send_type, TYPE_PENDING)}
            />

            <RadioButton
              name="send_type"
              label="Una selección"
              value={TYPE_SELECTION}
              onChange={updateRadioButton}
              checked={isEqual(formData.send_type, TYPE_SELECTION)}
            />
          </RadioButtonsWrapper>

          {isEqual(formData.send_type, TYPE_SELECTION) && (
            <MultiSelectEmployeeInput
              ref={multiSelectRef}
              placeholder="Escribe para buscar empleados"
            />
          )}
        </div>
      </div>
      <button className="button" onClick={handleSend}>
        Enviar
      </button>
    </ModalTemplate>
  )
})

export default PayslipSendModal
