import {combineReducers} from 'redux'
import notificationReducer from './ducks/notification'
import notificationUsersReducer from './ducks/notificationUsers'
import userReducer, {SIGN_OUT} from './ducks/user'
import employerReducer from './ducks/employer'
import branchOfficesReducer from './ducks/branchOffices'
import payPeriodsReducer from './ducks/payPeriods'
import employerMonthReducer from './ducks/employerMonth'
import workplacesReducer from './ducks/workplaces'
import jobsReducer from './ducks/jobs'
import conceptReducer from './ducks/concepts'
import areasReducer from './ducks/areas'
import accountingInterfaceConfigsReducer from './ducks/accountingInterfaceConfigurations'
import dimensionReducer from './ducks/dimension'
import processTypeConfigReducer from './ducks/processTypeConfig'

const reducers = combineReducers({
  notificationReducer,
  userReducer,
  employerReducer,
  notificationUsersReducer,
  branchOfficesReducer,
  payPeriodsReducer,
  employerMonthReducer,
  workplacesReducer,
  jobsReducer,
  conceptReducer,
  areasReducer,
  accountingInterfaceConfigsReducer,
  dimensionReducer,
  processTypeConfigReducer,
})

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined
  }

  return reducers(state, action)
}

export default rootReducer
