import {actions as notificationActions} from '../redux/ducks/notification'

export const notifyError = message => {
  return notificationActions.set({severity: 'error', message})
}

export const notifySuccessful = message => {
  return notificationActions.set({severity: 'success', message})
}

export const notifyInfo = message => {
  return notificationActions.set({severity: 'info', message})
}

export const notifyWarning = message => {
  return notificationActions.set({severity: 'warning', message})
}
