import React, {useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import EmployeeInformationContainer from '../Containers/EmployeeInformationContainer'
import PanelInformationItem from '../../Atoms/Items/PanelInformationItem'
import {
  getLocalCache,
  getCacheKeyGeneral,
} from '../../../helpers/cache'
import {selectors as workplaceSelector} from '../../../redux/ducks/workplaces'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {isNull, isEqual} from '../../../helpers/utils'
import {hasFounds, pensionerTypes, titleFunds} from 'helpers/socialsecurity'
import { COLOMBIA_CODE, PERU_CODE } from 'helpers/country'
import SocialSecurityContractEditModal from '../Modals/SocialSecurityContractEditModal'
import SocialSecurityFundEditModal from '../Modals/SocialSecurityFundEditModal'

const SocialSecurityPanel = ({data, onUpdate}) => {
  const workplaces = useSelector(workplaceSelector.getAll)
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractTypeEntityType =
    !isNull(cacheGeneral) && cacheGeneral.data?.contract_types_entity_types

  const [formData, setFormData] = useState(data)
  const [fundTypeModal, setFundTypeModal] = useState({id: 0, code: ""})
  const editModal = useRef()
  const socialSecurityFundEditModal = useRef()

  const findWorkplaceName = () => {
    const workplace = workplaces.filter(w => isEqual(w.id, data.contract.workplace_id))
    if (!isEqual(workplace.length, 1)) return null
    return workplace[0].description
  }

  const handleOnEdit = () => {
    setFormData(data)
    editModal.current.openModal()
  }

  const handleOnEditSocialSecurityFund = entityTypeObj => {
    setFundTypeModal(entityTypeObj)
    socialSecurityFundEditModal.current.openModal()
  }

  return (
    hasFounds(data.contract.contract_type_id, contractTypeEntityType) && (
      <>
        <EmployeeInformationContainer
          title="Seguridad social"
          icon="shield"
          // editFunction={handleOnEdit}
        >
          {isEqual(employerSession.code_iso_3, COLOMBIA_CODE) && (
            <>
              <PanelInformationItem
                title="Centro de trabajo ARL"
                text={findWorkplaceName() || ""}
              />
              <PanelInformationItem
                title="¿Es pensionado?"
                text={data.contract.is_pensioner ? 'Sí' : 'No'}
              />
              {data.contract.is_pensioner && (
                <PanelInformationItem
                  title="Tipo Pensionado"
                  text={pensionerTypes.find(t => isEqual(t.id, data?.contract?.mapping?.pila?.subcontributor))?.description}
                />
              )}
              <PanelInformationItem
                title="¿Extranjero sin pensión?"
                text={data.contract.is_foreigner_without_pension ? 'Sí' : 'No'}
              />
            </>
          )}

          {isEqual(employerSession.code_iso_3, PERU_CODE) && (
            <>
              <PanelInformationItem
                title="Tipo Comisión AFP"
                text={data.contract.afp_commission_type}
              />
            </>
          )}

          {/* Social Security Founds */}
          {Array.isArray(data.employee_entity_history) &&
            data.employee_entity_history.map(e => (
              <PanelInformationItem
                key={e.employee_entity_history.id}
                title={titleFunds[e.entity_type_code]}
                text={e.social_security_entity_name}
                hasAction
                handleAction={() =>
                  handleOnEditSocialSecurityFund({
                    id: e.employee_entity_history.entity_type_id,
                    code: e.entity_type_code,
                  })
                }
              />
            ))}

          {/* <PanelInformationItem
        title="¿Colombiano en el extranjero? / ¿Cotiza Salud?"
        text=""
        twoColumns
      /> */}
        </EmployeeInformationContainer>
        <SocialSecurityContractEditModal
          ref={editModal}
          formData={formData}
          setFormData={setFormData}
          onUpdate={onUpdate}
        />

        <SocialSecurityFundEditModal
          ref={socialSecurityFundEditModal}
          data={data}
          onUpdate={onUpdate}
          fundType={fundTypeModal}
        />
      </>
    )
  )
}

export default SocialSecurityPanel
