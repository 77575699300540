import React, {forwardRef, useState} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import ValidatorForm from '../../../helpers/validator'
import {useDispatch} from 'react-redux'
import {notifyError} from '../../../services/notification'
import {readBackendDate, stringFormatDate} from '../../../helpers/dates'

const rules = {
  from: 'date|required',
  to: 'date|required|after_or_equal:from',
}

const fieldNames = {
  from: 'Desde',
  to: 'Hasta',
}

const OccasionalNoveltyReportModal = forwardRef(({initialBeginsAt, initialEndsAt, onGenerate = () => {}}, ref) => {
  const dispatch = useDispatch()
  const initialState = {
    from: readBackendDate(initialBeginsAt),
    to: readBackendDate(initialEndsAt),
  }

  const [formData, setFormData] = useState(initialState)

  const handleGenerate = () => {
    const validator = new ValidatorForm(formData, rules)
    validator.setAttributeNames(fieldNames)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    onGenerate(stringFormatDate(formData.from), stringFormatDate(formData.to))
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(initialState)
  }

  const handleOnChangeFrom = date => {
    setFormData(state => ({
      ...state,
      from: date,
    }))
  }

  const handleOnChangeTo = date => {
    setFormData(state => ({
      ...state,
      to: date,
    }))
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <div className="ed-grid rows-gap">
        <h3>Reporte novedades ocasionales</h3>
        <div className="ed-grid s-grid-2 rows-gap s-mb-1">
          <DatePickerInput
            isRequired
            label={fieldNames.from}
            name="from"
            id="from"
            value={formData.from}
            onChange={handleOnChangeFrom}
          />

          <DatePickerInput
            isRequired
            label={fieldNames.to}
            name="to"
            id="to"
            minDate={formData.from}
            value={formData.to}
            onChange={handleOnChangeTo}
          />
        </div>

        <div>
          <button className="button" onClick={handleGenerate}>
            Descargar
          </button>
        </div>
      </div>
    </ModalTemplate>
  )
})

export default OccasionalNoveltyReportModal
