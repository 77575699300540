import {isEmpty, isString} from './utils'

export const capitalize = text => {
  let wordCapitalized = ''

  if (!isString(text) || isEmpty(text)) {
    return wordCapitalized
  }

  const words = text.split(' ')
  words.forEach(word => {
    wordCapitalized += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' '
  })

  return wordCapitalized.trim()
}

export const fullName = employee => {
  return `${capitalize(employee.first_name)} ${capitalize(
    employee.middle_name
  )} ${capitalize(employee.last_name)} ${capitalize(employee.surname)}`
}
