import React, {useContext} from 'react'
import Employee from './Templates/Employee'
import {PhonePanelContext} from '../../contexts/contexts'
import {useMedia} from '../../hooks/useMedia'
import {mediaToM} from '../../helpers/media-queries'
import Proptypes from 'prop-types'

const SidebarEmployee = ({isActive, employee, onSelectEmployee, tag}) => {
  const {setActive} = useContext(PhonePanelContext)
  const toTablet = useMedia(mediaToM)

  const activePhonePanel = () => {
    if (toTablet) setActive(true)
  }

  return (
    <div
      onClick={activePhonePanel}
      className={`hover-change blue normal-radius cursor-pointer s-px-6px s-py-4px ${
        isActive && 'is-active'
      }`}
    >
      <Employee employee={employee} onSelectEmployee={onSelectEmployee} tag={tag} />
    </div>
  )
}

SidebarEmployee.propTypes = {
  employee: Proptypes.object.isRequired,
  onSelectEmployee: Proptypes.func,
  isActive: Proptypes.bool,
}

SidebarEmployee.defaultProps = {
  employee: {},
  onSelectEmployee: () => {},
  isActive: false,
}

export default SidebarEmployee
