import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {TabsWrapperContext} from '../../../contexts/contexts'
import {isFunction} from '../../../helpers/utils'

const TabTemplate = ({index, children, className, disabled, onClick}) => {
  const {tabActive, setTabActive} = useContext(TabsWrapperContext)
  const isActive = tabActive === index

  const setThisTabActive = () => {
    if (disabled) return
    if (tabActive === index) return

    setTabActive(index)
    if (isFunction(onClick)) onClick()
  }

  return (
    <div
      onClick={setThisTabActive}
      className={`tab-template ${isActive ? 'active' : 'inactive'} ${className}`}
    >
      {children}
    </div>
  )
}

TabTemplate.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

TabTemplate.defaultProps = {
  className: '',
  disabled: false,
}

export default TabTemplate
