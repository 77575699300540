import React from 'react';
import ActionTextButton from "../Templates/ActionTextButton";

const SaveTextButton = ({className, onClick}) => {
  return (
    <ActionTextButton
      className={`s-color-blue blue ${className}`}
      onClick={onClick}
      icon="save"
      text="Guardar"
    />
  );
};

export default SaveTextButton;
