import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = props => {
  const {children, className} = props
  return (
    <div className={`tooltip-template ${className}`}>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  className: '',
}

export default Tooltip;
