import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/occasional-novelties'

export const getOccasionalNoveltiesByHashProcess = (processHash, params, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/processes/${processHash}?${params}`, callback)
}

export const createOccasionalNovelty = (payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, response => callback(response))
}

export const updateOccasionalNovelty = (noveltyID, payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${noveltyID}`, payload, response => callback(response))
}

export const deleteOccasionalNovelty = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${noveltyID}`, callback)
}

export const getOccasionalNoveltyByID = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/${noveltyID}`, callback)
}

export const bulkOccasionalNovelty = payload => {
  const request = new RequestWithEmployerAuthorization()

  let data = new FormData()
  data.append('file', payload.file)
  data.append('pay_period_id', payload.pay_period_id)

  if (payload.process_id) {
    data.append('process_id', payload.process_id)
  }

  return new Promise((resolve, reject) => {
    request.post(`${RESOURCE}/bulk`, data, resolve, reject)
  })
}

export const generateOccasionalNoveltiesReport = (from, to) => {
  const request = new RequestWithEmployerAuthorization()

  const params = new URLSearchParams()
  params.set('begins_at', from)
  params.set('ends_at', to)

  return new Promise((resolve, reject) => {
    request.getFile(`${RESOURCE}/reports?${params.toString()}`, (data, headers) => resolve({data, headers}), reject)
  })
}
