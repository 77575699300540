import React from 'react'
import Icon from '../Icon'
import NotificationDot from '../NotificationDot'
import {useSelector, useDispatch} from 'react-redux'
import {selectors as notificationUserSelectors} from '../../../redux/ducks/notificationUsers'
import {markSummaryNotificationUserAsRead} from '../../../services/notificationUsers'

const NotificationButton = ({className}) => {
  const dispatch = useDispatch()

  const hasNewNotification = useSelector(notificationUserSelectors.getHasNewNotification)

  const handleOnClick = e => {
    if (!hasNewNotification) return

    dispatch(markSummaryNotificationUserAsRead())
  }

  return (
    <div
      className={`notification-button ${hasNewNotification && 'animate'} ${className}`}
      onClick={handleOnClick}
    >
      {hasNewNotification && (
        <NotificationDot className="dot s-absolute s-right-4px s-top-4px" />
      )}
      <span className="wave"></span>
      <span className="wave"></span>
      <span className="wave"></span>
      <Icon className="bell" svg="bell" size="24px" />
    </div>
  )
}

export default NotificationButton
