import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PaymentSkeleton = () => (
  <div className="ed-grid rows-gap">
    <div>
      <Skeleton height={150} />
    </div>
    <div>
      <Skeleton height={150} />
    </div>
    <div>
      <Skeleton height={600} />
    </div>
  </div>
)

export default PaymentSkeleton
