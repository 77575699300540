import React, {useEffect} from 'react'
import TabsWrapper from "../components/Wrappers/TabsWrapper";
import CompanyAboutForm from "../components/Organisms/Forms/CompanyAboutForm";
import { getCache } from '../services/cache'
import { COLOMBIA_CODE } from '../helpers/country'

const CompanyAbout = () => {
  useEffect(() => {
    getCache(COLOMBIA_CODE)
  }, [])

  return (
    <TabsWrapper defaultActive={1}>
      <main className="s-center l-big-section">
        <div className="l-block"/>
        <h1 className="t3 l-block">Háblanos sobre tu empresa</h1>
        <CompanyAboutForm />
      </main>
    </TabsWrapper>
  );
};

export default CompanyAbout;
