import {getShortMonths, readBackendDate} from './dates'
import {capitalize} from './string-format'
import {isNull} from './utils'

export const getPeriodDescription = (beginsAt, endsAt, frequency) => {
  const b = readBackendDate(beginsAt)
  const e = readBackendDate(endsAt)

  if (isNull(b) || isNull(e)) return null

  return `Periodo ${capitalize(frequency)} ${b.getDate().toString().padStart(2, '0')} - ${e.getDate()}`
}

export const getFullPeriodDescription = (beginsAt, endsAt, frequency) => {
  const b = readBackendDate(beginsAt)
  const e = readBackendDate(endsAt)

  if (isNull(b) || isNull(e)) return null

  return `Periodo ${capitalize(frequency)} ${b
    .getDate()
    .toString()
    .padStart(2, '0')} - ${e.getDate()} de ${getShortMonths(e.getMonth())} ${e.getFullYear()}`
}

export const getPeriodRange = (beginsAt, endsAt) => {
  const b = readBackendDate(beginsAt)
  const e = readBackendDate(endsAt)

  return `${b.getDate().toString().padStart(2, '0')} - ${e.getDate()}`
}

export const getPeriodRangeAndFrequency = (beginsAt, endsAt, frequency) => {
  const b = readBackendDate(beginsAt)
  const e = readBackendDate(endsAt)

  if (isNull(b) || isNull(e)) return null

  return `${capitalize(frequency)} ${b.getDate().toString().padStart(2, '0')} - ${e.getDate()}`
}
