import React from 'react'
import FloatContainer from '../../Wrappers/FloatContainer'
import ActionButton from '../../Atoms/Templates/ActionButton'

const DropdownButton = ({
  children,
  icon,
  content,
  isSelected,
  className,
  sizeFloatContainer,
  floatDirection = 'right',
  isMain = false,
  isDisabled = false,
}) => {
  return (
    <div className={`s-relative ${className}`}>
      <FloatContainer
        container={{
          className: `float-container s-top-0 s-${floatDirection}-0 ${
            sizeFloatContainer ? `size-${sizeFloatContainer}` : ''
          }`,
        }}
      >
        <ActionButton
          icon={icon}
          content={content}
          isSelected={isSelected}
          isMain={isMain}
          isDisabled={isDisabled}
        />
        <div className="ed-grid row-gap s-gap-1">{children}</div>
      </FloatContainer>
    </div>
  )
}

export default DropdownButton
