import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/leaves-relation'

export const getLeaveNoveltiesByHashProcess = (processHash, params, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/processes/${processHash}?${params}`, callback)
}

export const createLeaveNovelty = (payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, response => callback(response))
}

export const updateLeaveNovelty = (noveltyID, payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${noveltyID}`, payload, response => callback(response))
}

export const deleteLeaveNovelty = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${noveltyID}`, callback)
}

export const getLeaveNoveltyByID = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/${noveltyID}`, callback)
}
