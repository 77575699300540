import React, {useEffect, useState} from 'react'
import IconPanelContainer from '../Containers/IconPanelContainer'
import UserItem from '../../Molecules/Items/UserItem'
import {getUsersByEmployerID} from '../../../services/user'

const UsersPanel = () => {
  const [userList, setUserList] = useState([])

  useEffect(() => {
    getUsersByEmployerID(response => {
      setUserList(response)
    })
  }, [])

  // const invitedUserModal = useRef()

  // const handleOpenInvitedUserModal = () => {
  //   invitedUserModal.current.openModal()
  // }

  return (
    <>
      <IconPanelContainer icon="settings" title="Usuarios en la empresa">
        <div className="ed-grid rows-gap s-gap-2">
          {/* <AddButton
            onClick={handleOpenInvitedUserModal}
            text="Agrega un usuario"
          /> */}
          {Array.isArray(userList) &&
            userList.map(user => (
              <UserItem
                key={user.id}
                name={user.name}
                email={user.email}
                picture={user.picture}
                isActive={user.is_active}
              />
            ))}
        </div>
      </IconPanelContainer>
      {/* <InvitedUserModal ref={invitedUserModal} /> */}
    </>
  )
}

export default UsersPanel
