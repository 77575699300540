import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import UserAvatar from '../../Atoms/Templates/UserAvatar'
import ReactTimeAgo from 'react-time-ago'
import {getMonth} from '../../../helpers/dates'
import { payrollDateRoute } from '../../../config/routes'
import {FloatContainerContext} from '../../../contexts/contexts'
import {markNotificationUserAsRead} from '../../../services/notificationUsers'

const NotificationItemCloseMonth = ({itemID, content, unread, createdAt}) => {
  const {setActive} = useContext(FloatContainerContext)
  const history = useHistory()
  const dispatch = useDispatch()

  const goToPayrollDashboard = () => {
    setActive(false)

    history.push(payrollDateRoute({year: content?.closed_year, month: content?.closed_moth}))
  }

  const handleOnClick = e => {
    if (unread) {
      dispatch(markNotificationUserAsRead(itemID, goToPayrollDashboard))
      return
    }

    goToPayrollDashboard()
  }

  return (
    <div className="s-cross-start nowrap" onClick={handleOnClick}>
      <div className="s-mr-8px">
        <UserAvatar picture={content?.user_picture} userName={content?.user_name} />
      </div>
      <div>
        <p className="small s-mb-0">
          <span className="s-color-title">{content?.user_name}</span> cerró el mes de{' '}
          {getMonth(content?.closed_moth - 1)} {content?.closed_year}
        </p>
        <p className="s-mb-0 smaller s-color-light-text">
          <ReactTimeAgo date={createdAt} />
        </p>{' '}
      </div>
    </div>
  )
}

NotificationItemCloseMonth.propTypes = {}

export default NotificationItemCloseMonth
