import {isNumber, isEmpty} from './utils'

const formatNumberWithOutDecimal = new Intl.NumberFormat('es-CO', {
  style: 'decimal',
  minimumFractionDigits: 0,
})

const formatNumberWithDecimal = new Intl.NumberFormat('es-CO', {
  style: 'decimal',
  minimumFractionDigits: 2,
})

const currencyByCountry = {
  PER: "S/.",
  COL: "$",
  WRD: "$"
}

export const currencyName = {
  PER: 'SOLES',
  COL: 'PESOS',
  WRD: 'USD',
} 

export const getFormatNumber = (number) => {
  if (!isNumber(number) || isEmpty(number)) {
    return ''
  }

  return formatNumberWithOutDecimal.format(number)
}

export const getFormatCurrency = (number, countryCode, hasDecimal) => {
  const currencySymbol = currencyByCountry[countryCode]

  if (!isNumber(number) || isEmpty(number)) {
    return `${currencySymbol} NaN`
  }

  if (hasDecimal) {
    return `${currencySymbol} ${formatNumberWithDecimal.format(number)}`
  }

  return `${currencySymbol} ${formatNumberWithOutDecimal.format(number)}`
}
