import React, {useEffect, useState, useRef, useContext} from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from 'react-redux'
import ActionButton from '../../Atoms/Templates/ActionButton'
import UserPayslipInformation from '../UserPayslipInformation'
import useObjectURL from '../../../hooks/useObjectURL'
import {getPayslipDetail, generatePayslipReport} from '../../../services/payslip'
import UserPayslipInformationSkeleton from '../Skeleton/UserPayslipInformationSkeleton'
import {PayrollContext} from '../../../contexts/contexts'
import {
  ACTION_RUN,
  EXEC_STATUS_COMPLETED,
  EXEC_STATUS_PENDING,
  PROCESS_PRIMA,
  PROCESS_SETTLEMENT,
  PROCESS_SETTLEMENT_ADJUSTMENT,
  STATUS_FAILURE,
  STATUS_PRE_PAYROLL,
  STATUS_SUCCESSFUL,
} from '../../../helpers/payroll'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import {selectors as userSelectors} from '../../../redux/ducks/user'
import {execProcess} from '../../../services/process'
import PendingProcessModal from '../Modals/PendingProcessModal'
import {notifyError} from '../../../services/notification'
import {isAnyEqual, isEmpty, isEqual, isUndefined} from '../../../helpers/utils'
import DropdownSelect from '../../Molecules/Dropsdown/DropdownSelect'
import DropdownItem from '../../Atoms/Items/DropdownItem'
import PayslipPayroll from '../Payslip/PayslipPayroll'
import PayslipSettlement from '../Payslip/PayslipSettlement'
import Tooltip from '../../Atoms/Templates/Tooltip'
import TooltipContainer from '../Containers/TooltipContainer'
import {useMedia} from '../../../hooks/useMedia'
import {mediaFromM} from '../../../helpers/media-queries'
import Icon from '../../Atoms/Icon'
import PayslipPrima from '../Payslip/PayslipPrima'
import DropdownButton from '../../Molecules/Dropsdown/DropdownButton'
import {generateTaxDetail} from '../../../services/reportsTemp'
import Checkbox from '../../Atoms/Forms/Checkbox'
import {generateEmployerReport} from '../../../services/employerReports'

const OPTION_PAYSLIP_SUPPORT = 'OPTION_PAYSLIP_SUPPORT'
const OPTION_PAYSLIP_DETAIL_CALCULATION = 'OPTION_PAYSLIP_DETAIL_CALCULATION'

const OPTIONS = [
  {
    key: OPTION_PAYSLIP_SUPPORT,
    value: 'Comprobante de pago',
  },
  {
    key: OPTION_PAYSLIP_DETAIL_CALCULATION,
    value: '¿Cómo se calculo?',
  },
]

const REPORT_DETAIL_XLS = 'DETAIL_XLS'
const REPORT_DETAIL_PDF = 'DETAIL_PDF'
const REPORT_DETAIL_RTF = 'DETAIL_RTF'

const PayslipSection = ({onUpdateSummary, contractHash}) => {
  const fromM = useMedia(mediaFromM)

  const dispatch = useDispatch()
  const {setObject, objectURL} = useObjectURL(null)

  const employerSession = useSelector(employerSelectors.getSession)
  const configObj = useSelector(configProcessTypeSelectors.getObj)
  const user = useSelector(userSelectors.get)

  const pendingProcessModal = useRef()
  const downloadLinkPayslipReport = useRef()

  const [activeOption, setActiveOption] = useState(OPTION_PAYSLIP_SUPPORT)
  const [employeeDetail, setEmployeeDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')
  const {activeProcess} = useContext(PayrollContext)
  const [runWithDebugger, setRunWithDebugger] = useState(false)

  const configProcess = configObj[activeProcess?.process_type_code]
  const configReports = configProcess?.config?.reports?.in_payslip[employerSession.code_iso_3]
  const employerReports = employerSession?.params?.reports?.in_payslip[activeProcess?.process_type_code]

  useEffect(() => {
    setIsLoading(true)
    setActiveOption(OPTION_PAYSLIP_SUPPORT)

    getPayslipDetail(activeProcess.hash, contractHash, response => {
      setEmployeeDetail(response)
      setIsLoading(false)
    })
  }, [activeProcess.hash, contractHash])

  const handleOpenPendingProcessModal = () => pendingProcessModal.current.openModal()

  const handleExecContract = () => {
    setIsLoading(true)

    const payload = {
      process_type: activeProcess?.process_type_code,
      action: ACTION_RUN,
      hash: activeProcess?.hash,
      contracts: [employeeDetail?.Data?.contract_id],
      run_with_debugger: runWithDebugger,
    }

    setRunWithDebugger(false)

    execProcess(
      payload,
      response => {
        switch (response?.exec_status) {
          case EXEC_STATUS_PENDING:
            handleOpenPendingProcessModal()
            break

          case EXEC_STATUS_COMPLETED:
            if (isEqual(response?.record?.status, STATUS_SUCCESSFUL)) {
              getPayslipDetail(activeProcess.hash, contractHash, response => {
                setEmployeeDetail(response)
              })

              setIsLoading(false)
              onUpdateSummary()
            }

            if (isEqual(response?.record?.status, STATUS_FAILURE)) {
              setIsLoading(false)
              onUpdateSummary()
            }
            break

          default:
            break
        }
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  const handleDownloadPayslipReport = formatFile => {
    setIsLoadingDownloadReport(true)

    generatePayslipReport(
      activeProcess.hash,
      {contract_ids: [employeeDetail?.Data?.contract_id], format_file: formatFile},
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkPayslipReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoadingDownloadReport(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoadingDownloadReport(false)
      }
    )
  }

  const handleDownloadTaxDetail = () => {
    setIsLoadingDownloadReport(true)

    generateTaxDetail(employeeDetail?.Data?.contract_id, activeProcess.hash)
      .then(({data, headers}) => {
        setObject(data)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkPayslipReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
      })
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  const handleDownloadEmployerReport = codeReport => {
    setIsLoadingDownloadReport(true)

    const payload = {
      report: codeReport,
      process_hash: activeProcess.hash,
      contract_hash: contractHash,
    }

    generateEmployerReport(payload)
      .then(({response, headers}) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'] || codeReport)

        downloadLinkPayslipReport.current.click()

        setObject(null)
        setFileNameDownloaded('')
      })
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  const REPORT_OPTIONS = {
    [REPORT_DETAIL_XLS]: {
      key: REPORT_DETAIL_XLS,
      description: 'Comprobante Excel',
      onClick: () => handleDownloadPayslipReport('xls'),
    },
    [REPORT_DETAIL_PDF]: {
      key: REPORT_DETAIL_PDF,
      description: 'Comprobante PDF',
      onClick: () => handleDownloadPayslipReport('pdf'),
    },
    [REPORT_DETAIL_RTF]: {
      key: REPORT_DETAIL_RTF,
      description: 'Anexo Retención en la fuente',
      onClick: () => handleDownloadTaxDetail(),
    },
  }

  return isLoading ? (
    <UserPayslipInformationSkeleton />
  ) : (
    <section className="n-container-none-padding s-px-2 s-pb-2 m-px-4 m-pb-4">
      <header className="s-main-justify s-cross-center s-py-2 hr" style={{minHeight: '64px'}}>
        <DropdownSelect className="s-mr-1" selected={OPTIONS.find(option => isEqual(option.key, activeOption))?.value}>
          {OPTIONS.map(option => {
            if (isEqual(option.key, OPTION_PAYSLIP_DETAIL_CALCULATION) && activeProcess.is_migrated) return null

            return (
              <DropdownItem
                key={option.key}
                text={option.value}
                isActive={isEqual(option.key, activeOption)}
                onClick={() => {
                  setActiveOption(option.key)
                }}
              />
            )
          })}
        </DropdownSelect>

        <div className="s-cross-center">
          {isEqual(activeProcess?.status, STATUS_PRE_PAYROLL) && (
            <>
              {user?.is_staff && (
                <Checkbox
                  label={
                    <span className="s-cross-center">
                      <Icon svg="bug" color="var(--alt-red-accent)" />
                    </span>
                  }
                  onChange={e => setRunWithDebugger(e.target.checked)}
                  className="s-mr-1"
                />
              )}
              <TooltipContainer>
                <ActionButton
                  isMain
                  content={fromM ? 'Calcula' : ''}
                  icon="bolt"
                  className="s-mr-1"
                  onClick={handleExecContract}
                />
                <Tooltip className="whitespace-nowrap">
                  <span>Calcula este contrato</span>
                </Tooltip>
              </TooltipContainer>
            </>
          )}

          {employeeDetail?.Data?.total?.has_email_sent && (
            <TooltipContainer>
              <div className="s-bg-blue-500 circle-radius s-w-20px s-h-20px s-mr-1 s-cross-center s-main-center">
                <Icon svg="send-2" color="white" size="14px" />
              </div>
              <Tooltip className="whitespace-nowrap">
                <span>Correo enviado</span>
              </Tooltip>
            </TooltipContainer>
          )}

          {/* Reports */}
          <DropdownButton
            icon="document"
            content={fromM ? 'Reportes' : ''}
            sizeFloatContainer="m"
            isDisabled={isLoadingDownloadReport}
          >
            {/* Reports by process type */}
            {Array.isArray(configReports) &&
              configReports.map(opt => {
                const report = REPORT_OPTIONS[opt]
                if (isUndefined(report)) return null

                return <DropdownItem key={report.key} text={report.description} onClick={report.onClick} />
              })}

            {/* Custom reports of the employer */}
            {Array.isArray(employerReports) &&
              employerReports.map(code => {
                const report = employerSession?.params?.reports?.config[code]
                if (isUndefined(report)) return null
                if (!showReport(report, employeeDetail?.Data)) return null

                return (
                  <DropdownItem
                    key={code}
                    text={report?.name || code}
                    onClick={() => handleDownloadEmployerReport(code)}
                  />
                )
              })}
          </DropdownButton>
        </div>
      </header>

      {isEqual(activeOption, OPTION_PAYSLIP_SUPPORT) && <hr className="s-mb-24px m-mb-64px " />}

      {/* Comprobante de Pago */}

      {isEqual(activeOption, OPTION_PAYSLIP_SUPPORT) &&
        (isEqual(activeProcess?.process_type_code, PROCESS_SETTLEMENT) ||
          isEqual(activeProcess?.process_type_code, PROCESS_SETTLEMENT_ADJUSTMENT)) && (
          <PayslipSettlement employerSession={employerSession} {...employeeDetail} />
        )}

      {isEqual(activeOption, OPTION_PAYSLIP_SUPPORT) && isEqual(activeProcess?.process_type_code, PROCESS_PRIMA) && (
        <PayslipPrima employerSession={employerSession} {...employeeDetail} />
      )}

      {/* If the process is not SETTLEMENT or PRIMA then we always use the PAYROLL template */}
      {isEqual(activeOption, OPTION_PAYSLIP_SUPPORT) &&
        !isAnyEqual(activeProcess?.process_type_code, [
          PROCESS_SETTLEMENT,
          PROCESS_SETTLEMENT_ADJUSTMENT,
          PROCESS_PRIMA,
        ]) && <PayslipPayroll employerSession={employerSession} {...employeeDetail} />}

      {/* Detalle del cálculo */}

      {isEqual(activeOption, OPTION_PAYSLIP_DETAIL_CALCULATION) && (
        <UserPayslipInformation employerSession={employerSession} {...employeeDetail} />
      )}

      <PendingProcessModal ref={pendingProcessModal} />

      {/* This link is hidden, we only use for download the payslip PDF */}
      <a ref={downloadLinkPayslipReport} href={objectURL} download={fileNameDownloaded} className="hidden">
        Comprobante Nomina
      </a>
    </section>
  )
}

function showReport(config, data) {
  // if the report has not constraint then show it
  if (isUndefined(config?.constraint) || isEmpty(config?.constraint)) return true

  // if the report has constraint then validate it
  switch (config.constraint) {
    case 'mutual-agreement':
      return isEqual(data?.termination_reason, 'TERMINACION POR MUTUO ACUERDO')

    default:
      break
  }

  return true
}

PayslipSection.propTypes = {
  onFailureExec: PropTypes.func,
}

PayslipSection.defaultProps = {
  onFailureExec: () => {},
}

export default PayslipSection
