import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/vacations-relation'

export const getVacationNoveltiesByHashProcess = (processHash, params, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/processes/${processHash}?${params}`, callback)
}

export const createVacationNovelty = (payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, response => callback(response))
}

export const updateVacationNovelty = (noveltyID, payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${noveltyID}`, payload, response => callback(response))
}

export const deleteVacationNovelty = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${noveltyID}`, callback)
}

export const getVacationNoveltyByID = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/${noveltyID}`, callback)
}
