import {createContext} from 'react'

export const MainSidebarContext = createContext()

export const PhonePanelContext = createContext()

export const FloatContainerContext = createContext()

export const TabsWrapperContext = createContext()

export const AccordionWrapperContext = createContext({
  currentAccordionActive: null,
  setCurrentAccordionActive: () => {},
})

export const TableTemplateContext = createContext()

export const FormDataContext = createContext()

export const PayrollContext = createContext()

export const EmployeeContext = createContext()
