import {RequestWithEmployerAuthorization} from '../helpers/request'
import {actions} from '../redux/ducks/dimension'

const RESOURCE = '/dimension-relations'

export const getDimensions = () => dispatch => {
  const request = new RequestWithEmployerAuthorization()
  request.get(
    RESOURCE,
    response => {
      dispatch(actions.set(response))
      dispatch(actions.setMapping(getDimensionMappingObj(response)))
    },
    error => console.error('dimension employers:', error)
  )
}

const getDimensionMappingObj = dimensions => {
  let objMapping = {}

  if (!Array.isArray(dimensions)) return objMapping

  dimensions.forEach(record => {
    objMapping[`DIMENSION_${record?.dimension?.code}`] = record?.dimension?.description

    if (!Array.isArray(record?.values)) return
    record.values.forEach(
      value => (objMapping[`${record?.dimension?.code}_${value?.code}`] = value?.description?.toUpperCase())
    )
  })

  return objMapping
}
