import React, {useRef} from 'react'
import {useSelector} from 'react-redux'
import EmployeeInformationContainer from '../Containers/EmployeeInformationContainer'
import PanelInformationItem from '../../Atoms/Items/PanelInformationItem'
import {backendWeekDays, shortFormatDate} from '../../../helpers/dates'
import {isEmpty, isEqual, isNull, isObject} from '../../../helpers/utils'
import {getFormatCurrency} from '../../../helpers/format-number'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {selectors as branchOfficeSelector} from '../../../redux/ducks/branchOffices'
import {selectors as dimensionSelector} from '../../../redux/ducks/dimension'
import {getLocalCache, getCacheKeyGeneral} from '../../../helpers/cache'
import ContractEditModal from '../Modals/ContractEditModal'
import SalaryEditModal from '../Modals/SalaryEditModal'
import JobEditModal from '../Modals/JobEditModal'
import {PERU_CODE} from 'helpers/country'
import SAP_LOGO from '../../../static/images/utils/SAP-logo.png'

const ContractPanel = ({data, onUpdate}) => {
  const employerSession = useSelector(employerSelectors.getSession)
  const branchOffices = useSelector(branchOfficeSelector.getAll)
  const mappingDimensions = useSelector(dimensionSelector.getMapping)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const reasons = !isNull(cacheGeneral) && cacheGeneral.data?.termination_reasons
  const contractTypes = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types
  const dimensions = getDimensions(data?.contract?.dimensions, mappingDimensions)

  const editModal = useRef()
  const salaryEditModal = useRef()
  const jobEditModal = useRef()

  const handleOnEdit = () => editModal.current.openModal()
  const handleOnEditSalary = () => salaryEditModal.current.openModal()
  const handleOnEditJob = () => jobEditModal.current.openModal()
  const handleOnEditContractType = () => console.log('Editando')

  return (
    <>
      <EmployeeInformationContainer title="Contrato" icon="document" editFunction={handleOnEdit}>
        <PanelInformationItem
          title="Código"
          text={data.contract.hash}
          classesText="code-font"
          colorText="s-color-title"
        />

        { !isEmpty(data?.contract?.partner_alternate_code) && 
          <PanelInformationItem
          title={
            <span className="s-cross-center">
              <span className="s-mr-1">Código</span>
              <img src={SAP_LOGO} alt="SAP logo" style={{width: "2rem"}}/>
            </span>
          }
          text={data?.contract?.partner_alternate_code}
          classesText="code-font"
          colorText="s-color-title"
          />       
        }

        <PanelInformationItem
          title="Tipo"
          text={data.contract_type}
          // hasAction
          // handleAction={handleOnEditContractType}
        />

        <PanelInformationItem
          title="Sucursal"
          text={
            Array.isArray(branchOffices) &&
            branchOffices.find(b => b.id === data.contract.branch_office_id)?.description
          }
        />
        <PanelInformationItem title="Ciudad de trabajo" text={data.municipality_description.toUpperCase()} />
        <PanelInformationItem title="Área" text={data.area.toUpperCase()} />
        <PanelInformationItem title="Fecha de ingreso" text={shortFormatDate(data.contract.hire_date)} />
        <PanelInformationItem
          title="Fecha de finalización"
          text={shortFormatDate(data.contract.expiration_date) || ''}
        />
        <PanelInformationItem title="Período de prueba" text={shortFormatDate(data.contract.trial_period_date) ?? ''} />
        <PanelInformationItem title="Frecuencia de pago" text={data.pay_frequency} />
        <PanelInformationItem
          title="Días de descanso"
          text={getRestDays(data?.contract?.mandatory_rest_day, data?.contract?.additional_rest_day)}
        />
        <PanelInformationItem title="Tipo de salario" text={data.salary_type} />
        <PanelInformationItem
          title="Salario"
          text={`${getFormatCurrency(
            data.salary,
            employerSession.code_iso_3,
            !employerSession.not_include_decimals_in_calculation
          )}`}
          hasAction
          handleAction={handleOnEditSalary}
        />
        <PanelInformationItem title="Cargo" text={data.employer_job} hasAction handleAction={handleOnEditJob} />
        <PanelInformationItem title="Código alterno" text={`${data.contract.alternate_code}`} />
        <PanelInformationItem title="Horas trabajadas" text={`${data.contract.month_hours}`} />

        {isEqual(employerSession.code_iso_3, PERU_CODE) && (
          <>
            <PanelInformationItem
              title="¿Tiene asignación familiar?"
              text={data.contract.has_family_allowance ? 'Sí' : 'No'}
            />
          </>
        )}

        {!isEqual(data.contract.termination_reason_id, 0) && (
          <>
            <PanelInformationItem title="Fecha de terminación" text={shortFormatDate(data.contract.termination_date)} />
            <PanelInformationItem
              title="Motivo de terminación"
              text={
                !reasons ? '' : reasons.filter(r => isEqual(r.id, data.contract.termination_reason_id))[0].description
              }
            />
          </>
        )}

        {Array.isArray(dimensions) &&
          dimensions.map(dimension => (
            <PanelInformationItem key={dimension.id} title={dimension.label} text={dimension.value} />
          ))}
      </EmployeeInformationContainer>
      <ContractEditModal ref={editModal} data={data} onUpdate={onUpdate} contractTypes={contractTypes} />
      <SalaryEditModal ref={salaryEditModal} data={data} onUpdate={onUpdate} />
      <JobEditModal ref={jobEditModal} data={data} onUpdate={onUpdate} />
    </>
  )
}

const getRestDays = (mandatory, additional) => {
  let contractRestDays = [backendWeekDays[mandatory].toUpperCase()]

  if (!isEqual(additional, 0)) {
    contractRestDays.push(backendWeekDays[additional].toUpperCase())
  }

  return contractRestDays.join()
}

const getDimensions = (contractDimensions, mapping) => {
  let dimensions = []
  if (!isObject(contractDimensions) || !isObject(mapping)) return dimensions

  for (let key in contractDimensions) {
    const valueCode = contractDimensions[key]
    dimensions.push({
      id: key,
      label: mapping[`DIMENSION_${key}`],
      value: valueCode + ' - ' + mapping[`${key}_${valueCode}`],
    })
  }

  return dimensions
}

export default ContractPanel
