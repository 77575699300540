import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SidebarEmployeeSkeleton = () => (
  <>
    <EmployeeItemSkeleton />
    <EmployeeItemSkeleton />
    <EmployeeItemSkeleton />
    <EmployeeItemSkeleton />
    <EmployeeItemSkeleton />
    <EmployeeItemSkeleton />
    <EmployeeItemSkeleton />
  </>
)

const EmployeeItemSkeleton = () => {
  return (
    <div className="flex s-mb-0 s-cross-center nowrap">
      <div className="s-mr-8px">
        <Skeleton circle={true} height={40} width={40} />
      </div>
      <div className="overflow-ellipsis s-100">
        <Skeleton height={45} width={500} />
      </div>
    </div>
  )
}

export default SidebarEmployeeSkeleton
