import {useState, useEffect} from 'react'
import {isEmpty, isEqual, isNumber, isString} from '../helpers/utils'
import {NONE_OPTION} from '../components/Molecules/Sort'
import {ALL_OPTION} from '../components/Molecules/Filter'

const useFilterAndSortTable = (initialData, defaultSortOption, defaultSearchCols) => {
  const [sortOption, setSortOption] = useState(defaultSortOption)
  const [filterOptions, setFilterOptions] = useState([])
  const [searchCols, setSearchCols] = useState(defaultSearchCols)
  const [searchValue, setSearchValue] = useState('')
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)

  const appendFilter = newFilter => {
    setFilterOptions([
      ...filterOptions.filter(f => f.filterName !== newFilter.filterName),
      newFilter,
    ])
  }

  useEffect(() => {
    function run(inputData) {
      setLoading(true)

      const newData = inputData
        .filter(item => {
          let isFiltered = true
          for (let i in filterOptions) {
            const currentFilter = filterOptions[i]
            if (isEqual(currentFilter.key, ALL_OPTION.key)) continue

            if (!isEqual(item[currentFilter.key], currentFilter.value)) {
              isFiltered = false
              break
            }
          }

          return isFiltered
        })
        // search
        .filter(item => {
          if (isEmpty(searchValue) || !isString(searchValue)) return true

          return searchCols.some(col =>
            item[col]?.toLowerCase().includes(searchValue.toLowerCase())
          )
        })
        .sort((a, b) => {
          if (isEqual(sortOption.key, NONE_OPTION.key)) return 1

          const valueA = a[sortOption.key]
          const valueB = b[sortOption.key]

          if (isNumber(valueA) && isNumber(valueB)) return valueA - valueB

          if (isString(valueA) && isString(valueB)) {
            return valueA.toLowerCase() < valueB.toLowerCase() ? -1 : 1
          }

          return 1
        })

      setData(newData)
      setLoading(false)
    }

    run(initialData)
  }, [initialData, sortOption, filterOptions, searchCols, searchValue])

  return {
    data,
    loading,
    setSortOption,
    appendFilter,
    searchValue,
    setSearchCols,
    setSearchValue,
  }
}

export default useFilterAndSortTable
