import React, {useRef, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import AddButton from '../../Atoms/Buttons/AddButton'
import IconPanelContainer from '../Containers/IconPanelContainer'
import BankItem from '../../Molecules/Items/BankItem'
import NewBankModal from '../Modals/NewBankModal'
import {getEmployerBanks} from '../../../services/employerBanks'
import {isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getCacheKeyBanks, getLocalCache} from '../../../helpers/cache'

const initialState = {
  id: 0,
  bank_id: 0,
  account_number: '',
  account_type: '',
  is_active: false,
}

const BanksPanel = () => {
  const newBankModal = useRef()

  const [employerBankList, setEmployerBankList] = useState([])
  const [formData, setFormData] = useState(initialState)

  const employerSession = useSelector(employerSelectors.getSession)
  const cacheBanks = getLocalCache(getCacheKeyBanks(employerSession.code_iso_3))
  const banksCache = !isNull(cacheBanks) && cacheBanks.data?.banks

  useEffect(() => {
    getEmployerBanks(response => {
      setEmployerBankList(response)
    })
  }, [])

  const handleOnSuccessfulNewBank = newBank => {
    setEmployerBankList([...employerBankList, newBank])
  }

  const handleOnSuccessfulEditBank = bank => {
    const employerBanks = employerBankList.filter(
      employerBank => employerBank.id !== bank.id
    )
    employerBanks.push(bank)
    employerBanks.sort((a, b) => a.id - b.id)

    setEmployerBankList([...employerBanks])
  }

  const handleOnEdit = bankItem => {
    setFormData(bankItem)
    newBankModal.current.openModal()
  }

  const handleOnAdd = () => {
    setFormData(initialState)
    newBankModal.current.openModal()
  }
  return (
    <>
      <IconPanelContainer icon="bank" title="Cuentas bancarias">
        <div className="ed-grid rows-gap s-gap-2">
          <AddButton onClick={handleOnAdd} text="Agrega una cuenta" />
          {Array.isArray(employerBankList) &&
            employerBankList.map(bankItem => (
              <BankItem
                key={bankItem.id}
                bankItem={bankItem}
                banksCache={banksCache}
                onEdit={handleOnEdit}
              />
            ))}
        </div>
      </IconPanelContainer>
      <NewBankModal
        ref={newBankModal}
        onAdd={handleOnSuccessfulNewBank}
        onEdit={handleOnSuccessfulEditBank}
        banksCache={banksCache}
        formData={formData}
        setFormData={setFormData}
      />
    </>
  )
}

export default BanksPanel
