import React from 'react'
import PropTypes from 'prop-types'

const LoadMoreButton = ({description, onClick}) => {
  return (
    <div className="s-main-center s-pt-16px" onClick={onClick}>
      <p
        role="button"
        aria-pressed="false"
        className="s-center s-mb-16px m-mb-24px lg-mb-32px s-color-blue hover-change blue s-px-1 small-radius cursor-pointer"
      >
        {description}
      </p>
    </div>
  )
}

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  description: PropTypes.string,
}

LoadMoreButton.defaultProps = {
  onClick: () => {},
  description: 'Carga más',
}

export default LoadMoreButton
