import React, {useState, forwardRef} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import {PROCESS_EXEC_SETTLEMENT} from '../../../helpers/payroll'
import {isEqual} from '../../../helpers/utils'
import ProcessEditExecSettlementForm from '../Forms/ProcessEditExecSettlementForm'

const EditProcessModal = forwardRef(({process, config, setDashboardData}, ref) => {
  const [signalOpen, setSignalOpen] = useState(null)

  const handleCloseModal = () => {
    ref.current !== null && ref.current.closeModal()
  }

  const signalOpenModal = isOpen => {
    if (!isOpen) return

    setSignalOpen(new Date())
  }

  return (
    <ModalTemplate ref={ref} className="medium" onChangeOpen={signalOpenModal}>
      <div className="ed-grid form-grid">
        {isEqual(config?.executor, PROCESS_EXEC_SETTLEMENT) && (
          <ProcessEditExecSettlementForm
            data={process}
            setDashboardData={setDashboardData}
            handleCloseModal={handleCloseModal}
            signalOpen={signalOpen}
          />
        )}
      </div>
    </ModalTemplate>
  )
})

export default EditProcessModal
