import React from 'react'
import EmptyBirthday from '../../../static/images/stories/birthday.svg'

const BirthdayEmptyState = () => {
  return (
    <div className="s-column s-cross-center s-main-center s-center">
      <img
        className="s-mb-16px"
        src={EmptyBirthday}
        alt="Pintor en blanco"
        width="200px"
        height="200px"
      />
      <p className="small s-mb-0 s-color-light-text">
        No hay empleados que cumplan este mes.
      </p>
    </div>
  )
}

export default BirthdayEmptyState
