import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {mediaFromLg, mediaFromM} from '../../helpers/media-queries'
import CarouselButton from '../Atoms/Buttons/CarouselButton'

const CarouselGrid = props => {
  // Breakpoints para cambiar las columnas del carrusel
  const {breakpoints, children, className, gap} = props

  // Contenedores
  const gridContainer = useRef()
  const translateContainer = useRef()
  const carouselContainer = useRef()

  // Estados
  // Hijos del carrusel
  const [length, setLength] = useState(0)
  // Cantidad de traslado del carrusel
  const [translate, setTranslate] = useState(0)
  // Cantidad de elementos visibles en el grid del carrusel
  const [visibleElements, setVisibleElements] = useState(0)
  // Almacenar cuántos elementos ocultos hay en cada lado del carrusel
  const [aviableElements, setAviableElements] = useState({left: 0, right: 0})
  // Unidad de cantidad que se usará para trasladar el carrusel
  const [unitToTranslate, setUnitToTranslate] = useState(0)

  // Obtenemos los elementos que estarán visibles en el carrusel desde las propiedades
  const getVisibleElements = () => {
    if (matchMedia(mediaFromM).matches) {
      if (matchMedia(mediaFromLg).matches) {
        setVisibleElements(breakpoints.lg)
      } else {
        setVisibleElements(breakpoints.m)
      }
    } else {
      setVisibleElements(breakpoints.s)
    }
  }

  // Fijamos las elementos visibles que queremos en el grid de lcarrusel
  const setCarouselGrid = () => {
    setLength(children.length)
    getVisibleElements()

    // Variables necesarias para armar el grid
    // los estilos se encuentran en _carousel-grid.scss
    const initialValues = `
      --gap     : ${gap};
      --columns : ${length};
      --width   : ${(length / visibleElements) * 100}%;
    `

    // Fijamos el grid de elementos visibles
    gridContainer.current.setAttribute('style', initialValues)
    // Obtenemos la unidad que usaremos para trasladar
    // Viene siendo lo que mide cada elemento en el grid
    setUnitToTranslate(100 / visibleElements)
    // Obtenemos la cantidad de elementos que hay a la izquierda del carrusel
    setAviableElements({
      ...aviableElements,
      right: visibleElements >= length ? 0 : length - visibleElements,
    })
  }

  useEffect(() => {
    if (gridContainer && carouselContainer && translateContainer) {
      setCarouselGrid()
      window.addEventListener('resize', setCarouselGrid)
      return () => window.removeEventListener('resize', setCarouselGrid)
    }
  }, [length, visibleElements, children])

  const nextCarousel = () => {
    // Valida si la cantidad de elementos disponibles a la derecha es mayor que los elemenetos visibles
    // Si sí, no debemos hacer un cálculo, sólo mover el contenedor 100% y actualizar los elementos visibles y disponibles
    if (aviableElements.right > visibleElements) {
      setTranslate(translate - 100)
      setAviableElements({
        left: aviableElements.left + visibleElements,
        right: aviableElements.right - visibleElements,
      })
    }
    // En caso de que no haya suficientes elementos, se hace un cálculo para saber cuántas unidades se moverá en lugar del 100%
    else {
      setTranslate(translate - unitToTranslate * aviableElements.right)
      setAviableElements({
        left: aviableElements.left + aviableElements.right,
        right: 0,
      })
    }
  }

  // Lo mismo que la función de arriba, pero a la inversa
  const prevCarousel = () => {
    if (aviableElements.left > visibleElements) {
      setTranslate(translate + 100)
      setAviableElements({
        right: aviableElements.right + visibleElements,
        left: aviableElements.left - visibleElements,
      })
    } else {
      setTranslate(translate + unitToTranslate * aviableElements.left)
      setAviableElements({
        right: aviableElements.right + aviableElements.left,
        left: 0,
      })
    }
  }

  return (
    <div className={`carousel-grid s-cross-center nowrap s-flex-gap-4px ${className}`}>
      {/* Botón anterior */}
      {aviableElements.left !== 0 && <CarouselButton icon="arrow-circle-left" onClick={prevCarousel} />}
      {/* Contenedor encargado de ocultar el contenido desbordante */}
      <div className="overflow s-overflow-x-hidden" ref={carouselContainer}>
        {/* Contenedor que se trasladará para mostrar el resto de los elementos*/}
        <div
          ref={translateContainer}
          style={{
            transform: `translateX(${translate}%)`,
          }}
          className="translate transform-transition"
        >
          {/* Grid de elementos*/}
          <div className="grid" ref={gridContainer}>
            {children}
          </div>
        </div>
      </div>
      {/* Botón siguiente */}
      {aviableElements.right !== 0 && <CarouselButton icon="arrow-circle-right" onClick={nextCarousel} />}
    </div>
  )
}

CarouselGrid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  gap: PropTypes.string,
  breakpoints: PropTypes.shape({
    lg: PropTypes.number.isRequired,
    m: PropTypes.number.isRequired,
    s: PropTypes.number.isRequired,
  }).isRequired,
}

CarouselGrid.defaultProps = {
  className: '',
  gap: '2rem',
}

export default CarouselGrid
