import React, {useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import TableRow from '../../../Wrappers/TableRow'
import Employee from '../../Templates/Employee'
import Concept from '../../Templates/Concept'
import TableOptionsButton from '../../TableOptionsButton'
import {PayrollContext} from '../../../../contexts/contexts'
import {isEqual} from '../../../../helpers/utils'
import {EDIT, SHOW, STATUS_HISTORY} from '../../../../helpers/payroll'
import DeleteModal from '../../../Organisms/Modals/DeleteModal'
import {initialStateAction} from '../../../Organisms/Tables/NoveltyTable'

const OcassionalNoveltyRow = ({setAction, novelty, onDelete}) => {
  const {activeProcess} = useContext(PayrollContext)
  const isHistory = isEqual(activeProcess?.status, STATUS_HISTORY)
  const deleteModal = useRef()

  const handleOpenDeleteModal = () => {
    deleteModal.current.openModal()
  }

  const handleSee = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setAction({
      type: SHOW,
      noveltyID: novelty.id,
    })
  }

  const handleEdit = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setAction({
      type: EDIT,
      noveltyID: novelty.id,
    })
  }

  const handleDelete = () => {
    handleOpenDeleteModal()
    setAction(initialStateAction)
  }

  const handleOnClick = () => {
    onDelete(novelty.id)
    setAction(initialStateAction)
    deleteModal.current.closeModal()
  }

  return (
    <>
      <TableRow>
        <Employee employee={novelty} />
        <Concept concept={novelty} />
        <p className="s-mb-0 m-center">{novelty.quantity}</p>
        <p className="s-mb-0 m-right">{novelty.value}</p>

        {isHistory ? (
          <TableOptionsButton seeFunction={handleSee} />
        ) : (
          <TableOptionsButton deleteFunction={handleDelete} editFunction={handleEdit} />
        )}
      </TableRow>
      <DeleteModal ref={deleteModal} deleteFunction={handleOnClick} />
    </>
  )
}

OcassionalNoveltyRow.propTypes = {
  novelty: PropTypes.shape({}).isRequired,
  setAction: PropTypes.func,
}

OcassionalNoveltyRow.defaultProps = {
  novelty: PropTypes.shape({}),
  setAction: () => {},
}

export default OcassionalNoveltyRow
