import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {TabsWrapperContext} from '../../contexts/contexts'

const PanelTemplate = ({index, children, className, onlyHidden}) => {
  const {tabActive} = useContext(TabsWrapperContext)
  const isActive = tabActive === index
  return isActive || onlyHidden ? (
    <div className={`${className} ${onlyHidden && !isActive ? 'hidden' : 'show'}`}>{children}</div>
  ) : null
}

PanelTemplate.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  onlyHidden: PropTypes.bool,
}

PanelTemplate.defaultProps = {
  className: '',
  onlyHidden: false,
}

export default PanelTemplate
