import {RequestWithAuthorization} from '../helpers/request'

const RESOURCE = '/municipalities'

export const searchMunicipality = (deparmentID, description, callback, callbackErr) => {
  const request = new RequestWithAuthorization()
  request.get(
    `${RESOURCE}/departments/${deparmentID}/search/descriptions/${description}`,
    response => callback(response),
    error => callbackErr(error)
  )
}
