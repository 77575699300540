import React, {useRef} from 'react'
import BirthdayEmptyState from '../Emptys/BirthdayEmptyState'
import Container from '../../Wrappers/Container'
import {isEmpty, isEqual, len} from '../../../helpers/utils'
import {getMonth} from '../../../helpers/dates'
import {joinRoute} from '../../../helpers/routes'
import {getEmployeesBirthday} from '../../../services/employees'
import EmployeeBirthday from '../../Molecules/Templates/EmployeeBirthday'
import {useHistory} from 'react-router-dom'
import routes from '../../../config/routes'
import useFetchWithConditions from '../../../hooks/useFetchWithConditions'

const DashboardBirthday = ({month, limitEmployees}) => {
  const history = useHistory()
  const birthdayListContainer = useRef()
  const defaultSortOption = {key: 'id', value: 'ID'}

  const handleGetEmployeesBirthday = (queryParams, callback, callbackErr) => {
    getEmployeesBirthday(month + 1, queryParams, callback, callbackErr)
  }

  const {data, page, setPage, isLastPage} = useFetchWithConditions(
    defaultSortOption,
    10,
    handleGetEmployeesBirthday
  )

  const handleScroll = e => {
    // return if it is last page
    if (isLastPage) return

    // return if the employee list reached its maximum limit for avoid more request to backend
    if (len(data) >= limitEmployees) return

    // if the scroll is reseted by others handlers to top 0 then returns, because it isn't
    // a scroll of the employee list
    const container = birthdayListContainer.current
    if (!container) return
    if (isEqual(container.scrollTop, 0)) return

    // if the scroll is in the bottom of the container update page
    if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
      setPage(page + 1)
    }
  }

  return (
    <Container>
      <h3>Cumpleaños de {getMonth(month)}</h3>
      {isEmpty(data) ? (
        <BirthdayEmptyState />
      ) : (
        <div
          className="birthday-list"
          ref={birthdayListContainer}
          onScroll={handleScroll}
        >
          {data.map(employee => (
            <EmployeeBirthday
              key={employee.id}
              employeeID={employee.id}
              first_name={employee.first_name}
              middle_name={employee.middle_name}
              last_name={employee.last_name}
              surname={employee.surname}
              gender={employee.gender}
              thumbnail={employee.thumbnail}
              month={month}
              birthday={employee.birthday}
              onClick={() => {
                history.push(
                  joinRoute(
                    routes.employees.base,
                    routes.employees.query,
                    employee.employee_hash
                  )
                )
              }}
            />
          ))}
        </div>
      )}
    </Container>
  )
}

export default DashboardBirthday
