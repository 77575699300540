import React, {forwardRef} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import BranchOfficeForm from '../Forms/BranchOfficeForm'

const BranchOfficeModal = forwardRef((props, ref) => {
  const handleCancel = () => ref.current.closeModal()

  return (
    <ModalTemplate ref={ref}>
      <h3 className="s-center s-mb-24px">Nueva sucursal</h3>
      <BranchOfficeForm onCancel={handleCancel} />
    </ModalTemplate>
  )
})

export default BranchOfficeModal
