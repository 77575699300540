import React from 'react'
import Icon from '../../Atoms/Icon'
import SelectionButtonItem from '../../Atoms/Items/SelectionButtonItem'
import FloatContainer from '../../Wrappers/FloatContainer'

const SelectButton = ({
  isDisable = false,
  className='',
  classButton = '',
  iconName = '',
  description = '',
  options = [],
}) => {
  return (
    <div className={`s-relative ${className}`}>
      <FloatContainer
        container={{
          className: `float-container s-top-0 s-right-0 ${isDisable && 'hidden'}`,
        }}
      >
        <button
          type="button"
          className={`button ${classButton} ${iconName && 'icon'}`}
          disabled={isDisable}
        >
          <div className="s-cross-center s-main-center">
            {iconName && <Icon svg={iconName} className="s-mr-4px" />}
            <span>{description}</span>
          </div>
        </button>
        {!isDisable && (
          <div>
            {Array.isArray(options) &&
              options.map(option => {
                if (!option.isVisible) return null

                return (
                  <SelectionButtonItem
                    key={option?.key}
                    text={option?.description}
                    onClick={option?.onClick}
                    icon={option?.icon}
                  />
                )
              })}
          </div>
        )}
      </FloatContainer>
    </div>
  )
}

export default SelectButton
