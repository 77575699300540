import React from 'react';
import ActionTextButton from "../Templates/ActionTextButton";

const SeeTextButton = ({icon = "eye", text="Ver", className, onClick}) => {
  return (
    <ActionTextButton
      className={`${className}`}
      onClick={onClick}
      icon={icon}
      text={text}
    />
  );
};

export default SeeTextButton;
