import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ElectronicPayrollSkeleton = () => (
  <div className="ed-grid rows-gap">
    <Skeleton height={120} />
    <Skeleton height={500} />
  </div>
)

export default ElectronicPayrollSkeleton
