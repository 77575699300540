import React from 'react'

// Este componente se usa para encapsular todo el contenido que debe desplazarse en dispositivos móviles
// Por ejemplo, la información de un empleado
const TranslatePhonePanel = ({className, children}) => {
  return (
    <div className={`off-canvas ${className}`}>
      {children}
    </div>
  );
};

export default TranslatePhonePanel

