import React, {forwardRef} from 'react'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import XMLViewer from 'react-xml-viewer-2'

const ElectronicPayrollXMLModal = forwardRef(({xml}, ref) => {

  return (
    <ModalTemplate ref={ref} className="jumbo">
      <h3>Documento XML</h3>
      <div className='smaller'>
        <XMLViewer xml={xml} collapsible invalidXml={<p>Cargando...</p>}/>
      </div>
    </ModalTemplate>
  )
})

export default ElectronicPayrollXMLModal
