import React, {useEffect, useState, useContext, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useMedia} from '../../../hooks/useMedia'
import {mediaToM} from '../../../helpers/media-queries'
import PropTypes from 'prop-types'
import Icon from '../../Atoms/Icon'
import PayrollHeaderWrapper from '../../Wrappers/PayrollHeaderWrapper'
import MonthYearPickerInput from '../../Molecules/Pickers/MonthYearPickerInput'
import {getMonth, readBackendDate} from '../../../helpers/dates'
import {PayrollContext} from '../../../contexts/contexts'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {isEqual, isUndefined} from '../../../helpers/utils'
import {COLOMBIA_CODE} from '../../../helpers/country'
import TooltipContainer from '../Containers/TooltipContainer'
import Tooltip from '../../Atoms/Templates/Tooltip'
import {closeEmployerMonth, setMonthActive} from '../../../services/employermonth'
import {notifyError, notifySuccessful} from '../../../services/notification'
import Tag from '../../Atoms/Tag'
import ConfirmModal from '../Modals/ConfirmModal'
import SelectButton from '../../Molecules/Selects/SelectButton'
import SocialSecurityModal from '../Modals/SocialSecurityModal'
import routes, { payrollDateRoute } from '../../../config/routes'
import useObjectURL from '../../../hooks/useObjectURL'
import { generateMonthlyTaxReport } from '../../../services/reportsTemp'

const PayrollHeader = ({
  employerMonthID,
  maxMonth,
  isActiveMonth,
  isMigratedMonth,
  periods,
}) => {
  const history = useHistory()

  const employerSession = useSelector(employerSelectors.getSession)
  const {payrollDate} = useContext(PayrollContext)
  const toM = useMedia(mediaToM)
  const dispatch = useDispatch()

  const confirmModalCloseMonth = useRef()
  const socialSecurityModal = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [isAllPeriodClosed, setIsAllPeriodClosed] = useState(false)
  
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const [fileNameDownloaded, setFileNameDownloaded] = useState('')
  const {setObject, objectURL} = useObjectURL(null)
  const downloadLinkReport = useRef()

  useEffect(() => {
    setIsAllPeriodClosed(false)

    const periodFound = periods.find(p => p.is_active)

    if (isUndefined(periodFound)) {
      setIsAllPeriodClosed(true)
    }
  }, [periods])

  const handleChangeDate = date => {
    history.push(payrollDateRoute({year:date.getFullYear(),month:date.getMonth() + 1}))
  }

  const handleOpenConfirmModalCloseMonth = () => {
    confirmModalCloseMonth.current.openModal()
  }

  const handleCloseMonth = () => {
    setIsLoading(true)

    closeEmployerMonth(
      employerMonthID,
      response => {
        const nextMonth = {year: response.year_number, month: response.month_number}

        setIsLoading(false)
        dispatch(setMonthActive(response))
        dispatch(notifySuccessful('El mes fue cerrado correctamente 🎉'))
        
        history.push(payrollDateRoute(nextMonth))
      },
      error => {
        setIsLoading(false)
        dispatch(notifyError(error))
      }
    )
  }

  const handleOpenSocialSecurityModal = () => {
    socialSecurityModal.current.openModal()
  }

  const handleElectronicPayroll = () => {
    history.push(`${payrollDateRoute(payrollDate)}${routes.payroll.payrollElectronic}`)
  }

  const handleDownloadReportTax = () => {
    setIsLoadingDownloadReport(true)

    generateMonthlyTaxReport(payrollDate?.year, payrollDate?.month)
      .then(({data, headers}) => downloadReport(data, headers))
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  const downloadReport = (file, headers) => {
    setObject(file)
    setFileNameDownloaded(headers['x-file-name'])

    downloadLinkReport.current.click()

    // after of clicked then reset the state
    setObject(null)
    setFileNameDownloaded('')
  }

  return (
    <PayrollHeaderWrapper className="date-picker">
      <label className="s-relative">
        <h1 className="t2 s-mb-0 s-cross-center s-cross-center nowrap hover-change cursor-pointer normal-radius s-px-8px s-py-4px">
          <Icon className="s-mr-8px" svg="calendar" color="currentColor" />
          <span className="s-mr-8px">{`${getMonth(payrollDate.month - 1)} del ${
            payrollDate.year
          }`}</span>
          <Icon size="1rem" svg="down" />
        </h1>
        <MonthYearPickerInput
          id="payroll-date"
          minDate={readBackendDate(employerSession.first_pay_period_migration)}
          maxDate={new Date(maxMonth.year, maxMonth.month - 1, 1)}
          value={new Date(payrollDate.year, payrollDate.month - 1, 1)}
          onChange={handleChangeDate}
        />
      </label>

      <div className={`s-column m-row s-cross-center s-flex-gap-16px ${toM && 's-100'}`}>
        {isMigratedMonth && <Tag text="Mes Migrado" color="red" hasCircle></Tag>}

        {isEqual(employerSession.code_iso_3, COLOMBIA_CODE) && (
            <SelectButton
              iconName="document"
              description="Reportes"
              className={`${toM && 's-100'}`}
              classButton={`ghost ${toM && 's-100'}`}
              isDisable={isLoadingDownloadReport}
              options={[
                {
                  key: 1,
                  description: 'Bases de retención',
                  icon: 'excel',
                  onClick: handleDownloadReportTax,
                  isVisible: true,
                }
              ]}
            />
          )}

        {isActiveMonth && (
          <TooltipContainer className={`${toM && 's-100'}`}>
            <button
              className={`button  ${toM && 's-100'}`}
              disabled={isLoading || !isAllPeriodClosed}
              onClick={handleOpenConfirmModalCloseMonth}
            >
              <div className="s-cross-center s-main-center">
                <Icon svg="lock-open" className="s-mr-4px" />
                <span>{isLoading ? 'Cerrando...' : 'Cierra el mes'}</span>
              </div>
            </button>

            {!isAllPeriodClosed && (
              <Tooltip>
                <div className="whitespace-nowrap">
                  <span>Primero debes:</span>
                  <p className="s-mb-0 smaller s-color-lighter-text">
                    {isLoading ? 'Cerrando...' : 'Cerrar todos tus periodos'}
                  </p>
                </div>
              </Tooltip>
            )}
          </TooltipContainer>
        )}

        {/* social security, electronic payroll and interface*/}
        {!isActiveMonth &&
          payrollDate.year >= 2023 &&
          isEqual(employerSession.code_iso_3, COLOMBIA_CODE) && (
            <SelectButton
              iconName="cube"
              description="Actividades"
              classButton={`${toM && 's-100'}`}
              options={[
                {
                  key: 1,
                  description: 'Seguridad social',
                  icon: 'shield',
                  onClick: handleOpenSocialSecurityModal,
                  isVisible: true,
                },
                {
                  key: 2,
                  description: 'Nómina electrónica',
                  icon: 'rectangle-group',
                  onClick: handleElectronicPayroll,
                  isVisible: true,
                },
                // {
                //   key: 3,
                //   description: (
                //     <span>
                //       <span className="s-mr-1">Interfaz Contable</span>
                //       <Tag text="⭐ Muy Pronto" color="blue" />
                //     </span>
                //   ),
                //   icon: 'folder',
                //   onClick: () => {
                //     console.log('interfaz')
                //   },
                //   isVisible: true,
                // },
              ]}
            />
          )}
      </div>

      {/* Modal para cerrar el periodo */}
      <ConfirmModal
        ref={confirmModalCloseMonth}
        hasCodeConfirmation
        confirmFunction={handleCloseMonth}
        title="Cierra el Mes"
        content={
          <>
            ¿Estás seguro de cerrar el mes{' '}
            <span className="s-color-title">
              {`${getMonth(payrollDate.month - 1)} del ${payrollDate.year}`}
            </span>
            ? Esta acción no se puede revetir.
          </>
        }
      />

      {/* SocialSecurity modal */}
      <SocialSecurityModal
        ref={socialSecurityModal}
        year={payrollDate.year}
        month={payrollDate.month}
      />

      {/* This link is hidden, we only use for download the payroll report */}
      <a
        ref={downloadLinkReport}
        href={objectURL}
        download={fileNameDownloaded}
        className="hidden"
      >
        file downloaded
      </a>      
    </PayrollHeaderWrapper>
  )
}

PayrollHeader.propTypes = {
  maxMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
  }).isRequired,
}

export default PayrollHeader
