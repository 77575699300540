import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/absences-relation'

export const getAbsenceNoveltiesByHashProcess = (processHash, params, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/processes/${processHash}?${params}`, callback)
}

export const createAbsenceNovelty = (payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, response => callback(response))
}

export const updateAbsenceNovelty = (noveltyID, payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${noveltyID}`, payload, response => callback(response))
}

export const deleteAbsenceNovelty = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${noveltyID}`, callback)
}

export const getAbsenceNoveltyByID = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/${noveltyID}`, callback)
}
