import React from 'react'
import MainMenu from './MainMenu'
import SidebarHeader from './Headers/SidebarHeader'
import CompanySelector from '../Molecules/CompanySelector'
import FloatContainer from '../Wrappers/FloatContainer'

const MainSidebar = () => {
  return (
    <aside className="main-sidebar s-column nowrap transform-transition s-left-0 s-column s-bg-container s-px-16px s-py-24px z-header s-border-right-border lg-border-right-transparent">
      <FloatContainer>
        <SidebarHeader />
        <CompanySelector />
      </FloatContainer>
      <MainMenu />
    </aside>
  )
}

export default MainSidebar
