import React, {useState, useContext} from 'react'
import {useEffect} from 'react'
import Icon from '../components/Atoms/Icon'
import Tooltip from '../components/Atoms/Templates/Tooltip'
import ErrorCard from '../components/Molecules/ErrorCard'
import TooltipContainer from '../components/Organisms/Containers/TooltipContainer'
import Container from '../components/Wrappers/Container'
import {PayrollContext} from '../contexts/contexts'
import {readBackendDateAndTime} from '../helpers/dates'
import {ACTION_RUN} from '../helpers/payroll'
import {fullName} from '../helpers/string-format'
import {isEqual, isNull, len} from '../helpers/utils'
import {getLastHistoryByHashAndAction} from '../services/processRelation'

const defaultMessageError = (
  <>
    Se presentaron los siguientes errores cuando calculabamos <span className="s-color-title">todos</span> los
    contratos, por favor corrigelos e intenta de nuevo.
  </>
)

const Errors = ({errors}) => {
  const {activeProcess} = useContext(PayrollContext)
  const [messageError, setMessageError] = useState(defaultMessageError)

  useEffect(() => {
    getLastHistoryByHashAndAction(activeProcess?.hash, ACTION_RUN, resp => {
      setMessageError(defaultMessageError)

      if (!isNull(resp?.contract_ids)) {
        setMessageError(getMessageErrorForContracts(resp))
      }
    })
  }, [errors, activeProcess.hash])

  return (
    <main className="ed-grid row-gap">
      <Container className="s-cross-start nowrap">
        <div className="s-mr-8px">
          <TooltipContainer className="s-cross-center">
            <Icon svg="information" color="var(--red-accent)" size="24px" />
            <Tooltip>
              <p className="s-mb-0 whitespace-nowrap">
                Soluciona los errores <br />y calcula de nuevo el proceso
              </p>
            </Tooltip>
          </TooltipContainer>
        </div>
        <div>
          <p className="s-color-text s-mb-0">{messageError}</p>
        </div>
      </Container>

      <div className="ed-grid rows-gap">
        {Array.isArray(errors) &&
          errors.map(error => {
            return (
              <ErrorCard
                key={error.id}
                type={error.type}
                description={error.description}
                createdAt={readBackendDateAndTime(error.created_at)}
              />
            )
          })}
      </div>
    </main>
  )
}

const getMessageErrorForContracts = processHistory => {
  const size = len(processHistory?.contract_ids)

  return (
    <>
      Se presentaron los siguientes errores cuando calculabamos {isEqual(size, 1) ? 'el contrato' : 'los contratos'} de{' '}
      <span className="s-color-blue">{processHistory?.employees.map(e => fullName(e)).join(', ')}</span>
      {size > 2 && (
        <>
          {' '}
          y <span className="s-color-blue">{`${size - 2} empleado(s) más`}</span>
        </>
      )}
      , por favor corrigelos e intenta de nuevo.
    </>
  )
}

export default Errors
