import React from 'react'
import DatePicker, {registerLocale} from 'react-datepicker'
import es from 'date-fns/locale/es'
import PropTypes from 'prop-types'

registerLocale('es', es)

const MonthPickerInput = ({
  label,
  className,
  isRequired,
  id,
  name,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div className={`form-item month-picker ${className}`}>
      <DatePicker
        id={id}
        name={name}
        selected={value}
        onChange={onChange}
        dateFormat="MMMM"
        showMonthYearPicker
        required={isRequired}
        className="date-picker"
        locale="es"
        {...rest}
      />
      {label && (
        <label htmlFor={id} className={isRequired && 'required'}>
          {label}
        </label>
      )}
    </div>
  )
}

MonthPickerInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  id: PropTypes.string,
}

MonthPickerInput.defaulProps = {
  isRequired: false,
  id: '',
}

export default MonthPickerInput
