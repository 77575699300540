import React from 'react'
import CreditCard from '../../../static/images/stories/credit-card.svg'

const ContractPaymentPanelEmptyState = ({className}) => {
  return (
    <div className={`s-column s-cross-center s-main-center ${className}`}>
      <div />
      <img
        className="s-40 m-20 lg-50 s-mb-12px"
        src={CreditCard}
        alt="tarjeta de crédito"
        width="200px"
        height="200px"
      />
      <p className="small s-color-light-text s-center s-mb-6px">
        Aún no has creado un método de pago.
      </p>
    </div>
  )
}

export default ContractPaymentPanelEmptyState
