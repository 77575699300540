import React from 'react';
import Loader from "../Atoms/Loader";

const FullPageLoader = () => {
  return (
    <div className="full-page-loader s-w-full s-h-full s-cross-center s-main-center s-fixed s-bg-body z-modal s-top-0 s-left-0">
      <Loader />
    </div>
  );
};

export default FullPageLoader;
