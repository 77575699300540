import React, {useContext, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {AccordionWrapperContext} from '../../contexts/contexts'
import { isEqual, isNull } from '../../helpers/utils'

const AccordionTemplate = props => {
  const {children, contractFromChildren, isOpen, index, className, isContentsLoaded, trigger} = props
  // Context para obtener el acordeon activo en un sistema de acordeon
  const {currentAccordionActive, setCurrentAccordionActive} = useContext(
    AccordionWrapperContext
  )
  // Valida si el acordión se encuentra en un context
  const isAccordionContext = currentAccordionActive !== null
  // Valida si es el acordeon activo en el context
  const isCurrent = currentAccordionActive === index

  // Contenedor referencia que usaremos para conseguir la altura
  const container = useRef()
  const [height, setHeight] = useState(0)
  const [expanded, setExpanded] = useState(isOpen || isCurrent)

  // Función para obtener la altura del contenedor a ocultar
  const getContainerHeight = () => {
    if (container.current) {
      setHeight(Math.round(container.current.getBoundingClientRect().height))
    }
  }

  // Función para abrir y cerrar el acordeón
  const handleExpand = () => setExpanded(!expanded)

  const handleExpandContext = () => {
    if (index === currentAccordionActive) handleExpand()
    else {
      setExpanded(true)
      setCurrentAccordionActive(index)
    }
  }

  const handleManagementExpand = () => { 
    isAccordionContext ? handleExpandContext() : handleExpand()
  }

  const handleClick = () => {
    if (!isNull(trigger)) return

    handleManagementExpand()
  }
  
  useEffect(getContainerHeight, [height, isContentsLoaded])

  // Escucha si un acordión dentro de un context se abre, para así cerrarse
  useEffect(() => {
    if (isAccordionContext) {
      if (index !== currentAccordionActive) setExpanded(false)
    }
  }, [currentAccordionActive])

  // Escucha si el manejador para contraer y expandir el accordion es ejecutado desde afuera
  useEffect(() => {
    if (isNull(trigger) ||  isEqual(trigger, 0)) return

    handleManagementExpand()
  }, [trigger])

  return (
    <>
      {/* En el primer contenedor irán los elementos que se usarán como trigger para activar el acordeón*/}
      <div
        onClick={handleClick}
        className={`accordion-trigger ${expanded && 'expanded'} ${className}`}
      >
        {children[0]}
      </div>
      {/*  En el segundo contenedor irán los elementos que se quieren ocultar */}
      <div
        className="accordion-container s-overflow-hidden height-transition"
        // Si el acordeón está expandido poner la altura que obtuvimos, si no dejarlo en 0 para ocultar los elementos
        style={{height: expanded ? `${height}px` : '0'}}
      >
        <div ref={container} onClick={contractFromChildren ? handleExpand : null}>
          {children[1]}
        </div>
      </div>
    </>
  )
}

AccordionTemplate.propTypes = {
  index: PropTypes.number,
  children: PropTypes.node.isRequired,
  contractFromChildren: PropTypes.bool,
  isOpen: PropTypes.bool,
  isContentsLoaded: PropTypes.bool,
  trigger: PropTypes.number,
}

AccordionTemplate.defaultProps = {
  index: null,
  contractFromChildren: false,
  isOpen: false,
  isContentsLoaded: false,
  trigger: null,
}

export default AccordionTemplate
