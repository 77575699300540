import {PROCESS_TYPE_RUN_BY_PERIOD, STATUS_PRE_PAYROLL} from './payroll'
import {isAnyEqual, isEmpty, isEqual} from './utils'

export const hasUniqueProcessTypeInPeriod = (configProcessTypes, processes, periodId) => {
  if (!Array.isArray(configProcessTypes) || !Array.isArray(processes) || isEmpty(processes)) return false

  const uniqueProcessTypes = configProcessTypes.filter(
    t => isEqual(t?.config?.rules?.run?.run_by, PROCESS_TYPE_RUN_BY_PERIOD) && t?.config?.rules?.run?.is_unique
  )

  if (isEmpty(uniqueProcessTypes)) return true

  for (const processType of uniqueProcessTypes) {
    const isFound = processes.some(
      p => isEqual(p.process_type_code, processType.code) && isEqual(p.pay_period_id, periodId)
    )

    if (!isFound) return false
  }

  return true
}

export const hasCloseAllProcessesInPeriod = (configProcessTypes, processes, periodId) => {
  if (!Array.isArray(configProcessTypes) || !Array.isArray(processes) || isEmpty(processes)) return false

  const processTypeByPeriod = configProcessTypes.filter(t =>
    isEqual(t?.config?.rules?.run?.run_by, PROCESS_TYPE_RUN_BY_PERIOD)
  )

  if (isEmpty(processTypeByPeriod)) return true

  const codes = []
  processTypeByPeriod.forEach(element => {
    codes.push(element.code)
  })

  return !processes.some(
    p =>
      isEqual(p.status, STATUS_PRE_PAYROLL) &&
      isAnyEqual(p.process_type_code, codes) &&
      isEqual(p.pay_period_id, periodId)
  )
}

export const hasPaidAllProcessesInPeriod = (configProcessTypes, processes, periodId) => {
  if (!Array.isArray(configProcessTypes) || !Array.isArray(processes) || isEmpty(processes)) return false

  const processTypeByPeriod = configProcessTypes.filter(t =>
    isEqual(t?.config?.rules?.run?.run_by, PROCESS_TYPE_RUN_BY_PERIOD)
  )

  if (isEmpty(processTypeByPeriod)) return true

  const codes = []
  processTypeByPeriod.forEach(element => {
    codes.push(element.code)
  })

  return !processes.some(
    p => !p.is_paid_full && isAnyEqual(p.process_type_code, codes) && isEqual(p.pay_period_id, periodId)
  )
}

export const canClosePeriod = (configProcessTypes, processes, periodId) => {
  return (
    hasUniqueProcessTypeInPeriod(configProcessTypes, processes, periodId) &&
    hasCloseAllProcessesInPeriod(configProcessTypes, processes, periodId) &&
    hasPaidAllProcessesInPeriod(configProcessTypes, processes, periodId)
  )
}
