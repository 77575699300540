import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {TabsWrapperContext} from '../../../contexts/contexts'

const TabTemplateBasic = ({index, children, className, onClick}) => {
  const {tabActive} = useContext(TabsWrapperContext)
  const isActive = tabActive === index

  return (
    <div onClick={onClick} className={`${className} ${isActive && 'active'}`}>
      {children}
    </div>
  )
}

TabTemplateBasic.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

TabTemplateBasic.defaultProps = {
  className: '',
}

export default TabTemplateBasic
